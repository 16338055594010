<template>
  <div>
    <div  v-show="!open">
      <div class="row open" v-if="names.length">
          <div class="col-6 col-lg-4 d-inline-flex border-checkbox-section">
            <div class="border-checkbox-group border-checkbox-group-primary">
              <input
                  type="checkbox"
                  style="padding-top: 0"
                  v-model="checked"
                  @change="disableNames"
                  class="border-checkbox"
                  id="checkbox-translation-min"
              />
              <label class="border-checkbox-label" for="checkbox-translation-min"><span>{{ $t("name_translate.also_look_for_translations") }}: </span></label>
            </div>
        </div>

        <div  class="col-6 col-lg-8">
          <div style="display: flex; flex-direction: column; width: 100%" v-for="(name, index) in names" :key="index">{{name}}</div>
        </div>
      </div>
    </div>

    <div v-show="open" class="settings-block">
        <div class="row">
          <div class="col-12 col-lg-3 settings-text border-checkbox-section" style="display: flex; margin-top: 0">
            <div class="border-checkbox-group border-checkbox-group-primary">
              <input type="checkbox" class="border-checkbox" id="checkbox-translation" v-model="checked" @change="disableNames(!checked)">
              <label for="checkbox-translation" class="border-checkbox-label">
                <h4 class="title-search-name">{{ $t("name_translate.also_look_for_translations") }}</h4>
              </label>
            </div>
          </div>


<!--          <h4 class="col-lg-3 settings-text"-->
<!--              style="white-space: nowrap; overflow-x: hidden; justify-content: center;margin-bottom: 0; text-overflow: ellipsis;">{{ $t("name_translate.also_look_for_translations") }}</h4>-->
          <div class="col-lg-9">
            <b-form-tags  class="b-form-tags form-control h-auto"
                          addButtonText="+"
                          ref="namesInput" type="text"
                          :before-adding="beforeAdding" v-model="names"
                          icon="info-circle" :placeholder="this.$t('name_translate.add_name')"
                          :aria-close-label="this.$t('name_translate.remove_name')" :ellipsis="false">
            </b-form-tags>
          </div>
        </div>
    </div>

  </div>
</template>






<script>
import { mapGetters } from 'vuex';

export default {
  name: "nameTranslate",
  props: ['searchPayload','open'],
  data() {
    return {
      names: [],
      timeout: null,
      isFullPage: false,
      checked: true
    }
  },
  computed: {
    ...mapGetters('translation', ['translation']),
  },
  methods: {

    disableNames(val){
      this.$store.dispatch('nameSearchAdditionalSettings/changeAltValueState', val)
    },

    beforeAdding(tag){
      let val = this.eachWordToUpperCase(tag);
      if (/[0-9]/.test(val)) {
        this.$buefy.toast.open({
          message: this.$t('name_translate.invalid_characters_in_name'),
          type: 'is-danger'
        })
      } else {
        return val
      }
    },
    getTranslation(value) {
      // let loadingComponent = this.$buefy.loading.open({
      //   container: this.isFullPage ? null : this.$refs.namesInput.$el
      // })
      this.$store.dispatch('translation/getTranslation', {val: value, lang: 'en'})
        .then((resp) => {
          this.disableNames(true)
          this.checked = true;
          let temp = []
          let re = /&#39;/gi;
          resp.forEach(word=>{
            let newWord;
            newWord = word.replace(re, '’')
            temp.push(newWord)
          })
          this.names = this.names.concat(temp);
          // loadingComponent.close()
        })
        this.$store.dispatch('translation/getTranslation', {val: value, lang: 'uk'})
        .then((resp) => {
          this.disableNames(true)
          this.checked = true;
          let temp = []
          let re = /&#39;/gi;
          resp.forEach(word=>{
            let newWord;
            newWord = word.replace(re, '’')
            temp.push(newWord)
          })
          this.names = this.names.concat(temp);
          // loadingComponent.close()
        })
    },
    clearForm(){
      this.names = [];
    },

    eachWordToUpperCase(val){
      if (val && val.length && val !== ' '){
        return  val.trim().split(/\s+/).map(word => word[0].toUpperCase() + word.substring(1)).join(' ')
      }
    },

  },
  watch: {
    names(){
      this.$store.dispatch('nameSearchAdditionalSettings/nameFilters', this.names)
    },
    'searchPayload.value': function (value) {
          clearTimeout(this.timeout);
          let self = this;
          this.timeout = setTimeout(function () {
            let val = self.eachWordToUpperCase(value);
            if (val  &&  val.length) {
              self.getTranslation(val);
            } else {
              self.names = [];
            }
          }, 1500)
    }
  },
  mounted() {
    if(this.searchPayload.value.length) {
      this.getTranslation(this.searchPayload.value)
    }
  }
}
</script>
<style lang="scss" >
.b-form-tag{
  background-color: #4099ff;
  border-radius: 2px !important;
  padding-right: 0;
}
.b-form-tag-remove{
  background-color: #4099ff;
  border: none;
}
.b-form-tag-content{
  margin-bottom: 4px;
}
.settings-block{
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 6px;
}
//.selectCheckbox{
//  margin-top: 2px !important;
//  @media (min-width: 923px) {
//    margin-top: 5px!important;
//
//  }
//}
.settings-title{
  white-space: nowrap !important;
}
.open{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 8px;
  padding-bottom: 8px;

}

.title-search-name {
  margin-bottom: 0;
  //white-space: nowrap;
  overflow-x: hidden;
  justify-content: center;
  margin-bottom: 0;
  text-overflow: ellipsis;
  font-size: 16px;
}
</style>