import {mapGetters} from "vuex";
import chunkArray from "@/helpers/chunk";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import PdfPrompt from "@/components/PdfPrompt";
import moment from "moment/moment";
import i18n from "@/i18n";
import _, { includes } from 'underscore';
import {ImageCaching} from "@/mixins/imageClass";

export default {
    data() {
        return {
            resizedImages: 0,
            content: {},
            startedExport: false,
            promptPdfActive: false,
            formProps: {
                filename: null,
                password: null
            },
            fileName: '',
            pdfPassword: 'test',
            needPass: true,
            watermark: true
        }
    },
    components: {
      PdfPrompt
    },
    computed: {
        ...mapGetters('images', ['images', 'allImages','photoLoaded']),
        ...mapGetters('auth', ['user']),

        photosCount() {
            let photoCount = 0;
            for(let result of this.results) {
                for(let sr of result.search_results) {
                    photoCount += sr.photos.length;
                }
            }
            return photoCount
        }

    },
    methods: {
        pdfUpdateData(event) {
            this.fileName = event.filename;
            this.pdfPassword = event.password;
            this.needPass = event.passwordEnable;
            this.watermark = event.watermark;
            this.beforeDownload();
        },
        getLevels(){
            let levels = []
            let levelOne = this.results.filter((item) => item.level === 1);
            let levelTwo = this.results.filter((item) => item.level === 2);
            let levelThree = this.results.filter((item) => item.level === 3)
            if (levelOne.length){
                levels.push(levelOne)
            }
            if (levelTwo.length){
                levels.push(levelTwo)
            }
            if (levelThree.length){
                levels.push(levelThree)
            }

            if(!levels.length) {
                levels.push(this.results)
            }

            let tempArr = [];

            levels.forEach(level => {
                level.forEach(item => {
                    if (tempArr.length){
                        let  pos = tempArr.map(function(e) { return e.value; }).indexOf(item.value);
                        if (pos === -1){
                            let obj = {
                                value: item.value,
                                level: item.level,
                                items: []
                            }
                            obj.items.push(item)
                            tempArr.push(obj)
                        } else {
                            tempArr[pos].items.push(item)
                        }
                    } else {
                        let obj = {
                            value: item.value,
                            level: item.level,
                            items: []
                        }
                        obj.items.push(item)
                        tempArr.push(obj)
                    }
                })
            })
            return tempArr;
        },


        isEnyResultInLevel(level){
            let count = 0;
            level.items.forEach(item => {
                if (item.search_results){
                    count += item.search_results.length
                }
            })
            return count > 0
        },



      async  updateContent() {
                let resizedPhotos = 0;
                let content = [];
                let head = true;
                let levels = this.getLevels();
                for(let level of levels){
                    
                    if (this.isEnyResultInLevel(level)){
                        if(level.items[0].param === 'image' && head) {
                            content.push({
                                text: this.$t('search.search_by_photo'),
                                style: 'header'
                            });
                            const imageCache = new ImageCaching();
                            const imageBase = await imageCache.loadNonComponentImage(level.items[0].value)
                            let mainPhoto =  {
                                image: await this.resizeImage(imageBase),
                                width: 230
                            }
                            content.push(mainPhoto);
                            head = false;
                        } else {
                            if(head) {
                                content.push({
                                    text: this.$t('search.search_results'),
                                    style: 'header'
                                });
                                content.push({
                                    text: level.items[0].value,
                                    style: 'subheading'
                                });
                            }
                            head = false;

                            if(this.schemaPic) {
                                content.push(
                                    {
                                        image: this.schemaPic,
                                        width: 500
                                    }
                                )
                            }
                            if (typeof level.value !== 'undefined'){
                                let text = this.$t('result.stage')+' '+level.level+': '+ this.$t('result.search_by') +' '+ level.value
                                content.push({
                                    text: text,
                                    style: 'header'
                                });
                            }
                        }
                        for (let result of level.items) {
                            for(let k in result.search_results) {
                                let stack = {
                                    stack: []
                                }
                                let middleArray = stack.stack;
                                let table = {
                                    table: {
                                        layout: 'noBorders',
                                        widths: [80, 'auto'],
                                        header: ['header'],
                                        body: [],
                                    }
                                };
                                if(result.search_results[k].data.length > 0) {
                                    for(let infoRow of result.search_results[k].data) {
                                        infoRow = this.setAddressLocation(result.search_results[k].data, infoRow)
                                        if(['status', 'subscriber_status'].includes(infoRow.param)) {
                                            table.table.body.push([{text: this.$options.filters.capitalize(this.$t('params.'+infoRow.param)), bold: true}, infoRow.value])
                                        } else {
                                            if(typeof infoRow.value !== 'undefined') {
                                                if(infoRow.param === 'social_url') {
                                                    table.table.body.push(
                                                        [
                                                            {
                                                                text:
                                                                    this.$options.filters
                                                                        .capitalize(this.$t('params.'+infoRow.param)),
                                                                bold: true
                                                            },
                                                            {link: infoRow.value, text: this.splitter(infoRow.value, 30)}
                                                        ]
                                                    )
                                                } else {
                                                    if(!['latitude','longitude'].includes(infoRow.param)) {
                                                        table.table.body.push([{text: this.$options.filters.capitalize(this.$t('params.'+infoRow.param)), bold: true}, infoRow.value ])
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (typeof  result.search_results[k].locations !== 'undefined'){
                                        for(let locationInfo of result.search_results[k].locations) {
                                            let index = 1;
                                            let scecifyingIndex =  this.$t('geo.specifiing') +' # '+ index
                                            let time = moment(locationInfo.info_date, 'X').format('DD.MM.YYYY HH:mm:ss')
                                            let val = time +' '+ locationInfo.address +','+ ( locationInfo.latitude +',' +  locationInfo.longitude)
                                            table.table.body.push([{text: scecifyingIndex, bold: true}, val])
                                            index++
                                        }
                                    }
                                } else {
                                    table.table.body.push([{text: this.$options.filters.capitalize(this.$t('params.'+'name')), bold: true}, 'Нет имени'])
                                }
                                if (result.type === 'database' || result.source === 'FaceRecognition'){
                                    middleArray.push({
                                        text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                        style: 'header'
                                    })
                                } else {
                                    middleArray.push({
                                        text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                        style: 'header'
                                    })
                                }

                                if(result.search_results[k].info_date) {
                                    let text;
                                    if (Number.isInteger(result.search_results[k].info_date)){
                                        text = moment(result.search_results[k].info_date, 'X').format('DD.MM.YYYY, HH:mm')
                                    } else if (result.search_results[k].info_date.length === 10 && result.search_results[k].info_date.includes('-')){
                                        text = moment(result.search_results[k].info_date, 'YYYY-MM-DD').isValid() 
                                            ? moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('HH:mm') === '00:00' ?
                                            moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('DD.MM.YYYY') :
                                            moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('DD.MM.YYYY HH:mm')
                                            : result.search_results[k].info_date

                                    } else if(result.search_results[k].info_date.length === 10 && result.search_results[k].info_date.includes('.')) {
                                        text = moment(result.search_results[k].info_date, 'DD.MM.YYYY').isValid() 
                                            ? moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('HH:mm') === '00:00' ?
                                            moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('DD.MM.YYYY') :
                                            moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
                                            : result.search_results[k].info_date
                                    } else {
                                        if (!moment(result.search_results[k].info_date).isValid()){
                                            text = result.search_results[k].info_date;
                                        } else {
                                            text = moment(result.search_results[k].info_date).format('HH:mm') === '00:00' ?
                                                moment(result.search_results[k].info_date).format('DD.MM.YYYY') :
                                                moment(result.search_results[k].info_date).format('DD.MM.YYYY HH:mm');
                                        }
                                    }
                                    middleArray.push({
                                        text: text
                                    });
                                }
                                middleArray.push(table);
                                if(result.search_results[k].photos && result.search_results[k].photos.length) {
                                    let chunkSize;
                                    if (result.source === 'InternetArchive'){
                                        chunkSize = 1;
                                    } else {
                                        chunkSize = 2;
                                    }
                                    let photoArray = chunkArray(result.search_results[k].photos,chunkSize);
                                    for(let photosChunk of photoArray) {
                                        let photoRow = {
                                            columns: [],
                                            columnGap: 10,
                                            margin: [0, 10]
                                        };
                                        for(let photoObj of photosChunk) {
                                            if(photoObj && typeof photoObj.image !== 'undefined') {
                                                try {
                                                    const imageCache = new ImageCaching();
                                                    const imageBase = await imageCache.loadNonComponentImage(photoObj.image)
                                                    let tempImage = await this.resizeImage(imageBase);
                                                    let date;
                                                    if (moment(photoObj.date * 1000).format('DD.MM.YYYY, HH:mm') === 'Invalid date'){
                                                        date = photoObj.date
                                                    } else {
                                                        date = moment(photoObj.date * 1000).format('DD.MM.YYYY, HH:mm')
                                                    }
                                                    if (typeof photoObj.source_url !== 'undefined') {
                                                        let width;
                                                        if (result.source === 'InternetArchive'){
                                                            width = 500;
                                                        } else {
                                                            width = 230;
                                                        }

                                                        photoRow.columns.push(
                                                            [
                                                                {
                                                                    image: tempImage,
                                                                    width: width
                                                                },
                                                                {
                                                                    text: date
                                                                },
                                                                {
                                                                    text: photoObj.source_url,
                                                                    link: photoObj.source_url
                                                                }
                                                            ]
                                                        ) && resizedPhotos++;
                                                    } else {
                                                        photoRow.columns.push(
                                                            [
                                                                {
                                                                    image: tempImage,
                                                                    width: 230
                                                                },
                                                                {
                                                                    text: date
                                                                }
                                                            ]
                                                        ) && resizedPhotos++;
                                                    }
                                                } catch(e) {
                                                    console.log(e);
                                                }
                                            }
                                        }
                                        middleArray.push(photoRow);
                                    }
                                }
                                let tableToContent = {
                                    table: {
                                        layout: 'lightHorizontalLines',
                                        widths: ['*'],
                                        // dontBreakRows: true,
                                        // pageBreak: 'before',
                                        body: [],
                                    }
                                }
                                tableToContent.table.body.push([stack]);
                                content.push({margin: [0, 20], text: ''})
                                content.push(tableToContent)
                            }
                        }
                    }

                }

                this.content = content;
                this.resizedImages = resizedPhotos;
            return content;
        },
        promptPdf() {
            this.promptPdfActive = true;
        },
        beforeDownload () {
            this.isLoading = true;
            if(this.photoLoaded >= this.photosCount) {
                this.updateContent().then(content => {
                    let pdfDocGenerator;
                    if (this.needPass){
                         pdfDocGenerator = pdfMake.createPdf({
                            content,
                            // userPassword: this.pdfPassword ? this.pdfPassword : 'test',
                            userPassword: this.pdfPassword,
                             pageSize: 'A4',
                            watermark: { text: this.watermark ?  this.user.login : "", color: 'red', opacity: 0.1, bold: true, italics: false },
                            fonts: {
                                normal: '/fonts/fontello.ttf',
                                bold: '/fonts/fontello.ttf',
                                italics: '/fonts/fontello.ttf',
                                bolditalics: '/fonts/fontello.ttf'
                            },
                            styles: {
                                header: {
                                    fontSize: 22,
                                    bold: true,
                                    marginBottom: 15,
                                    marginTop: 15
                                },
                                subheading: {
                                    fontSize: 16,
                                    bold: true,
                                    marginBottom: 10
                                }
                            }
                        });
                    } else {
                        pdfDocGenerator = pdfMake.createPdf({
                            content,
                            watermark: { text: this.watermark ?  this.user.login : "", color: 'red', opacity: 0.1, bold: true, italics: false },
                            styles: {
                                header: {
                                    fontSize: 22,
                                    bold: true,
                                    marginBottom: 15,
                                    marginTop: 15
                                },
                                subheading: {
                                    fontSize: 16,
                                    bold: true,
                                    marginBottom: 10
                                },
                                icon: { font: 'Fontello' }
                            }
                        });
                    }
                        pdfDocGenerator.getBlob( (blob) => {
                            this.isLoading = false
                            this.$toast.success( this.$t('search.pdf_saved') , {
                                position: "top-center",
                                timeout: 5000,
                                closeOnClick: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: "button",
                                icon: true,
                                rtl: false
                            });
                            window.saveAs(blob, this.fileName + '.pdf')
                        });

                    
                })
            } else {
                this.beforeDownload();
            }
        },

         resizeImage(base64Str, maxWidth = 640, maxHeight = 640) {
                return new Promise((resolve) => {
                    let img = document.createElement('img');
                    img.src = base64Str
                    img.onload = () => {
                        let canvas = document.createElement('canvas')
                        const MAX_WIDTH = maxWidth
                        const MAX_HEIGHT = maxHeight
                        let width = img.width
                        let height = img.height

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width
                                width = MAX_WIDTH
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height
                                height = MAX_HEIGHT
                            }
                        }
                        canvas.width = Math.floor(width)
                        canvas.height = Math.floor(height)
                        let ctx = canvas.getContext('2d')
                        ctx.drawImage(img, 0, 0, width, height)
                        resolve(canvas.toDataURL())
                    }
                })
        },
        getBase64Image(img) {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL("image/jpg");
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        },
        splitter(str, l){
            const strs = [];
            while(str.length > l){
                let pos = str.substring(0, l).lastIndexOf(' ');
                pos = pos <= 0 ? l : pos;
                strs.push(str.substring(0, pos));
                let i = str.indexOf(' ', pos)+1;
                if(i < pos || i > pos+l)
                    i = pos;
                str = str.substring(i);
            }
            strs.push(str);
            return strs;
        },
        setAddressLocation(parentItems, row) {
            if(!_.isUndefined(parentItems) && row.param == 'address') {
              const addresses = _.where(parentItems, {param: 'address'});
              const lats = _.where(parentItems, {param: 'latitude'});
              const longs = _.where(parentItems, {param: 'longitude'});
              const indexAdress = _.findIndex(addresses, {value: row.value});
              if(lats.length && longs.length && lats[indexAdress].value !== '-') {
                return {param: row.param, value:`${row.value} (${parseFloat(lats[indexAdress].value).toFixed(4)}, ${parseFloat(longs[indexAdress].value).toFixed(4)})`};
              } else {
                return row;
              }
            }
            return row;
        },
        setMountedPdfAction() {
            if (this.meta && this.meta.parent && this.meta.payload.param === 'image'){
                this.fileName = 'image_search_'+ this.meta.key.split('/')[6]
            } else {
                this.fileName = this.meta.key.includes('geo_search_telegram_point') ? this.meta.key.replace('geo_search_telegram_point', 'whos_around') : this.meta.key;
            }
            this.formProps.filename = this.fileName;
        }
    },
}