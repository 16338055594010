<template>
    <div class="settingsElement">
        <div class="settingsFlex border-checkbox-section">
            <div class="border-checkbox-group border-checkbox-group-primary">
                <input type="checkbox" class="border-checkbox" :value="true" v-model="database" disabled />
                <label class="border-checkbox-label">{{ $t('search_options.search_in_db') }}</label>
            </div>
        </div>
        <div class="settingsFlex border-checkbox-section" v-show="options.iterative || options.service_types.includes('face') || options.service_types.includes('online')">
            <div class="border-checkbox-group border-checkbox-group-primary">
                <input type="checkbox" class="border-checkbox" id="check-online" :disabled="disabledOnline" v-model="online"/>
                <label class="border-checkbox-label" for="check-online">{{ $t('search_options.search_online') }}</label>
            </div>
        </div>
        <div class="settingsFlex border-checkbox-section" v-show="options.service_types.includes('hlr')">
            <div class="border-checkbox-group border-checkbox-group-primary">
                <input type="checkbox" class="border-checkbox" value="hlr" v-model="payload.service_types"
                    :disabled="!options.service_types.includes('hlr') || !this.online" id="check-hlr" />
                <label class="border-checkbox-label" for="check-hlr">{{ $t('search_options.search_in_hlr') }}</label>
            </div>

        </div>
      <div class="settingsFlex border-checkbox-section" v-if="typeof options.service_options !== 'undefined'
        && typeof payload.service_options !== 'undefined'">
        <div v-for="(option,i) in Object.keys(options.service_options)" :key="i">
          <div class="border-checkbox-group border-checkbox-group-primary"
               :key="j" v-for="(addParam, j) in options.service_options[option].request_options">
            <input type="checkbox" :ref="'serviceOptions_'+addParam.name" class="border-checkbox" @change="recount()" :disabled="!online"
                   v-model="payload.service_options[option].request_options[addParam.name]"
                   :id="`service_option_${option}_${addParam.name}`" />
            <label class="border-checkbox-label" :for="`service_option_${option}_${addParam.name}`">
              {{ $t(`searchOptions.service_option_${option}_${addParam.name}`) }}
            </label>
          </div>
        </div>

      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'underscore';

export default {
    name: 'DataSources',
    props: ['param', 'iterative'],
    data() {
        return {
            database: true,
            online: false,
            payload: {
              watch: false,
                offline: true,
                service_types: [],
                service_options: {
                  GetContact: {
                    request_options: {
                      tags: false
                    }
                  }
                }
            },
            fullData: {}
        }
    },
    computed: {
        ...mapGetters('contacts', ['searchOptions']),
        options() {
            if (this.param !== 'mass_isdn') {
                return this.searchOptions[this.param]
            }
            let params = {
                iterative: undefined,
                advanced: undefined,
            }
            params.offline = true;
            params.service_types = ['online', 'hlr'];

            return params
        },
        onlineType() {
            return [];
        },
        disabledOnline() {
            return this.iterative.includes('iterative_search_false') && !this.options.service_types.includes('online')
                    && !this.param !== 'image'
        }

    },
    methods: {
        typesRecount() {
            this.payload.offline = !this.online;
            if(this.online && !this.disabledOnline &&  this.options.service_types.includes('online') && !this.payload.service_types.includes('online')) {
                this.payload.service_types.push('online');
            }
            if(this.online && this.options.service_types.includes('face') && !this.payload.service_types.includes('face')) {
                this.payload.service_types.push('face');
            }
        },
        setParams(param) {
          // this.$nextTick(() => {
          //   this.payload.service_options = this.setServiceOptions();
          // })
          if(_.isUndefined(this.fullData[param])) {
              if (this.options.service_types.includes('face')) {
                  // this.payload.service_types = [];
                  this.payload.service_types = ['face'];
              } else {
                if(this.options.service_types.includes('online')) {
                  this.online = true;
                  // this.payload.service_types = [];
                  if(!this.payload.service_types.includes('online')) {
                    this.payload.service_types.push('online')
                  }
                } else {
                  this.online = false
                  this.payload.service_types = [];
                }
              }
              this.fullData[param] = this.payload;
              localStorage.setItem('fullData', JSON.stringify(this.fullData));
          } else {
            this.online = !this.fullData[param].offline;
            if(_.isUndefined(this.options.service_options)) {
              this.fullData[param].service_options = undefined;
            } else if (!_.isUndefined(this.options.service_options)
                && _.isUndefined(this.fullData[param].service_options)) {
                  this.fullData[param].service_options = this.setServiceOptions();
            }
            this.payload = this.fullData[param];
            if(!this.options.service_types.includes('hlr')) {
              this.payload.service_types = this.payload.service_types.filter(item => item !== 'hlr')
            }
            if(!this.options.service_types.includes('face')) {
              this.payload.service_types = this.payload.service_types.filter(item => item !== 'face')
            }
          }
        },
      setServiceOptions() {
        let service_options = {};
        // if(!_.isUndefined(this.options.service_options) && _.isUndefined(this.fullData[this.param].service_options)) {
          service_options = {}
          for(let par of Object.keys(this.options.service_options)) {
            service_options[par] = {
              request_options: {}
            };
            for(let pr of this.options.service_options[par].request_options) {
              service_options[par].request_options[pr.name] = false
            }
          }
          return service_options
        // }
        // return undefined;
      },
      recount() {
        this.payload.watch = !this.payload.watch;
      }
    },
    watch: {
        param(value) {
            if(localStorage.getItem('fullData')) {
                this.fullData = JSON.parse(localStorage.getItem('fullData'))
            } else {
                localStorage.setItem('fullData', {})
            }
          if(value === 'image') {
              this.payload.service_types = [];
              this.payload.service_types.push('face')
          }
            this.setParams(value);
        },
        'payload.service_types': function (value) {
            this.typesRecount();
            if(value.includes('hlr') && this.payload.offline) {
                this.payload.service_types = this.payload.service_types.filter(item => item !== 'hlr')
            }
        },
        payload: {
            deep: true,
            handler() {
                this.$emit('push', this.payload);
                localStorage.setItem('fullData', JSON.stringify(this.fullData));
            }
        },
        online(val) {
            this.typesRecount()
            if(this.payload.service_types.includes('hlr') && this.payload.offline) {
              this.payload.service_types = this.payload.service_types.filter(item => item !== 'hlr')
            }
            if(!val) {
              this.payload.service_types = [];
              if(!_.isUndefined(this.payload.service_options)) {
                this.payload.service_options.GetContact.request_options.tags = false;
              }
            }
        },
        disabledOnline(val) {
            if(val) {
                this.payload.service_types = [];
                this.online = false;
            }
        },
        options: {
          handler() {
            this.setParams(this.param);
            this.typesRecount();
          },
          deep: true
        },
        // fullData: {
        //   handler() {
        //     this.$emit('push', this.payload);
        //     localStorage.setItem('fullData', JSON.stringify(this.fullData));
        //   },
        //   deep: true
        // }
    },
    mounted() {
        if(localStorage.getItem('fullData')) {
            this.fullData = JSON.parse(localStorage.getItem('fullData'))
        } else {
            localStorage.setItem('fullData', {})
        }
        this.setParams(this.param);
        this.typesRecount();
    }
}
</script>

<style lang="scss">
/*
.disabled {
 color: rgba(44, 62, 80, 0.52);
}
.limitIcon{
  width: 31px;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  color: forestgreen;
}
small {
  white-space: nowrap;
  margin-top: 0;
  font-weight: normal;
}

.settingsElement{
  width: 100%;
  .settingsFlex{
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: flex-start;
    text-align: start;
  }

  .b-checkbox.checkbox:not(.button){
    margin-right: 0;
    .control-label{
      padding-left: 0 !important;
    }
  }
  .b-checkbox.checkbox .control-label{
    padding-left: 0;
  }
}
*/
</style>
