<template>
  <div class="result simple-result">
    <div class="d-grid d-lg-flex justify-content-lg-end btn-save" >
      <button class="btn btn-primary" :disabled="enablePDF || !isAnyResultsLeft || isLoading"
              @click="promptPdf()" style="text-transform: none"><b-spinner small v-if="enablePDF || isLoading"></b-spinner><i v-else class="feather icon-download"></i> <span style="padding-left: 4px;">{{ $t("pdf.save_pdf") }}</span>
      </button>
    </div>
    <div class="card" style="margin-top: 1.5rem; margin-left: 12px; margin-right: 12px">
      <div class="card-body">
        <div id="mapBlocker" :class="{'hold-map':holdMap}"></div>
        <div id="mapLocation" style="height: 400px;"></div>
      </div>
    </div>

    <div v-if="!loadingResult">
      <div class="container" v-if="!isUndefined(results) && results.length > 0">
        <div v-if="showResults">
          <result-level
              :meta="meta"
              :errors="errors"
          />
        </div>
      </div>
      <div v-else>
        <div>
          <h3 style="display: flex;justify-content: center">{{ $t("result.search_results_have_been_deleted") }}</h3>
        </div>
      </div>
      <b-modal v-model="promptPdfActive" :title="$t('pdf.save_file')" has-modal-card centered hide-footer trap-focus :destroy-on-hide="false"
               aria-role="dialog" aria-label="Example Modal" aria-modal>
        <PdfPrompt v-bind="formProps" @close="closeModal" @doc-data="resultToDocument" @pdf-data="pdfUpdateData">
        </PdfPrompt>
      </b-modal>
    </div>
    <div v-else class="d-flex justify-content-center">
      <b-spinner/>
    </div>

  </div>

</template>


<script>
import moment from "moment/moment";

const CustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon.png'
  }
});
const pinMarker = L.Marker.extend({
  options: {
    targetId: ''
  }
})
import ResultLevel from "@/components/results/resultLevel";
import _, {isUndefined} from "underscore";
import filters from "@/mixins/filters";
import imageMixin from "@/mixins/imageMixin";
import saveDoc from "@/mixins/saveDoc";
import imageAxios from "@/configs/iamgeAxios";
import {mapGetters} from "vuex";
import i18n from "@/i18n";
import L, { icon } from "leaflet";
import drawLocales from "leaflet-draw-locales";
import 'leaflet-geosearch/dist/geosearch.css';
import savePdf from "@/mixins/savePdf";



export default {
  name: "movementHistoryResult",
  components: {ResultLevel},
  mixins: [filters, imageMixin, saveDoc, savePdf],
  setup() {
    return {
      isUndefined: _.isUndefined
    }
  },
  data() {
    return {
      loadingResult: false,
      routeName: this.$route.name,
      pdfPassword: 'test',
      showResults: true,
      imageCount: 0,
      arr: [],
      schemaPic: null,
      schemaPicSize: [],
      enablePDF: true,
      isLoading: false,

      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [55.019531, 41.71803],
      bounds: null,
      map: null,
      drawnItems: null,
      startZoom: 3,
      maxRadius: 100000,
      radius: 0,
      locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
      selectedLayout: null,
      drawing: false,
      holdMap: false,
      isEditing: false,
      searchLoad: false,
      ratesLoading: false,
      lustPointZoomLevel : null

    }
  },
  methods:{



    async reDrawItems(){
      if (this.drawnItems){
        this.drawnItems.eachLayer(layer => {
          this.drawnItems.removeLayer(layer);
        });
      }
      let coordsArr = this.makeCoordsArr;
      if (coordsArr.length > 1){
        await this.setPolylines(coordsArr);
      }
      await this.setPoints(coordsArr);
    },

    loadAllImages() {
      for (let result of this.results) {
        if(result.search_results) {
          for (let searchResult of result.search_results) {
            for (let i = 0; i < searchResult.photos.length; i++) {
              this.loaderForImages(searchResult.photos[i].image)
            }
          }
        }
      }
    },
    countImages() {
      for (let result of this.results) {
        if(result.search_results) {
          for (let searchResult of result.search_results) {
            for (let i = 0; i < searchResult.photos.length; i++) {
              this.imageCount++;
            }
          }
        }
      }
    },
    closeModal() {
      this.promptPdfActive = false;
    },

     initMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale;
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

      this.map = L.map('mapLocation', {
        zoomControl: false,
        attributionControl: false,
        center: this.center,
        zoom: this.startZoom
      });

      L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map)
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
      L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);
      L.drawLocal.edit.toolbar.buttons.remove = this.$t('map.delete')
      L.drawLocal.edit.toolbar.buttons.removeDisabled = this.$t('map.remove_disabled')

      // this.clearMap();
      // this.setPolylines();
      // this.setPoints();

    },
    async setPolylines(coordsArr){
      if (coordsArr.length > 1){
        let arr = [];
        coordsArr.forEach(point=> {
          if (typeof point.distance !== 'undefined') {
            arr.push(point.coordinates)
          }
        })
        // let polyline = L.polyline(arr, {color: 'rgba(13,110,253,0.4)'}).addTo(this.map);
        if (this.drawnItems) {
          L.polyline(arr, {color: 'rgba(13,110,253,0.4)'}).addTo(this.drawnItems);
        }
        // this.map.fitBounds(polyline.getBounds());
      }
    },
    async setPoints(coorsArr) {
      let coords = _.groupBy(coorsArr, 'info_date');
      let index = 0;
      for (const key of Object.keys(coords)) {
        index++;
        coords[key].forEach((point) => {
          if (point.distance){
            const svgTemplate = `<div class="marker"><div class="marker-icon">${index}</div></div>`;

            const icon = L.divIcon({
              className: "custom-div-icon",
              html: svgTemplate,
              iconSize: [40, 40],
              iconAnchor: [20, 40],
              popupAnchor: [0, -30]
            });

            let marker = L.marker(point.coordinates, {
              icon: icon
            });

            marker.bindTooltip(this.pintTooltip(point));
            if (this.drawnItems) {
              marker.addTo(this.drawnItems);
            }
          } else {
            const svgTemplate = `<div class="noDistanceMarker"><div class="marker-icon">${index}</div></div>`;

            const icon = L.divIcon({
              className: "custom-div-icon",
              html: svgTemplate,
              iconSize: [40, 40],
              iconAnchor: [20, 40],
              popupAnchor: [0, -30]
            });

            let marker = L.marker(point.coordinates, {
              icon: icon
            });

            marker.bindTooltip(this.pintTooltip(point), {
              direction: 'auto',
              offset: L.point(0, -45)
            });
            if (this.drawnItems) {
              marker.addTo(this.drawnItems);
            }
          }
          if (this.drawnItems) {
            this.map.fitBounds(this.drawnItems.getBounds());
          }
        });
      }
    },
    pintTooltip(point){
      let text = ''
      if (typeof point.address !== 'undefined') {
        text +=  `<span class='f-w-700'>${this.$t('address.address')}</span>` + ': ' + point.address + `<br>`
      }
      if (typeof point.info_date !== 'undefined') {
        text += `<span class='f-w-700'>${this.$t('search.search_results_date')}</span>` +': '+  this.setDate(point.info_date)+ `<br>`
      }
      if (typeof point.distance !== 'undefined' ){
        text += this.$t('fullText.radius') +': '+  point.distance+''+this.$t('geo.meters')
      } else {
        text += this.$t('geo.tooltip_user_info')
      }
      return text
    },




    // polylineCoords(){
    //   let arr = [];
    //   this.meta.coordsArr.forEach(point=> {
    //     if (typeof point.distance !== 'undefined') {
    //       arr.push(point.coordinates)
    //     }
    //   })
    //   return arr;
    // },
    // clearMap() {
    //   if (this.drawnItems){
    //     this.drawnItems.eachLayer(layer => {
    //       this.drawnItems.removeLayer(layer);
    //     });
    //   }
    // },
    setDate(date){
      return  moment(date, 'X').format('DD.MM.YYYY, HH:mm')
    },

    async getResultById() {
      return new Promise((resolve) => {
        if(!_.isUndefined(this.$route.params.id)) {
          this.loadingResult = true;
          this.$store.dispatch('contacts/updateResultPush', parseInt(this.$route.params.id)).finally(()=>{
            window.scrollTo({ top: 0, behavior: 'smooth' });
            resolve();
            this.loadingResult = false
          })
        } else {
          this.loadingResult = false
          resolve()
        }
      })
    },
    async loaderForImages(payload) {
      let result = await this.checkCache(payload);
      if (result.isCached){
        this.$store.dispatch('images/addImage', {
          key: payload,
          value: ''
        });
      } else {
        let axiosSettings;
        axiosSettings = {
          method: "get",
          responseType: 'arraybuffer',
          headers: {
            'Authorization': this.token
          }
        }
        return new Promise((resolve, reject) => {
          // const imAxios = imageAxios(localStorage.getItem('token'))
          imageAxios(payload, axiosSettings)
              .then((resp) => {
                let blob = new Blob([resp.data], {type: "image/jpg"})
                let reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => {
                  this.saveImage(reader.result, payload)
                  this.$store.dispatch('images/addImage', {
                    key: payload,
                    value: ''
                  });
                  resolve()
                }
              })
              .catch(err => {
                reject(err)
              })
        })
      }
    },
    updateResults(){
      this.showResults = false;
      this.$nextTick(()=>{
        this.showResults = true;
        this.$forceUpdate();
      })
    },

    sortResult() {
      this.results.sort((a, b) => a.source > b.source ? 1 : -1).sort((a, b) => a.level > b.level ? 1 : -1)
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }
  },
  computed:{
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('contacts', ['results', 'meta', 'errors', 'paginationEventWasTriggered']),
    ...mapGetters('images', ['images', 'photoLoaded']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('ui', ['services']),
    ...mapGetters('movementHistory', ['resultMapCenter']),


    makeCoordsArr(){
      let results = this.results
      let coordsArr = [];
      if (results.length){
        results.forEach(result => {
          if (!isUndefined(result.search_results) && result.search_results && result.search_results.length){
            let searchResults = result.search_results.sort(function (a, b) { return a.info_date - b.info_date; })
            searchResults.forEach(search_result=> {
              if (search_result.data.length){
                let point = {
                  address: undefined,
                  coordinates: [],
                  distance: undefined,
                  info_date: search_result.info_date
                };
                // CHECK FOR RADIUS
                search_result.data.forEach(dataItem => {
                  if (dataItem.param ===  'latitude'){
                    point.coordinates.push(dataItem.value)
                  } else if (dataItem.param ===  'longitude'){
                    point.coordinates.push(dataItem.value)
                  } else if (dataItem.param ===  'distance'){
                    point.distance = dataItem.value
                  } else if (dataItem.param ===  'distance'){
                    point.distance = dataItem.value
                  } else if (dataItem.param ===  'address'){
                    point.address = dataItem.value
                  }
                })
                if (point.coordinates.length === 2){
                  coordsArr.push(point)
                }
              }
            })
          }
        })
      }
      return coordsArr
    },



    isAnyResultsLeft() {
      return !!this.total.length;
    },

    contextMenu() {
      return {
        contextmenu: true,
        contextmenuItems: [
          {
            text: `<i class="feather icon-trash"></i> ${this.$t('geo.remove')}`,
            callback: this.removeFigure
          }
        ]
      }
    },
    selectedLocale(){
      if (i18n.locale === 'ua'){
        return 'uk'
      } else {
        return i18n.locale
      }
    },
    total() {
      let arr = [];
      if(!_.isUndefined(this.results)) {
        this.results.forEach(result => {
          if (result.search_results) {
            result.search_results.forEach(searchResult => {
              arr.push(searchResult)
            })
          }
        })
      }

      return arr;
    },
    paginatedItems() {
      let page_number = this.current-1
      return this.total.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
    },
    fullCount() {
      const full = _.reduce(this.results, (memo, resultItem) => {return memo + resultItem.matches_count}, 0);
      const filtered = _.reduce(this.results, (memo, resultItem) => {return memo + resultItem.total_count},0)
      return {full, filtered};
    },
  },
  watch:{
    photoLoaded() {
      if (this.photoLoaded >= this.imageCount || this.imageCount === 0) {
        this.enablePDF = false;
      }
    },
    total(){
      this.reDrawItems();
    },


    results(){
      if (this.results > 0) {
        this.$store.dispatch('contacts/setCurrentResultValue', this.results[0].value)
      }
      this.updateResults();
      this.reDrawItems();

    },
    paginationEventWasTriggered(){
      // if (this.paginationEventWasTriggered){
      //   if (this.map){
      //     this.map.remove();
      //   }
      //   this.initMap();
      // }
      this.reDrawItems();
    },
    resultMapCenter(val) {
      this.map.setView(val, 18)
      window.scrollTo(0, 0)
    }
  },
  async mounted() {
    await this.$store.dispatch('contacts/paginationEventExist', false)
    await this.getResultById();
    await this.setMountedPdfAction();
    await this.mountActionsDoc();
    await this.countImages();
    await this.loadAllImages();
    await this.$store.dispatch('images/clearImages');
    await this.$store.dispatch('contacts/forceUpdateSearchParams');
    this.initMap();
    this.reDrawItems();
  },
}
</script>

<style >
.leaflet-tooltip-pane>* {
  width: 300px !important;
  white-space: normal !important;
}
.marker {
  margin-left: 8px;
  position: relative;
  width: 26px;
  height: 40px;
  background: url(../../public/images/marker-icon.png) no-repeat;
  background-size: contain;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.noDistanceMarker {
  margin-left: 8px;
  position: relative;
  width: 26px;
  height: 40px;
  background: url(../../public/images/marker-icon-second.png) no-repeat;
  background-size: contain;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
}


.marker-icon {
  position: absolute;
  bottom: 20px;
  left: 4.5px;
  width: 15px;
  height: 15px;
  text-align: center;
  color: black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}


.leaflet-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 6px 10px;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}


.custom-div-icon {
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 25px;
  width: 40px;
  text-align: center;
}
</style>