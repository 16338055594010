
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n.js'

export default {
    setup() {
        return {
            store,
            router,
            i18n
        }
    },
    methods: {
        setMenuParam(val) {
            setTimeout(() => {
                localStorage.setItem("lastParam", val);
            }, 100);
            this.store.dispatch('mainMenuState/menuParam', val).then(() => {
                this.store.dispatch('full_search/clearResultFilters');
                this.router.push({ name: 'Home' })
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                    document.getElementsByClassName("pcoded-overlay-box")[0].click();
                }
            })
        },
    },
}