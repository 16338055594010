<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="form-group">
        <input class="form-control" :placeholder="`${$t('placeholders.enter')} ${$t('placeholders.' + payload.param).toLocaleLowerCase()}`" id="searchName" type="text"
               autocomplete="off" v-model="payload.value" />
      </div>
      <name-search-additional-settings :search-payload="payload" ref="nameSearchSettings"
                                       v-if="payload.param === 'name' && searchOptions.name.advanced" />
      <OptionsSearch param="name" @push="setPayload" @invalid="setInvalid" />
      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button variant="primary"  class="btn" type="submit" :disabled="$v.payload.$invalid || searchButtonNeedDisable">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>

    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NameSearchAdditionalSettings from "@/components/nameSearchAdditionalSettings";
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import OptionsSearch from "@/components/search/optionsSearch";
import {validationMixin} from 'vuelidate'
import openResult from "@/mixins/openResult";

export default {
  name: "nameForm",
  components: {
    NameSearchAdditionalSettings,
    OptionsSearch
  },
  data() {
    return {
      payload: {
        param: 'name',
        value:''
      },
      invalid: true,
      modalDialog: false,
      showBtn: true,
    }
  },
  mixins: [validationMixin, openResult],
  validations() {
    return {
      payload: {
        value: {
          required,
        },
        optionsValid() {
          return !this.invalid
        }
      }
    }
  },
  beforeDestroy() {
    this.$refs.nameSearchSettings.clearForm()
  },
  computed:{
    ...mapGetters('contacts', ['searchOptions']),
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters("nameSearchAdditionalSettings", ["searchButtonNeedDisable", 'alt_values', 'filters', 'enableAltValues', 'enableAge', 'enableAddress']),
    ...mapGetters("auth", ["user"]),
  },
  methods:{
    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    beforeSearch() {
      let value = this.payload.value;
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.setAdditionalParamsToNameSearch();
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    addSearch() {
        let value = this.payload.value;
        if (value.substring(0, 1) === '@') {
          value = value.substring(1);
        }
        let payload = Object.assign({}, this.payload);
        payload = Object.assign(payload, {value})
        this.$store.dispatch("contacts/search", {
          payload,
        }).then(() => {
          this.$store.dispatch('ui/getFreeSpaceInfo')
        });
        this.payload.value = null;
        this.val = "";
        this.$refs.nameSearchSettings.clearForm()
        delete this.payload.filters
        delete this.payload.alt_values
    },
    setAdditionalParamsToNameSearch() {

      if (typeof this.alt_values !== 'undefined' && this.alt_values.length !== 0) {
        if (this.enableAltValues) {
          this.payload.alt_values = this.alt_values;
        }
      }
      if (typeof this.filters !== 'undefined' && this.filters.length !== 0) {
        let temp = this.filters
        if (!this.enableAddress) {
          temp = this.filters.filter(el => el.param !== 'address' && el.param !== 'city')
        }
        if (!this.enableAge) {
          temp = this.filters.filter(el => el.param !== 'birthdate')
        }
        this.payload.filters = temp;
      }
      this.clearNameSearchForm();
    },
    clearNameSearchForm() {
      this.$refs.nameSearchSettings.clearForm();
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
  },
}
</script>

<style scoped>

</style>