<template>
  <form @submit.prevent="submit" name="feedback_form" enctype="multipart/form-data">
    <div class="form-group">
      <label>{{$t('ui.message')}}</label>
      <b-form-textarea
          id="textarea-state"
          v-model="payload.message"
          :state="payload.message.length <= maxLength ? null: false"
          rows="3"
          :disabled="loading"
      ></b-form-textarea>

      <p><small :class="{'text-danger': payload.message.length > maxLength}">{{ payload.message.length }}/{{maxLength}}</small></p>
      <p class="text-danger" v-if="error">{{this.error}}</p>
    </div>
    <div class="form-group">
      <div class="file btn-primary btn m-r-10">
        {{ $t('ui.Click_to_upload') }}
        <input type="file" @change="uploadFile"  name="attachments[]" accept="image/*" >
      </div>
      <label>{{typeof files[0] !== 'undefined' ? files[0].name : ""}}</label>
    </div>
    <div class="form-group" v-show="files.length > 0">
      <div class="file btn-primary btn m-r-10">
        {{ $t('ui.Click_to_upload') }}
        <input type="file" @change="uploadFile"  name="attachments[]" accept="image/*" >
      </div>
      <label>{{typeof files[1] !== 'undefined' ? files[1].name : ""}}</label>
    </div>
    <div class="form-group" v-show="files.length > 1">
      <div class="file btn-primary btn m-r-10">
        {{ $t('ui.Click_to_upload') }}
        <input type="file" @change="uploadFile" name="attachments[]" accept="image/*" >
      </div>
      <label>{{typeof files[2] !== 'undefined' ? files[2].name : ""}}</label>
    </div>
    <p class="text-danger" v-if="filesError.length">{{$t('ui.filesError')}}</p>
    <div class="d-grid gap-1">
      <b-button type="submit" variant="primary" :disabled="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
        <span v-else>{{$t('ui.send')}}</span>
      </b-button>
      <b-button type="reset" @click="$emit('feedbacked')" variant="secondary">{{ $t('user.cancel') }}</b-button>
    </div>
  </form>
</template>

<script>
import axios from "@/configs/axios"
import Compressor from "compressorjs";
export default {
  name: "Feedback",
  data() {
    return {
      loading: false,
      maxLength: 1000,
      error: '',
      payload: {
        message: ""
      },
      images: [],
      form: null,
      files: [],
      filesError: ''
    }
  },
  methods: {
    async submit() {
      if(this.payload.message.length > 0 && this.payload.message.length <= this.maxLength) {

        const formData = new FormData();
        for(let image of this.files) {
          try {
            const file = await this.compressFile(image);
            await formData.append('attachments[]', file);
          } catch (e) {
            console.log(e);
            this.filesError = "only_for_images"
          }
        }
        if(this.filesError.length) {
          return null;
        }
        this.loading = true;
        await formData.append('message', this.payload.message)
        const headers = { 'Content-Type': 'multipart/form-data' };
        await axios.post('feedback', formData, {headers}).then(() => {
          this.loading = false;
          this.$emit('feedbacked')
        })
      } else if(this.payload.message.length === 0) {
        this.error = this.$t('ui.feedback_required');
      } else if(this.payload.message.length > this.maxLength) {
        this.error = this.$t('ui.feedback_max_length');
      }
    },
    compressFile(image) {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          convertSize: 5000000,
          success(file) {
            resolve(file)
          },
          error(error) {
            reject(error);
          }
        })
      })
    },
    uploadFile() {
      this.filesError = "";
      if(this.form && typeof this.form['attachments[]'] !== 'undefined') {
        const files = [];
        for(let item of this.form['attachments[]']) {
          if(typeof item.files[0] !== 'undefined') {
            files.push(item.files[0]);
          }
        }
        this.files = files;
      }
    }
  },
  computed: {
    // files() {
    //
    // }
  },
  watch: {
    'payload.message': {
      handler(val) {
        if (val.length > 0 && val.length <= this.maxLength) {
          this.error = '';
        }
      }
    }
  },
  mounted() {
    this.form = document.forms.feedback_form
  }
}
</script>

<style>
  input[type=file].attachments {
    position: relative;
    top: auto;
    left: auto;
    font-size: inherit;
    opacity: 1;
  }
</style>