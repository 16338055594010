<template>
    <div>
        <div id="mapBlocker" :class="{'hold-map':holdMap}"></div>

        <div id="mapLocation" style="height: 400px;">
        </div>
      <div v-if="!ratesLoading">
        <div style="width: 100%;margin-top: 16px; font-weight: 700">{{ $tc('servicesSelect.used', 1,  { servicesLength: 1, totalPrice: searchPrice }) }}{{ $t('servicesSelect.c') }}</div>
      </div>
        <div class="d-grid m-t-15"> 
          <b-button variant="primary" disabled class="btn" 
                    @click="geo_search">
            {{ $t("search.search") }}
          </b-button>
        </div>
    </div>
</template>
<script>
// :disabled="searchLoad || !payload.location.coordinates"
import L, { icon } from "leaflet";
import drawLocales from "leaflet-draw-locales";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import _ from "underscore";
import {SimpleMapScreenshoter} from "leaflet-simple-map-screenshoter";
import {ImageStorage} from "@/mixins/imageStorage";
import {mapGetters} from "vuex";

const CustomMarker = L.Icon.extend({
    options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 41),
        iconSize: new L.Point(25, 41),
        iconUrl: '/images/marker-icon.png'
    }
});

export default {
    name: "TelegramLocationForm",
    data() {
        return {
            url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
            zoom: 5,
            center: [55.019531, 41.71803],
            bounds: null,
            map: null,
            payload:{
              cache_search_enabled: false,
              online_search_enabled: true,
              services: ['TelegramGeo'],
              location: {
                type: 'point',
                coordinates: null
              }
            },
            drawnItems: null,
            startZoom: 3,
            maxRadius: 100000,
            radius: 0,
            locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
            selectedLayout: null,
            drawing: false,
            holdMap: false,
            isEditing: false,
            searchLoad: false,
            ratesLoading: false,
            lustPointZoomLevel : null
        };
    },
    computed: {
      ...mapGetters('search_rates', ['search_rates', 'servicesList']),
      searchPrice(){
        if(typeof this.search_rates !== 'undefined' && typeof this.search_rates.defaults !== 'undefined') {
          if (typeof this.search_rates.services_search_rates !== 'undefined' && this.search_rates.services_search_rates.length > 0 && this.search_rates.services_search_rates.map(item => item.source).includes('TelegramGeo')){
            let telegramGeoPrice = this.search_rates.services_search_rates.find(item => item.source === 'TelegramGeo')
            return telegramGeoPrice.online_result_price
          } else {
            return this.search_rates.defaults.online_result_price
          }
        }
      },
    },
    methods: {
        getSearchRates() {
          this.ratesLoading = true;
          this.$store.dispatch('search_rates/getSearchRates').then(()=> {
            this.ratesLoading = false;
          })
        },


        async geo_search(){
          this.searchLoad = true;
          const payload = JSON.parse(JSON.stringify(this.payload));
          const payloadCollect = { payload, type: 'geo_search_telegram'};
          payloadCollect['screenshotId'] = await this.makeScreen();
          // let navBarHeight = 90;
          // let scrollDistance = document.getElementById("searchList").offsetTop;
          // window.scrollTo({ top: scrollDistance - navBarHeight, behavior: 'smooth'});
          this.$toast.success( this.$t('search.telegram_location_toast') , {
            position: "top-center",
            timeout: 30000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.$store.dispatch('contacts/geo_search', payloadCollect).finally(() => {
            this.searchLoad = false;
            this.clearMap();
          })
        },


        async makeScreen() {
          this.holdMap = true;
          this.map.panTo(new L.LatLng(this.payload.location.coordinates[1], this.payload.location.coordinates[0]));
          this.map.setZoom(this.lustPointZoomLevel);
          let overridedPluginOptions = {
            mimeType: 'image/jpeg'
          }
          // await this.setBounds();
          const screenShoter = new SimpleMapScreenshoter().addTo(this.map);
          return await new Promise(async (resolve) => {
              try {

                screenShoter.takeScreen('image', overridedPluginOptions).then(async image => {
                  const imageStorage = new ImageStorage('map', 'screenshots');
                  imageStorage.init().then(async () => {
                    const screenshotId = await imageStorage.addImage(image)
                    await resolve(screenshotId);
                    await this.enableMap();
                  })
                });
              } catch (error) {
                resolve(null)
              }
          })
        },
        enableMap() {
          this.holdMap = false;
        },


        drawMarker(event){
            let editableLayers = new L.FeatureGroup();
            const layer = event.layer;
            this.map.addLayer(editableLayers);
            editableLayers.addLayer(layer);
            let point = layer.toGeoJSON()
            this.payload.location.coordinates = point.geometry.coordinates;
        },
        clearMap() {
            this.drawnItems.eachLayer(layer => {
              this.drawnItems.removeLayer(layer);
            });
            this.payload.location.coordinates = null;
        },
        naturalMountAction() {
            return false;
        },
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        centerUpdated(center) {
            this.center = center;
        },
        boundsUpdated(bounds) {
            this.bounds = bounds;
        },
        success(pos) {
            const crd = pos.coords;
            this.center = [crd.latitude, crd.longitude];
            this.map.setView(this.center, this.startZoom)
        },
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

            this.map = await L.map('mapLocation', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
                zoom: this.startZoom
            });

            L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
            L.control.zoom({
                zoomInTitle: this.$t('geo.zoomIn'),
                zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map)
            L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);

            var southWest = L.latLng(-89.98155760646617, -180),
            northEast = L.latLng(89.99346179538875, 180);
            var bounds = L.latLngBounds(southWest, northEast);

            this.map.setMaxBounds(bounds);
            this.map.on('drag', () => {
                this.map.panInsideBounds(bounds, { animate: false });
            });
            this.drawnItems = new L.FeatureGroup().addTo(this.map);

            const provider = new OpenStreetMapProvider();
            const searchControl = new GeoSearchControl({
                provider: provider,
                style: 'button',
                showMarker: false,
                searchLabel: this.$t('geo.enter_address'),
                notFoundMessage: this.$t('errors.not_found'),
                marker: {
                    icon: new CustomMarker(),
                    draggable: false,
                },
                autoComplete: true,
                autoCompleteDelay: 250
            })


            // const drawControl = new L.Control.Draw({
            //     edit: {
            //       featureGroup: this.drawnItems,
            //       edit: false,
            //       delete: false,
            //     },
            //     draw: {
            //         circle: false,
            //         polygon: false,
            //         marker: {
            //             icon: new CustomMarker()
            //         },
            //         polyline: false,
            //         rectangle: false,
            //         circlemarker: false
            //     }
            // });
            // this.map.addControl(drawControl);
            this.map.addControl(searchControl)

            this.map.on('draw:created', (event) => {
              this.clearMap();
              this.drawnItems.addLayer(event.layer);
              const layer = event.layer;
              let point = layer.toGeoJSON()
              this.payload.location.coordinates = point.geometry.coordinates;
              this.lustPointZoomLevel = this.map.getZoom();
              drawControl.setDrawingOptions({
                edit: {
                  featureGroup: this.drawnItems,
                  edit: false,
                  delete: true,
                }
              });

              this.map.on('draw:deletestart', () => {
                L.drawLocal.edit.handlers.remove.tooltip.text = ''
                this.clearMap();
              });
            });

        },
    },
    mounted() {
      this.initMap();
      this.getSearchRates();
    }
}
</script>
<style>
#mapBlocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
}
#mapBlocker.hold-map {
  display: block;
  z-index: 99999999999;
}
</style>