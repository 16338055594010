<template>
  <div>
    <nav class="card-body">
      <div class="panel-heading fw-bold d-flex justify-content-between m-b-20" v-if="infoResult.info_date">
        <div class="infoResult">{{ infoResult.info_date | moment('DD.MM.YYYY, HH:mm') }}<span
            style="margin-left: 1rem">{{resultDateState(infoResult, result)}}</span></div>
        <figure @click="deleteSelectedResult" style="margin: 0">
          <i class="feather icon-x"  :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }"></i>
        </figure>
      </div>
      <InfoBlock v-for="(param, i) in infoResult.data" :key="i" :dataParam="param" :resultTitle="result.param" />
      <CardFooter :photos="infoResult.photos" :meta="meta" />
    </nav>
  </div>
</template>

<script>
import CardFooter from "./CardFooter";
import InfoBlock from "./InfoBlock.vue";
import resultDateState from "@/mixins/resultDateState";
export default {
  name: "CardResult",
  props: ['infoResult', 'meta', 'result'],
  mixins: [resultDateState],
  components: {
    InfoBlock,
    CardFooter
  },
  methods: {
    deleteSelectedResult() {
      this.$buefy.dialog.confirm({
        message: 'Удалить результат поиска?',
        confirmText: 'Да',
        cancelText: 'Нет',
        onConfirm: () => {
          this.$store.dispatch('contacts/setRemoveResultOnlineAccount', {
            infoResult: this.infoResult,
            meta: this.meta,
            result: this.result
          }).then(() => {
            if (typeof this.result.face_select !== 'undefined') {
              this.$store.dispatch('contacts/updateResultChild', this.meta.key).then(() => {
                this.$emit("updateResults")
              })
            } else {
              let payload = {
                keyVal: this.meta.key
              }
              this.$store.dispatch('contacts/updateResult', payload).then(() => {
                this.$emit("updateResults")
              })
            }
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
/*
.flexHeading{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}
.icon{
  //width: 10%;
  margin: 0;
}
*/
</style>
