import {mapGetters} from "vuex";
export default {
    methods:{
        setLanguage(item){
            let leng;
            if (this.$i18n.locale === 'ua'){
                leng =  'uk'
            } else {
                leng = this.$i18n.locale
            }
            return item[leng]
        },
    },
    computed: {
        // results() {
        //     return this.$route.name === 'Result' ? this.$store.state.contacts.results.results:
        //         this.$store.state.contacts.childResults.results
        // },
        ...mapGetters('contacts', ['results']),
        levels(){
            let levels = []
            let first = this.results.filter((item) => item.level === 1);
            let second = this.results.filter((item) => item.level === 2);
            let third = this.results.filter((item) => item.level === 3);
            if (first.length){
                levels.push(first)
            }
            if (second.length){
                levels.push(second)
            }
            if (third.length){
                levels.push(third)
            }
            let sourceList = []
            levels.forEach(level =>{
                level.forEach(item => {
                    if (sourceList.length){
                        let  pos = sourceList.map(function(e) { return e.value; }).indexOf(item.value);
                        if (pos === -1){
                            let obj = {
                                value: item.value,
                                level: item.level,
                                items: [],
                                parentSources: [],
                                parentSourcesLocales: [],
                                children: []
                            }
                            // if (item.search_results){
                                obj.items.push(item)
                                sourceList.push(obj)
                            // }

                        } else {
                            sourceList[pos].items.push(item)
                        }
                    } else {
                        let obj = {
                            value: item.value,
                            level: item.level,
                            items: [],
                            parentSources: [],
                            parentSourcesLocales: [],
                            children: []
                        }
                        // if (item.search_results){
                            obj.items.push(item)
                            sourceList.push(obj)
                        // }
                    }
                })
            })
                /// find parents
            sourceList.forEach((item, index) =>{
                if (item.level !== 1){
                    let topLevel = this.results.filter(el => el.level === item.level - 1)
                    topLevel.forEach(tempLevelItem => {
                        if(tempLevelItem.search_results) {
                            tempLevelItem.search_results.forEach(searchResultItem => {
                                searchResultItem.data.forEach(dataItem => {
                                    if (item.value === dataItem.value){
                                        sourceList[index].parentValue = tempLevelItem.value
                                        if (sourceList[index].parentSources.indexOf(tempLevelItem.source) === -1){
                                            sourceList[index].parentSources.push(tempLevelItem.source)
                                            if (typeof tempLevelItem.source_locales !== 'undefined'){
                                                sourceList[index].parentSourcesLocales.push(this.setLanguage(tempLevelItem.source_locales))
                                            } else {
                                                sourceList[index].parentSourcesLocales.push(tempLevelItem.source)
                                            }
                                        }
                                    }
                                })
                            })
                        }
                    })
                }
            })
                /// find child
            sourceList.forEach((item,index) =>{
                if (item.level !== 3){
                    let lowerLevel = this.results.filter(el => el.level === item.level +1 )
                    item.items.forEach(itemResults=>{
                        if(itemResults.search_results) {
                            itemResults.search_results.forEach(searchResultItem=>{
                                searchResultItem.data.forEach(dataItem =>{
                                    lowerLevel.forEach(lowerLevelItem =>{
                                        if (dataItem.value === lowerLevelItem.value){
                                            if (sourceList[index].children.indexOf(lowerLevelItem.value) === -1){
                                                sourceList[index].children.push(lowerLevelItem.value)
                                            }
                                        }
                                    })
                                })
                            })
                        }
                    })
                }
            })


            let resultArr = [];

            let levelOne = sourceList.filter((item) => item.level === 1);
            let levelTwo = sourceList.filter((item) => item.level === 2);
            let levelThree = sourceList.filter((item) => item.level === 3);
            if (levelOne.length){
                resultArr.push(levelOne)
            }
            if (levelTwo.length){
                resultArr.push(levelTwo)
            }
            if (levelThree.length){
                resultArr.push(levelThree)
            }
            return resultArr
        },
    }
}