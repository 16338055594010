import { Result } from "@/models/result";
export default {
    methods: {
        async openResult(result) {
            if ((!result.loaded || result.error ) && result.name !== "many faces" ) {
                return false;
            } else if (result.name !== "many faces") {
                // this.$store.dispatch('contacts/updateResult', result);
                const res = await Result.getResultByKey(result.keyVal)
                if (result.param === 'image') {
                    await this.$router.push({name: 'Photo', params: {id: res.id}})
                } else if (result.param === 'geo_search_telegram') {
                    await this.$router.push({name: 'Location', params: {id: res.id}})
                } else {
                    await this.$router.push({name: 'Result', params: {id: res.id}})
                }
            }

            if (result.loaded && result.name === "many faces" && !this.showLoader ){
                this.isPhotoSegments = true;
                if (typeof this.photo !=='undefined' && this.photo.face_select === "undefined" ){
                    this.searchPhoto();
                }
            }
        }
    }
}