export default {
    namespaced: true,
    state: {
        currentParam: '',
    },
    getters: {
        currentParam: state => state.currentParam,
    },
    actions: {
        menuParam({commit}, param) {
            commit('setMenuParam', param)
        },

    },
    mutations: {
        setMenuParam(state, param) {
            state.currentParam = param;
        },

    }
}