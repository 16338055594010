<template>
    <div class="zoom-hover" style="position: relative; cursor: zoom-in">
      <img   v-if="fromSearchListItem" :src="payload" :class="{'cursor-pointer': loaded, fromSearchListItem: fromSearchListItem || fromSearchListChild }" @click="preview">
<!--      <img  style="min-width: 128px" v-else-if="FaceSearchGlobal" :src="srcMini" :class="{'cursor-pointer srcMini': loaded, fromSearchListItem: fromSearchListItem || fromSearchListChild }" @click="preview">-->
      <img class="imgSize"  :style="{ width: this.size }" v-else :src="src" :class="{'cursor-pointer': loaded, fromSearchListItem: fromSearchListItem || fromSearchListChild, 'min-height': modal }" @click="preview">

      <div class="zoom-placeholder" style="right: 5px;bottom: 0; color: white; position: absolute; cursor: pointer" v-if="makeZoom && !internetArchive && searchByPhoto" @click.prevent="zoom()" >
        <i style="text-shadow: rgb(28 28 28) 2px 2px 5px" class="feather icon-search rotating" v-show="!searching"></i>
        <div  v-show="searching" class="loading">
          <i
              class="feather icon-refresh-cw"
          />
        </div>
      </div>
    </div>
</template>
<style>
  .result-image-wrapper .zoom-hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    width: auto;
  }
</style>
<script>
import imageMixin from "../mixins/imageMixin";
import env from "../configs/env.json"

export default {
  props: {
    searching:  {
      type: Boolean,
      default: false
    },
    internetArchive: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    FaceSearchGlobal: {
      type: Boolean,
      default: false
    },
    searchByPhoto: {
      type: Boolean,
      default: true,
      required: false
    },
    needReturnSrc: {
      type: Boolean,
      required: false
    },
    fromSearchListItem : {
      type: Boolean,
      required: false
    },
    fromSearchListChild: {
      type: Boolean,
      required: false
    },
    payload: {
      type: String,
      required: true
    },
    makeZoom: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: '128px'
    },
    thumbnail: {
      type: String,
      required: false,

    }

  },
  mixins: [
    imageMixin
  ],
  data() {
    return {
      src: env.prefix + "images/user-found.png",
      srcMini: env.prefix + "images/user-found.png",
      loaded: false,
      fullLoad: false,
      showSearchIcon : true,
    }
  },
  methods: {
    showSearch() {
      if(this.$route.name !== "Home") {
        this.showSearchIcon = true;
      }
    },
    hideSearch() {
      if(this.$route.name !== "Home") {
        this.showSearchIcon = false;
      }
    },
    zoom() {
      this.$emit('zoom');
    },
    search(){
      this.$emit('search');
    },
    preview(){

      this.$emit('preview')
    },
  },
  mounted() {
    if (!this.fromSearchListItem || this.fromSearchListChild){
      this.loadImg(true)
    }
  },
  watch: {
    loaded(){
      if (this.loaded){
        this.loadImg(false)
        this.$emit('loaded');
      }
    },
    src() {
      this.$emit('imgSource', {
        source: this.src,
        fullLoad : this.fullLoad
      })
      if (this.needReturnSrc){
        this.$emit('returnSrc', this.src)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.loading {
  animation: rotate 3s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

  .min-height {
    min-height: 210px !important;
    width: auto !important;
    max-width: 100%;
    max-height: calc(100vh - 300px);
  }
</style>