<template>
  <div style="display: flex; justify-content: center;">
    <!-- <b-datepicker
        inline
        :first-day-of-week="1"
        :events="events"
        :indicators="indicators"
        input="fireDates"
        ref="datepicker"
        :open-on-focus="false"
        v-on:change-year="setCurrentYear"
        v-on:change-month="setCurrentMonth"
        v-on:input="setCurrentDate"
        :focusable="false"
    >
    </b-datepicker> -->
    <date-picker v-model="date"
      @input="setCurrentDate(date)"  
      format="DD.MM.YYYY"
      :editable="false"
      :lang="lang[$i18n.locale]"></date-picker>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {isUndefined} from "underscore"

export default {
  name: "MonthCalendar",
  setup() {
    return {
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      }
    }
  },
  props: {
    target: {
      required: false,
      type: Object
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      date: new Date(),
      bars: true,
      events: [],
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      monthMoment: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters('monitoring', ['colors']),
    indicators() {
      return this.bars ? 'bars' : 'dots'
    },
    monthStatuses() {
      return this.$store.state.monitoring.monthStatuses
    },
    selectedIndexes() {
      if(typeof this.target !== 'undefined') {
        return [this.target.id];
      }
      return this.$store.state.monitoring.selectedIndexes;
    },
  },
  methods: {
    dispatchMonthTargets() {
      if(this.selectedIndexes.length) {
        this.loading = true;
        this.$emit('setload', true);
        this.$store.dispatch('monitoring/getGroupedOnlineStatuses', {
          filters: {
            target_id: this.selectedIndexes,
            status: ['online'],
            created_from: parseInt(this.monthMoment
                .clone().startOf('month').format('X')),
            created_to: parseInt(this.monthMoment
                .clone().endOf('month').format('X'))
          },
          order_by: 'created_at',
          order_type: 'asc',
          group_by: [
            'target_id'
          ],
          source: this.$route.name
        }).then(events => {
          if(this.selectedIndexes.length && !isUndefined(events)) {
            this.events = events.map(item => {
              return {
                date: new Date(item.created_at),
                type: this.colors[parseInt(item.target_id)] || 'is-warning'
              }
            })
          } else {
            this.events = [];
          }
        }).finally(() => {
          this.loading = false;
          this.$emit('setload', false);
        })
      } else {
        this.events = []
        this.$emit('setload', false);
      }
    },
    setCurrentYear(year) {
      this.currentYear = year;
      this.monthMoment = moment(new Date(this.currentYear, this.currentMonth));
      // this.dispatchMonthTargets();
    },
    setCurrentMonth(month) {
      this.currentMonth = month;
      this.monthMoment = moment(new Date(this.currentYear, this.currentMonth));
      // this.dispatchMonthTargets();
    },
    setCurrentDate(date) {
      this.$emit('setdate', date);
    },
    getLastOnline() {
      let payload = {
        "filters": {
          "target_id": [this.target.id],
          "status": ["online"]
        },
        "order_by": "created_at",
        "order_type": "desc",
        "limit": 1,
        "offset": 0
      };

      this.loading = true;
      this.$store.dispatch('monitoring/lastOnline', payload).then((answer) => {
        if(answer.length) {
          this.loading = true;
          this.date = moment(answer[0].created_at, 'X').toDate();
          if(this.$route.name === 'Result') {
            if(this.selectedIndexes.length) {
              // this.dispatchMonthTargets();
            }
          }
        } else {
          this.loading = false;
        }
      });
    }
  },
  watch: {
    monthStatuses() {
      this.events = this.monthStatuses
    },
    selectedIndexes() {
      // this.dispatchMonthTargets();
    },
  },
  mounted() {
    this.monthMoment = moment(new Date(this.currentYear, this.currentMonth));
    this.$store.dispatch('monitoring/currentSourceAction', this.$route.name)

    if(typeof this.target != 'undefined') {
      this.getLastOnline();
    }
  }
}
</script>

<style lang="scss">/*

*/</style>