<template>
<div class="mt-3">
  <div class="d-flex justify-content-center mb-3" v-if="loading">
    <b-spinner label="Loading..."></b-spinner>
  </div>
  <div v-else>
    <NotificationItem v-for="notification in notifications" v-if="!isGreater(notification.id)" :notification="notification" :key="notification.id"/>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import NotificationItem from "@/components/NotificationItem.vue";
import moment from "moment";
import axios from "@/configs/axios";

export default {
  name: "NotificationList",
  components: {NotificationItem},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('ui', ['notifications'])
  },
  methods: {
    getNotifications() {
      this.$store.dispatch('ui/getNotifications').finally(() => {
        this.loading = false
      })
    },
    isGreater(id) {
      if(localStorage.getItem('notifications')) {
        const notifications = JSON.parse(localStorage.getItem('notifications'));
        if(notifications[id] && moment().format('YYYY-MM-DD') === notifications[id]) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.getNotifications()
  },
  watch: {
    "$i18n.locale": function () {
      this.loading = true;
      this.getNotifications();
    },
  }
}
</script>

