import _ from "underscore";
import { OptionsModel } from "./OptionsModel";
export class Sources extends OptionsModel{
    
    constructor(param) {
        const options = {
            cache_search_enabled: false,
            online_search_enabled: false
        }
        const storageName = "searchSources";
        super(param, options, storageName)
        this.initParam()
    }

}

