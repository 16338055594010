<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="form-group">
        <textarea v-model="payload.value" class="form-control" rows="4" :placeholder="'+184716391410\n+936047291513\n+335193618345'"></textarea>
      </div>
      <OptionsSearch param="mass_isdn" @push="setPayload" @invalid="setInvalid" />

      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button variant="primary" type="submit" class="btn" :disabled="$v.payload.$invalid">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>
    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import massNumber from "@/helpers/massNumber";
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import OptionsSearch from '../optionsSearch.vue';
import openResult from "@/mixins/openResult";
import {validationMixin} from 'vuelidate'


export default {
  name: "massIsdnForm",
  components:{OptionsSearch},
  data() {
    return {
      payload: {
        param: 'mass_isdn',
        value:''
      },
      invalid: true,
      modalDialog: false,
      showBtn: true,
    }
  },
  mixins: [validationMixin, openResult],
  computed:{
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters("auth", ["user"]),
  },
  validations() {
    return {
      payload: {
        value: {
          required
        },
        optionsValid() {
          return !this.invalid
        }
      }
    }
  },
  methods:{
    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    beforeSearch() {
      let value = this.payload.value;
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    addSearch() {
        const valuesA = this.payload.value.split("\n");
        valuesA.map((item) => {
          if (item.length < 5){
            return
          }
          let payloadObj = JSON.parse(JSON.stringify(this.payload));
          let payload = Object.assign(payloadObj, {
            param: "isdn",
            value: massNumber(item),
          });
          this.$store.dispatch("contacts/search", {
            payload,
          });
        });
        this.payload.param = "mass_isdn";
        this.payload.value = null;
        return true;
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
  }
}
</script>

<style scoped>

</style>