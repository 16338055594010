export default function (number) {
    let _deltas = [0, 1, 2, 3, 4, -4, -3, -2, -1, 0],
        _checksum = 0,
        _digits = [],
        i,
        j;

    while (number != 0) {
        _digits.push(parseInt((number % 10), 10));

        number = parseInt((number / 10), 10);
    }

    for (i = (_digits.length - 1), j = _digits.length; i > -1; i--) {
        let _digit = _digits[i];
        _checksum += _digit;
        _checksum += ((((i - j) % 2) === 0) ? _deltas[_digit] : 0);
    }

    if((_checksum % 10) === 0) {
        return 0;
    } else {
        return 10 - (_checksum % 10);
    }
}