<template>
  <div class="card" style="margin-top: 2rem; border-radius: 5px">
    <div id="map" style="width: 100%; height: 400px;border-radius: 5px"></div>
    <div id="images"></div>
  </div>
</template>

<script>
import drawLocales from "leaflet-draw-locales";
import L from "leaflet";
import filters from "@/mixins/filters";
import { LMap, LPolyline, LTileLayer } from "vue2-leaflet";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";
import _ from "underscore"
import { mapGetters } from "vuex";
import "@/plugins/wise-leaflet-pip"
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';

const pinMarker = L.Marker.extend({
  options: {
    targetId: ''
  }
})

const CustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon.png'
  }
});
const CustomMarker2 = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon-modified.png'
  }
});

export default {
  name: "mapResult",
  mixins: [filters],
  components: { LMap, LTileLayer, LDrawToolbar },
  props: ['pins', 'location'],
  data() {
    return {
      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [60.117188, 51.134555],
      map: null,
      startZoom: 3,
      maxRadius: 100000,
      drawnItems: null,
      bounds: null
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale;
    },
    ...mapGetters('full_search', ['resultMapCenter'])
  },

  methods: {
    async initMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale
      this.map = await L.map('map', { zoomControl: false, attributionControl: false, center: this.center, zoom: this.startZoom });
      const tile = L.tileLayer(this.url, { maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map);
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map);
      L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);
      var southWest = L.latLng(-89.98155760646617, -180), 
            northEast = L.latLng(89.99346179538875, 180); 
            var bounds = L.latLngBounds(southWest, northEast); 
            
            this.map.setMaxBounds(bounds); 
            this.map.on('drag', () => { 
                this.map.panInsideBounds(bounds, { animate: false }); 
            });
      L.Circle.include({
        contains: function (latLng) {
          return this.getLatLng().distanceTo(latLng) < this.getRadius();
        }
      });
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
      // tile.on('load', (event) => {
      //     this.setCardImage()
      // });
    },
    async setFigures() {
      if (this.location) {
        for (const figure of this.location) {
          switch (figure.type) {
            case 'circle':
              await this.setCircle(figure);
              break;
            case 'polygon':
              await this.setPolygon(figure);
              break;
            case 'point':
              await this.setPoint(figure);
              break;
            default:
              break;
          }
        }
        this.bounds = this.drawnItems.getBounds();
        this.map.fitBounds(this.bounds);
      }
    },
    async setBounds() {
            return new Promise(resolve => {
              this.map.fitBounds(this.drawnItems.getBounds());
              resolve('seted')
            })
        },
    setCircle(figures) {
      const coordinates = figures.coordinates;
      const circle = L.circle([coordinates[1], coordinates[0]], {
        radius: figures.radius
      })
      this.drawnItems.addLayer(circle);
    },
    async setPolygon(figures) {
      const geoJSON = {
        "type": "Polygon",
        "coordinates": figures.coordinates
      };
      const polygon = L.geoJSON(geoJSON)
      for (const poly of Object.values(polygon._layers)) {
        this.drawnItems.addLayer(poly);
      }
    },
    setPoint({ coordinates }) {
      // console.log('point');
      // this.center = [coordinates[1], coordinates[0]];
      // const marker = L.marker(this.center, {
      //   icon: new CustomMarker()
      // })
      // marker.addTo(this.map);
      // this.map.setView(this.center, 10)
    },
    setPins() {
      for (const pin of this.pins) {
        const containsArray = [];
        if(!this.bounds) {
          containsArray.push(true)
        }
        for (const k of Object.keys(this.drawnItems._layers)) {
          const bounds = this.drawnItems._layers[k].getBounds()
          containsArray.push(bounds ? this.drawnItems._layers[k].contains(new L.latLng(pin.location[0], pin.location[1])) : true);
        }
        const contains = _.some(containsArray)
        const title = contains ? pin.title : `${this.$t('geo.in_connection')} ${pin.title}`
        let marker = new pinMarker(pin.location, { icon: contains ? new CustomMarker() : new CustomMarker2(), targetId: pin.contact }).addTo(this.map);
        marker.on('click', (e) => {
          document.getElementById(e.target.options.targetId).scrollIntoView({ block: "start", behavior: "smooth" });
        });
        marker.bindTooltip(title)
      }
    },
    async setCardImage() {
      // setTimeout(() => {
      //   leafletImage(this.map, (err, canvas) => {
      //     // now you have canvas
      //     // example thing to do with that canvas:
      //     var img = document.createElement('img');
      //     var dimensions = this.map.getSize();
      //     img.width = dimensions.x;
      //     img.height = dimensions.y;
      //     img.src = canvas.toDataURL();
      //     document.getElementById('images').innerHTML = '';
      //     document.getElementById('images').appendChild(img);
      // });
      // }, 1500);
      let overridedPluginOptions = {
                mimeType: 'image/jpeg'
                }
      
      const screenShoter = new SimpleMapScreenshoter().addTo(this.map);
      this.setBounds().then( () => {
        screenShoter.takeScreen('image', overridedPluginOptions).then(image => {
          this.$emit('setSchemaPic', image);
          // setTimeout(() => {
          //   this.$emit('setSchemaPic', image) 
          // }, 2000)
        })
      })
      // this.map.on('load', () => {
      // })
    }
  },
  async mounted() {
  await this.initMap();
  await this.setFigures();
  await this.setPins();
  // await this.setCardImage();
},
watch: {
  resultMapCenter: {
    deep: true,
      handler(val) {
      this.map.setView(val, 12)
      this.map.eachLayer(element => {
        const pinLocation = new L.LatLng(val[0], val[1])
        if (pinLocation.equals(element._latlng)) {
          element.openTooltip()
          window.scrollTo(0, 0)
        } else {
          element.closeTooltip()
        }
      });
    }
  }
}
}
</script>

<style>
.leaflet-control-simpleMapScreenshoter.leaflet-control {
  display: none;
}
</style>