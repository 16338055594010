<template>
    <div>
        <div class="card text-bg-light" animation="slide" aria-id="contentIdForA11y3" :open="expanded">
            <div class="card-header addiotion-options-paddings" 
                data-bs-toggle="collapse" 
                data-bs-target="#searchOptionsCollapse" 
                aria-expanded="true">
                <span class="container-span-option">
                    {{ $t("searchOptions.options") }}:
                    <span  v-for="(item, k) in visualOptions" 
                        :key="k" 
                        class="visual-option-span">
                        {{ $t(`visualOptions.${item}`) }}{{ k === visualOptions.length - 1 ? '' : ', ' }}
                    </span>
                </span>
            </div>
            <div class="collapse" :class="{'show': !expanded}" id="searchOptionsCollapse">
                <div class="card-body">
                    <div class="content">
                        <div class="row">
                            <SearchSources 
                                :param="param" 
                                ref="searchSources"
                                @invalid="(invalid)=>setInvalid(invalid, 'sourceInvalid')" 
                                @push="(data) => {setPayload(data, 'searchSources')}" />
                            <SearchAlgorithms 
                                :param="param" 
                                @push="(data) => {setPayload(data, 'searchAlgorithms')}"
                                v-if="!['mass_isdn', 'image'].includes(param)" />
                        </div>
                        <Services
                            @ratesLoading="ratesLoading"
                            @push="(data) => {setPayload(data, 'services')}"
                            @serviceOptions="setPayload" :param="param" ref="services"
                            @invalid="(invalid)=>setInvalid(invalid, 'services')" 
                             />
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import SearchSources from './searchSources.vue';
    import SearchAlgorithms from './searchAlgorithms.vue';
    import Services from './services.vue';
    import _ from "underscore";
    export default {
        data() {
            return {
                expanded: false,
                validation: {
                    sourceInvalid: false
                },
                payload: {

                },
                visualOptions: []
            }
        },
        props: ['param'],
        components: {
            SearchSources,
            SearchAlgorithms,
            Services
        },
        computed: {
            
        }, 
        methods: {
            ratesLoading(val){
              this.$emit('ratesLoading', val)
            },
            setInvalid(invalid, componentName) {
                this.validation[componentName] = invalid;
                const validArray = Object.values(this.validation)
                const invalidFull = validArray.indexOf(true) >= 0;
                this.$emit('invalid', invalidFull);
            },
            setPayload(data, optionsType) {
                this.payload = Object.assign(this.payload, data);
                this.$emit('push', this.payload)
                this.setVisualOptions();
                if(optionsType === 'searchAlgorithms') {
                    this.$refs.services.setAlgorithms(data)
                    this.$refs.searchSources.setAlgorithms(data)
                }
                if(optionsType === 'services') {
                    this.$refs.searchSources.setServices(data)
                }
                if(optionsType === 'searchSources') {
                    this.$refs.services.setSearchSources(data)
                }
            },
            setVisualOptions() {
                const keys = Object.keys(this.payload);
                this.visualOptions = [];
                const options = [];
                for(const key of keys) {
                    if(typeof this.payload[key] !== 'undefined') {
                        if(key !== 'service_options') {
                            options.push(`${key}_${this.payload[key]}`);
                        } else {
                            if(!_.isUndefined(this.payload[key].GetContact)) {
                                options.push(`get_contact_${this.payload[key].GetContact.request_options.tags}`);
                            }
                        }
                    }
                }
                const filteredOptions = options.filter(item => {
                    const optionsToFilter = [
                        'service_types_online',
                        'service_types_face',
                        'get_contact_false',
                        'services',
                        'services_',
                        'online_search_enabled_false',
                        'cache_search_enabled_false'];
                    if(this.payload.param !== 'isdn') {
                        optionsToFilter.push('service_types_');
                    }
                    
                    return !optionsToFilter.includes(item) && !item.includes('services_');
                })
                // if(this.param !== 'image') {
                    this.visualOptions = this.visualOptions.concat(filteredOptions);
            }
        }
    }
</script>
<style>
    .card .card-header span.container-span-option {
        font-weight: 700;
        font-size: 1em;
    }
    .card .card-header span.visual-option-span {
        display: inline-block;
        font-size: 1em;
    }
    .visual-option-span {
        display: inline-block;
        margin-right: 5px;
        font-weight: 700;
        
        margin-top: 0;
    }
    .card .card-header.addiotion-options-paddings {
        padding-top: 0;
        padding-bottom: 8px;
        padding-left: 16px;
    }
</style>