import moment from "moment";

export default function(payload) {
    const payloadUpdated = JSON.parse(JSON.stringify(payload));
    if(payload.filters !== undefined) {
        if(
            typeof payload.filters.search !== "undefined" &&
            (payload.filters.search === null || payload.filters.search.length === 0)
        ) {
            delete payloadUpdated.filters.search;
        }
        if(payload.filters.created_from === null) {
            delete payloadUpdated.filters.created_from;
        }
        if(payload.filters.created_to === null) {
            delete payloadUpdated.filters.created_to;
        }
        if(typeof payloadUpdated.filters !== "undefined" && payloadUpdated.filters.created_from) {
            payloadUpdated.filters.created_from = parseInt(
            moment(payloadUpdated.filters.created_from).toDate().getTime() / 1000);
        }
        if(typeof payloadUpdated.filters !== "undefined" && payloadUpdated.filters.created_to) {
            payloadUpdated.filters.created_to = parseInt(
            moment(payloadUpdated.filters.created_to).add(1, 'day').toDate().getTime() / 1000);
        }
        if(typeof payloadUpdated.filters !== 'undefined' && Object.keys(payloadUpdated.filters).length === 0) {
            payloadUpdated.filters = undefined;
        }
    }
    return payloadUpdated;
}