<template>
  <div style="z-index: 2" v-if="typeof totalCost !=='undefined'">
    <div class="d-flex d-lg-none">
      <div class="iconContainer" v-b-modal="`cost_${ident}`" data-bs-toggle="modal" >
        <!-- <img style="width: 16px;height: 16px"  :src="prefix+'images/coin.png'"> -->
        <span class="itemCost">{{ totalCost }}</span>
      </div>
      <b-modal v-if="totalCost> 0" :id="`cost_${ident}`" centered hide-footer :title="$t('search.search_results_cost_total') +': ' +totalCost">
        <div v-for="(value, name, ind) in grouped" :key="ind" v-if="value.length && und.reduce(value, (mem, val) => mem + val.cost, 0)">
            <div style="padding-left: 6px;" v-if="Object.keys(grouped).length > 1">{{ $t('billing.iteration') }}: {{ name.split(',')[0] }} ({{ $t('params.'+name.split(',')[1]) }})</div>
            <div class="list"  v-for="(item, index) in und.sortBy(value, 'name')" :key="index">
              <div class="source" v-if="item.cost > 0" style="display:flex; justify-content: space-between">
                <div style="display: flex">
                  <!-- <img style="width: 14px; height: 14px" :src="item.icon"> -->
                  <span style="padding-left: 4px;">{{ item.name }} {{ item.local ? `(${$t('params.cache')})`: '' }}</span>
                </div>
                <div style="display: flex">
                  <span>: </span>
                  <span> {{ item.cost}}</span>
                </div>
              </div>
            </div>
        </div>
        <footer class="d-grid gap-2">
<!--          <div class="total paddingSide">{{ $t('search.search_results_cost_total') }}: {{ totalCost }}</div>-->
        </footer>
      </b-modal>
    </div>



    <div v-if="header" class="d-none d-lg-block" style="cursor: pointer;" data-bs-toggle="dropdown" :disabled="totalCost === 0">
      <div class="iconContainer">
        <img style="width: 16px;height: 16px"  :src="prefix+'images/coin.png'">
        <span class="itemCost">{{ totalCost }}</span>
        <div v-if="show" class="costContainer">
          <div class="title paddingSide" style="white-space: nowrap">{{title}}</div>
          <div v-for="(value, name, ind) in grouped" :key="ind" v-if="value.length && und.reduce(value, (mem, val) => mem + val.cost, 0)">
            <div style="padding-left: 6px;" v-if="Object.keys(grouped).length > 1">{{ $t('billing.iteration') }}: {{ name.split(',')[0] }} ({{ $t('params.'+name.split(',')[1]) }})</div>
            <div class="headerDropdown paddingSide list" style="line-height: 18px;font-size: 14px" v-for="(item, index) in und.sortBy(value, 'name')" :key="index">
              <div class="source" v-if="item.cost !== 0">
                <img style="width: 14px; height: 14px" :src="item.icon">
                <span style="padding-left: 4px;">{{ item.name }} {{ item.local ? `(${$t('params.cache')})`: '' }}</span>
                <div>: {{item.cost}}</div>
              </div>
            </div>
          </div>
<!--          <div class="total paddingSide" style="white-space: nowrap">{{ $t('search.search_results_cost_total') }}: {{ totalCost }}</div>-->
        </div>
      </div>
    </div>



      <div v-if="!header" class="dropdown-toggle d-none d-lg-block" :class="{'zeroCost': totalCost === 0 , 'hesCost' : totalCost !== 0 }" data-bs-toggle="dropdown" :disabled="totalCost === 0">
        <div class="iconContainer">
          <!-- <img style="width: 16px;height: 16px"  :src="prefix+'images/coin.png'"> -->
          <span class="itemCost badge badge-inverse-info"> {{ totalCost }}</span>
        </div>
      </div>
      <div class="dropdown-menu dropdownContent" style="max-height: 15rem; overflow: hidden; overflow-y: auto" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
        <div class="title paddingSide">{{ title }}</div>
        <div v-for="(value, name, ind) in grouped" :key="ind" v-if="value.length && und.reduce(value, (mem, val) => mem + val.cost, 0)">
          <div style="padding-left: 6px;" v-if="Object.keys(grouped).length > 1">{{ $t('billing.iteration') }}: {{ name.split(',')[0] }} ({{ $t('params.'+name.split(',')[1]) }})</div>
          <div class=" content paddingSide list" style="line-height: 18px;font-size: 14px" v-for="(item, index) in und.sortBy(value, 'name')" :key="index">
            <div class="source" v-if="item.cost !== 0">
              <img style="width: 14px; height: 14px" :src="item.icon">
              <span style="padding-left: 4px;">{{ item.name }} {{ item.local ? `(${$t('params.cache')})`: '' }}</span>
              <div>: {{item.cost}}</div>
            </div>
          </div>
        </div>
<!--        <div class="total paddingSide" style="white-space: nowrap">{{ $t('search.search_results_cost_total') }}: {{ totalCost }}</div>-->
      </div>
  </div>
</template>

<script>
import env from "@/configs/env.json";
import i18n from "@/i18n";
import _ from "underscore";


export default {
  name: "searchCost",
  props: ['cost', 'totalCost','ident','position','header'],
  setup() {
    return {
      und: _
    }
  },
  data() {
    return {
      prefix: env.prefix,
      show: false
    }
  },
  computed:{
    title(){
      return this.$t('search.search_results_cost_total')+': '+ this.totalCost
    },
    locale(){
      return i18n.locale === 'ua' ? 'uk' : i18n.locale
    },
    grouped() {
      const cost = this.cost.map(item => {
        const name = item.source_locales ? item.source_locales[this.locale] : item.source
        return Object.assign(item, {name})
      })
      return _.groupBy(cost, (item) => [item.level, item.param, item.value])
    }
  },
  methods:{
    showCost(){
      if (this.totalCost > 0){
        this.show = !this.show
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .iconContainer{
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    .itemCost{
      padding: 2px 8px;
    }
  }
  .costContainer{
    z-index: 111;
    background-color: #FFFFFF;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    top: 30px;
    left: 0;
  }
  .headerDropdown{
    width: max-content;
    display: flex;
  }

  .source{
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    align-items: flex-start;
    img{
      margin-top: 4px;
    }
  }
  .list:last-child{
    margin-bottom: 0.8rem;
  }
  .paddingSide{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .zeroCost::after{
    display: none;
  }
  .hesCost{
    //padding-top: 1.2rem;
    &::after {
      margin-left: 7px;
    }
  }


  .title{
    padding-top: 4px;
    padding-bottom: 2px;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.10);
  }
  .total{
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-weight: 600;
  }
</style>