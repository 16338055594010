<template>
    <div class="card full-text-block">
        <div class="card-body" style="padding-bottom: 8px; padding-top: 8px">
            <div class="form-block">
                <div class="form-block-left">
                    <div class="row">
                        <div class="col-sm-4 text-capitalize position-relative">
                            <div class="element-label">
                                {{ $t('fullText.address.address') }}
                                <i class="feather icon-info primaryIcon m-l-5" 
                                    v-tippy="{ placement : 'top',  arrow: true }" 
                                    :content="$t('fullText.addressHelp')"></i>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <select class="form-select full-text-element-select" v-model="payload.operation">
                                <option v-for="operation in operations" :value="operation">{{ $t('fullText.' + operation) }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.payload.value.$invalid && $v.payload.value.$anyDirty }"
                                    @input="operateValue" v-model="payload.value">
                        </div>
        
                    </div>
                </div>
                <div class="form-block-right">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-danger" @click="$emit('remove')"><i class="feather icon-trash-2"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-center full-text-block-connection connection-element" v-if="showConnection"><span class="label label-inverse-primary">{{ $t('fullText.and') }}</span></p>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import RuleValidation from "@/components/RuleValidation.vue";
export default {
    name: 'FullTextBlockAddress',
    props: ['filter', 'showConnection', 'firstBlock'],
    mixins: [validationMixin],
    components: { RuleValidation },
    data() {
        return {
            payload: {
                operation: 'match_query',
                value: null
            },
            operations: [
                'match_query',
                'match_substr',
                'match_prefix',
                'match_suffix',
            ],
            validationRules: {
                required
            },
            conditions: [],
            filters: []
        }
    },
    validations() {
        return {
            payload: {
                value: this.validationRules
            }
        }
    },
    watch: {
        payload: {
            handler() {
                this.$emit('invalid', this.$v.$invalid);
            }
        }
    },
    mounted() {
        this.$emit('invalid', this.$v.$invalid);
    },
    methods: {
        operateValue() {
            this.filters = this.payload.value.trim().split(' ').map(word => {
                return {
                    optional: false,
                    fields: ['address'],
                    conditions: [
                        {
                            operation: this.payload.operation,
                            value: word.replace(/[^а-яА-ЯёЁa-zA-Z0-9-ІіЇїЄє]/g, '')
                        }
                    ]
                }
            });
            
            this.$v.payload.value.$touch()
            this.$emit('invalid', this.$v.$invalid);
            this.$emit('filters', this.filters);
        }
    }
}
</script>