<template>
  <div class="accordion" role="tablist" >
    <stage v-for="(item,index) in level"
           :key="index" :item="item" :level="item.level"
           :meta="meta" :errors="errors"
           :result-visible="resultVisible"></stage>
  </div>
</template>

<script>
import Stage from "@/components/Stage.vue";

export default {
  name: "Level",
  components: {
    Stage,
  },
  props: ['level', 'meta', 'errors', 'resultVisible'],
  computed: {
    // sources() {
    //   return this.results.filter(result => !result.search_results && result.success).map(result => result.source)
    // },
    // finalServices() {
    //   return this.services.filter(result => this.sources.includes(result.name)
    //       && result.search_params.includes(this.results[0].param));
    // },
    // errorServices() {
    //   const sources = this.results.filter(result => !result.search_results && !result.success).map(result => result.source)
    //   return this.services.filter(result => sources.includes(result.name)
    //       && result.search_params.includes(this.results[0].param));
    // }
  }
}
</script>