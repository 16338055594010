export default {
    namespaced: true,
    state: {
        alt_values: undefined,
        enableAltValues: true,
        filters: [],
        enableAge: true,
        enableAddress: true,
        searchButtonNeedDisable: false
    },
    getters: {
        alt_values: state => state.alt_values,
        filters: state => state.filters,
        searchButtonNeedDisable: state => state.searchButtonNeedDisable,
        enableAltValues: state => state.enableAltValues,
        enableAge: state => state.enableAge,
        enableAddress: state => state.enableAddress,
    },
    actions: {
        nameFilters({commit}, names) {
            commit('setNameFilters', names)
        },
        ageFilters({commit}, age) {
            commit('setAgeFilters', age)
        },
        deleteDate({commit}){
            commit('setNoDate')
        },
        addressFilters({commit}, address) {
            commit('setAddressFilters', address)
        },
        changeButtonState({ commit }, enable){
            commit('setButtonState', enable)
        },
        changeAltValueState({ commit }, enable){
            commit('setAltValueState', enable)
        },
        changeAgeState({ commit }, enable){
            commit('setAgeState', enable)
        },
        changeAddressState({ commit }, enable){
            commit('setAddressState', enable)
        },
    },
    mutations: {
        setNameFilters(state, names) {
            state.alt_values = names;
        },
        setAgeFilters(state, age) {
            if (state.filters){
                let temp = state.filters
                temp = temp.filter(el => el.param !== 'birthdate');
                temp = temp.concat(age)
                state.filters = temp
            } else {
                state.filters = age;
            }
        },
        setNoDate(state){
            if (state.filters.length){
                let temp = state.filters
                temp = temp.filter(el => el.param !== 'birthdate');
                state.filters = temp
            }
        },
        setAddressFilters(state, address) {
            if (state.filters){
                let temp = state.filters
                temp = temp.filter(el => el.param !== 'address' && el.param !=='city')
                temp = temp.concat(address)
                state.filters = temp
            } else {
                state.filters = address;
            }
        },
        setAltValueState(state, enable){
            state.enableAltValues = enable;
        },
        setAgeState(state, enable){
            state.enableAge = enable
        },
        setAddressState(state, enable){
            state.enableAddress = enable
        },
        setButtonState(state, enable){
            state.searchButtonNeedDisable = enable;
        },
    }
}