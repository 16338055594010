<template>
  <div>
    <div v-if="!loadingResult" class="result m-b-50">
      <div class="d-grid d-lg-flex justify-content-lg-end btn-save" >
        <button class="btn btn-primary" :disabled="enablePDF || !isAnyResultsLeft || isLoading"
                @click="promptPdf()" style="text-transform: none"><b-spinner small v-if="enablePDF || isLoading"></b-spinner><i v-else class="feather icon-download"></i> <span style="padding-left: 4px;">{{ $t("pdf.save_pdf") }}</span>
        </button>
      </div>
      <div>
        <div class="card d-none" style="margin-top: 2rem">
          <div class="card-body">
  
  
            <div class="searchedPhotoContainer m-b-30">
              <div class="search-photo-container" id="searchPhotoId" v-if="results.length && results[0].param === 'image'">
                <div class="row">
                  <div class=" full-photo-wrapper d-flex"
                    :class="{
                      'col-sm-6': showPreview,
                      'col-sm-12': !showPreview,
                      'justify-content-center': !showPreview,
                      'justify-content-end': showPreview
                      }">
                    <image-profile :size="'256px'" :need-return-src="true" :payload="results[0].value" @returnSrc="returnSrc" />
                  </div>
                  <div class="col-sm-1 d-flex align-items-center justify-content-center" v-if="showPreview">
                    <div class="text-center fs-1">
                      <i class="feather icon-chevron-right d-none d-lg-inline"></i>
                      <i class="feather icon-chevron-down d-inline d-lg-none"></i>
                    </div>
                  </div>
                  <div class="col-sm-2 d-flex justify-content-center justify-content-lg-start align-items-center" v-if="showPreview">
                    <searched-face ref="searchedFace" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs md-tabs bg-white" style="margin-top: 2rem" role="tablist" v-if="isAnyResultsLeft">
          <li class="nav-item" v-if="grouped.online && grouped.online.length !== 0 && isNotEmpty(grouped.online)">
            <a class="nav-link" data-bs-toggle="tab" href="#vk" role="tab">VK</a>
            <div class="slide"></div>
          </li>
          <li class="nav-item" v-if="grouped.local && grouped.local.length !== 0 && isNotEmpty(grouped.local)">
            <a class="nav-link active" data-bs-toggle="tab" href="#home3" role="tab">Local Database</a>
            <div class="slide"></div>
          </li>
          <li class="nav-item" v-if="grouped.faceSearchGlobal && grouped.faceSearchGlobal.length > 0 && isNotEmpty(grouped.faceSearchGlobal)">
            <a class="nav-link" :class="{active: !grouped.local || grouped.local.length === 0}" data-bs-toggle="tab" href="#profile3" role="tab">Global</a>
            <div class="slide"></div>
          </li>
          <li class="nav-item" v-if="grouped.faceSearchSocial && grouped.faceSearchSocial.length > 0 && isNotEmpty(grouped.faceSearchSocial)">
            <a class="nav-link" :class="{active: (!grouped.local || grouped.local.length === 0)
                && (!grouped.faceSearchGlobal || grouped.faceSearchGlobal.length === 0 || !isNotEmpty(grouped.faceSearchGlobal))
            }"
               data-bs-toggle="tab" href="#messages3" role="tab">Social</a>
            <div class="slide"></div>
          </li>
          <li class="nav-item" v-if="grouped.yandex && grouped.yandex.length > 0 && isNotEmpty(grouped.yandex)">
            <a class="nav-link" :class="{active: (!grouped.local || grouped.local.length === 0)
              && (!grouped.faceSearchGlobal || grouped.faceSearchGlobal.length === 0 || !isNotEmpty(grouped.faceSearchGlobal))
              && (!grouped.faceSearchSocial || grouped.faceSearchSocial.length === 0 || !isNotEmpty(grouped.faceSearchSocial))
            }"
               data-bs-toggle="tab" href="#yandex3" role="tab">YandexPhoto</a>
            <div class="slide"></div>
          </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content" v-if="isAnyResultsLeft">
          <div class="tab-pane" id="vk" v-if="grouped.online && grouped.online.length !== 0">
            vk
            <div v-if="grouped.online && grouped.online.length !== 0">
  
              <ChildContact v-if="grouped.online[0].search_results" :result="result" v-for="(result, k) in grouped.online" :key="k" :itter="k" :meta="meta"
                :result-online-length="grouped.online[0].search_results.length"></ChildContact>
            </div>
            <section v-else>
              <div>
                <h3>{{ $t("result.nothing_found") }}</h3>
              </div>
            </section>
          </div>
          <div class="tab-pane active" id="home3" role="tabpanel" v-if="grouped.local && grouped.local.length !== 0">
            <local-database-search-result :grouped="grouped.local" :meta="meta" :icon="grouped.localIcon"
              @isLocalResultEmpty="isLocalEmpty()" :result="grouped.local[0]" :photosrc="photoSrc" />
          </div>
          <div class="tab-pane" :class="{active: !grouped.local || grouped.local.length === 0}" id="profile3" role="tabpanel"
            v-if="grouped.faceSearchGlobal && grouped.faceSearchGlobal.length > 0 && isNotEmpty(grouped.faceSearchGlobal)">
            <local-database-search-result :result="grouped.faceSearchGlobal[0]" :photosrc="photoSrc" :grouped="grouped.faceSearchGlobal" :meta="meta" :icon="grouped.globalIcon" />
          </div>
          <div class="tab-pane" :class="{active: (!grouped.local || grouped.local.length === 0)
                && (!grouped.faceSearchGlobal || grouped.faceSearchGlobal.length === 0 || !isNotEmpty(grouped.faceSearchGlobal)) 
            }" id="messages3" role="tabpanel"
            v-if="grouped.faceSearchSocial && grouped.faceSearchSocial.length > 0 && isNotEmpty(grouped.faceSearchSocial)">
            <local-database-search-result :result="grouped.faceSearchSocial[0]" :photosrc="photoSrc" :grouped="grouped.faceSearchSocial" :meta="meta" :icon="grouped.socialIcon" />
          </div>
  
          <div class="tab-pane" :class="{active: (!grouped.local || grouped.local.length === 0)
              && (!grouped.faceSearchGlobal || grouped.faceSearchGlobal.length === 0 || !isNotEmpty(grouped.faceSearchGlobal))
              && (!grouped.faceSearchSocial || grouped.faceSearchSocial.length === 0 || !isNotEmpty(grouped.faceSearchSocial))
            }" id="yandex3" role="tabpanel"
               v-if="grouped.yandex && grouped.yandex.length > 0">
            <local-database-search-result :result="grouped.yandex[0]" :photosrc="photoSrc" :grouped="grouped.yandex" :meta="meta" :icon="grouped.yandexIcon" />
          </div>
  
        </div>
  
  
        <div class="noResults" style="margin-top: 2rem" v-show="!isAnyResultsLeft">
          <div>
            <h3 style="display: flex;justify-content: center">{{ $t("result.search_results_have_been_deleted") }}</h3>
          </div>
        </div>
      </div>
  
      <b-modal v-model="promptPdfActive" :title="$t('pdf.save_file')" has-modal-card centered hide-footer trap-focus :destroy-on-hide="false"
        aria-role="dialog" aria-label="Example Modal" aria-modal>
        <PdfPrompt v-bind="formProps" @close="closeModal" @doc-data="resultToDocument" @pdf-data="pdfUpdateData">
        </PdfPrompt>
      </b-modal>
    </div>
    <div v-else class="d-flex justify-content-center">
      <b-spinner/>
    </div>
  </div>
</template>

<script>
import ChildContact from "@/components/ChildContact";
import { mapGetters, mapState } from 'vuex';
import savePdf from "@/mixins/savePdf";
import filters from "@/mixins/filters";
import LocalDatabaseSearchResult from "@/components/localDatabaseSearchResult";
import SearchedFace from "@/components/results/searchedFace";
import ImageProfile from "@/components/ImageProfile";
import imageMixin from "@/mixins/imageMixin";
import saveDoc from "@/mixins/saveDoc";
import _ from "underscore";
import imageAxios from "@/configs/iamgeAxios";
export default {
  name: 'ChildResult',
  mixins: [savePdf, filters, imageMixin, saveDoc],
  components: {
    LocalDatabaseSearchResult,
    ChildContact,
    SearchedFace,
    ImageProfile,
  },
  data() {
    return {
      isLoading: false,
      loadingResult: false,
      routeName: this.$route.name,
      activeTab: 1,
      imageCount: 0,
      enablePDF: true,
      localResultsEmpty: undefined,
      photoSrc: null
    }
  },
  methods: {
    closeModal() {
      this.promptPdfActive = false;
    },
    loadAllImages() {
      for (let result of this.results) {
        if(result.search_results) {
          for (let searchResult of result.search_results) {
            for (let i = 0; i < searchResult.photos.length; i++) {
              this.loaderForImages(searchResult.photos[i].image)
            }
          }
        }
      }
    },
    isNotEmpty(result) {
      const arrayResults = [];
      if(_.isArray(result)) {
        for(const resultItem of result) {
          if(resultItem.search_results) {
            arrayResults.push(1)
          }
        }
      }
      return !!arrayResults.length;
    },
    async loaderForImages(payload) {
      let result = await this.checkCache(payload);
      if (result.isCached) {
        this.$store.dispatch('images/addImage', {
          key: payload,
          value: ''
        });
      } else {
        let axiosSettings;
        axiosSettings = {
          method: "get",
          responseType: 'arraybuffer',
          headers: {
            'Authorization': this.token 
          }
        }
        return new Promise((resolve, reject) => {
          // const imAxios = imageAxios(localStorage.getItem('token'))
          imageAxios(payload, axiosSettings)
            .then((resp) => {
              let blob = new Blob([resp.data], { type: "image/jpg" })
              let reader = new window.FileReader();
              reader.readAsDataURL(blob);
              reader.onload = () => {
                this.saveImage(reader.result, payload)
                this.$store.dispatch('images/addImage', {
                  key: payload,
                  value: ''
                });
              }
            })
            .catch(err => {
              reject(err)
            })
        })
      }
    },
    returnSrc($event) {
      this.photoSrc = $event;
      if (typeof this.results[0].face_select !== 'undefined' && this.meta.parent) {
        this.$refs.searchedFace.setImageParam($event, this.results[0]);
      }
    },
    countImages() {
      for (let result of this.results) {
        if(result.search_results) {
          for (let searchResult of result.search_results) {
            for (let i = 0; i < searchResult.photos.length; i++) {
              this.imageCount++;
            }
          }
        }
      }
    },
    isLocalEmpty() {
      let count = 0;
      this.grouped.local.forEach(item => {
        if (typeof item.search_results !== 'undefined' && item.search_results && item.search_results.length !== 0) {
          count++
        }
      })
      this.localResultsEmpty = count === 0;
    },
    resultsCount(result){
      let count = 0;
      result.forEach(item => {
        if(item.search_results) {
          item.search_results.forEach(result=>{
            count += result.photos.length
          })
        }
      })
      return count;
    },
    async getResultById() {
      return new Promise((resolve) => {
        if(!_.isUndefined(this.$route.params.id)) {
          this.loadingResult = true;
          this.$store.dispatch('contacts/updateResultPush', parseInt(this.$route.params.id)).finally(()=>{
            window.scrollTo({ top: 0, behavior: 'smooth' });
            resolve();
            this.loadingResult = false
          })
        } else {
          this.loadingResult = false
          resolve()
        }
      })
    },
    async initialAction() {
      await this.getResultById()
      await this.isLocalEmpty()
      // this.$nextTick(() => {
      await this.loadAllImages();
      // })
      await this.countImages();
      await this.setMountedPdfAction();
      await this.mountActionsDoc();
      await this.$store.dispatch('images/clearImages');
      await this.$store.dispatch('contacts/forceUpdateSearchParams');
    }
  },
  computed: {
    showPreview() {
      return typeof this.results[0].face_select !== 'undefined' && this.meta.parent
    },
    isAnyResultsLeft() {
      return typeof this.grouped.online[0] !== 'undefined' && this.grouped.online[0].length > 0
        || !this.localResultsEmpty
        || typeof this.grouped.faceSearchGlobal[0] !== 'undefined'
          && this.grouped.faceSearchGlobal[0].search_results
          && this.grouped.faceSearchGlobal[0].search_results.length > 0
        || typeof this.grouped.faceSearchSocial[0] !== 'undefined'
          && this.grouped.faceSearchSocial[0].search_results
          && this.grouped.faceSearchSocial[0].search_results.length > 0
        || typeof this.grouped.yandex[0] !== 'undefined'
          && this.grouped.yandex[0].search_results
          && this.grouped.yandex[0].search_results.length > 0;
    },

    ...mapGetters('contacts', ['results', 'meta']),
    // results() {
    //   return ['Result', 'Photo'].includes(this.$route.name) ? this.$store.state.contacts.results.results :
    //     this.$store.state.contacts.childResults.results
    // },
    // meta() {
    //   return ['Result', 'Photo'].includes(this.$route.name) ? this.$store.state.contacts.results.meta :
    //     this.$store.state.contacts.childResults.meta
    // },
    ...mapState({
      loading: state => state.contacts.loading
    }),
    ...mapGetters('images', ['images', 'photoLoaded']),
    grouped: function () {
      // let local = this.results.filter(item => (item.source !== 'FaceRecognition') && item.source !== 'FaceSearchGlobal' && item.source !== 'FaceSearchSocial' && item.source !== 'YandexPhoto')
      let local = this.results.filter(item => item.local)
      let localIcon;
      if (local.length) {
        localIcon = {
          icon: local[0].icon,
          source: local[0].source,
          type: local[0].type
        }
      }

      const online = this.results.filter(item => {
        return item.source === 'FaceRecognition' && !item.local;
      })

      const faceSearchGlobal = this.results.filter(item => {
        return item.source === 'FaceSearchGlobal' && !item.local;
      })

      const faceSearchSocial = this.results.filter(item => {
        return item.source === 'FaceSearchSocial' && !item.local;
      })


      const yandex = this.results.filter(item => {
        return item.source === 'YandexPhoto' && !item.local;
      })

      // let tempGlobal = this.results.filter(item => item.source === 'FaceSearchGlobal' && !item.local)
      let globalIcon
      if (faceSearchGlobal.length) {
        globalIcon = {
          icon: faceSearchGlobal[0].icon,
          source: faceSearchGlobal[0].source,
          type: faceSearchGlobal[0].type
        }
      }

      // let tempSocial = this.results.filter(item => item.source === 'FaceSearchSocial' && !item.local)
      let socialIcon
      if (faceSearchSocial.length) {
        socialIcon = {
          icon: faceSearchSocial[0].icon,
          source: faceSearchSocial[0].source,
          type: faceSearchSocial[0].type
        }
      }

      // let tempYandex = this.results.filter(item => item.source === 'YandexPhoto' && !item.local)
      let yandexIcon;

      if (yandex.length) {
        yandexIcon = {
          icon: yandex[0].icon,
          source: yandex[0].source,
          type: yandex[0].type
        }
      }




      return {
        local,
        localIcon,
        online,
        faceSearchGlobal,
        globalIcon,
        faceSearchSocial,
        socialIcon,
        yandex,
        yandexIcon
      }
    }
  },
  watch: {
    photoLoaded() {
      if (this.photoLoaded >= this.imageCount || this.imageCount === 0) {
        this.enablePDF = false;
      }
    },
    '$route.path': {
      handler(val) {
        this.initialAction();
      }
    }
  },
  async mounted() {
    this.initialAction();
  },
}
</script>
<style>
.search-photo-container img {
  max-width: 100%;
}

.nav-tabs.md-tabs {
  box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%);
}

.full-photo-wrapper img {
  max-height: 400px;
}
</style>
