import env from "@/configs/env.json";
const token = env.translationToken;
const googleTranslationApiUrl = 'https://translation.googleapis.com/language/translate/v2';
const clearAxios = require('axios').create();

export default {
    namespaced: true,
    state: {
        translation: []
    },
    getters: {
        translation: state => state.translation,
    },
    actions: {
        getTranslation({ commit }, {val, lang}) {
            return new Promise(resolve => {
                clearAxios({
                    url: googleTranslationApiUrl,
                    method: 'get',
                    params: { q: val, source:"ru", target: lang, key: token }
                })
                    .then(resp => {
                        const translation = resp.data.data.translations.map(item => item.translatedText);
                        commit('setTranslation', translation);
                        resolve(translation);
                    })
            })
        }
    },
    mutations: {
        setTranslation(state, translation) {
            let temp = []
            let re = /&#39;/gi;
            translation.forEach(word=>{
                let newWord;
                newWord = word.replace(re, '’')
                temp.push(newWord)
            })
            state.translation = temp;
        }
    }
}