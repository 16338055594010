import Dexie from "dexie";
const db = new Dexie('Results');

db.version(1).stores({
    results: '++id,key,result'
})

db.open().catch((err) => {
    console.error(`Error opening database: ${err.stack || err}`);
})

export {db};