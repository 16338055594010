import axios from "@/configs/axios";
import env from "@/configs/env.json";
const baseURL = `${env.statisticEndpoint}/api`;
export default {
    namespaced: true,
    state: {
        grabbers: [],
        monitors: []
    },
    getters: {
        grabbers: state => state.grabbers,
        monitors: state => state.monitors
    },
    actions: {
        getGrabbers({commit}) {
            return new Promise(resolve => {
                axios({baseURL,method: 'GET', url: 'software/grabberhubs'})
                    .then(resp => {
                        commit('setGrabbers', resp.data[0].results.grabbers);
                        commit('setMonitors', resp.data[0].results.monitors);
                        resolve(resp.data);
                    })
            })
        },
    },
    mutations: {
        setGrabbers(state, grabbers) {
            let first = grabbers.filter(el => { return typeof el.stats !== 'undefined'})
            let lust = grabbers.filter(el => { return typeof el.stats === 'undefined'})
            first = first.sort((a, b) => b.grabber_online - a.grabber_online);
            state.grabbers = first.concat(lust);
        },
        setMonitors(state, monitors) {
            state.monitors = monitors
        },
    },
}