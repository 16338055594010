<template>
  <div class="setting">

    <div  v-show="!open">
      <div class="open row" v-if="towns.length || streets.length">

        <div class="d-flex col-6 col-lg-4 border-checkbox-section">
          <div class="border-checkbox-group border-checkbox-group-primary">
            <input
                type="checkbox"
                v-model="checked"
                class="border-checkbox"
                id="checkbox-address-min"
            />
            <label class="border-checkbox-label" for="checkbox-address-min">
              <span>{{ $t("address.address") }}: </span>
            </label>
          </div>
        </div>

        <div class="col-6 col-lg-8">
          <div v-if="towns.length" style="display: flex; flex-direction: column">
            <div style="display: flex" v-if="typeof town !== 'undefined'">{{town}}<div style="margin-right: 3px" v-if="towns.length ">,</div></div>
            <div  style="display: flex" v-for="(town, index) in towns" :key="index">{{town}}<div style="margin-right: 3px" v-if="index !== towns.length-1 || typeof street !== 'undefined' ">,</div></div>
          </div>

          <div v-if="streets.length" style="display: flex;flex-direction: column">
            <div style="display: flex" v-if="typeof street !== 'undefined'">{{street}}<div style="margin-right: 3px" v-if="streets.length">,</div></div>
            <div  style="display: flex" v-for="(street, index) in streets" :key="index">{{street}}<div style="margin-right: 3px" v-if="index !== streets.length-1">,</div></div>
          </div>

        </div>

      </div>
    </div>

    <div v-show="open" class="settings-block">
      <div class="row">
          <div class="col-12 col-lg-3 settings-text border-checkbox-section" style="display: flex; margin-top: 0">
            <div class="border-checkbox-group border-checkbox-group-primary">
              <input class="border-checkbox" id="checkbox-address" type="checkbox" v-model="checked">
              <label for="checkbox-address" class="border-checkbox-label">
                <h4 class="title-search-name">{{ $t("address.address") }}</h4>
              </label>
            </div>
          </div>
         <div>
            <div>
              <div class="row align-items-center" style="margin-top: 0.5rem">
                <div class="col-lg-3 s-text"><span>{{ $t("address.locality") }}</span></div>
                <div class="col-lg-9">
                  <b-input type="text" :max="4" :placeholder="this.$t('address.enter_the_title')"  v-model="town"></b-input>
                </div>
              </div>
              <div class="row align-items-center" style="margin-top: 0.5rem">
                <div class="col-lg-3 s-text"><span>{{ $t("address.also_look_for_translations") }}</span></div>
                <div class="col-lg-9">
                  <b-form-tags  ref="townsInput" type="text" add-button-text="+" v-model="towns" icon="info-circle" :placeholder="this.$t('address.add_city')"
                                :aria-close-label="this.$t('address.remove_city')" :ellipsis="false">
                  </b-form-tags>
                </div>
              </div>
              <div class="row align-items-center" style="margin-top: 0.5rem">
                <div class="col-lg-3 s-text"><span>{{ $t("address.street") }}</span></div>
                <div class="col-lg-9">
                  <b-input type="text" :max="4" :placeholder="this.$t('address.enter_the_title')" v-model="street"></b-input>
                </div>

              </div>
              <div class="row align-items-center" style="margin-top: 0.5rem">
                <div class="col-lg-3 s-text"><span>{{ $t("address.also_look_for_translations") }}</span></div>
                <div class="col-lg-9">
                  <b-form-tags class="col-lg-9" add-button-text="+" ref="streetsInput" type="text"  v-model="streets" icon="info-circle" :placeholder="this.$t('address.add_street')"
                               :aria-close-label="this.$t('address.remove_street')" :ellipsis="false">
                  </b-form-tags>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "addAddress",
  props:['open'],
  data() {
    return {
      town: undefined,
      street: undefined,
      towns: [],
      streets: [],
      timeoutTown: null,
      timeoutStreet: null,
      isFullPage: false,
      checked: true,
    }
  },
  computed:{
    ...mapGetters("nameSearchAdditionalSettings", ["searchButtonNeedDisable"]),
  },
  watch: {
    'town': function (value) {
      clearTimeout(this.timeoutTown);
      if (!this.searchButtonNeedDisable){
        this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', true)
      }
      let self = this;
      let type = 'town'
      this.timeoutTown = setTimeout(function () {
        if (value && value.length) {
          self.getTranslation(value, type);
        } else {
          self.towns = [];
        }
      }, 1500)
    },
    'street': function (value) {
      clearTimeout(this.timeoutStreet);
      if (!this.searchButtonNeedDisable){
        this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', true)
      }
      let self = this;
      let type = 'street'
      this.timeoutStreet = setTimeout(function () {
        if (value && value.length) {
          self.getTranslation(value, type);
        } else {
          self.streets = [];
        }
      }, 1500)
    },
    towns(){
      this.getAllAddress();
    },
    streets(){
      this.getAllAddress();
    },
  },
  methods:{




    disableAddress(val){
      this.$store.dispatch('nameSearchAdditionalSettings/changeAddressState', val)
    },

    clearForm(){
      this.town = undefined;
      this.street = undefined
      this.towns = []
      this.streets = []
      let data = []
      this.$store.dispatch('nameSearchAdditionalSettings/addressFilters', data)
    },


    getAllAddress(){
      this.disableAddress(true)
      this.checked = true;
      let temp = [];
      if (this.town){
        temp.push({
          "param": "city",
          "value": this.town,
          "operator": "eq"
        })
      }
      if (this.street){
        temp.push({
          "param": "address",
          "value": this.street,
          "operator": "eq"
        })
      }
      if (this.towns.length > 0){
        this.towns.forEach(town =>{
          temp.push({
            "param": "city",
            "value": town,
            "operator": "eq"
          })
        })
      }
      if (this.streets.length > 0){
        this.streets.forEach(street =>{
          temp.push({
            "param": "address",
            "value": street,
            "operator": "eq"
          })
        })
      }
      this.$store.dispatch('nameSearchAdditionalSettings/addressFilters', temp).then(()=> {
        this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', false)
      })
    },

    getTranslation(value, type) {
      // let loadingComponent;

      // if (type === 'town'){
      //   loadingComponent = this.$buefy.loading.open({
      //     container: this.isFullPage ? null : this.$refs.townsInput.$el
      //   })
      // } else {
      //   loadingComponent = this.$buefy.loading.open({
      //     container: this.isFullPage ? null : this.$refs.streetsInput.$el
      //   })
      // }
      this.$store.dispatch('translation/getTranslation', {val: value, lang: 'uk'})
          .then((resp) => {
            if (type === 'town'){
              this.towns = this.towns.concat(resp);
            } else {
              this.streets = this.streets.concat(resp);
            }
            // loadingComponent.close()
          })
      this.$store.dispatch('translation/getTranslation', {val: value, lang: 'en'})
          .then((resp) => {
            if (type === 'town'){
              this.towns = this.towns.concat(resp);
            } else {
              this.streets = this.streets.concat(resp);
            }
            // loadingComponent.close()
          })

    },
  },
}
</script>

<style lang="scss">
.s-text {
  span {
    @media (min-width: 922px) {
      padding-left: 10px;
    }
  }
}
.b-form-tag{
  background-color: #4099ff;
  border-radius: 2px !important;
  padding-right: 0;
}
.b-form-tag-remove{
  padding-top: 2px;
  background-color: #4099ff;
  border: none;
}
.custom-control{
  padding: 6px;
}
.settings-block{
  border: 1px solid #dbdbdb !important;
  border-radius: 4px;
  padding: 6px;
}
//.selectCheckbox{
//  margin-top: 2px !important;
//  @media (min-width: 923px) {
//    margin-top: 5px !important;
//  }
//}
.settings-text{
  //margin-top: 4px!important;
  margin-bottom: 0;
}

.open{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 8px;
  padding-bottom: 8px;
}

</style>