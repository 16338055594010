/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export default function (myArray, chunk_size){
    let index = 0;
    let arrayLength = myArray.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
        if(myChunk.length < chunk_size) {
            for (let i = 0; i < chunk_size - myChunk.length; i++) {
                myChunk.push(null);
            }
        }
    }

    return tempArray;
}