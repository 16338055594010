import axios from "../../configs/axios";
export default {
    namespaced: true,
    state: {
        tenantList: [],
        selectedTab: 0,
        currentTenant: null
    },
    getters: {
       tenantList: state => state.tenantList,
       selectedTab: state => state.selectedTab,
       tenant: state => state.currentTenant,
       countTenants: state => Array.isArray(state.tenantList) ? state.tenantList.length : state.tenantList.data.length
    },
    actions: {
        getTenantList({commit}) {
            return new Promise((resolve, reject) => {
                axios({url: 'admin/tenants',  method: 'POST'})
                    .then((resp) => {
                        commit('setTenantList', resp.data)
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getTenant({commit}, id) {
            return new Promise((resolve, reject) => {
                axios({url: `admin/tenant/${id}`, method: 'GET'})
                    .then(resp => {
                        resolve(resp.data);
                        commit('setCurrentTenant', resp.data)
                    }).catch(e =>{
                        reject(e)
                    })
            })
        },
        selectedTab({commit}, payload){
            commit('setSelectedTab', payload)
        },
        update({}, {id, data}) {
            return new Promise((resolve, reject) => {
                axios({url: `admin/tenant/${id}`, method: 'PUT', data})
                    .then(resp => {
                        resolve(resp.data);
                    }).catch(e => {
                        reject(e);
                    })
            })
        }
    },
    mutations: {
        setTenantList(state, payload){
            state.tenantList = payload
        },
        setSelectedTab(state, payload){
            state.selectedTab = payload
        },
        setCurrentTenant(state, payload) {
            state.currentTenant = payload
        }
    }
}