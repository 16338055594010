import axios from '../../configs/axios';
// import instAxios from '../../configs/iamgeAxios';
import env from '@/configs/env.json';
let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.statisticEndpoint = `https://stats.${host}`
}
const baseURL = `${env.statisticEndpoint}/api`;
import _ from "underscore";

export default {
    namespaced: true,

    state: {
        statProviders: [],
        statsMeta: {},
        performanceItems: [],
        searchProcesses: [],
        searchRequests: [],
        mongoStats: [],
        elasticStats: [],
        phonePrefixStats: [],
        paramsChartStat: [],
        paramsChartsCount: 0,
        groupedServices: {},
        serviceDatasets: {
            online: {},
            all: {},
            cache: {}
        },
        paramsTotalRequests: [],
        sourcesCacheStates: []
    },

    getters: {
        statProviders: state => state.statProviders,
        statProvidersNames: state => {
            if (state.statProviders.length) {
                return state.statProviders.map(provider => {
                    return provider.name
                })
            }
            return [
                "Telegram",
                "Skype",
                "HLR",
                "FaceRecognition",
                "GetContact",
                "NumBuster",
                "Viber",
                "Truecaller",
                "Eyecon",
                "BazaGAI",
                "Vkontakte",
                "FaceSearchGlobal",
                "Avto-Nomer"
            ];
        },
        statsMeta: state => state.statsMeta,
        performanceItems: state => state.performanceItems,
        searchProcesses: state => state.searchProcesses,
        searchRequests: state => {
            return _.sortBy(state.searchRequests, 'total').reverse();
        },
        mongoStats: state => state.mongoStats,
        elasticStats: state => state.elasticStats,
        phonePrefixStats: state => state.phonePrefixStats,
        serviceDatasets: state => state.serviceDatasets,
        paramsChartStat: state => state.paramsChartStat,
        paramsChartsCount: state => state.paramsChartsCount,
        paramsTotalRequests: state => state.paramsTotalRequests,
        sourcesCacheStates: state => state.sourcesCacheStates
    },

    actions: {
        getStatProviders({ commit }, data) {
            axios({ baseURL, url: 'software/data_providers', method: 'POST', data })
                .then(resp => {
                    commit('setStatProviders', resp.data.data);
                    commit('setStatsMeta', resp.data.meta);
                })
        },
        getPerformance({ commit }, data) {
            axios({ baseURL, url: 'performance', method: 'POST', data })
                .then(resp => {
                    commit('setPerformance', resp.data.data)
                })
        },
        getSearchProcesses({ commit }, data) {
            return new Promise(resolve => {
                axios({ baseURL, url: 'usage/search_processing', method: 'POST', data })
                    .then(resp => {
                        commit('setSearchProcesses', resp.data.processing_ranges)
                        resolve('yes');
                    })
            })

        },
        getSearchRequests({ commit }, data) {
            return new Promise(resolve => {
                axios({ baseURL, url: 'usage/search_requests', method: 'POST', data })
                    .then(resp => {
                        commit('setSearchRequests', resp.data.users_requests);
                        resolve('yes');
                    });
            });
        },
        getMongoStat({ commit }) {
            return new Promise((resolve, reject) => {
                axios({ baseURL, url: 'storage/mongodb', method: 'GET' })
                    .then(resp => {
                        commit('setMongoStats', resp.data);
                        resolve('yes');
                    })
                    .catch(err => {
                        reject(err)
                    })
            });
        },
        getElasticStat({ commit }) {
            return new Promise((resolve, reject) => {
                axios({ baseURL, url: 'storage/elastic', method: 'GET' })
                    .then(resp => {
                        commit('setElasticStats', resp.data);
                        resolve('yes');
                    })
                    .catch(err => {
                        reject(err)
                    })
            });
        },
        getPhonePrefixStats({ commit }) {
            return new Promise((resolve, reject) => {
                axios({ baseURL, url: 'storage/data/phone_prefixes', method: 'GET' })
                    .then(resp => {
                        commit('setPhonePrefixStats', resp.data);
                        resolve('yes');
                    })
                    .catch(err => {
                        reject(err)
                    })
            });
        },
        getParamsCartStat({ commit }, payload) {
            commit('setParamsChartsCount', "decrease")
            return new Promise(resolve => {
                let arr = []
                payload.params.forEach(param => {
                    let obj = {
                        param: param,
                        has_results: 0,
                        no_result: 0
                    }
                    payload.analysis.forEach(analysis => {
                        axios({
                            baseURL,
                            url: 'usage/search_requests',
                            method: 'POST',
                            data: { filters: { param: param, created_from: payload.filters.created_from, created_to: payload.filters.created_to }, analysis: { has_results: analysis } }
                        })
                            .then(resp => {
                                if (analysis) {
                                    obj.has_results = resp.data.total_requests
                                } else {
                                    obj.no_result = resp.data.total_requests
                                }

                                let ell = arr.find(el => el.param === obj.param)
                                if (typeof ell === "undefined") {
                                    arr.push(obj)
                                }
                                commit('setParamsCartStat', arr)
                                commit('setParamsChartsCount', "increase")
                                resolve('yes');
                            });
                    })
                })
            })
        },
        getParamsTotalRequests({ commit }, payload) {
            let params = ["isdn", "email", "car_plate_num", "name", "image"];
            return new Promise(resolve => {
                params.forEach(param => {
                    axios({
                        baseURL,
                        url: 'usage/search_requests',
                        method: 'POST',
                        data: { filters: { param: param, created_from: payload.filters.created_from, created_to: payload.filters.created_to } }
                    })
                        .then(resp => {
                            const total = resp.data.total_requests
                            commit('setParamsTotalRequests', { total, param })
                            resolve('yes');
                        });

                })
            })
        },

        getStatByServices({ commit }, data) {
            const service = data.analysis.service;
            const source_results = data.analysis.source_results;
            // const inAxios = instAxios(localStorage.getItem('token'));
            data = JSON.parse(JSON.stringify(data));
            if (typeof data.filters.param !== 'undefined') {
                delete data.analysis.service;
                if (Object.keys(data.analysis).length === 0) {
                    delete data.analysis;
                }
            }
            return new Promise((resolve, reject) => {
                axios({ baseURL, url: 'usage/search_requests', method: 'POST', data })
                    .then(resp => {
                        const [
                            total_requests
                        ] = [
                                resp.data.total_requests
                            ]
                        commit('setServiceObj', { service, source_results, total_requests })
                        resolve('yes')
                    }).catch(err => {
                        reject(err);
                    })
            })
        },
        getSourcesCacheStates({commit}, data){
            return new Promise((resolve, reject) => {
                axios({ baseURL, url: 'cache_usage/sources', method: 'POST', data })
                    .then(resp => {
                        commit('setSourcesCacheStates', resp)
                        resolve('yes')
                    }).catch(err => {
                    reject(err);
                })
            })
        },

    },

    mutations: {
        setStatProviders(state, payload) {
            state.statProviders = payload;
        },
        setStatsMeta(state, payload) {
            state.statsMeta = payload;
        },
        setPerformance(state, payload) {
            state.performanceItems = payload
        },
        setSearchProcesses(state, payload) {
            state.searchProcesses = payload;
        },
        setSearchRequests(state, payload) {
            state.searchRequests = payload;
        },
        setMongoStats(state, payload) {
            state.mongoStats = payload
        },
        setElasticStats(state, payload) {
            state.elasticStats = payload
        },
        setPhonePrefixStats(state, payload) {
            state.phonePrefixStats = payload
        },
        setParamsCartStat(state, payload) {
            state.paramsChartStat = payload
        },
        setParamsChartsCount(state, payload){
            if (payload === "increase"){
                state.paramsChartsCount++;
            } else {
                state.paramsChartsCount = 0;
            }
        },
        setServiceObj(state, payload) {
            payload.source_results = typeof payload.source_results !== 'undefined' ? payload.source_results : 'all';
            state.serviceDatasets[payload.source_results][payload.service] = payload.total_requests;
        },
        setParamsTotalRequests(state, payload) {
            state.paramsTotalRequests[payload.param] = payload.total;
        },
        setSourcesCacheStates(state, payload){
            state.sourcesCacheStates = payload
        }
    }
}