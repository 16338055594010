import Dexie from "dexie";
import { isUndefined } from "underscore";

export default {
    namespaced: true,
    state: {
        images: {},
        allImages: [],
        photosCount: 0
    },
    getters: {
        images(state) {
            return state.images
        },
        photoLoaded(state) {
            return state.photosCount;
        },
        allImages(state){
            return state.allImages;
        }
    },
    actions: {
        addImage({commit}, payload) {
            commit('setImage', payload)
        },
        removeImage({commit}, payload) {
            commit('setRemoveImage', payload)
        },
        clearImages({commit}) {
            commit('setCleanImages');
        }
    },
    mutations: {
        setImage(state, payload) {

            let obj = {
                key: payload.key,
                val: ''
            }
            if (isUndefined(state.images[`${payload.key}`])){
                state.allImages.push(obj)
                state.images[payload.key] = ''
                state.photosCount++;
            }
        },
        setRemoveImage(state, payload) {
            delete state.images[payload.key]
            state.photosCount--
        },
        setCleanImages(state) {
            state.images = {};
            state.allImages = [];
            state.photosCount = 0;
        }
    }
}