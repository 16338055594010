var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoWrapper"},[_c('div',{staticClass:"facePreview",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","background-repeat":"no-repeat"},style:({
      backgroundSize: _vm.faceParam.imageWidth+'px'+' '+_vm.faceParam.imageHeight+'px',
      backgroundImage: 'url(' + _vm.facePreviewSrc + ')',
      backgroundPosition: `${_vm.faceParam.left}px ${_vm.faceParam.top}px`,
      width: _vm.canShowSearchedFace ? _vm.faceParam.photoPreviewWidth+'px' :  _vm.width+'px',
      height: _vm.canShowSearchedFace ? _vm.faceParam.photoPreviewHeight+'px' : _vm.width+'px'
    }),attrs:{"id":"facePreview"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }