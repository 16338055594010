<template>
    <div v-show="typeof options !== 'undefined' && (options.iterative || options.advanced)" class="algoritms col-sm-6 form-radio">
        <p style="
                      text-align: left;
                      font-weight: 600;
                      margin-bottom: 0.5rem;
                    " v-show="typeof options !== 'undefined' && options.iterative">
            {{ $t('searchOptions.search_algorithms') }}
        </p>
        <div class="radio" v-show="typeof options !== 'undefined' && options.iterative">
            <label >
                <input type="radio"  v-model="payload.iterative_search" :value="false" :disabled="typeof options !== 'undefined' && !options.iterative">
                <i class="helper"></i>
                {{ $t('searchOptions.one_iteration') }}
            </label>
        </div>
        <div class="radio" v-show="typeof options !== 'undefined' && options.iterative">
            <label >
                <input type="radio"  v-model="payload.iterative_search" :value="true" :disabled="typeof options !== 'undefined' && !options.iterative">
                <i class="helper"></i>
                {{ $t('searchOptions.three_iterations') }}
            </label>
        </div>
        <p style="text-align: center; font-weight: 600; margin: 0.5rem" v-show="typeof options !== 'undefined' && options.advanced">
            {{ $t('searchOptions.search_by_name') }}
        </p>

        <div class="radio" v-show="typeof options !== 'undefined' && options.advanced">
            <label >
            <input  type="radio" v-model="payload.advanced" :value="true"
                :disabled="typeof options !== 'undefined' && !options.advanced">
                <i class="helper"></i>
                {{ $t('searchOptions.wider') }}
            </label>
        </div>

        <div class="radio" v-show="typeof options !== 'undefined' && options.advanced">
            <label >
            <input type="radio"  v-model="payload.advanced" :value="false" :disabled="typeof options !== 'undefined' && !options.advanced">
            <i class="helper"></i>
                {{ $t('searchOptions.full_equal') }}
            </label>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'SearchAlgorithms',
    props: ['param', 'searchPayload'],
    data() {
        return {
            payload: {
                iterative_search: undefined,
                advanced: undefined,
            },
            payloadKeys: ['iterative', 'advanced'],
            fullAlgorithms: {}
        }
    },
    computed: {
        ...mapGetters('contacts', ['searchOptions']),
        options() {
            if(this.param !== 'mass_isdn') {
                return this.searchOptions[this.param]
            }
        },
    },
    methods: {
        settingsByParam() {
            if(typeof this.fullAlgorithms[this.param] === 'undefined' || ( typeof this.fullAlgorithms[this.param].advanced === 'undefined' && typeof this.fullAlgorithms[this.param].iterative_search === 'undefined')) {
                for(let k of this.payloadKeys) {
                    const keyPayload = k !== 'iterative' ? k : 'iterative_search'
                    if (typeof this.options !== 'undefined' ){
                      if(!this.options[k]) {
                        this.payload[keyPayload] = undefined;
                      } else {
                        this.payload[keyPayload] = undefined;
                        this.payload[keyPayload] = false;
                      }
                    }
                }
                this.fullAlgorithms[this.param] = this.payload;
                localStorage.setItem('fullAlgorithms', JSON.stringify(this.fullAlgorithms));
            } else {
              if (typeof this.param !== 'undefined'){
                this.payload.advanced = undefined
                delete this.payload.services
                this.payload = Object.assign(this.payload, this.fullAlgorithms[this.param]);
              }
            }
        },
        setPayload() {
            const l = JSON.parse(JSON.stringify(this.searchPayload));
            this.payload = JSON.parse(JSON.stringify(Object.assign(l, this.payload)));
        }

    },
    watch: {
        param(value) {
          this.payload = {
              iterative_search: undefined,
              advanced: undefined,
          }
          if(localStorage.getItem('fullAlgorithms')) {
                this.fullAlgorithms = JSON.parse(localStorage.getItem('fullAlgorithms'));
            } else {
                localStorage.setItem('fullAlgorithms', {})
            }
            this.settingsByParam();
        },
        // payload: {
        //     deep: true,
        //     handler() {
        //         console.log( this.payload)
        //         let innerPayload = this.payload;
        //         // delete innerPayload.online_search_enabled
        //         // delete innerPayload.cache_search_enabled
        //         // delete innerPayload.cache_search_enabled
        //         if (this.searchOptions[this.param].services.length === 0 && !this.payload.iterative_search ){
        //           console.log("111111111111111")
        //           innerPayload.online_search_enabled = false
        //         } else {
        //           innerPayload = this.payload;
        //         }
        //         console.log(innerPayload)
        //         console.log('watch_payload')
        //
        //
        //
        //         this.$emit('push', innerPayload);
        //         this.fullAlgorithms[this.param] = innerPayload;
        //         localStorage.setItem('fullAlgorithms', JSON.stringify(this.fullAlgorithms));
        //     }
        // },

        "payload.iterative_search"(){
          let  innerPayload = {
            iterative_search: this.payload.iterative_search
          }
          // innerPayload.iterative_search = this.payload.iterative_search;
          this.$emit('push', innerPayload);
          this.fullAlgorithms[this.param].iterative_search = innerPayload.iterative_search;
          localStorage.setItem('fullAlgorithms', JSON.stringify(this.fullAlgorithms));
        },
        "payload.advanced"(){
          let  innerPayload = {
            advanced: this.payload.advanced
          }
          this.$emit('push', innerPayload);
          this.fullAlgorithms[this.param].advanced = innerPayload.advanced;
          localStorage.setItem('fullAlgorithms', JSON.stringify(this.fullAlgorithms));
        }
    },
    mounted() {
        if(localStorage.getItem('fullAlgorithms')) {
            this.fullAlgorithms = JSON.parse(localStorage.getItem('fullAlgorithms'))
        } else {
            localStorage.setItem('fullAlgorithms', JSON.stringify({}))
        }
        this.settingsByParam();
        delete this.payload.services
        this.$emit('push', this.payload);
    }
}
</script>