<template>
    <div>
         {{ i18n.t('result.new_search') }}: <a href="#" @click.prevent="setMenuParam('telegram_id')">{{ i18n.t('params.telegram_id') }}</a>
    </div>
</template>

<script>
import toastMixin from '@/mixins/toastMixin';
export default {
    name: 'TelegramIdSearch',
    mixins: [toastMixin],
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>