<template>
    <tr class="child-result" >
        <td @click="openChild(child.keyVal)" class="d-flex align-items-center padding">
            <div class="d-inline-block result-item-photo-container m-r-10" style="display: flex">
                 <image-profile
                     v-if="child.face_select === undefined"
                      class="imageFromResults"
                      @imgSource="onLoadImg"
                      :from-search-list-child="true"
                      :size="'64px'"
                      :payload="typeof result.value !== 'undefined' ? child.value : result.imageFromResults"
                  />
                <searched-face v-else class="face" :parent="result" :index="child.index" :child="child" :width="64" />


            </div>
          <div style="display: flex;flex-direction: column; justify-content: center">
              <h6>{{$t('search_list_item.file')}}</h6>
              <p class="text-muted m-b-0">{{ $t("search_list_item.options") }}: <span
                  v-for="(item, k) in visualOptions" :key="k">{{
                  $t(`visualOptions.${item}`) }}{{ k ===
                visualOptions.length - 1 ? '' : ', '
                }}</span></p>
            </div>
        </td>
        <td class="padding" @click="openChild(child.keyVal)"><h6 class="m-b-0" ><span style="white-space: break-spaces;">{{ name || $t('child_contact.no_name') }}</span></h6></td>
        <td class="padding" @click="openChild(child.keyVal)">{{resultsCount || ''}}</td>
<!--        <td class="padding" @click="openChild(child.keyVal)">{{ child.date | moment('DD.MM.YYYY, HH:mm') }}</td>-->


        <td class="padding d-none d-lg-table-cell" @click="resultsCount && openChild(child.keyVal)">
          <div style="display: flex; flex-direction: column">
            <div class="d-none d-lg-inline">{{ child.date | moment('DD.MM.YYYY') }}</div>
            <div class="d-none d-lg-inline">{{ child.date | moment('HH:mm') }}</div>
          </div>
        </td>

        <td class="padding d-flex d-lg-table-cell" >
          <div class="d-inline d-lg-none"> {{ child.date | moment('DD.MM.YYYY, HH:mm') }}</div>
          <search-cost
              v-if="typeof child.totalCost !== 'undefined'"
              style="margin-top: 2px"
              :header='header'
              :cost="child.cost"
              :total-cost="child.totalCost"
              :ident="child.keyVal"
          />
        </td>






      <td class="text-right padding">
        <div class="d-none d-lg-block text-right" style="margin-left: 12px">

          <a href="#" v-b-modal="`delete_${child.keyVal}`" data-bs-toggle="modal" v-show="result.loaded" :content="$t('modal.deleteResult')" v-tippy="{ placement : 'top',  arrow: true }"><i
              class="feather icon-trash-2 f-w-600 f-16 text-c-red"></i>
          </a>


        </div>
        <div class="btn-group d-lg-none" style="width: 100%;">
          <a href="#" class="btn btn-danger" v-b-modal="`delete_${child.keyVal}`" data-bs-toggle="modal" v-show="result.loaded" :content="$t('result.edit_result')" v-tippy="{ placement : 'top',  arrow: true }"><i class="feather icon-trash-2"></i></a>
        </div>
      </td>


      <b-modal :id="`delete_${child.keyVal}`" :ref="`delete-child-modal`" hide-footer :title="$t('search_list_item.the_result_will_be_deleted_proceed')" centered>
        <div class="d-grid">
          <b-button class="mt-3" variant="danger" block @click="deleteSearchItem()">{{$t('modal.actionDelete') }}</b-button>
          <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
        </div>
      </b-modal>





    </tr>
</template>
<script>
import ImageProfile from '../ImageProfile.vue';
import SearchedFace from '../results/searchedFace.vue';
import SearchCost from "@/components/billing/searchCost";
import {Result} from '../../models/result'
import { isUndefined } from "underscore";
export default {
    name: "SearchListItemChild",
    props: ['result', 'child'],
    data() {
        return {
            childResult: {},
            name: '' ,
            header: false
        }
    },
    components: {
      SearchCost,
        ImageProfile,
        SearchedFace
    },
    computed:{
      resultsCount(){
        return !isUndefined(this.child.countResult) ? this.child.countResult : this.child.resultCount
      },
      visualOptions: {
        get: function () {
          if (typeof this.child.payload !== 'undefined') {
            const keys = Object.keys(this.child.payload);
            const options = [];
            for (const key of keys) {
              if (typeof this.child.payload[key] !== 'undefined') {
                options.push(`${key}_${this.child.payload[key]}`);
              }
            }
            return options.filter(item => [
              'online_search_enabled_true',
              'cache_search_enabled_true',
              'advanced_false',
              'offline_true',
              'offline_false',
              // 'service_types_online',
              'service_types_hlr',
              'advanced_true',
              // 'iterative_search_true',
              // 'iterative_search_false',
              'service_types_online,hlr',
              'service_types_hlr,online'].includes(item)).filter(item => {
              return true;
            });
          }
            return ['offline_true'];
          }
        },
    },
    methods: {
      hideModal() {
        this.$refs['delete-child-modal'].hide();
      },
      deleteSearchItem() {
        this.$emit('deleteChild', this.child)
        // let payload = {
        //   result: this.result,
        //   child: this.child
        // }
        // this.$store.dispatch('contacts/removeChild', payload);
        // this.$emit('force');
      },
        onLoadImg(data) {
        },
        async openChild(childKey) {
          const res = await Result.getResultByKey(childKey)
          await this.$router.push({name: 'Child', params: {id: res.id}})
            // this.$store.dispatch('contacts/updateResultChild', childKey).then(() => {
            //     this.$router.push({ name: 'Child' })
            // })
        },
        loadChildResult() {
            this.$store.dispatch('contacts/getChildResult', this.child.keyVal).then(data => {
              if(typeof data !== 'undefined') {
                this.childResult = data;
                const obj = this.childResult.results
                const nameArr = [];
                if(obj.length) {
                    for (let objItem of obj) {
                      if(objItem.search_results) {
                        for(let res of objItem.search_results) {
                            let nameFilter = res.data.filter((el) => {
                                return ['name', 'first_name'].includes(el.param);
                            })
                            nameArr.push(...nameFilter);
                        }
                      }
                    }
                }
                if(nameArr.length) {
                    this.name = nameArr[0].value;
                }
              }
            })
        }
    },
    mounted() {
        this.loadChildResult();
    }
}
</script>

<style>
.result-item-photo-container img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}
.padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.title-block {
    height: 64px;
    vertical-align: middle;
}

tr.child-result {
    cursor: pointer;
    border-bottom-width: 1px;
}


</style>