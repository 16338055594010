import { OptionsModel } from "./OptionsModel";

export class Algorithms extends OptionsModel {
    constructor(param) {
        const options = {
            advanced: undefined,
            iterative_search: undefined
        }
        const storageName = "searchAlgorithms";
        super(param, options, storageName)
        this.initParam()
    }
}