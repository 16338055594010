import { mapGetters, mapState } from 'vuex';
import _ from 'underscore';
// import SettingsChanger from '@/components/settings/settingsChanger'
import DataSources from '@/components/settings/DataSources';
import SearchAlgorithms from '@/components/settings/SearchAlgorithms';
import SearchRates from "@/components/searchRates/searchRates";
import searchSources from "@/components/settings/searchSources";

export default {
    name: "SearchOptions",
    props: ['param', 'expanded','currentParam', 'iterative_search',"childPhotoSearch"],
    components: {
        // SettingsChanger,
        DataSources,
        SearchAlgorithms,
        SearchRates,
        searchSources
    },
    data() {
        return {
            notReal: null,
            numbers: "",
            hlr: localStorage.getItem("hlr") ? localStorage.getItem("hlr") : false,
            hlrDisabler: localStorage.getItem("search_services"),
            searchServices: localStorage.getItem("search_services")
                ? localStorage.getItem("search_services")
                : true,
            searchAlgorithms: localStorage.getItem("search_algorithms")
                ? localStorage.getItem("search_algorithms")
                : true,
            intellectualSearch: localStorage.getItem("intellectual_search")
                ? localStorage.getItem("intellectual_search")
                : false,
            optionsPayload: {
                searchAlgorithmDropdownState: true,
                intellectualSearchDropdownState: true,
            },
            payload: {},
            visualOptions: [],
            valid: true
        }
    },
    methods: {
        ratesLoading(val){
            this.$emit('ratesLoading', val)
        },
        validCheck(){
            if (!this.payload.cache_search_enabled && !this.payload.online_search_enabled){
                this.valid = false
            } else if (!this.payload.cache_search_enabled && this.payload.online_search_enabled && this.payload.iterative_search){
                let contains = false
                this.searchOptions[this.param].services.forEach(service=> {
                    if (typeof this.payload.services !== 'undefined'){
                        if (this.payload.services.includes(service.name)){
                            contains = true
                        }
                    }
                })
                this.valid = contains;
            } else {
                this.valid = true
            }
            this.$emit('setValidSearchOptions', this.valid)
        },
        changeOnlineSearchState(val){
            this.$refs.search_rates.changeOnlineSearchState(val);
        },
        disableOnlineSearch(val){
            this.$refs.searchSources.disableOnlineSearch(val);
        },
        setGetContactTags(val){
            this.$emit('setGetContactTags', val)
        },

        updateServices(val){
            val = Array.from(new Set(val));
            if (val.length === 0) {
                this.payload.services = undefined
            } else {
                this.payload.services = val;
            }
            this.$emit('push', this.payload);
            this.validCheck();
        },
        enableIterativeSearch(val) {
            localStorage.setItem("search_algorithms", val);
            this.setIterativeSearch();
        },

        setIterativeSearch() {
            let enable = localStorage.getItem("search_algorithms");
            this.optionsPayload.searchAlgorithmDropdownState = enable === "true";
            this.payload.iterative_search = enable === "true";
        },
        updatePayload(enableHLR) {
            if (typeof enableHLR !== "undefined") {
                this.hlrDisabler = !enableHLR;
            }
            let searchAlgorithms = localStorage.getItem("search_algorithms");
            let searchServices = localStorage.getItem("search_services");

            this.payload.offline = searchServices !== "true";
            this.payload.iterative_search = searchAlgorithms === "true";
        },
        setAlgorithms(data) {
            this.payload = Object.assign(this.payload, data);
            this.$refs.searchSources.iterative = this.payload.iterative_search
            this.$emit('push', this.payload);
            this.setVisualOptions();
            this.validCheck();
        },
        setSources(data) {
            const sendData = JSON.parse(JSON.stringify(data));
            if(typeof data.service_options !== 'undefined' && !data.service_options.GetContact.request_options.tags) {
                sendData.service_options = undefined;
            }
            if(typeof data.service_options === 'undefined') {
                sendData.service_options = undefined;
            }
            sendData.watch = undefined;
            this.payload = Object.assign(this.payload, sendData);
            this.$emit('push', this.payload);
            this.setVisualOptions();
        },
        setVisualOptions() {
            const keys = Object.keys(this.payload);
            this.visualOptions = [];
            const options = [];
            for(const key of keys) {
                if(typeof this.payload[key] !== 'undefined') {
                    if(key !== 'service_options') {
                        options.push(`${key}_${this.payload[key]}`);
                    } else {
                        if(!_.isUndefined(this.payload[key].GetContact)) {
                            options.push(`get_contact_${this.payload[key].GetContact.request_options.tags}`);
                        }
                    }
                }
            }
            const filteredOptions = options.filter(item => {
                const optionsToFilter = [
                    'service_types_online',
                    'service_types_face',
                    'get_contact_false',
                    'services',
                    'services_',
                    'online_search_enabled_false',
                    'cache_search_enabled_false'];
                if(this.payload.param !== 'isdn') {
                    optionsToFilter.push('service_types_');
                }
                
                return !optionsToFilter.includes(item) && !item.includes('services_');
            })
            // if(this.param !== 'image') {
                this.visualOptions = this.visualOptions.concat(filteredOptions);
            // } else {
            //     this.visualOptions = ['service_types_face']
            // }
        }
    },
    computed: {
        ...mapGetters('contacts', ['searchOptions']),
        ...mapState({
            searchLimit: (state) => state.contacts.searchLimit,
        }),
        options() {
            if(this.param !== 'mass_isdn') {
                return this.searchOptions[this.param]
            }
        },
    },
    watch: {
        payload: {
            deep: true,
            handler() {
                this.$emit('push', this.payload);
                this.validCheck();
            }
        }
    },
    mounted() {
        this.setVisualOptions();
        this.$emit('push', this.payload);
    }

}