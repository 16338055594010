import axios from "../../configs/axios";

export default {
    namespaced: true,
    state: {
        usersList: [],
        hiddenUserList: [],
        userActivity: []
    },
    getters: {
        usersList: state => state.usersList,
        hiddenUserList: state => state.hiddenUserList,
        userActivity: state => state.userActivity,
    },
    actions: {
        getAllUserList({commit}){
            return new Promise((resolve, reject) => {
                axios({url: 'admin/users', method: 'POST'})
                    .then((resp) => {
                        commit('setHiddenUserList', resp.data.data);
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getHiddenUserList({commit}){
            return new Promise((resolve, reject) => {
                axios({url: 'admin/users', data: { filters: { same_tenant : true }}, method: 'POST'})
                    .then((resp) => {

                        commit('setUsersList', resp.data.data);
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getUserActivity({commit}, payload){
            commit('setClearUserActivity');
            return new Promise((resolve, reject) => {
                axios({url: 'admin/user_activity', data: payload, method: 'POST'})
                    .then((resp) => {
                        if (resp.data.total > payload.limit){
                            let innerPayload = payload;
                            commit('setUserActivity', resp.data)
                            for (let i = payload.limit ; i < resp.data.total; i+= payload.limit){
                                innerPayload.offset = i;
                                axios({url: 'admin/user_activity', data: innerPayload, method: 'POST'})
                                    .then((resp) => {
                                        commit('setUserActivityPart', resp.data);
                                    })
                            }
                        } else {
                            commit('setUserActivity', resp.data);
                        }
                        resolve(resp.data.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        clearUserActivity({commit}){
            commit('setClearUserActivity');
        },
    },
    mutations: {
        setUsersList(state , usersList){
            state.usersList = usersList;
        },
        setHiddenUserList(state, userList){
            state.hiddenUserList = userList
        },
        setUserActivity(state, userActivity){
            state.userActivity = userActivity;
        },
        setUserActivityPart(state, userActivity){
            let temp = []
            userActivity.data.forEach(item=> {
                if (state.userActivity.data.findIndex(dataItem => dataItem.created_at === item.created_at) < 0){
                    temp.push(item)
                }
            })
            state.userActivity.data = state.userActivity.data.concat(temp);
        },
        setClearUserActivity(state){
            state.userActivity = [];
        }
    }
}

