<template>
  <div class="text-danger"  v-if="raw.$anyDirty">
    {{ $t('rules.' + model + '_' + rule) }}
  </div>

</template>

<script>
import moment from "moment";
export default {
  name: "RuleValidation",
  props: ['rule', 'raw', 'model'],
  setup() {
    return {
      moment
    }
  },
}
</script>

<style scoped>

</style>