<template>
    <div class="card">
        <div class="card-body" id="mapHolder" style="padding-bottom: 8px; padding-top: 8px">
            <div id="mapBlocker" :class="{'hold-map':holdMap}"></div>
            <div class="element-label d-none">{{ $t('params.geo_search') | capitalize }}</div>




            <div class="d-flex justify-content-between" style="margin-bottom: 1rem" v-if="currentParam !== 'geo_search'">
                <div class="element-label">{{ $t('params.geo_search') | capitalize }}</div>


                <div class="form-block-right">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-danger mapDeleteButt" @click="$emit('remove')"><i
                                class="feather icon-trash-2"></i></button>
                    </div>
                </div>

            </div>

            <div style="display: flex;vertical-align: center; margin-bottom: 1rem">
                <div class="form-block-left" :class="{ geoSearchBlock: currentParam === 'geo_search' }">
                    <div id="map" style="width: 100%; height: 400px;"></div>
                    <p v-if="selectedLayout" class="m-t-10"><i class="feather icon-info"></i> {{ $t('geo.remove_selected') }}</p>
                    <p v-if="isEditing" class="m-t-10"><i class="feather icon-info"></i> {{ $t('geo.stop_editing') }}</p>
                </div>
                <div class="form-block-right d-none" v-if="currentParam !== 'geo_search'">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-danger"
                            @click="firstBlock ? $emit('removeblock') : $emit('remove')"><i
                                class="feather icon-trash-2"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-center full-text-block-connection connection-element" v-if="showConnection"><span
                class="label label-inverse-primary text-uppercase">{{ $t('fullText.and') }}</span></p>
    </div>
</template>
  
<script>
import L from "leaflet"
import 'leaflet/dist/leaflet.css';
import _ from "underscore";
import drawLocales from 'leaflet-draw-locales';
import filters from '@/mixins/filters'
import { mapGetters } from "vuex";
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';
import { ImageStorage } from "@/mixins/imageStorage";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css'

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};
const CustomMarker = L.Icon.extend({
    options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 41),
        iconSize: new L.Point(25, 41),
        iconUrl: '/images/marker-icon.png'
    }
});


export default {
    props: ['filter', 'showConnection', 'firstBlock'],
    mixins: [filters],
    // setup() {
    //     return {
            
    //     }
    // },
    data() {
        return {
            url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
            zoom: 5,
            center: [55.019531, 41.71803],
            bounds: null,
            map: null,
            geofenceStore: [],
            featureCollection: [],
            payload: null,
            drawnItems: null,
            startZoom: 3,
            maxRadius: 100000,
            radius: 0,
            locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
            selectedLayout: null,
            drawing: false,
            holdMap: false,
            isEditing: false
        };
    },
    computed: {
        // locale() {
        //     return ;
        // },
        ...mapGetters('mainMenuState', ['currentParam']),
        contextMenu() {
            return {
                contextmenu: true,
                contextmenuItems: [
                    {
                        text: `<i class="feather icon-edit"></i> ${this.$t('geo.edit')}`,
                        callback: this.startEditFigure
                    },
                    {
                        text: `<i class="feather icon-trash"></i> ${this.$t('geo.remove')}`,
                        callback: this.removeFigure
                    }
                ]
            }
        },
        

    },
    watch: {
      locale(){
        this.map.remove();
        this.initMap();
      },
      selectedLayout(val) {
        if(val) {
            this.$emit('invalid', true)
        } else {
            this.checkInvalid()
        }
      }
    },
    methods: {
        naturalMountAction() {
            return false;
        },
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        centerUpdated(center) {
            this.center = center;
        },
        boundsUpdated(bounds) {
            this.bounds = bounds;
        },
        success(pos) {
            const crd = pos.coords;
            this.center = [crd.latitude, crd.longitude];
            this.map.setView(this.center, this.startZoom)
        },

        setEditing() {
            const invalidArr = [];
            if(this.drawnItems) {
                this.drawnItems.eachLayer(l => {
                    invalidArr.push(l.editing._enabled)
                })
            }
            this.isEditing = invalidArr.some(item => item)
        },

        error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        },
        async setGeolocation() {
            // await navigator.geolocation.getCurrentPosition(this.success, this.error, options);
        },
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;
            
            this.map = await L.map('map', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
                zoom: this.startZoom
            });
            
            L.tileLayer(this.url, {maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
            L.control.zoom({
                zoomInTitle: this.$t('geo.zoomIn'),
                zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map)

            var southWest = L.latLng(-89.98155760646617, -180), 
            northEast = L.latLng(89.99346179538875, 180); 
            var bounds = L.latLngBounds(southWest, northEast); 
            
            this.map.setMaxBounds(bounds); 
            this.map.on('drag', () => { 
                this.map.panInsideBounds(bounds, { animate: false }); 
            });
            this.map.on('click', (e) => {
                this.stopEdit();
                // this.checkInvalid();
                this.map.contextmenu.hide();
            })

            const provider = new OpenStreetMapProvider();
            const searchControl = new GeoSearchControl({
                provider: provider,
                style: 'button',
                showMarker: false,
                searchLabel: this.$t('geo.enter_address'),
                notFoundMessage: this.$t('errors.not_found'),
                marker: {
                    icon: new CustomMarker(),
                    draggable: false,
                },
                autoComplete: true,
                autoCompleteDelay: 250
            })


            this.drawnItems = new L.FeatureGroup().addTo(this.map);


            const drawControl = new L.Control.Draw({
                edit: {
                    featureGroup: this.drawnItems,
                    edit: false,
                    remove: false
                },
                draw: {
                    circle: true,
                    polygon: {
                        icon: new L.DivIcon({
                            iconSize: new L.Point(8, 8),
                        }),
                        showArea: true,
                    },
                    marker: false,
                    polyline: false,
                    rectangle: false,
                    circlemarker: false
                }
            });
            L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);
            this.map.on('draw:drawstart', this.drawStart);
            this.map.on('draw:drawstop', this.drawStop);
            this.map.on('draw:created', this.drawCreated);
            // this.map.on('draw:editstart', (e) => console.log(e))
            this.map.on('draw:edited', this.drawEdited)
            this.map.on('draw:deleted', this.drawDeleted)
            this.map.on('draw:editresize', this.drawEditResize);
            this.map.on("draw:editstop", this.drawEditStop);
            this.map.addControl(drawControl);
            this.map.addControl(searchControl)
        },
        showCoordinates (e) {
        },
        async stopEdit() {
            this.drawnItems.eachLayer(async layer => {
                if(layer.editing._enabled) {
                    await layer.editing.disable()
                }
                if (layer instanceof L.Circle) {

                    const radius = Math.floor(layer.getRadius());
                    if (radius < this.maxRadius) {
                        layer.setStyle({
                            color: '#4099ff',
                            fillColor: '#4099ff'
                        })
                        if (layer.getTooltip()) {
                            layer.unbindTooltip();
                        }
                    } else {
                        layer.setStyle({
                            color: '#ff5370',
                            fillColor: '#ff5370'
                        })
                        if (!layer.getTooltip()) {
                            layer.bindTooltip(this.$t('fullText.maxRadiusTooltip'), {
                                direction: 'bottom',
                                offset: [0, 0]
                            }).openTooltip()
                        }
                    }
                }
            })
            await this.setGeoJSON();
        },
        removeMap(){
          this.map.remove();
        },
        drawStart() {
            this.drawing = true;
            this.checkInvalid()
        },
        drawStop() {
            this.drawing = false;
            this.checkInvalid();
        },
        drawCreated(event) {
            this.drawing = false;
            const layer = event.layer;
            if (layer.getTooltip()) {
                layer.unbindTooltip();
            }
            layer.bindContextMenu(this.contextMenu);
            layer.on('contextmenu', e => {
                this.unselectOthers()
                if(e.target._map.contextmenu._items.length > this.contextMenu.contextmenuItems.length) {
                    e.target._map.contextmenu.hide()
                }
            })
            if (layer instanceof L.Circle) {
                const radius = layer.getRadius()
                if (radius > this.maxRadius) {
                    layer.setStyle({
                        color: '#ff5370',
                        fillColor: '#ff5370'
                    })
                    layer.bindTooltip(this.$t('fullText.maxRadiusTooltip'), {
                        direction: 'bottom',
                        offset: [0, 0]
                    }).openTooltip()
                }
            }
            
            const figure = this.drawnItems.addLayer(layer);
            figure.on('click', this.selectLayout);
            this.setGeoJSON();
            this.toggleDisableClasses();
        },
        startEditFigure(event) {
            const layer = event.relatedTarget;
            layer.editing.enable()
            this.unselectOthers();
            this.setEditing();
            this.checkInvalid();
            layer.on('click', async () => {
                if(layer.editing._enabled) {
                    await layer.editing.disable()
                    await this.setGeoJSON();
                }
            })
        },
        async removeFigure(e) {
            this.unselectOthers();
            await this.drawnItems.removeLayer(e.relatedTarget)
            await this.setGeoJSON()
            await this.checkInvalid()
        },
        checkInvalid() {
            if(this.isEditing || !!this.selectedLayout || !this.featureCollection.length || this.drawing) {
                this.$emit('invalid', true)
            } else {
                this.$emit('invalid', false)
            }
        },
        drawDeleted(event) {
            this.unselectOthers();
            this.setGeoJSON();
            this.toggleDisableClasses();

            this.checkInvalid()

        },
        async selectLayout(e) {
            if(!e.layer.editing._enabled) {
                if(_.isUndefined(e.layer.options.selected) || !e.layer.options.selected) {
                    await this.unselectOthers();
                    await this.setSelectedLayout(e);
                } else {
                    await this.unselectOthers();
                }
            }
        },
        setSelectedLayout(e) {
            e.layer.setStyle({
                color: '#aeaeae',
                fillColor: '#aeaeae'
            })
            e.layer.options.selected = true;
            this.selectedLayout = e.layer;
            this.checkInvalid()
        },
        drawEditStop(event) {
            event.target.eachLayer((layer) => {
                if (layer instanceof L.Circle) {

                    const radius = Math.floor(layer.getRadius());
                    if (radius < this.maxRadius) {
                        layer.setStyle({
                            color: '#4099ff',
                            fillColor: '#4099ff'
                        })
                        if (layer.getTooltip()) {
                            layer.unbindTooltip();
                        }
                    } else {
                        layer.setStyle({
                            color: '#ff5370',
                            fillColor: '#ff5370'
                        })
                        if (!layer.getTooltip()) {
                            layer.bindTooltip(this.$t('fullText.maxRadiusTooltip'), {
                                direction: 'bottom',
                                offset: [0, 0]
                            }).openTooltip()
                        }
                    }
                }
            })
        },
        drawEdited(event) {
            this.setGeoJSON();
            this.toggleDisableClasses();
            this.checkInvalid()
        },
        drawEditResize(event) {
            if (event.layer && event.layer instanceof L.Circle && event.layer.getRadius()) {
                const radius = Math.floor(event.layer.getRadius());
                if(!_.isUndefined(event.layer.editing._map._editTooltip)) {
                    event.layer.editing._map._editTooltip.updateContent({
                        text: `${this.$t('fullText.radius')}: ${L.GeometryUtil.readableDistance(radius, 'metric')}`
                    })
                }
                this.$emit('invalid', true)
                if (radius < this.maxRadius) {

                    event.layer.setStyle({
                        color: '#4099ff',
                        fillColor: '#4099ff'
                    })
                } else {
                    event.layer.setStyle({
                        color: '#ff5370',
                        fillColor: '#ff5370'
                    })
                }
            }
        },
        async setGeoJSON() {
            this.featureCollection = Object.values(
                _.mapObject(this.drawnItems._layers,
                    (item) => {
                        const json = item.toGeoJSON().geometry;
                        if (item instanceof L.Circle) {
                            const radius = parseInt(item.getRadius());
                            json.type = 'circle'
                            json.radius = radius > this.maxRadius ? this.maxRadius : radius;
                        } else {
                            json.type = json.type.toLowerCase();
                        }
                        return json;
                    })
            );
            this.payload = this.featureCollection;
            this.$emit('location', this.payload)
            this.setEditing();
            this.checkInvalid();
        },
        toggleDisableClasses() {
            if (Object.keys(this.featureCollection).length >= 10) {
                for (const elementBar of document.querySelectorAll("[class^='leaflet-draw-draw-']")) {
                    elementBar.classList.add("disabled-draw-control");
                }
            } else {
                for (const elementBar of document.querySelectorAll("[class^='leaflet-draw-draw-']")) {
                    elementBar.classList.remove("disabled-draw-control");
                }
            }
        },
        async setFigures(figures) {
            for (const figure of figures) {
                switch (figure.type) {
                    case 'circle':
                        await this.setCircle(figure);
                        break;
                    case 'polygon':
                        await this.setPolygon(figure);
                        break;
                    case 'point':
                        await this.setPoint(figure);
                        break;
                    default:
                        break;
                }
            }
            this.map.fitBounds(this.drawnItems.getBounds());
            await this.setGeoJSON();
            await this.toggleDisableClasses();
            // this.$emit('invalid', false);
        },
        setCircle(figures) {
            const coordinates = figures.coordinates;
            const circle = L.circle([coordinates[1], coordinates[0]], {
                radius: figures.radius
            });
            circle.bindContextMenu(this.contextMenu);
            circle.on('contextmenu', e => {
                this.unselectOthers();
                if(e.target._map.contextmenu._items.length > this.contextMenu.contextmenuItems.length) {
                    e.target._map.contextmenu.hide()
                }
            })
            // circle.hasEvent
            const figure = this.drawnItems.addLayer(circle);
            figure.on('click', this.selectLayout);
        },
        async setPolygon(figures) {
            const geoJSON = {
                "type": "Polygon",
                "coordinates": figures.coordinates
            };
            const polygon = L.geoJSON(geoJSON)
            for (const poly of Object.values(polygon._layers)) {
                poly.bindContextMenu(this.contextMenu);
                poly.on('contextmenu', e => {
                    this.unselectOthers()
                    if(e.target._map.contextmenu._items.length > this.contextMenu.contextmenuItems.length) {
                        e.target._map.contextmenu.hide()
                    }
                })
                const figure = this.drawnItems.addLayer(poly);
                figure.on('click', this.selectLayout);
            }
        },
        setPoint({ coordinates }) {
            this.center = [coordinates[1], coordinates[0]];
            const marker = L.marker(this.center, {
                icon: new CustomMarker()
            })
            this.drawnItems.addLayer(marker);
            this.map.setView(this.center, 10)
        },
        async setBounds() {
            return new Promise(resolve => {
                this.map.fitBounds(this.drawnItems.getBounds());
                setTimeout(() => {
                    this.map._handlers.forEach((handler) => {
                        handler.disable();
                    });
                    this.drawnItems.eachLayer(l => {
                        l._map._handlers.forEach(handler => {
                            handler.disable();
                        })
                    })
                    resolve('foo');
                }, 1000);
            })
        },
        enableMap() {
            this.holdMap = false;
        },
        async makeScreen() {
            this.holdMap = true;
            let overridedPluginOptions = {
                mimeType: 'image/jpeg'
                }
            await this.setBounds();
            const screenShoter = new SimpleMapScreenshoter().addTo(this.map);
            return await new Promise(async (resolve) => {
                this.setBounds().then(()=> {
                    try {
                        
                        screenShoter.takeScreen('image', overridedPluginOptions).then(async image => {
                            const imageStorage = new ImageStorage('map', 'screenshots');
                            imageStorage.init().then(async () => {
                                const screenshotId = await imageStorage.addImage(image)
                                await resolve(screenshotId);
                                await this.enableMap();
                            })
                        });
                    } catch (error) {
                        resolve(null)
                    }
                })
            })
        },
        removeSelectedLayouts() {
            document.getElementById('map').addEventListener('keydown', async (event) => {
                if (['Delete', 'Backspace'].includes(event.code)) {
                    if(this.selectedLayout) {
                        await this.drawnItems.removeLayer(this.selectedLayout);
                        await this.setGeoJSON();
                        this.selectedLayout = null;
                        await this.checkInvalid();
                    }
                }
            });
        },
        async unselectOthers() {
            await this.drawnItems.eachLayer(l => {
                l.setStyle({
                        color: '#4099ff',
                        fillColor: '#4099ff'
                    });
                if (l instanceof L.Circle && l.getRadius()) {
                    const radius = l.getRadius();
                    if(radius > this.maxRadius) {
                        l.setStyle({
                            color: '#ff5370',
                            fillColor: '#ff5370'
                        })
                    }
                }
                
                l.options.selected = false
            });
            this.selectedLayout = null;            
        }
    },
    async mounted() {
        this.$emit('invalid', true)
        await this.setGeolocation();
        await this.initMap();
        this.removeSelectedLayouts()
    }
};
</script>
<style>
.leaflet-div-icon {
    border-radius: 50%;
    border: 1px solid #4099ff;
}

.radiusAlert {
    stroke: red;
}

.leaflet-draw-actions a {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.disabled-draw-control:before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.disabled-draw-control {
    padding-left: 0;
    position: relative;
    cursor: default;
    pointer-events: none;
}

.form-block-right {
    width: 60px;
}

.delete_on_birthday {
    padding-top: 35px;
}

.form-block-left {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .form-block-left {
        width: 100%;
    }
}

.geoSearchBlock {
    width: 100% !important;
}

#map {
    background: #aad3df;
}
.leaflet-control-geosearch.active form {
    margin-left: 5px;
}

body .leaflet-draw-toolbar .leaflet-draw-draw-circle, body .leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-circle {
    background-image: url('/images/circle.svg');
    background-size: 28px;    
}
body .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
    background-position: center;
}
body .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: center;
}
body .leaflet-draw-toolbar .leaflet-draw-draw-polygon, body .leaflet-retina .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-image: url('/images/polygon.svg');
    background-size: 20px;
}

body .leaflet-control-geosearch a.leaflet-bar-part:after {
    height: 10px;
    width: 10px;
    top: 8px;
    left: 8px;
    border: 2px solid #000;
}
body .leaflet-control-geosearch a.leaflet-bar-part:before {
    top: 18px;
    width: 7px;
    border-top: 2px solid #000;
}

#mapHolder {
    position: relative;
}
#mapBlocker {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
}
#mapBlocker.hold-map {
    display: block;
    z-index: 99999999999;
}
body .leaflet-draw-actions.leaflet-draw-actions-top {
    display: none !important;
}
body .leaflet-draw-actions.leaflet-draw-actions-bottom {
    display: none !important;
}
</style>