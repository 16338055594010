<template>
  <nav class="pcoded-navbar" style="position: fixed; margin-top: 9px">
    <div class="nav-list">
      <div class="pcoded-inner-navbar main-menu">

<!--        <ul class="pcoded-item pcoded-left-item">-->
<!--          <div  class="menuTitle">-->
<!--            <div>-->
<!--                <span >-->
<!--                  <i class="feather icon-phone"></i>-->
<!--                </span>-->
<!--                <span class="menuText">{{ $t('side_menu.phone') }}</span>-->
<!--            </div>-->

<!--            <span class="pcoded-right-header">-->
<!--              <i class="feather icon-chevron-down"></i>-->
<!--            </span>-->
<!--          </div>-->
<!--          <li>-->
<!--            <a style="padding-left: 2rem;height: 44px" href="#" @click.prevent="setMenuParam('isdn')" class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-chevron-right"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ $t('side_menu.ISDN') }}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="" >-->
<!--            <a style="padding-left: 2rem;height: 44px" href="#" @click.prevent="setMenuParam('mass_isdn')" class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-chevron-right"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ $t('side_menu.ISDN_set') }}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="pointer" @click="setMenuParam('name')">-->
<!--            <a class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-user"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.name')}}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="pointer" @click="setMenuParam('email')">-->
<!--            <a class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-mail"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.email')}}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="pointer" @click="setMenuParam('car_plate_num')">-->
<!--            <a class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-square"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.car_plate_num')}}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="pointer" @click="setMenuParam('image')">-->
<!--            <a class="waves-effect waves-dark">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-image"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.image')}}</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="pointer" @click="setMenuParam('username')">-->
<!--            <router-link style="margin-bottom: 8px" class="waves-effect waves-dark" :to="{name: 'Home'}">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-at-sign"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.username')}}</span>-->
<!--            </router-link>-->
<!--          </li>-->

<!--          <li class="pointer is-separated" @click="setMenuParam('')">-->
<!--            <router-link class="waves-effect waves-dark" :to="{name: 'Home'}">-->
<!--              <span class="pcoded-micon">-->
<!--                <i class="feather icon-menu"></i>-->
<!--              </span>-->
<!--              <span class="pcoded-mtext">{{ this.$t('side_menu.search_history')}}</span>-->
<!--            </router-link>-->
<!--          </li>-->
<!--        </ul>-->










        <ul class="pcoded-item pcoded-left-item p-b-50">
          <div  class="menuTitle">
            <div>
                <span >
                  <i class="feather icon-phone"></i>
                </span>
              <span class="menuText">{{ $t('side_menu.phone') }}</span>
            </div>

            <span class="pcoded-right-header">
              <i class="feather icon-chevron-down"></i>
            </span>
          </div>
          <li style="cursor: pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}" @click.prevent="setMenuParam('isdn')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ $t('side_menu.ISDN') }}</span>
            </a>
          </li>
          <li style="cursor: pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}" @click.prevent="setMenuParam('mass_isdn')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ $t('side_menu.ISDN_set') }}</span>
            </a>
          </li>
<!--          <div  class="menuTitle" v-if="searchParams.includes('image') || searchParams.includes('name') || searchParams.includes('email') || searchParams.includes('username') || searchParams.includes('telegram_id')">-->
          <div  class="menuTitle">
            <div>
                <span >
                  <i class="feather icon-user"></i>
                </span>
              <span class="menuText">{{ $t('side_menu.person') }}</span>
            </div>
            <span class="pcoded-right-header">
              <i class="feather icon-chevron-down"></i>
            </span>
          </div>
          <li class="pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}" @click.prevent="setMenuParam('name')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.name')}}</span>
            </a>
          </li>
          <li class="pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}"  @click.prevent="setMenuParam('itn')">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.itn')}}</span>
            </a>
          </li>
          <li class="pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}"  @click.prevent="setMenuParam('image')">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.image')}}</span>
            </a>
          </li>

          <li class="pointer">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}" @click.prevent="setMenuParam('email')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.email')}}</span>
            </a>
          </li>
          <li class="pointer" >
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}"  @click.prevent="setMenuParam('username')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.username')}}</span>
            </a>
          </li>
          <li class="pointer" >
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}" @click.prevent="setMenuParam('telegram_id')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.telegram_id')}}</span>
            </a>
          </li>

          <div  class="menuTitle">
            <div>
                <span >
                <svg style="width: 15px; height: 16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#b7c0cd" d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm288 32c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z"/></svg>
                </span>
              <span class="menuText">{{ $t('side_menu.car') }}</span>
            </div>
            <span class="pcoded-right-header">
              <i class="feather icon-chevron-down"></i>
            </span>
          </div>

          <li class="pointer" style="margin-bottom: 0.7rem">
            <a style="padding-left: 2rem;height: 44px" :to="{name: 'Home'}"  @click.prevent="setMenuParam('car_plate_num')" class="waves-effect waves-dark">
              <span class="pcoded-micon">
                <i class="feather icon-chevron-right"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.car_plate_num')}}</span>
            </a>
          </li>

          <li class="pointer is-separated waves-effect waves-dark" @click.prevent="setMenuParam('')">
            <a class="waves-effect waves-dark" :to="{name: 'Home'}">
              <span class="pcoded-micon">
                <i class="feather icon-menu"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.search_history')}}</span>
            </a>
          </li>
        </ul>





      </div>
    </div>
  </nav>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "sideNav",
  data() {
    return {
      params: ['name', ''],
      searchParams: []
    }
  },
  mounted() {
    // $(document).trigger('ready');
    // if(window.login) {
    //   $(document).trigger('loadHome');
    //   window.login = false;
    // }
    if(localStorage.getItem('search_params') && JSON.parse(localStorage.getItem('search_params')).length) {
      this.searchParams =  JSON.parse(localStorage.getItem('search_params'))
    } else {
      this.$store.dispatch('contacts/getSearchParams').then(() => {
        this.searchParams =  JSON.parse(localStorage.getItem('search_params'))
      })
    }
  },
  methods: {
    setMenuParam(val){
      // console.log(el.target.parentElement.parentElement)
      // if (el.target.parentElement.parentElement.classList.contains("pcoded-trigger")){
      //   el.target.parentElement.parentElement.classList.add("pcoded-trigger")
      // }
      // console.log(el.target.parentElement.parentElement)

      // $('#mobile-collapse i').toggleClass('icon-toggle-right');
      // $('#mobile-collapse i').toggleClass('icon-toggle-left');
      // $('.pcoded-navbar').toggleClass('show-menu');
      setTimeout(() => {
        localStorage.setItem("lastParam", val);
      }, 100);
      this.$store.dispatch('mainMenuState/menuParam', val).then(()=>{
        if (this.$route.name !== 'Home'){
          this.$router.push({name: 'Home'})
        }
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)){
          console.log('asdfasdf');
          // document.getElementsByClassName("pcoded-overlay-box")[0].click();
        }
      })
    },
  }

}
</script>

<style scoped lang="scss">
@media (max-width: 992px) {
  .pcoded-inner-navbar{
    margin-top: 70px !important;
  }
}
.pointer {
  cursor: pointer;
}
.is-separated {
  margin-top: 20px;
  border-top: 1px solid #fff;
}
.menuTitle{
  display: flex;
  justify-content: space-between;
  color: #b7c0cd;
  text-align: left;
  padding: 7px 15px 7px 24px;
  margin: 5px 0 0;
  border-radius: 0;
  border-left: 3px solid transparent;
  .menuText{
    padding-left: 10px;
  }
}
</style>