<template>
    <div>
        <div class="form-block">
            <div class="form-block-left">
                <div class="row">
                    <div class="col-sm-4 position-relative element-label">
                        <div class="param-label">{{ filter.fields.map(field => $t('params.' + field.name)).join(', ') | capitalize }}

                          <i class="feather icon-info primaryIcon m-l-5" v-if="filter.fields[0].name === 'address'"
                             v-tippy="{ placement : 'top',  arrow: true }"
                             :content="$t('fullText.addressHelp')"></i>

                        </div>
                    </div>
                    <div class="col-sm-4">
                        <select class="form-select full-text-element-select" v-model="payload.operation">
                            <option v-for="operation in filteredOperations" :value="operation">{{ $t('fullText.' +
                                operation) }}</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.$invalid && $v.$anyDirty }"
                            v-model="payload.value" @input="$v.payload.value.$touch()" :disabled="payload.operation === 'optional'">
                        <rule-validation v-for="(rule, key) in validationRules" :key="`validation${key}`"
                            v-if="!$v.payload.value[key]" model="fulltext_element_value" :rule="key"
                            :raw="$v.payload.value" />
                    </div>
                </div>
            </div>
            <div class="form-block-right">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-danger" @click="removeItem"><i class="feather icon-trash-2"></i></button>
                </div>
            </div>
        </div>
        <div>
            <span v-if="showConnection" class="full-text-element-connection connection-element" style="margin-top: 4px; margin-bottom: 4px"><span
                    class="label label-inverse-primary text-uppercase">{{
                        $t('fullText.or') }}</span></span>
        </div>
    </div>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import RuleValidation from "@/components/RuleValidation.vue";
import _ from 'underscore';
import { mapGetters } from 'vuex';
import filters from "@/mixins/filters";

const oneWord = (value) => {
    return value.search(/\s/) < 0;
}
const backTrue = () => {
    return true;
}
export default {
    name: 'FullTextElement',
    props: ['filter', 'showConnection', 'firstBlock', 'firstElement', 'rulesLenght'],
    components: {
        RuleValidation
    },
    mixins: [validationMixin, filters],
    data() {
        return {
            payload: {
                value: "",
                operation: null
            },
            operations: [
                'match_query',
                'not_match_query',
                'match_substr',
                'not_match_substr',
                'match_prefix',
                'match_suffix',
            ],
            validationRules: {
                required: requiredIf((payload) => {
                    return payload.operation !== 'optional'
                }),
                oneWord:  this.filter.fields[0].name === 'address' ? backTrue : oneWord
            }
        }
    },
    computed: {
        additionValidations() {
            let rules = {};
            for (let field of this.filter.fields) {
                if (!_.isUndefined(field.validationRules)) {
                    Object.assign(rules, field.validationRules)
                }
            }
            return rules;
        },
        ...mapGetters('contacts', ['filteredSearchOptions']),
        filteredOperations: {
            get() {
                let all_operations = (() => {
                    if(!_.isUndefined(this.filteredSearchOptions[this.filter.fields[0].name])) {
                        if(this.filter.fields.length === 1) {
                            return this.filteredSearchOptions[this.filter.fields[0].name].operations
                        } else {
                            return ['match_query', 'not_match_query']
                        }
                    }
                    return this.operations
                })()
                all_operations = _.sortBy(all_operations, (item) => item.includes('not'))
                if (this.firstBlock || this.filter.fields.length > 1 || !this.firstElement || this.rulesLenght > 1) {
                    all_operations = all_operations.filter(item => !item.includes('not'))
                }
                if (!this.firstElement && !this.firstBlock && this.filter.fields[0].name !== 'allData') {
                    all_operations.push('optional')
                }
                return all_operations;
            }
        }
    },
    validations() {
        return {
            payload: {
                value: this.validationRules
            }
        }
    },
    methods: {
        removeItem() {
            this.$emit('remove');
        },
        setValidations() {
            const stableRules = {
                required: requiredIf((payload) => {
                    return payload.operation !== 'optional'
                }),
                oneWord: this.filter.fields[0].name === 'address' ? backTrue : oneWord
            };
            let additionRules = !_.isUndefined(this.additionValidations[this.payload.operation]) ? this.additionValidations[this.payload.operation] : {}
            this.validationRules = Object.assign(stableRules, additionRules);
        },
        setPayload(payload) {
            this.payload = payload;
        }
    },
    watch: {
        payload: {
            deep: true,
            handler(val) {
                this.$emit('update', val);
            }
        },
        'payload.operation': {
            handler() {
                this.setValidations();
                this.$emit('update', this.payload)
            }
        },
        '$v.$invalid': {
            handler(val) {
                this.$emit('invalid', val)
            }
        },
        filteredOperations(val) {
            if (!val.includes(this.payload.operation)) {
                this.payload.operation = val[0]
            }
        }
    },
    mounted() {
        if (!_.isUndefined(this.filteredOperations) && this.filteredOperations.length) {
            this.payload.operation = this.filteredOperations[0]
        }
        this.setValidations();
        this.$emit('invalid', this.$v.$invalid)
        this.$emit('update', this.payload)
    }
}
</script>
<style>
.full-text-element-connection {
    position: relative;
}

.full-text-element-connection .badge {
    height: 20px;
    min-width: 40px;
    font-size: .9em;
}

.form-select.full-text-element-select {
    font-size: 15px;
    border-radius: 2px;
    height: 35px;
    border: 1px solid rgb(204, 204, 204);
}

.element-label {
    min-height: 35px;
    display: flex;
    align-items: center;
}

.param-label {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  width: 100%;
  padding: 5px 12px;
}
</style>