<template>
  <div class="home">
    <div class="container">
      <PageHeader></PageHeader>
      <notification-list/>
      <div class="alert alert-danger color-black" v-if="currentParam === 'geo_search_telegram'" role="alert">
        {{ $t('ui.geo_search_telegram_warning') }}
      </div>
      <full-text v-if="['full_search', 'geo_search'].includes(currentParam)"/>
      <SimpleSearch v-else-if="currentParam === 'simple_search'"/>
      <!-- <SearchForm v-else @changeTitle="changeTitle" /> -->
      <MainSearch v-else></MainSearch>
      <SearchList />
    </div>
  </div>
</template>

<script>
import SearchForm from "../components/SearchForm";
import SearchList from "@/components/list/SearchList";
import PageHeader from "../components/layouts/headerPage";
import {mapGetters} from "vuex";
import NotificationList from "@/components/NotificationList.vue";
import FullText from "@/views/FullText";
import SimpleSearch from "../components/fullText/SimpleSearch.vue";
import MainSearch from "@/components/search/mainSearch.vue"
import rewriteResults from "../helpers/rewriteResults";

export default {
  name: 'Home',
  // data() {
  //   return {
  //     firstInit: false
  //   }
  // },  
  mixins: [rewriteResults],
  components: {
    FullText,
    NotificationList,
    SearchList,
    SearchForm,
    PageHeader,
    SimpleSearch,
    MainSearch
  },
  async mounted() {
    this.$store.dispatch('auth/getMe').then(() => {
      if(typeof window.reloadHome && window.reloadHome) {
        location.reload();
      }
      this.$store.dispatch('contacts/forceUpdateSearchParams');
      // this.$store.dispatch('full_search/clearResultFilters');
      this.rewriteResults(this.user.login);
      this.$store.dispatch('contacts/initSearchResults');
    });
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('mainMenuState', ['currentParam']),
  },
  methods:{
    changeTitle(val){
      this.$emit('changeTitle', val)
    },
    goToMonitoring(){
      this.$router.push({name: 'Monitoring'})
    }
  },
  // watch: {
  //   $route: {
  //     handler(value, oldValue) {
  //       if(oldValue.name === 'Login' && value.name === 'Home') {
  //         console.log(oldVal);
  //         this.firstInit = true;
  //       }
  //     }
  //   }
  // }
}
</script>
<style scoped lang="scss">
.buttContainer {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}
</style>


<style lang="scss">
.card-header {

  &[aria-expanded=false] {
    &::after {
      font-family: 'feather' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e842";
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 15px;

      @media (max-width: 992px) {
        right: 2px;
      }
    }
  }

  &[aria-expanded=true] {
    &::after {
      font-family: 'feather' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e845";
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 15px;
      @media (max-width: 992px) {
        right: 2px;
      }
    }
  }
}
body .alert.color-black {
  font-size: 16px;
  color: var(--bs-table-color);
}
</style>
