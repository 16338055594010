<template>
    <div class="searchStructure" v-if="levels.length !== 0 && levels[0][0].level === 1 && typeof levels[1] !== 'undefined' && levels[1][0].level === 2 && isAnyResultsSecondLevel()">
      <p v-if="!disableDelete" class="structure-title">{{ $t('graph.search_structure') }}</p>
      <div class="tree" id="searchStructure">
        <ul id="tree_screenshot" style="background-color: #fbfbfb; border: none; padding-bottom: 1rem">
          <li
              class="start-tree"
              v-for="(item, key) in levels[0]"
              :key="key"
          >
            <span style="white-space: nowrap;">{{ item.value }}</span>
            <ul v-if="typeof levels[1] !== 'undefined' &&  levels[1].length">
              <li
                  v-for="(v1, k1) in levels[1]"
                  :key="k1"
                  v-if="isEnyResults(v1)"
              >
                <span style="white-space: nowrap; cursor: pointer; display: flex;align-items: center; max-width: 170px; justify-content: space-between; overflow: hidden" :class="{ forDelete:  itemsForDeleteValues.includes(v1.value)}">
                    <span style="border: none;max-width: 170px;overflow: hidden;text-overflow: ellipsis;" @click="scrollToResult(v1.value)">{{ v1.value }}</span>
                    <graph-tooltip
                        style="margin-left: 5px;"
                        :result="v1"
                        :source-list="v1.parentSources"
                        :source-list-locales="v1.parentSourcesLocales"
                        :meta="meta"
                        :disableDelete="disableDelete"
                        :id="'tooltip'+v1.value"
                    />
                </span>
                <ul v-if="typeof levels[2] !== 'undefined' &&  levels[2].length">
                  <li
                      v-for="(valueChild, k2) in levels[2].filter(el => el.parentValue === v1.value)"
                      :key="k2"
                      v-if="isEnyResults(valueChild)"
                  >
                  <span  style="white-space: nowrap; cursor: pointer; display: flex;align-items: center;justify-content: space-between  " :class="{ forDelete:  itemsForDeleteValues.includes(valueChild.value)}" >
                    <span style="border: none; cursor: pointer;max-width: 170px;overflow: hidden;text-overflow: ellipsis; " @click="scrollToResult(valueChild.value)">{{ valueChild.value }}</span>
                    <graph-tooltip
                        style="margin-left: 5px;"
                        :result="valueChild"
                        :source-list="valueChild.parentSources"
                        :source-list-locales="valueChild.parentSourcesLocales"
                        :meta="meta"
                        :disableDelete="disableDelete"
                        :id="'tooltip'+valueChild.value"
                    />
                  </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import GraphTooltip from "@/components/results/graphTooltip";
import domtoimage from "dom-to-image";
import resultsToLevels from "@/mixins/resultsToLevels";

export default {
  name: "graph",
  components: {GraphTooltip},
  props:['imageCount','meta','disableDelete','itemsForDelete'],
  mixins: [resultsToLevels],
  data() {
    return {
      itemsForDeleteValues: []
    }
  },
  methods:{
    isAnyResultsSecondLevel(){
      let count = 0;
      this.levels[1].forEach(item=> {
        item.items.forEach(innerItem => {
          if (innerItem.search_results){
            count += innerItem.search_results.length
          }
        })
      })
      return count > 0
    },
    isEnyResults(result){
      let count = 0
      result.items.forEach(item=> {
        if (item.search_results){
          count+= item.search_results.length
        }
      })
      return count > 0
    },
    scrollToResult(val){
      let element = document.getElementById(val);
      element.click();
      let elDistanceToTop = window.pageYOffset + element.getBoundingClientRect().top
      this.$nextTick(() => {
        window.scrollTo(0, elDistanceToTop);
      });
      element.parentElement.classList.add('highlighted')
      setTimeout(()=>{
        element.parentElement.classList.remove('highlighted')
      }, 1000)
    },

    screenShot() {
      if(typeof this.levels[1] !== 'undefined' && this.levels[1].length) {
        let node = document.getElementById('tree_screenshot');
        if (node){
          domtoimage.toJpeg(node).then(dataurl => {
            this.$emit('setSchemaPic', dataurl);
          })
        }
      }
    },
    isShowItem(level) {
      const showed = [];
      for(let item of level[0].items) {
        if(item.search_results) {
          showed.push(1);
        }
      }
      return !!showed.length;
    }
  },
  mounted() {
    if (typeof this.itemsForDelete !== 'undefined' && this.itemsForDelete.length){
      this.itemsForDelete.forEach(item=>{
        this.itemsForDeleteValues.push(item.value)
      })
    }
  },
  beforeDestroy() {
    this.itemsForDeleteValues = [];
  }
}

</script>


<style lang="scss">
.forDelete{
  border-color: darkred !important;
  background-color: rgba(139, 0, 0, 0.18);
}
.searchStructure{
  margin-top: 1em;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);

  .tree {
    display: flex;
    justify-content: center;
    min-height:20px;
    padding:19px;
    padding-bottom: 2rem;

    background-color:#fbfbfb;
    -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
    ul {
      padding-inline-start: 40px;
      @media (max-width: 992px) {
        padding-inline-start: 20px;
      }
    }
  }
  .tree li {
    list-style-type:none;
    margin:0;
    padding:10px 5px 0 120px;
    position:relative;
    @media (max-width: 992px) {
      padding-left: 0;
    }
    &.start-tree {
      padding-left: 0;
    }
  }
  .tree li::before, .tree li::after {
    content:'';
    left:-16px;
    position:absolute;
    right:auto
  }
  .tree li::before {
    border-left:1px solid #999;
    bottom:50px;
    height:100%;
    top:0;
    width:1px
  }
  .tree li::after {
    border-top:1px solid #999;
    height:20px;
    top:30px;
    width:136px
  }

  @media (max-width: 992px) {
    .tree li::after {
      width: 16px;
    }
  }
  .tree li span {
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    border:1px solid #999;
    border-radius:5px;
    display:inline-block;
    padding:3px 8px;
    text-decoration:none
  }
  .tree li.parent_li>span {
    cursor:pointer
  }
  .tree>ul>li::before, .tree>ul>li::after {
    border:0
  }
  .tree li:last-child::before {
    height:30px
  }
  .tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
    background:#eee;
    border:1px solid #94a0b4;
    color:#000
  }

  .structure-title{
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem;
    background-color: white;
    align-items: stretch;
    box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 10%);
    font-size: 1.25em;
    color: #363636;
    font-weight: 600;
    line-height: 1.125;
  }
}
</style>