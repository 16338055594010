<template>
    <div class="card full-text" v-if="show">
        <div class="card-body">
            <h5 class="full-text-title" v-if="!filters.length">{{ $t('fullText.add_filters') }}</h5>
            <component :is="filter.fields[0].component || FullTextBlock" v-for="(filter, k) in filters"
                @update="(payload) => updatePayload(filter.block, payload)"
                :ref="`block_${filter.block}`"
                @invalid="(val) => {invalidObj[filter.block] = val; setInvalid()}"
                @filters="(payload) => addFullFilters(payload, filter.block)"
                @checkremove="(fieldKey) => checkRemove({fieldKey, blockName: filter.block})"
                @removeblock="() => checkRemove({fieldKey: null, blockName: filter.block})"
                @location="setLocation"
                :filter="filter" @remove="removeBlock(filter.block)" :first-block="k === 0"
                :show-connection="filters.length - k > 1"
                :key="filter.block" />
            
                <div v-if="!disallowFreeSpace">
                    <div class="add-filter-block" v-if="filters.length === 0">
                        <button class="label connection-element label-inverse-primary" @click="showModal"><i class="feather icon-plus"></i></button>
                    </div>
                  <div class="add-filter-block" v-else-if="filters.length < 10">
                    <button class="label connection-element label-inverse-primary" style="text-transform: uppercase;" @click="showModal">{{ $t('fullText.and') }}...</button>
                  </div>
                    <div class="d-grid m-t-15" v-if="filters.length">
                        <b-button variant="primary" class="btn" :disabled="!filters.length || invalid || searchLoad"

                            @click="search">
                            {{ $t("search.search") }}
                        </b-button>
                    </div>
            </div>
            <div v-else class="text-danger f-w-700 p-l-15 p-r-15">{{ $t('ui.fullLocalStorage') }}</div>
        </div>
        <b-modal ref="add-filter" centered hide-footer hide-header>
            <AddFilter @close="closeModal" @options="addFilter" :existing-fields="fieldNames"></AddFilter>
        </b-modal>
        <b-modal ref="approveRemovingBlocks" centered hide-footer :title="this.$t('fullText.will_be_deleted')" style="font-weight: 500">
<!--            <h5>{{ $t('fullText.will_be_deleted') }}</h5>-->
            <p class="option-description">{{ firstFieldName ? firstFieldName.map(field => $t(`params.${field.name}`)).join(', ') : '' | capitalize }}</p>
            <OptionDescription v-for="block in notConditionsBlocks" :block="block" :key="`desc_${block.key}`"></OptionDescription>
<!--            <p class="text-center">{{ $t('graph.continue') }}</p>-->
            <footer class="d-grid gap-2 m-t-10">
                <b-button variant="primary" type="button" class="btn " @click="removeNotConditionsBlock">{{ $t('modal.yes') }}</b-button>
                <b-button variant="secondary" type="button" @click="$refs.approveRemovingBlocks.hide()" class="btn">{{ $t('modal.no') }}</b-button>
            </footer>
        </b-modal>
    </div>
</template>
<script>
import FullTextBlock from '@/components/fullText/FullTextBlock';
import AddFilter from '../components/fullText/AddFilter.vue';
import OptionDescription from "../components/fullText/OptionDescription.vue";
import categories from '@/components/fullText/categories';
import _ from 'underscore';
import {mapGetters} from "vuex";
import filters from "@/mixins/filters"
import FullTextMap from "@/components/FullTextMap";
export default {
    name: 'FullText',
    setup(){
        return {
            FullTextBlock
        }
    },
    components: {
        AddFilter,
        OptionDescription
    },
    mixins: [filters],
    data() {
        return {
            filters: [],
            payload: {
                filters: []
            },
            payloadObj: {},
            invalidObj: {},
            invalid: true,
            additionFilters: {},
            show: true,
            notConditionsBlocks: [],
            fieldToDelete: null,
            blockToDelete: null,
            arrayPayloadObject: [],
            addedBirthday: false,
            searchLoad: false,
            firstFieldName: null
        }
    },
    methods: {
        async search() {
            const payload = JSON.parse(JSON.stringify(this.payload));
            if(!_.isUndefined(payload.location) && payload.filters.length === 0) {
                delete payload.filters
            }
            this.searchLoad = true;
            const payloadCollect = { payload, type: 'full_search'};
            payloadCollect['screenshotId'] = await this.takeScreenshotGeo();
            this.$store.dispatch('contacts/fullSearch', payloadCollect)
                .then(async (resp)=> {
                    await this.$store.dispatch('full_search/clearResultFilters');
                    await this.setEmpty();
                    if(this.currentParam === 'geo_search') {
                        this.initGeoSearch()
                    }
                    try {
                        this.$matomo.trackEvent('Web Full Search', 'WasClicked')
                        if(this.currentParam === 'geo_search') {
                            this.$matomo.trackEvent('Web Location Search', 'WasClicked');
                        }
                    } catch (e) {
                        console.log('ignore error');
                    }
                })
                .finally(() => {
                    this.searchLoad = false;
                    this.$store.dispatch('ui/getFreeSpaceInfo');
                })
        },

        async addFilter(fields) {
            const indexesRules = this.filters.length ? this.filters.map(filter => parseInt(filter.block.match(/\d+/)[0])) : [0];
            const maxIndex = _.max(indexesRules)+1
            await this.filters.push({
                optional: false,
                block: 'block' + maxIndex,
                fields
            });
            this.closeModal();
            const refName = `block_block${maxIndex}`;
            await this.$refs[refName][0].naturalMountAction();
            return refName;
        },
        checkRemove({blockName, fieldKey}) {
            const block = _.findWhere(this.filters, {block: blockName});
            this.firstFieldName = block.fields
            const blockWithNotOperations = _.filter(this.arrayPayloadObject, (block) => {
                return _.find(block.conditions, (condition) => {
                    const notConditions = condition.operation.includes('not')
                    return notConditions
                })
            })
            if(blockWithNotOperations.length > 0 && !this.isPositiveSecondCondition()) {
                this.$refs.approveRemovingBlocks.show();
                this.notConditionsBlocks = blockWithNotOperations;
                this.fieldToDelete = fieldKey;
                this.blockToDelete = blockName;
            } else {
                if(fieldKey) {
                    this.$refs[`block_${blockName}`][0].removeItem(fieldKey);
                } else {
                    this.removeBlock(blockName);
                }
            }
        },
        async takeScreenshotGeo() {
            const withGeo = this.filters.filter(item => item.fields[0].name === 'geo_search');
            return new Promise(async resolve => {
                if(withGeo.length) {
                    const screenId = await this.$refs['block_' + withGeo[0].block][0].makeScreen();
                    await resolve(screenId)
                } else {
                    resolve(null)
                }
            });

        },
        isPositiveSecondCondition() {
            if(!_.isUndefined(this.arrayPayloadObject[1])) {
                return _.find(this.arrayPayloadObject[1].conditions, (condition) => {
                    const positiveConditions = !condition.operation.includes('not')
                        return positiveConditions
                })
            }
            return false;
        },
        removeBlock(blockKey) {
            const deletedFilter = _.findWhere(this.filters, {block: blockKey})
            if(deletedFilter.fields[0].name === 'geo_search') {
                this.payload.location = undefined;
            }
            this.filters = this.filters.filter(block => block.block !== blockKey);
            delete this.payloadObj[blockKey];
            delete this.invalidObj[blockKey];
            delete this.additionFilters[blockKey]
            this.complectPayload();
            this.setInvalid();
            this.setArrayPayloadObject();
        },
        removeNotConditionsBlock() {
            this.$refs.approveRemovingBlocks.hide()
            if(this.fieldToDelete) {
                this.$refs[`block_${this.blockToDelete}`][0].removeItem(this.fieldToDelete);
            } else {
                this.removeBlock(this.blockToDelete)
            }
            this.blockToDelete = null;
            this.fieldToDelete = null;
            for(const blockName of this.notConditionsBlocks.map(item => item.key)) {
                this.removeBlock(blockName);
            }
        },
        showModal() {
            this.$refs['add-filter'].show();
        },
        closeModal() {
            this.$refs['add-filter'].hide();
        },
        updatePayload(blockTitle, payloadBlock) {
            if(typeof payloadBlock.fields !== 'undefined' && payloadBlock.fields.includes('allData')) {
                delete payloadBlock.fields;
            }
            this.payloadObj[blockTitle] = payloadBlock;
            this.complectPayload();
            this.setArrayPayloadObject();
        },
        addFullFilters(payload, blockKey) {
            this.additionFilters[blockKey] = payload;
            this.complectPayload();
            this.setArrayPayloadObject();
        },
        complectPayload() {
            const additionFiltersArray = _.flatten(Object.values(this.additionFilters));
            this.payload.filters = [...Object.values(this.payloadObj), ...additionFiltersArray];
        },
        setInvalid() {
            this.invalid = Object.values(this.invalidObj).includes(true)
        },
        setArrayPayloadObject() {
            const arrayFromObject = [];
            for(const block of Object.keys(this.payloadObj)) {
                const newElement = {key: block, ...this.payloadObj[block]}
                arrayFromObject.push(newElement)
            }
            const flatBlocks = [];
            for(const keyBlock of Object.keys(this.additionFilters)) {
                for(const filter of this.additionFilters[keyBlock]) {
                    const newElement = {key: keyBlock, ...filter}
                    arrayFromObject.push(newElement)
                }
            }
            this.arrayPayloadObject = _.sortBy(arrayFromObject, (item) => parseInt(item.key.replace('block', '')));
        },
        async addBlocksFromPayload(filters) {
            const indexesRules = this.filters.length ? this.filters.map(filter => parseInt(filter.block.match(/\d+/)[0])) : [0];
            const maxIndex = _.max(indexesRules)+1
            let blockCounter = maxIndex+1;
            const groupedFilters = _.groupBy(filters, 'fields')
            for(const filter of filters) {
                if(!_.isUndefined(filter.fields) && !filter.fields.includes('birthdate') || _.isUndefined(filter.fields)) {
                    const blockConstruction = {
                        optional: false,
                        block: `block${blockCounter}`,
                        fields: !_.isUndefined(filter.fields) ? filter.fields.map(item => {
                            return {name: item, component: this.getFilters(item)}
                        }) : [{name: 'allData'}],

                    }
                    await this.filters.push(blockConstruction)
                    const refName = `block_block${blockCounter}`;
                    await this.$refs[refName][0].setPayload(filter);
                    blockCounter++;
                } else {
                    if(!this.addedBirthday) {
                        const blockConstruction = {
                            optional: false,
                            block: `block${blockCounter}`,
                            fields: filter.fields.map(item => {
                                return {name: item, component: this.getFilters(item)}
                            }),
                        };
                        await this.filters.push(blockConstruction)
                        const refName = `block_block${blockCounter}`;
                        await this.$refs[refName][0].setPayload(groupedFilters['birthdate']);
                        this.addedBirthday = true;
                        blockCounter++;
                    }
                }

            }
        },
        getFilters(field) {
            const params = _.flatten(Object.values(categories));
            const param = _.findWhere(params, {name: field});
            return param.component
        },
        setEmpty() {
            this.filters = [];
            this.payloadObj = {};
            this.payload.filters = [];
            this.payload.location = undefined;
            this.invalidObj = {};
            this.payloadObj = {};
            this.additionFilters = {};
            this.arrayPayloadObject = [];
            this.addedBirthday = false;
        },
        setEditFilters(filters) {
            this.show = false;
            let editResultFilters = JSON.parse(JSON.stringify(filters));
            if (editResultFilters.length > 0){
                this.payload.filters = editResultFilters
                this.addBlocksFromPayload(editResultFilters)
            }
            this.show = true;
        },
        setLocation(payload) {
            this.payload.location = payload;
        },
        initGeoSearch(){
          this.addFilter([{"name":"geo_search","component": FullTextMap,"compatibility":null}]);
        },
        async setEditLocation(location) {
          const locationRef = await this.addFilter([{"name":"geo_search","component": FullTextMap,"compatibility":null}]);
          await this.$refs[locationRef][0].setFigures(location)
        }
    },
    computed: {
        fieldNames() {
            return _.flatten(this.filters.map(item => item.fields.map(field => field.name)))
        },
        ...mapGetters('full_search', ['editResultFilters']),
        ...mapGetters("ui", ['disallowFreeSpace']),
        ...mapGetters('mainMenuState', ['currentParam']),
        ...mapGetters('auth', ['permissions'])
    },
    watch:{
        editResultFilters: {
            deep: true,
            async handler(val){
              if (Object.keys(val).length){
                await this.setEmpty();
                if(!_.isUndefined(val.location)) {
                  await this.setEditLocation(val.location)
                }
                if(!_.isUndefined(val.filters)) {
                  await this.setEditFilters(val.filters);
                }
              }
            },
        },

        currentParam: {
          async handler(val, preval){
            if (preval === 'geo_search' && val === 'full_search'){
              this.setEmpty()
            } else if (val === 'geo_search'){
                if(this.filters.length) {
                    await this.setEmpty();
                }
              await this.initGeoSearch();
            }
          }
        }
    },
    mounted() {
        this.$store.dispatch('ui/getFreeSpaceInfo');
        this.$store.dispatch('contacts/getFilteredSearchOptions')
        if(this.currentParam === 'geo_search') {
            this.initGeoSearch();
        }
    },
    beforeDestroy() {
        this.$store.dispatch('full_search/clearResultFilters');
    }

}
</script>

<style>
.modal-header{
  font-weight: 500 !important;
}
.add-filter-block {
    display: flex;
    justify-content: center;
}
.full-text-title {
    text-align: center;
}
.connection-element {
    font-size: 14px;
    display: inline-block;
}
.connection-element .label {
    min-width: 40px;
    display: inline-block;
    text-align: center;
    text-transform:lowercase;
    font-size: 13px;
}
button.label.connection-element.label-inverse-primary {
    height: 23px;
    font-size: 13px;
    background-color: #fff;
    min-width: 40px;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
}

button.label.connection-element.label-inverse-primary:hover, button.label.connection-element.label-inverse-primary:active {
    background-color: #4099ff;
    color: #fff;
}

button.label.label-primary {
    border: 0;
}
.full-text .full-text-block+.add-filter-block {
    margin-top: -15px;
}


</style>
<style scoped lang="scss">
p.option-description {
  margin-bottom: 5px;
  word-break: break-word;
}
.full-text{
  padding: 8px !important;
  .card-body{
    padding: 0 !important;
  }
}
</style>