import Dexie from 'dexie';

export class ImageStorage {
    constructor(dbName, storeName) {
        this.dbName = dbName;
        this.storeName = storeName;
        this.db = new Dexie(dbName);
        this.db.version(1).stores({
            [storeName]: '++id, imageData'
        });
    }

    async init() {
        try {
            await this.db.open();
            return this.db;
        } catch (error) {
            console.error("Error opening IndexedDB", error);
            throw error;
        }
    }

    async addImage(imageData) {
        try {
            const id = await this.db[this.storeName].add({ imageData });
            return id;
        } catch (error) {
            console.error("Error adding image to IndexedDB", error);
            throw error;
        }
    }

    async getAllImages() {
        try {
            const images = await this.db[this.storeName].toArray();
            return images;
        } catch (error) {
            console.error("Error retrieving images from IndexedDB", error);
            throw error;
        }
    }

    async getImageById(id) {
        try {
            const image = await this.db[this.storeName].get(id);
            return image;
        } catch (error) {
            console.error(`Error retrieving image with id ${id} from IndexedDB`, error);
            throw error;
        }
    }
}
