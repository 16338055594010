<template>
    <div>
        {{ i18n.t('result.new_search') }}: <a href="#" @click.prevent="setMenuParam('isdn')">{{ i18n.t('params.isdn') }}</a>
    </div>
</template>

<script>
import toastMixin from '@/mixins/toastMixin';
export default {
    name: 'IsdnSearch',
    mixins: [toastMixin],
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>