import moment from 'moment';
import Version from './Version';

/**
 * получить версию сборки
 * @return {Version} версия сборки
 */

export async function getVersion() {
    let response = await fetch(`/version.json?t=${moment().format('X')}`);
    let data = await response.json();
    if (data) {
        return new Version(data);
    }
}

/**
 * проверить, что значение является булевым
 * @param {any} value значение
 * @return {Boolean} результат
 */
export function isBoolean(value) {
    return value === true || value === false || toString.call(value) === '[object Boolean]';
}