<template>
    <div class="modal fade" tabindex="-1" :id="id">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="executePrompt">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group" style="margin-bottom: 0">
                            <input type="text" class="form-control" v-model="val">
                        </div>
                    </div>
                    <p style="margin-left: 1rem" class="text-danger" v-if="!val.length">{{ $t("errors.the_value_is_invalid") }}</p>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ cancel }}</button>
                        <button type="submit" class="btn" :disabled="!val.length" :class="[actionclass || 'btn-default' ]">{{ actionbutton }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ModalPrompt',
        props: ['title', 'content', 'action', 'id', 'actionclass', 'actionbutton', 'initialdata', 'cancel'],
        data() {
            return {
                val: ''
            }
        },
        methods: {
            executePrompt() {
                this.$emit('prompt', this.val);
            }
        },
        mounted() {
            this.val = this.initialdata;
        }

    }
</script>
<style scoped>
    .modal {
        z-index: 1051;
    }
</style>