import axios from "@/configs/axios";

export default {
    namespaced: true,
    state: {
        editResultFilters: {},
        mapCenter: null
    },
    getters: {
        editResultFilters: state => state.editResultFilters,
        resultMapCenter: state => state.mapCenter
    },
    actions: {
        fullSearch({ commit, dispatch }, { payload }) {
            const innerPayload = JSON.parse(JSON.stringify(payload));
            if (innerPayload.param === 'imei' && innerPayload.value.length === 14) {
                innerPayload.value = innerPayload.value + luhnAlgorithm(innerPayload.value);
            }
            let secondarySearch;
            if (typeof innerPayload.secondarySearch !== 'undefined'){
                secondarySearch = true;
                delete innerPayload.secondarySearch
            }
            commit('contacts/increaseSearchCount',{ root: true });
            const controller = new AbortController();
            commit('contacts/addSearch', { ...payload, loaded: false, error: false, value: innerPayload.value, controller, secondarySearch }, { root: true })
            commit('contacts/setLastSearch', payload.param, { root: true });
            commit('contacts/setInitFilterOptions', { root: true });
            return new Promise((resolve, reject) => {
                axios({ url: 'filtered_search', data: innerPayload, method: 'POST', signal: controller.signal })
                    .then((resp) => {
                        if (typeof resp.data.account !== 'undefined'){
                            dispatch('auth/updateAccount', resp.data.account , { root: true })
                        }

                        commit('contacts/setSearchResult', { result: resp.data.results, payload: innerPayload, targets: resp.data.targets, errors: resp.data.errors || [], secondarySearch }, { root: true });
                        commit('contacts/setSearchesCache', { root: true });
                        if(typeof resp.data.config.search_options !== 'undefined') {
                            commit('contacts/setSearchOptions', resp.data.config.search_options, { root: true });
                        }
                        commit('contacts/stopLoading', { root: true });
                        commit('contacts/decreaseSearchCount', { root: true })
                        resolve(resp.data.results);
                    })
                    .catch(err => {
                        reject(err);
                        commit('contacts/decreaseSearchCount', { root: true })
                        let message = typeof err.response !== 'undefined'
                            ? err.response.data : { message: 'some_undefined' };
                        commit('contacts/setError', { result: message, payload: innerPayload }, { root: true });
                        commit('contacts/stopLoading', { root: true });
                    })
            })
        },
        updateResultFilters({commit}, {location, filters}){
            commit('setUpdateResultFilters', {filters, location})
        },
        resultCenter({commit}, center) {
            commit('setMapCenter', center)
        },
        clearResultFilters({commit}) {
            commit('setCleanResultFilters');
        }
    },
    mutations: {
        setUpdateResultFilters(state, payload){
            state.editResultFilters = JSON.parse(JSON.stringify(payload));
        },
        setCleanResultFilters(state) {
            state.editResultFilters = [];
        },
        setMapCenter(state, center) {
            state.mapCenter = center
        }
    }
}