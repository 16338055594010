<template>
  <div class="flexed-block has-text-centered is-justify-content-center" >
    <template v-if="typeof isChild === 'undefined'">
      <small>{{ photo.date | moment('DD.MM.YYYY, HH:mm') }}</small>
      <small v-if="typeof photo.score !== 'undefined'" style="display: block;">
        {{ $t("photo_fragment.probability") }}: {{ photo.score }}
      </small>
    </template>
    <figure class="flex-figure image is-128x128">
      <ImageProfile :internet-archive="source === 'InternetArchive'"
                    :searching="loading"
                    :payload="photo.thumbnail || photo.image"
                    :make-zoom="true"
                    :search-by-photo="isSearch"
                    @preview="zoomImg()"
                    @zoom="typeof photo.face_select !== 'undefined'
                      && typeof photo.face_select.face_boxes !== 'undefined'
                      && photo.face_select.face_boxes.length ? zoomImg() : showModal()"
                    @imgSource="onLoadImg"/>
    </figure>
    <div v-if="!loading">
      <template v-if="!photo.hasOwnProperty('results') && typeof photo.face_select === 'undefined'">
        <a v-if="typeof photo.source_url !== 'undefined' && source === 'InternetArchive'"
           style="margin-top: 0.5rem;"
           target="_blank" :href="photo.source_url"
           class="btn btn-primary">InternetArchive <i class="feather icon-link"></i></a>
      </template>
      <template v-else-if="(typeof photo.face_select === 'undefined' && !isChild) || photo.results === 0">
        <small @click="searchPhoto()" style="cursor: pointer;height: 1rem;" class="with-results">
          {{ $tc('contact.found', photo.results, { count: photo.results }) }}
        </small>
      </template>
      <template v-else-if="typeof photo.face_select !== 'undefined' && !isChild ">
        <small @click="searchPhoto()" class="with-results" style="height: 1rem;">
          {{ $tc('contact.face_count', photo.results, { count: photo.face_select.face_boxes.length}) }}
        </small>
      </template>
    </div>
    <b-modal v-model="isPhotoSegments"
             :size="isArchive? 'xl' : 'xl'"
             :width="!isArchive ? 640: 960"
             centered
             scroll="keep"
             hide-footer>
      <face-inner :photo="photoInsideSearch || photo"
                  :search-result="result"
                  :search="search"
                  :meta="metaInsideSearch || meta"
                  :from-main-search="isChild || false"
                  :imgBase="imgBase"/>
    </b-modal>


    <b-modal centered
             ref="search-photo-modal"
             hide-footer
             size="xl"
             :title="this.$t('photo_fragment.search_params')">
      <!-- <search-options
            :expanded="true"
            @push="getPayload"
            @ratesLoading="ratesLoading"
            param="image"
            currentParam="image"
            childPhotoSearch="true"
      ></search-options> -->
      <optionsSearch param="image" @push="getPayload" @ratesLoading="ratesLoading" @invalid="setInvalid"/>
      <div class="d-grid">
        <b-button class="mt-3"
                  variant="primary"
                  block
                  :disabled="loadSearch || loadingRates || invalid "
                  @click="confirmModal" v-if="!disallowFreeSpace">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                v-if="loadSearch || loadingRates"></span>
          <span>{{ $t("modal.search") }}</span>
        </b-button>
        <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
        <b-button class="mt-2"
                  variant="secondary"
                  block
                  @click="cancelModal">
          {{ $t("modal.cancel") }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import ImageProfile from "./ImageProfile";
import { mapGetters, mapState } from "vuex";
import FaceInner from "./results/FaceInner";
import imgSearch from "../mixins/imgSearch";
import {ImageCaching} from "@/mixins/imageClass";
import SearchOptions from "@/components/searchOptions";
import baseToBlob from "@/helpers/baseToBlob";
import _ from "underscore";
import randString from "@/helpers/randString";
import optionsSearch from "./search/optionsSearch.vue";

export default {
  name: "PhotoFragment",
  props: ['photo', 'meta', 'source', 'isChild'],
  mixins: [imgSearch],
  components: {
    SearchOptions,
    FaceInner,
    ImageProfile,
    optionsSearch
  },
  data() {
    return {
      imgBase: '',
      isPhotoSegments: false,
      type: '',
      photoWidth: 0,
      loading: false,
      childKey: "",
      search: true,
      disableSearchButt: true,
      destroyed: true,
      payload: {},
      visualOptions: [],
      optionsPayload: {},
      searchedKeyVal: null,
      result: null,
      loadSearch: false,
      loadingRates: false,
      invalid: false
    }
  },
  methods: {
    ratesLoading(val){
      this.loadingRates = val
    },
    setInvalid(val){
      this.invalid = val;
    },
    cancelModal(){
      this.$refs['search-photo-modal'].hide();
    },
    async collectPayload() {
      let obj = this.optionsPayload;
      const imageCache = new ImageCaching();
      const imageBase = await imageCache.loadNonComponentImage(this.photo.image)
      const fileToSend = baseToBlob(imageBase);
      const form = new FormData();
      form.append('param', 'image');
      form.append('value', fileToSend);
      form.append("cache_search_enabled",  parseInt(0));
      let online = obj.online_search_enabled ? 1: 0
      form.append("online_search_enabled", parseInt(online));
      for(const service of this.optionsPayload.services) {
        form.append("services[]", service);
      }
      return form
    },
    showModal(){
      this.payload = {
        param: 'image',
        value: this.photo.image,
      }
      this.payload = Object.assign(this.payload, this.optionsPayload)
      this.$refs['search-photo-modal'].show();
      this.$store.dispatch('ui/getFreeSpaceInfo')
    },
    successToast() {
      this.$toast.success(this.$t("result.search_added_to_main_page"), {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    async searchBlob() {
      this.loading = true;
      this.$refs['search-photo-modal'].hide()
      const payload = {
        payload: Object.assign(this.payload, JSON.parse(JSON.stringify(this.optionsPayload)))
      };
      payload.data = await this.collectPayload();
      this.$store.dispatch('contacts/faceSearchBlob', payload).then(data => {
        if (!data.face_select) {
          this.successToast();
        }
        this.loading = false;
        this.afterPhotoSearch(data, this.payload);
      });
    },
    async confirmModal(){
      if(this.isChild) {
        await this.searchBlobFromChild();
      } else {
        await this.searchBlob();
      }
    },
    async searchBlobFromChild() {
      const fileName = randString(16);
      const formPayload = await this.collectPayload();
      const payload = {
        payload: formPayload,
        file: fileName,
        secondarySearch: true
      }
      this.loadSearch = true
      this.$store.dispatch("contacts/searchFile", payload).then(async (data) => {
        this.$refs['search-photo-modal'].hide();
        this.successToast();
        this.searchedKeyVal = data.keyVal;
        if(_.isUndefined(data.data.results)) {
          let searchedFaces = {};
          searchedFaces[this.photo.image] = data.keyVal
          if(localStorage.getItem('searchedFaces')) {
            searchedFaces = Object.assign(JSON.parse(localStorage.getItem('searchedFaces')), searchedFaces);
          }
          localStorage.setItem('searchedFaces', JSON.stringify(searchedFaces));
          this.adaptResult();
          this.isPhotoSegments = true;
        }
      }).finally(() => {
        this.loadSearch = false;
      })
    },
    onLoadImg(data) {
      this.imgBase = data;
      this.disableSearchButt = false;
    },
    searchPhoto() {
      this.destroyed = false;
      if (this.photo.results && typeof this.photo.face_select === 'undefined') {
        this.openChild();
      } else if (typeof this.photo.results !== 'undefined' && this.photo.results === 0) {
        return true;
      } else {
        this.search = true;
        if (this.loading) {
          return true;
        }
        if (typeof this.photo.face_select === 'undefined') {
          this.showModal();
        } else {
          this.isPhotoSegments = true;
        }
      }
    },
    getPayload(payload){
      this.optionsPayload = payload;
    },
    openChild() {
      if (this.photo.results) {
        this.$store.dispatch('contacts/updateResultChild', this.photo.childKey)
          .then((res) => {
            this.$router.push({ name: "Child", params: {id: res.id} })
          })
      }
    },
    zoomImg() {
      this.search = !!this.photo.face_select;
      this.isPhotoSegments = true;
    },
    async mountedAction() {
      if (typeof this.tempObject !== 'undefined'
        && typeof this.tempObject.request !== 'undefined'
        && typeof this.tempObject.data === 'undefined') {
        this.loading = true;
        const answer = await this.tempObject.request;
        await this.afterPhotoSearch(answer);
      }
    },
    adaptResult() {
      const searchedFaces = localStorage.getItem('searchedFaces')
          ? JSON.parse(localStorage.getItem('searchedFaces')) : null;
      if(searchedFaces && searchedFaces[this.photo.image]) {
        const photoFactSearch = searchedFaces[this.photo.image];
        this.result = this.$store.state.contacts.searches.filter(
            item => item.keyVal === photoFactSearch
        )[0];
      }
    },
  },
  computed: {
    ...mapState({
      searchedImage: state => state.contacts.searchedImage,
      faceSelect: state => state.contacts.faceSelect,
      searchLimit: state => state.contacts.searchLimit
    }),
    ...mapGetters('ui', ['disallowFreeSpace']),
    ...mapGetters('contacts', ['temporarySearches', 'params']),
    imgKey() {
      return `image_${this.photo.image}`;
    },
    isLoading() {
      return typeof this.temporarySearches[this.imgKey] !== 'undefined' &&
        typeof this.temporarySearches[this.imgKey].data === 'undefined'
    },
    tempObject: {
      get() {
        return this.temporarySearches[this.imgKey]
      }
    },
    faces() {
      if (Object.keys(this.faceSelect).length) {
        const coefficient = this.photoWidth / this.faceSelect.width;
        return this.faceSelect.face_boxes.map(item => {
          return {
            height: item.height * coefficient,
            left: item.left * coefficient,
            width: item.width * coefficient,
            top: item.top * coefficient,
            index: item.index
          }
        })
      } else {
        return [];
      }
    },
    isArchive() {
      return typeof this.photo.source_url !== 'undefined'
    },

    photoInsideSearch() {
      if(this.result) {
        return {
          date: this.result.date,
          face_select: this.result.face_select,
          image: this.result.image_url,
        }
      }
      return null;
    },
    metaInsideSearch() {
      if(this.result) {
        return {
          children: [],
          key: this.result.keyVal,
          parent: null
        }
      }
      return null;
    },
    isSearch() {
      return this.params.includes('image') && typeof this.meta !== 'undefined' &&  !!Object.keys(this.meta).length
    }
  },
  mounted() {
    this.mountedAction()
    this.adaptResult();
  },
}
</script>
<style scoped>
.content figure:not(:last-child) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.with-results {
  font-size: 12px;
  max-width: 100%;
}

.buttons {
  margin-top: 0.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: -0.5rem;
}

.flexed-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-figure {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
</style>
