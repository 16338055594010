<template>
    <div v-show="typeof options !== 'undefined' && (options.iterative || options.advanced)" class="algoritms col-sm-6 form-radio">
        <p style="
                      text-align: left;
                      font-weight: 600;
                      margin-bottom: 0.5rem;
                    " v-show="typeof options !== 'undefined' && options.iterative">
            {{ $t('searchOptions.search_algorithms') }}
        </p>
        <div class="radio" v-show="typeof options !== 'undefined' && options.iterative">
            <label >
                <input type="radio"  v-model="payload.iterative_search" :value="false" :disabled="typeof options !== 'undefined' && !options.iterative">
                <i class="helper"></i>
                {{ $t('searchOptions.one_iteration') }}
            </label>
        </div>
        <div class="radio" v-show="typeof options !== 'undefined' && options.iterative">
            <label >
                <input type="radio"  v-model="payload.iterative_search" :value="true" :disabled="typeof options !== 'undefined' && !options.iterative">
                <i class="helper"></i>
                {{ $t('searchOptions.three_iterations') }}
            </label>
        </div>
        <p style="text-align: center; font-weight: 600; margin: 0.5rem" v-show="typeof options !== 'undefined' && options.advanced">
            {{ $t('searchOptions.search_by_name') }}
        </p>

        <div class="radio" v-show="typeof options !== 'undefined' && options.advanced">
            <label >
            <input  type="radio" v-model="payload.advanced" :value="true"
                :disabled="typeof options !== 'undefined' && !options.advanced">
                <i class="helper"></i>
                {{ $t('searchOptions.wider') }}
            </label>
        </div>

        <div class="radio" v-show="typeof options !== 'undefined' && options.advanced">
            <label >
            <input type="radio"  v-model="payload.advanced" :value="false" :disabled="typeof options !== 'undefined' && !options.advanced">
            <i class="helper"></i>
                {{ $t('searchOptions.full_equal') }}
            </label>
        </div>
    </div>
</template>
<script>
import {Algorithms} from '@/models/searchOptions/Algorithms';
import { mapGetters } from 'vuex';
import { some } from "underscore";
export default {
    
    props: ['param'],
    data() {
        return {
            payload: {
                iterative_search: undefined,
                advanced: undefined,
            },
            fullAlgorithms: {},
            payloadKeys: ['iterative', 'advanced'],
        }
    },
    computed: {
        ...mapGetters('contacts', ['searchOptions']),
        options() {
            return this.searchOptions[this.param]
        },
    },
    methods: {
        settingsByParam() {
            if(typeof this.fullAlgorithms[this.param] === 'undefined' 
                || ( typeof this.fullAlgorithms[this.param].advanced === 'undefined' 
                        && typeof this.fullAlgorithms[this.param].iterative_search === 'undefined')) {
                for(let k of this.payloadKeys) {
                    const keyPayload = k !== 'iterative' ? k : 'iterative_search'
                    if (typeof this.options !== 'undefined' ){
                      if(!this.options[k]) {
                        this.payload[keyPayload] = undefined;
                      } else {
                        this.payload[keyPayload] = undefined;
                        this.payload[keyPayload] = false;
                      }
                    }
                }
                this.fullAlgorithms[this.param] = this.payload;
                this.algorithms.updateOptions(this.payload)
            } else {
                // this.payload.advanced = undefined
                this.payload = Object.assign(this.payload, this.fullAlgorithms[this.param]);
            }
        },
    },
    mounted() {
        this.algorithms = new Algorithms(this.param)
        if(!some(Object.values(this.algorithms.options))) {
            this.settingsByParam();
        }
        this.payload = this.algorithms.options;
        this.$emit('push', this.payload);
    },
    watch: {
        payload: {
            deep: true,
            handler(val) {
                this.algorithms.updateOptions(val);
                this.$emit('push', this.payload);
            }
        }
    }
}

</script>