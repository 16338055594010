<template>
    <div class="modal fade" tabindex="-1" :id="id">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn" :class="[actionclass || 'btn-default' ]" @click="executeEmit" data-bs-dismiss="modal">{{ actionbutton }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ModalInfo',
        props: ['title', 'content', 'action', 'id', 'actionclass', 'actionbutton'],
        methods: {
            executeEmit() {
                this.$emit('execute', this.action);
            }
        }
    }
</script>
<style scoped>
    .modal {
        z-index: 1051;
    }
</style>