<template>
    <div>
        {{ i18n.t('result.new_search') }}: <a href="#" @click.prevent="setMenuParam('itn')">{{ i18n.t('params.itn') }}</a>
    </div>
</template>

<script>
import toastMixin from '@/mixins/toastMixin';
export default {
    name: 'ItnSearch',
    mixins: [toastMixin],
};
</script>

<style scoped>

</style>