<template>
    <div>
        <p class="option-description" v-for="(condition, k) in block.conditions" :key="k">{{
          typeof block.fields !== 'undefined' ?
            block.fields.map(field => $t(`params.${field}`)).join(', ') : $t('params.allData') | capitalize
            }}{{ $t(`fullTextSearchListItem.${condition.operation}`) }} {{ condition.value }}
        </p>
    </div>
</template>
<script>
import filters from "@/mixins/filters";
export default {
    name: "OptionDescription",
    props: ['block'],
    mixins: [filters]
}
</script>
<style>
p.option-description {
  margin-bottom: 5px;
  word-break: break-word;
}
</style>