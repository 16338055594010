<template>
  <section class="login-block" style="min-height: 480px">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="login-form-wrapper">
          <form class="md-float-material" @submit.prevent="verify">
            <div class="verification-content auth-box card d-flex justify-content-center">
              <div class="card-block">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <div style="margin-top: 1rem">
                      <label for="input-code" class="m-b-10">{{ $t('addVerification.send_code') }}</label>
                      <div class="input-group" style="margin-bottom: 0">
                        <input style="padding-left: 5px" v-mask="'### ###'" @input="wrongCode = false" id="input-code" placeholder="XXX XXX" autocomplete="off" type="text" maxlength="7"  class="form-control" v-model="inputCode">
                        <b-button variant="primary" class="btn " type="submit" :disabled="$v.code.$invalid">{{$t('ui.send')}}</b-button>
                      </div>

                      <div class="border-checkbox-section m-b-10" style="margin-top: 1rem" v-if="!verificationChannels.includes('telegram')">
                        <div class="border-checkbox-group border-checkbox-group-primary">
                          <input class="border-checkbox" type="checkbox" id="sendTelegramCode" v-model="sendTelegramCode">
                          <label class="border-checkbox-label" for="sendTelegramCode" style="display: flex; align-items: center">
                            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="#2AABEE" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"/></svg>

                            <span style="margin-left: 6px"> {{$t('addVerification.sendCode')}} </span>
                          </label>
                        </div>
                      </div>
                      <small v-if="!verificationChannels.includes('telegram')"> {{$t('addVerification.sendCodeInstruction')}} </small>

                      <b-button
                          :disabled="(typeof delayCounter !== 'undefined' &&  delayCounter > 0) || (typeof counter !== 'undefined' &&  counter > 0)"
                          variant="primary" style="text-transform: none; margin-top: 1rem"
                          v-if="verificationChannels.includes('telegram')"
                          @click="sendCode">
                        {{$t('settings.send_to_Telegram')}}
                      </b-button>
                      <div style="margin-top: 1rem">
                        <div v-if="typeof delayCounter !== 'undefined' && delayCounter > 0">{{ $t('addVerification.generatingCode', { timer: delayCounter }) }}</div>
<!--                        <div v-if="typeof delayCounter !== 'undefined' && delayCounter > 0">Генерируем код подтверждения… Осталось {{ delayCounter }} секунд </div>-->
                        <div v-if="typeof counter !== 'undefined' && counter > 0 && delayCounter === 0"> {{ $t('addVerification.codeTimeLeft', { timer: counter }) }}</div>
<!--                        <div v-if="typeof counter !== 'undefined' && counter > 0 && delayCounter === 0">Код действителен в течение {{ counter }} секунд.  </div>-->
                      </div>

                      <div v-if="isLoading" class="d-flex justify-content-center" style="margin-top: 1.25em;">
                        <b-spinner></b-spinner>
                      </div>
                      <div class="text-danger" v-if="wrongCode && !isLoading" style="min-height: 32px">{{$t('addVerification.not_valid_code')}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { minLength, maxLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  name: "verification",
  data() {
    return {
      isLoading: false,
      inputCode: '',
      code:'',
      wrongCode: false,
      sendTelegramCode: false,
      telegramCodeSettings: {
        channel: "telegram"
      },
      delayCounter: undefined,
      counter : undefined
    }
  },
  computed:{
    ...mapGetters('auth', ['verificationChannels','VerificationCodeExpiredTime'])
  },
  validations() {
    return {
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
    }
  },
  watch:{
    'inputCode': {
      handler(value) {
        this.code = value.toString().replace(/[^0-9]/i, '') || '';
      }
    },
    'VerificationCodeExpiredTime.delay': {
      deep: true,
      handler(val) {
            this.delayCounter = val;
      }
    },
    'VerificationCodeExpiredTime.expires': {
      deep: true,
      handler(val) {
        this.counter = val + this.VerificationCodeExpiredTime.delay;
      }
    },
    delayCounter(value) {
      if (value) {
        setTimeout(() => {
          this.delayCounter--;
        }, 1000);
      }
    },
    counter(value){
      if (value) {
        setTimeout(() => {
          this.counter--;
        }, 1000);
      }
    },
  },
  methods: {
    sendCode(){
      this.isLoading = true;
      this.$store.dispatch("auth/sendVerificationCode", this.telegramCodeSettings).finally(()=>{
        this.isLoading = false;
      })
    },
    verify() {
      this.isLoading = true;
      this.$store.dispatch('auth/verify', this.code).then((data) => {
        if (this.sendTelegramCode){
          this.$router.push({ name: 'Settings' }).then(() => {
            window.reloadHome = true;
          })
        } else {
          this.$router.push({ name: 'Home' }).then(() => {
            window.reloadHome = true;
          })
        }
      }).catch((err) => {
        if (err.response.status === 400){
          this.wrongCode = true;
        }
      }).finally(()=>{
        this.isLoading = false;
      })
    },
  },
}

</script>

<style scoped>
.textCentered{
  align-items: center;
  text-align: center;
  display: flex;
  margin-bottom: 0px;
}

.wait-for-code {
}

/*.verification-content {*/
/*  min-height: 12rem;*/
/*}*/
</style>