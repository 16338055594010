
export default {
    namespaced: true,
    state: {
        mapCenter: null
    },
    getters: {
        resultMapCenter: state => state.mapCenter
    },
    actions: {
        resultCenter({commit}, center) {
            commit('setMapCenter', center)
        },
    },
    mutations: {
        setMapCenter(state, center) {
            state.mapCenter = center
        }
    }
}