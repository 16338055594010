<template>
    <div class="card full-text-block">
        <div class="card-body">
            <FullTextElement v-for="(rule, k) in rules" 
                @update="(payload) => updatePayload(rule, payload)" 
                :filter="filter"
                @invalid="(val) => {invalidObj[rule] = val; setInvalid()}"
                :rule="rule" :ref="rule"
                :key="rule"
                :show-connection="rules.length - k > 1"
                :first-block="firstBlock"
                :first-element="k === 0"
                :rules-lenght="rules.length"
                @remove="checkRemove(rule)" />
                <button v-if="!fieldsOptionsNot && rules.length < 10" style="margin-top: 4px;text-transform: uppercase;" class="label label-inverse-primary connection-element" @click="addElement">{{ $t('fullText.or') }}...</button>
        </div>
        <p class="text-center full-text-block-connection connection-element" v-if="showConnection"><span class="label label-inverse-primary text-uppercase">{{ $t('fullText.and') }}</span></p>
    </div>
</template>
<script>
import FullTextElement from "./FullTextElement"
import {max, findWhere, isUndefined} from "underscore"
export default {
    name: 'FullTextBlock',
    components: {
        FullTextElement
    },
    props: ['filter', 'showConnection', 'firstBlock'],
    data() {
        return {
            field: 'first_name',
            rules: [
            ],
            conditionsObject: {},
            payload: {
                optional: false,
                fields: [],
                conditions: []
            },
            invalidObj: {},
            naturalMount: true
        }
    },
    methods: {
        addElement() {
            const indexesRules = this.rules.length ? this.rules.map(rule => parseInt(rule.match(/\d+/)[0])) : [0];
            const maxIndex = max(indexesRules)+1
            const ruleName = `item${maxIndex}`
            this.rules.push(ruleName);
            return ruleName;
        },
        checkRemove(itemKey) {
            
            if(!this.firstBlock || this.payload.conditions.length > 1) {
                this.removeItem(itemKey)
            } else {
                this.$emit('checkremove', itemKey);
            }
        },
        removeItem(itemKey) {
            this.rules = this.rules.filter(item => item !== itemKey);
            delete this.payload[itemKey];
            delete this.invalidObj[itemKey];
            delete this.conditionsObject[itemKey];
            this.updateConditions();
            this.setInvalid();
        },
        updatePayload(rule, elementPayload) {
            this.conditionsObject[rule] = elementPayload
            this.updateConditions();
        },
        updateConditions() {
            this.payload.conditions = Object.values(this.conditionsObject);
            const payload = JSON.parse(JSON.stringify(this.payload));
            if(!isUndefined(findWhere(payload.conditions, {operation: "optional"}))) {
                payload.optional = true;
                payload.conditions = payload.conditions.filter(condition => condition.operation !== 'optional')
            }
            this.$emit('update', payload)
        },
        setInvalid() {
            this.$emit('invalid', Object.values(this.invalidObj).includes(true));
        },
        async addOptionalField() {
            const ruleName = await this.addElement();
            await this.$refs[ruleName][0].setPayload({operation: 'optional', value: ''})
        },
        async setPayload(payload) {
            for (const condition of payload.conditions) {
                const ruleName = await this.addElement();
                await this.$refs[ruleName][0].setPayload(condition)
            }
            if(payload.optional) {
                await this.addOptionalField();
            }
        },
        naturalMountAction() {
            this.rules.push('item0')
        }
    },
    computed: {
        fieldsOptionsNot() {
            return this.payload.conditions.length && this.payload.conditions[0].operation.includes('not')
        }
    },
    watch: {
        rules(val) {
            if (val.length === 0) {
                this.$emit('remove');
            }
        },
    },
    mounted() {
        this.payload.fields = this.filter.fields.map(field => field.name)
    }
}
</script>

<style>
.full-text-block-connection {
    position: absolute;
    bottom: -43px;
    width: 100%;
    
}
.full-text-block-connection .badge {
    min-width: 40px;
    font-size: 1.2em;
}
.badge-button-add {
    border: 0;
    width: 40px;
    font-size: 2em;
    font-weight: 700;
    height: 20px;
}
.badge-button-add.text-bg-primary:hover {
    background-color: rgba(13, 110, 253, 0.7) !important;
    
}
</style>
<style lang="scss" scoped>
.full-text-block{
  .card-body{
    padding: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
</style>