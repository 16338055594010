<template>
  <div>
    <form @submit.prevent="submit">
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <b-form-group :label="$t('pdf.file_name')">
            <b-form-input v-model="currentName" :placeholder="$t('pdf.file_name')" autocomplete="off" required>
            </b-form-input>
          </b-form-group>

          <div style="display: flex;flex-direction: column">
            <div class="form-radio m-b-10">
              <div class="radio">
                <label for="pdf">
                  <input type="radio" id="pdf" value="PDF" v-model="documentType">
                  <i class="helper"></i>
                  PDF
                </label>
              </div>
              <div class="radio" v-if="permissions.includes('search_results.export.docx')">
                <label>
                  <input type="radio" id="doc" value="DOC" v-model="documentType">
                  <i class="helper"></i>
                  DOC
                </label>
              </div>
            </div>
            <div class="border-checkbox-section m-b-10" v-if="documentType === 'PDF'">
              <div class="border-checkbox-group border-checkbox-group-primary">
                <input class="border-checkbox" type="checkbox" id="protect" v-model="passwordEnable">
                <label class="border-checkbox-label" for="protect">
                  {{ $t("pdf.protect_the_file_with_a_password") }}
                </label>
              </div>
            </div>
            <div class="border-checkbox-section m-b-10" v-if="documentType === 'PDF'">
              <div class="border-checkbox-group border-checkbox-group-primary">
                <input class="border-checkbox" type="checkbox" id="watermark" v-model="watermark">
                <label class="border-checkbox-label" for="watermark">
                  {{ $t("pdf.addWatermark") }}
                </label>
              </div>
            </div>
          </div>

          <b-form-group :label="$t('pdf.password_for_document')" v-if="passwordEnable && documentType === 'PDF'"
            style="margin-top: 0.5rem">
            <b-form-input autocomplete="off" v-model="currentPassword" :placeholder="$t('pdf.your_password')">
            </b-form-input>
          </b-form-group>
        </section>
        <footer class="d-grid gap-2">
          <span class="text-danger" v-if="incorrectPassword"> {{ $t("pdf.incorrect_password") }}</span>
          <b-button variant="primary" type="submit" :disabled="(((currentPassword === null || !currentPassword.length) && passwordEnable) && documentType === 'PDF') || incorrectPassword"  class="btn"><i class="feather icon-download" style="margin-right: 8px"></i>{{ $t("pdf.save") }}</b-button>
          <b-button variant="secondary" type="button" @click="$emit('close')" class="btn">{{ $t('user.cancel') }}</b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "PdfPrompt",
  props: ['filename', 'password', 'canCancel'],
  data() {
      return {
        currentPassword: '',
        currentName: '',
        passwordEnable: true,
        watermark: true,
        documentType: 'PDF',
        incorrectPassword: false,
      }
  },
  watch:{
    currentPassword(){
      if (/[а-яА-ЯЁё-і]/g.test(this.currentPassword)){
        this.incorrectPassword = true;
      } else {
        this.incorrectPassword = false;
      }
    },
  },
  computed:{
    payload(){
      return {
        filename: this.currentName,
        passwordEnable: this.passwordEnable,
        password: this.currentPassword,
        documentType: this.documentType,
        watermark: this.watermark
      }
    },
    ...mapGetters('auth', ['permissions'])
  },
  methods: {
   submit() {
     if (this.payload.documentType === 'PDF'){
       this.$emit('pdf-data', this.payload);
       try {
        this.$matomo.trackEvent('Web Export PDF', 'WasClicked')
       } catch (e) {
         console.log('ignore error');
       }
     } else {
       this.$emit('doc-data', this.payload)
       try {
        this.$matomo.trackEvent('Web Export DOCX', 'WasClicked')
       } catch (e) {
         console.log('ignore error');
       }
     }
     this.$emit('close');
   }
  },
  mounted() {
    this.currentName = this.filename.replace(/.jpg/, "");
    this.currentPassword = this.password;
  }
}
</script>

<style lang="scss">
/*
.modal-card-head {
  justify-content: flex-end;
}
*/
</style>