import _, { isUndefined, isArray } from "underscore";
export default  function (obj, item) {
    let nameArr = [];
    let countResult = 0;
    if(obj.some(item => !!item.search_results)){
        for (let objItem of obj) {
            if(isArray(objItem.search_results)) {
                countResult += objItem.search_results.length;
                for(let res of objItem.search_results) {
                    let nameFilter = res.data.filter((el) => {
                        return ['name', 'first_name'].includes(el.param);
                    })
                    nameArr.push(...nameFilter);
                }
            }
        }
    }
    if(isUndefined(item.initial)) {
        let temp = []
        let totalCost = 0;
        obj.forEach(result =>{
            if (typeof result !== "undefined" && !result.error  ){
                totalCost += result.cost;
                temp.push({
                    source: result.source,
                    source_locales: result.source_locales,
                    cost: result.cost,
                    icon: result.icon
                })
            }
        })
        temp = temp.sort(function (a, b){ return b.cost - a.cost })
        item.cost = temp;
        item.totalCost = totalCost;
    }
    item.countResult = countResult;
    item.countSources = obj[0].results
    if(nameArr.length) {
        item.name = nameArr.map(nameVal => nameVal.value);
    } else {
        item.name = 'No name'
    }
    if(obj[0].error) {
        item.error = obj[0].error
        item.errorName = obj[0].errorName
    } else {
        item.error = false
    }
    item.initial = true

    if (!item.error){
        let resultWithImage;
        if (obj.length){
            let tempArr = [];
            obj.map( source =>{ source.search_results && source.search_results.map( searchResult =>{ tempArr.push(searchResult) }) })
            resultWithImage = tempArr.find(searchResultItem => searchResultItem.photos.length > 0)
            const initialValue = 0;
            const sumWithInitial = tempArr.reduce(
                (accumulator, currentValue) => {
                    const currentPhotosCount = _.isUndefined(currentValue.photos) ? 0 : currentValue.photos.length
                    return accumulator + currentPhotosCount
                },
                initialValue,
            );
            item.photosCount = sumWithInitial;
        }
        if (typeof resultWithImage !== 'undefined'){
            item.imageFromResults = resultWithImage.photos[0].image
        }
    }
}