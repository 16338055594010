<template>
  <nav class="pcoded-navbar" style="position: fixed; margin-top: 9px">
    <div class="nav-list">
      <div class="pcoded-inner-navbar main-menu">
        <ul class="pcoded-item pcoded-left-item p-b-50">
          <MenuItem v-for="item in sideMenuItems" :key="item.meta" :current-param="currentParam" :item="item" />
          <li @click.prevent="goToMonitoring()" v-if="permissions.includes('access.monitoring')"
            :class="{ 'active': $route.name === 'Monitoring' }">
            <a class="waves-effect waves-dark monitoring-link" :class="{ 'active': $route.name === 'Monitoring' }">
              <span class="pcoded-micon" style="padding-top: 4px">
                <i class="feather icon-eye"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('monitoring.monitoring') }}</span>
            </a>
          </li>
          <li class="pointer is-separated" :class="{ active: $route.name === 'Home' && currentParam === '' }"
            @click.prevent="setMenuParam('')">
            <a class="waves-effect waves-dark" :class="{ active: $route.name === 'Home' && currentParam === '' }">
              <span class="pcoded-micon" style="padding-top: 4px">
                <i class="feather icon-menu"></i>
              </span>
              <span class="pcoded-mtext">{{ this.$t('side_menu.search_history') }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "sideNavExpanded",
  components: { MenuItem },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('mainMenuState', ['currentParam']),
    sideMenuItems() {
      let arr = [
        {
          meta: 'all_data',
          icon: `<i class="feather icon-search"></i>`,
          children: [
            'simple_search',
            'full_search',
          ]
        },
        {
          meta: 'phone',
          icon: `<i class="feather icon-phone"></i>`,
          children: [
            'isdn',
            'mass_isdn'
          ]
        },
        {
          meta: 'person',
          icon: `<i class="feather icon-user"></i>`,
          children: [
            'name',
            'itn',
            'image',
            'email',
            'username',
            'telegram_id'
          ]
        },
        {
          meta: 'car',
          icon: `<svg style="width: 15px; height: 16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b7c0cd" d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm288 32c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z"/></svg>`,
          children: [
            'car_plate_num',
            'car_vin'
          ]
        }
      ]
      if (this.permissions.includes('search.geo')) {
        arr[0].children.push('geo_search');
        $(document).trigger('ready')
      }
      if (this.permissions.includes('search.geospatial')) {
        arr[0].children.push('geo_search_telegram');
      }
      // $(document).ready(function(e){   
      //   if(typeof e.isTrigger === 'undefined') {
      //     console.log('not triggered') 
      //     $(document).trigger('ready');
      //   } else {
      //     console.log('fff');
      //   }
      // });
      // $(document).trigger('ready');
      return arr;
    },
  },
  mounted() {
    $(document).trigger('ready');
  },
  methods: {
    goToMonitoring(val) {
      this.closeMenu();
      this.$router.push({ name: 'Monitoring' })
      this.$store.dispatch('mainMenuState/menuParam', val)
    },
    closeMenu() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        document.getElementsByClassName("pcoded-overlay-box")[0].click();
      }
    },
    setMenuParam(val) {
      setTimeout(() => {
        localStorage.setItem("lastParam", val);
      }, 100);
      this.$store.dispatch('mainMenuState/menuParam', val).then(() => {
        if (this.$route.name !== 'Home') {
          this.$router.push({ name: 'Home' })
        }
        this.closeMenu();
      })
    },
  },
  watch: {
    sideMenuItems: {
      deep: true,
      handler() {
        if (this.permissions.includes('search.geo')) {
          $(document).trigger('ready');
        }
      }
    },
    '$route.name': {
      handler(val) {
        if (val !== 'Home') {
          setTimeout(() => {
            localStorage.setItem("lastParam", '');
          }, 100);
          this.$store.dispatch('mainMenuState/menuParam', '').then(() => {
            // this.closeMenu();
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.none {
  display: none;
}

@media (max-width: 992px) {
  .pcoded-inner-navbar {
    margin-top: 70px !important;
  }
}

.pointer {
  cursor: pointer;
}

.is-separated {
  margin-top: 20px;
  border-top: 1px solid #fff;
}

.menuTitle {
  display: flex;
  justify-content: space-between;
  color: #b7c0cd;
  text-align: left;
  padding: 7px 15px 7px 24px;
  margin: 5px 0 0;
  border-radius: 0;
  border-left: 3px solid transparent;

  .menuText {
    padding-left: 10px;
  }
}

.pcoded[theme-layout=vertical] .pcoded-navbar[navbar-theme=theme1][active-item-theme=theme1] .pcoded-item>li.pcoded-trigger>a {
  background: transparent;
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>.pcoded-hasmenu>.pcoded-submenu {
  padding: 0;
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li>a {
  margin: 0;
  padding: 5px 10px 5px 20px;
}

.pcoded .pcoded-navbar .pcoded-item>li>a>.pcoded-micon {
  padding: 0;
}

.pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li.active>a,
.pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li:hover>a {
  color: #ffffff;
}

body .pcoded[theme-layout=vertical] .pcoded-navbar[navbar-theme=theme1][active-item-theme=theme1] .pcoded-item>li.pointer>a.active,
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li>a.active {
  color: #42a5f5;
}

body .pcoded[theme-layout=vertical] .pcoded-navbar[navbar-theme=theme1][active-item-theme=theme1] .pcoded-item>li.pcoded-trigger>a .pcoded-micon {
  color: inherit;
}

body .pcoded[theme-layout=vertical] .pcoded-navbar[navbar-theme=theme1][active-item-theme=theme1] .pcoded-item>li.pcoded-trigger:hover>a {
  background: transparent;
}

body .pcoded .pcoded-navbar {
  font-family: "Open Sans", sans-serif;
}

body .pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
}

body .pcoded[theme-layout=vertical] .pcoded-navbar[navbar-theme=theme1][active-item-theme=theme1] .pcoded-item>li.active>a {
  background: transparent;
}

.monitoring-link {
  cursor: pointer;
}
</style>