<template>
  <form @submit.prevent="submit">
    <div class="row">
      <h4>{{ $t('fullText.Select fields for search') }}</h4>
      <div class="col-12 m-b-10">
        <div class="multiselect__tags" :class="{'blured': !options.length}" style="border: none">
          <div class="multiselect__tags-wrap" style="">
            <span class="multiselect__tag" v-for="option in options">
              <span class="text-capitalize">{{ $t('params.' + option.name) }}</span> 
              <i tabindex="1" class="multiselect__tag-icon" @click="selectParam(option)"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <h5>{{ $t('fullText.categories') }}</h5>
        <div class="list-group" id="listCategories">
          <a href="#" @click.prevent="selectCategory(category)" v-for="category in Object.keys(categories)" v-if="category === 'geo' && permissions.includes('search.geo') || category !== 'geo'"
            class="list-group-item category" :class="{ 'active': category === selectedCategory }">
            {{ $t('fullText.cats.'+category) }}
          </a>
        </div>
      </div>
      <div class="col-6">
        <h5>{{ $t('fullText.params') }}</h5>
        <div class="list-group list-params" id="listParams" v-if="selectedCategory">
          <a href="#" class="list-group-item"
            :class="{ active: options.includes(param), 
              disabled: options.length && und.isNull(param.compatibility) 
              || options.length && und.isNull(param.addAddition)
              || options.length && options.filter(opt => und.isNull(opt.compatibility) && (opt.name !== param.name)).length 
              || options.length && options.filter(opt => und.isNull(opt.addAddition) && (opt.name !== param.name)).length 
              || und.isNull(param.compatibility) && existingFields.includes(param.name)
              }"
            v-for="param in categories[selectedCategory]" v-if="Object.keys(filteredSearchOptions).includes(param.name) || ['allData', 'geo_search'].includes(param.name)" @click.prevent="selectParam(param)">
            {{ $t('params.'+param.name) }}
          </a>
        </div>
        <div class="list-group list-params" v-else>
          <div class="list-group-item placeholder-group-item">
            {{ $t('fullText.selectCategory') }}
          </div>
        </div>
      </div>
      
    </div>
    <footer class="d-grid gap-2 m-t-10">
      <b-button variant="primary" type="submit" :disabled="!options.length" class="btn">{{ $t('fullText.add') }}</b-button>
      <b-button variant="secondary" type="button" @click="$emit('close')" class="btn">{{ $t('user.cancel') }}</b-button>
    </footer>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect';
import categories from './categories';
import und from 'underscore';
import { mapGetters } from 'vuex';
export default {
  name: "AddFilter",
  props: ['existingFields'],
  components: { Multiselect },
  setup() {
    return {
      categories,
      und
    }
  },
  data() {
    return {
      filters: [],
      selectedCategory: null,
      options: []
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('contacts',['filteredSearchOptions']),
  },
  methods: {
    submit() {
      this.$emit('options', this.options);
    },
    selectCategory(category) {
      this.selectedCategory = category;
      const categoryHeight = document.getElementById('listCategories').clientHeight;
      const paramsList = document.getElementsByClassName('list-params')[0];
      paramsList.style.height = categoryHeight + 'px';
    },
    selectParam(param) {
      if (this.options.includes(param)) {
        this.options = this.options.filter((item) => item.name !== param.name)
      } else {
        this.options.push(param)
      }
    },
    initialSettings() {
      setTimeout(() => {
        const categoryHeight = document.getElementById('listCategories').clientHeight;
        const paramsList = document.getElementsByClassName('list-params')[0];
        paramsList.style.height = categoryHeight + 'px';
        document.getElementsByClassName('placeholder-group-item')[0].style.height = categoryHeight + 'px';
      }, 100);
    }
  },
  watch: {
    options() {

    }
  },
  mounted() {
    this.initialSettings()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.list-group-item::first-letter {
  text-transform: capitalize;
}
.multiselect__option--selected:after {
  display: inline-block;
  content: "";
  height: 40px;
  width: 15px;
  background-color: #f3f3f3;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #d7d7d7;
  color: #35495e;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #d7d7d7;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}

.multiselect__option--highlight {
  background-color: #f3f3f3;
  color: #35495e;
}

.multiselect__tags {
  border-radius: 2px;
  border: 1px solid rgb(204, 204, 204);
}

.multiselect__tag {
  background: #d9ebff;
  color: #35495e;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #5aa8fb;

}

.list-params {
  overflow-y: scroll;
}

.list-group-item.placeholder-group-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727272
}
.multiselect__tags.blured {
  background: #f5f5f5;
}
</style>