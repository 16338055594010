<template>
  <div class="col-md-12 container card" style="overflow: hidden">
    <div class="cardHeader" style="overflow: hidden;">
      <name-translate
          ref="nameTranslate"
          :search-payload="searchPayload"
          :open="open"
      />
      <birthday-date
          ref="birthdayDate"
          :open="open"
      />
      <add-address
          ref="addAddress"
          :open="open"
      />
      <div style="margin-top: 5px; display: flex;justify-content: center; cursor: pointer" @click="open = !open">
        <b-icon
            :icon="open ? 'caret-up' :'caret-down'">
        </b-icon>
      </div>
    </div>
  </div>

</template>

<script>
import NameTranslate from "@/components/nameSearchSettings/nameTranslate";
import BirthdayDate from "@/components/nameSearchSettings/birthdayDate";
import AddAddress from "@/components/nameSearchSettings/addAddress";
export default {
  name: "nameSearchAdditionalSettings",
  props: ['searchPayload'],
  components: {AddAddress, BirthdayDate, NameTranslate},
  data(){
    return {
      open: true
    }
  },
  methods:{

    clearForm(){
      this.$refs.nameTranslate.clearForm();
      this.$refs.birthdayDate.clearForm();
      this.$refs.addAddress.clearForm();
      this.open = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.container{
  padding: 12px;
}
.card{
   border: solid 1px #dbdbdb;
   border-radius: 4px;
   margin-bottom: 1rem;
   padding: 8px;
 }
.checkboxContainer{
  display: flex;
  flex-direction: column;
}
.card-header-icon{
  padding-right: 0;
}
.contentIcon{
  padding-right: 8px;
  padding-bottom: 0;
}
.checkbox{
  margin-bottom: 5px;
}
.dataBlock{
  .data{
    margin-right: 0;
  }
}

</style>
<style lang="scss">

.settings-text{
  //margin-top: 4px!important;
  margin-bottom: 0;
  height: 35px;
  align-items: center;
  @media (min-width: 993px) {
    padding-left: 22px !important;
  }
  @media (min-width: 993px) {
    padding-left: 22px !important;
  }
  @media (max-width: 922px) {
    margin-bottom: 10px;
  }
}
</style>