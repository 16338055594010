export default {
    methods: {
        paginationFix(){
            if (this.result.search_results.length === 3){
                this.current = 1;
            }
        },
        removeSelectedItem(el){
            this.result.search_results.forEach(item => {
                if (item === el){
                    item.selected = true;
                }
            })
            if (this.result.search_results.length === 1){
                this.deleteSelectedResult()
            } else {
                let  payload=  {
                    keyVal: this.meta.key,
                    result: this.result,
                    el: el
                }
                this.findChildResults(el);
                if (!this.showModalDialogForSearchResult){
                    this.$store.dispatch('contacts/setRemoveSelectedResult', payload).then(()=>{
                        this.$store.dispatch('contacts/updateResult', payload).then(()=>{
                            this.paginationFix()
                        })
                    })
                }
            }

        },

        removeSelectedItems(){
            let count = 0;
            this.result.search_results.forEach(item => {
                if (item.selected ){
                    count++
                }
            })
            if (this.result.search_results.length > count && count !== 0 ){
                this.findChildResults();
                this.setDialogMessage(this.itemsForDeleteSearchedResult)
                if (this.itemsForDeleteSearchedResult.length === 0){
                    this.result.search_results.forEach(item => {
                        if (item.selected){
                            let  payload=  {
                                keyVal: this.meta.key,
                                result: this.result,
                                el: item
                            }
                            this.$store.dispatch('contacts/setRemoveSelectedResult', payload).then(()=>{
                                this.paginationFix()
                                // this.$store.dispatch('contacts/updateResult', payload).then(()=>{
                                // })
                            })
                        }
                    })
                }
            } else if (count !== 0) {
                this.deleteSelectedResult()
            }
        },

        deleteSearchedResultAndChildren(){
            let payload = {
                keyVal: this.meta.key,
                result: this.result
            }
            this.$store.dispatch('contacts/setRemoveSelectedResults', payload).then(()=>{
                if (this.itemsForDeleteSearchedResult.length ){
                    this.results.forEach(result =>{
                        this.itemsForDeleteSearchedResult.forEach(item=>{
                            if (result.value === item.value){
                                let payload = {
                                    keyVal: this.meta.key,
                                    result: result
                                }
                                this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
                                    this.$store.dispatch('contacts/updateResult', payload);
                                })
                            }
                        })
                    })
                }
            })
        },
        findChildResults(el){
            this.itemsForDeleteSearchedResult = [];
            let selectedItems = []
            if (typeof el !== 'undefined'){
                selectedItems.push(el)
            } else {
                this.result.search_results.forEach(item => {
                    if (item.selected){
                        selectedItems.push(item);
                    }
                })
            }
            if (this.result.level !== 3 ){
                let childLevel = this.levels[this.result.level]
                if (typeof childLevel !== 'undefined'){
                    childLevel.forEach(child =>{
                        if (child.parentSources.includes(this.result.source)){
                            if (child.parentSources.length === 1){
                                selectedItems.forEach(selectedItem =>{
                                    selectedItem.data.forEach(selectedItemDataObj =>{
                                        if (selectedItemDataObj.value === child.value){
                                            this.itemsForDeleteSearchedResult.push(child)
                                        }
                                        if (typeof child.children !== 'undefined' &&  child.children.length > 0 ){
                                            let childLevel = this.levels[child.level]
                                            childLevel.forEach(childOfChild =>{
                                                if (childOfChild.parentSources.length === 1 && selectedItemDataObj.value === childOfChild.parentValue){
                                                    this.itemsForDeleteSearchedResult.push(childOfChild)
                                                }
                                            })
                                        }


                                    })
                                })
                            }
                        }
                    })
                }
            }
            if (this.itemsForDeleteSearchedResult.length > 0){
                this.showModalDialogForSearchResult = true;
            }
        },
    },
}