import EmojiConvertor from "emoji-js";
export  default {
    filters: {
        capitalize(value)
        {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        paramName(str)
        {
            return str.split('_').map(function (item) {
                return item.charAt(0).toUpperCase() + item.substring(1);
            }).join(' ');
        },
        uppercase(value)
        {
            if (!value) return '';
            return value.toUpperCase();
        },
        lowerCase(value) {
            if(!value) return '';
            return value.toLowerCase();
        },
        makeEmoji(value) {
            let emojiLib = new EmojiConvertor();
            emojiLib.replace_mode = 'img';
            emojiLib.img_set = 'twitter';
            return emojiLib.replace_unified(value);
        },
        isLink(value) {
            // let regexQuery = "^(https?://)+";
            // let url = new RegExp(regexQuery,"i");
            // let isLink = !!url.test(value);
            // if(isLink) {
            //     return `<a href="${value}" target="_blank">${value}</a>`;
            // } else {
            //     return value.replace(/\n/g, "<br />");
            // }
            const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            let text = value.replace(pattern1, '<a href="$1" class="btn-link" target="_blank">$1</a>');

            const pattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            text = text.replace(pattern2, '$1<a href="http://$2" class="btn-link" target="_blank">$2</a>');
            return text.replace(/\n/g, "<br />");
        },
        errorTranslate(value) {
            const error = 'errors.'+value.replace(/[_\s]+/g, '_').toLowerCase().replaceAll('.','')
            if(error.includes('_does_not_satisfy_the_geojson_format_specification')) {
                return 'errors.the_location_does_not_satisfy_the_geojson_format_specification'
            }
            return error;
        },
        numberWithCommas(x) {
            let parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".");
        }
    },
    methods: {
        trans(val) {
            return this.$t(val);
        }
    }
}