import _ from "underscore";
export class OptionsModel {
       
    constructor(param, options, storageName) {
        this.param = param;
        this.options = options
        this.storageName = storageName;
    }

    initParam() {
        const jsonLocalstorage = this.getOptions();
        const optionsObj = JSON.parse(jsonLocalstorage);
        if(_.isUndefined(optionsObj[this.param])) {
            
            optionsObj[this.param] = this.options;
            this.setLocalStorageUpdate(optionsObj);
        } else {
            this.options = optionsObj[this.param]
        }
    }

    getOptions() {
        if(!localStorage.getItem(this.storageName)) {
            localStorage.setItem(this.storageName, JSON.stringify({}));
        } 
        return localStorage.getItem(this.storageName);
    }
    setLocalStorageUpdate(fullObjectOptions) {
        localStorage.setItem(this.storageName, JSON.stringify(fullObjectOptions))
    }
    updateOptions(optionsInside) {
        this.options = optionsInside;
        const fullObject = JSON.parse(localStorage.getItem(this.storageName));
        fullObject[this.param] = optionsInside;
        localStorage.setItem(this.storageName, JSON.stringify(fullObject));
    }

}