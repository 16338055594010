import {mapGetters} from "vuex";
import {WidthType, BorderStyle, Document, Paragraph, Packer, TextRun, HeadingLevel, ImageRun}
    from "docx";
import { saveAs } from 'file-saver';
import moment from "moment/moment";
import chunkArray from "@/helpers/chunk";
import i18n from "@/i18n";
import _ from "underscore"
import { ImageCaching } from "./imageClass";


export default {
    components: {
        Document, Paragraph, Packer, TextRun, saveAs, BorderStyle, WidthType
    },
    data() {
        return {
            resizedImages: 0,
            startedExport: false,
            promptPdfActive: false,
            formProps: {
                filename: null,
                password: null
            },

            pdfPassword: 'test',
            needPass: true,
        }
    },
    computed: {
        ...mapGetters('images', ['images', 'allImages', 'photoLoaded']),
        ...mapGetters('auth', ['user']),

        photosCount() {
            let photoCount = 0;
            for (let result of this.results) {
                if(result.search_results) {
                    for (let sr of result.search_results) {
                        photoCount += sr.photos.length;
                    }
                }
            }
            return photoCount
        }

    },
    methods: {
        mountActionsDoc() {
            if (this.meta && this.meta.parent && this.meta.payload.param === 'image'){
                this.formProps.fileName = 'image_search_'+ this.meta.key.split('/')[6]
            } else {
                this.formProps.fileName = this.meta.key.includes('geo_search_telegram_point') ? this.meta.key.replace('geo_search_telegram_point', 'whos_around') : this.meta.key;
            }
        },
        resizeImageDoc(base64Str, maxWidth = 400, maxHeight = 400) {
            return new Promise((resolve) => {
                let img = document.createElement('img');
                img.src = base64Str
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = maxWidth
                    const MAX_HEIGHT = maxHeight
                    let width = img.width
                    let height = img.height

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width
                            width = MAX_WIDTH
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height
                            height = MAX_HEIGHT
                        }
                    }
                    canvas.width = Math.floor(width)
                    canvas.height = Math.floor(height)
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    resolve({image: canvas.toDataURL(), width: width, height: height})
                }
            })
        },
        isEnyResultInLevel(level){
            let count = 0;
            level.items.forEach(item => {
                if (item.search_results){
                    count += item.search_results.length
                }
            })
            return count > 0
        },
        setAddressLocation(parentItems, row) {
            if(!_.isUndefined(parentItems) && row.param == 'address') {
              const addresses = _.where(parentItems, {param: 'address'});
              const lats = _.where(parentItems, {param: 'latitude'});
              const longs = _.where(parentItems, {param: 'longitude'});
              const indexAdress = _.findIndex(addresses, {value: row.value});
              if(lats.length && longs.length && lats[indexAdress].value !== '-') {
                return {param: row.param, value:`${row.value} (${parseFloat(lats[indexAdress].value).toFixed(4)}, ${parseFloat(longs[indexAdress].value).toFixed(4)})`};
              } else {
                return row;
              }
            }
            return row;
          },

        async resultToDocument(paylaod) {
            this.isLoading = true;
            this.formProps.filename = paylaod.filename
            let resizedPhotos = 0;
            let levels = this.getLevels();
            let obj = {children: []};
            let head = true;
            for(let level of levels){
                if (this.isEnyResultInLevel(level)){
                    if(level.items[0].param === 'image' && head) {
                        const imageCache = new ImageCaching();
                        const imageBase = await imageCache.loadNonComponentImage(level.items[0].value)
                        let imageObj = await this.resizeImageDoc(imageBase)
                        obj.children.push(new Paragraph({
                            text: this.$t('search.search_by_photo'),
                            heading: HeadingLevel.TITLE,
                        }))
                        obj.children.push(new Paragraph({
                            children: [
                                new ImageRun({
                                    data: imageObj.image,
                                    transformation: {
                                        width: imageObj.width,
                                        height: imageObj.height
                                    },
                                }),
                            ],
                        }))
                        head = false;
                    } else {
                        if(head) {
                            obj.children.push(new Paragraph({
                                text: this.$t('search.search_results'),
                                heading: HeadingLevel.TITLE,
                            }))
                            obj.children.push(new Paragraph({
                                text: level.items[0].value,
                                heading: HeadingLevel.TITLE,
                            }))
                        }
                        head = false;

                        if(this.schemaPic) {
                            obj.children.push(new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: this.schemaPic,
                                        transformation: {
                                            width: this.schemaPicSize.width * 0.75,
                                            height: this.schemaPicSize.height * 0.75
                                        },
                                    }),
                                ],
                            }))
                        }
                        if (typeof level.value !== 'undefined'){
                            let text = this.$t('result.stage')+' '+level.level+': '+ this.$t('result.search_by') +' '+ level.value
                            obj.children.push(new Paragraph({
                                text: text,
                                heading: HeadingLevel.TITLE,
                            }))
                        }

                    }
                    for (let result of level.items) {
                        for(let k in result.search_results) {

                            if (result.type === 'database' || result.source === 'FaceRecognition'){
                                obj.children.push(new Paragraph({
                                    text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                    heading: HeadingLevel.HEADING_1,
                                }))
                            } else {
                                obj.children.push(new Paragraph({
                                    text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                    heading: HeadingLevel.HEADING_1,
                                }))
                            }

                            if(result.search_results[k].data.length > 0) {
                                for(let infoRow of result.search_results[k].data) {
                                    infoRow = this.setAddressLocation(result.search_results[k].data, infoRow)
                                    if(['status', 'subscriber_status'].includes(infoRow.param)) {
                                        obj.children.push(new Paragraph({
                                            text: this.$options.filters.capitalize(this.$t('params.'+infoRow.param))+': ' +
                                                this.$t(`hlr.${infoRow.value}`),
                                        }))
                                    } else {
                                        if(typeof infoRow.value !== 'undefined') {
                                            if (infoRow.param === 'social_url'){
                                                obj.children.push(new Paragraph({
                                                    text: this.$options.filters.capitalize(this.$t('params.'+infoRow.param)) + this.splitter(infoRow.value, 30)
                                                }))
                                            }
                                            else {
                                                if(!['latitude','longitude'].includes(infoRow.param)) {
                                                    obj.children.push(new Paragraph({
                                                        text: this.$options.filters.capitalize(this.$t('params.'+infoRow.param))+': ' +
                                                            infoRow.value}))
                                                }
                                            }
                                        }
                                    }
                                }
                                if (typeof  result.search_results[k].locations !== 'undefined'){
                                    for(let locationInfo of result.search_results[k].locations) {
                                        let index = 1;
                                        let scecifyingIndex =  this.$t('geo.specifiing') +' # '+ index
                                        let time = moment(locationInfo.info_date, 'X').format('DD.MM.YYYY HH:mm:ss')
                                        let val = time +' '+ locationInfo.address +','+ ( locationInfo.latitude +',' +  locationInfo.longitude)

                                        obj.children.push(new Paragraph({
                                            text: this.$options.filters.capitalize(scecifyingIndex) +': ' + val,
                                        }))
                                        index++
                                    }
                                }
                            } else {
                                obj.children.push(new Paragraph({
                                    text: this.$options.filters.capitalize(this.$t('params.'+'name'))+': ' + 'Нет имени'}))
                            }



                            if(result.search_results[k].info_date) {
                                let text;
                                if (Number.isInteger(result.search_results[k].info_date)){
                                    text = moment(result.search_results[k].info_date, 'X').format('DD.MM.YYYY, HH:mm')
                                } else if (result.search_results[k].info_date.length === 10 && result.search_results[k].info_date.includes('-')){
                                    text = moment(result.search_results[k].info_date, 'YYYY-MM-DD').isValid() 
                                        ? moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('HH:mm') === '00:00' ?
                                        moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('DD.MM.YYYY') :
                                        moment(result.search_results[k].info_date, 'YYYY-MM-DD').format('DD.MM.YYYY HH:mm')
                                        : result.search_results[k].info_date

                                } else if(result.search_results[k].info_date.length === 10 && result.search_results[k].info_date.includes('.')) {
                                    text = moment(result.search_results[k].info_date, 'DD.MM.YYYY').isValid() 
                                        ? moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('HH:mm') === '00:00' ?
                                        moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('DD.MM.YYYY') :
                                        moment(result.search_results[k].info_date, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
                                        : result.search_results[k].info_date
                                } else {
                                    if (!moment(result.search_results[k].info_date).isValid()){
                                        text = result.search_results[k].info_date;
                                    } else {
                                        text = moment(result.search_results[k].info_date).format('HH:mm') === '00:00' ?
                                            moment(result.search_results[k].info_date).format('DD.MM.YYYY') :
                                            moment(result.search_results[k].info_date).format('DD.MM.YYYY HH:mm');
                                    }
                                }
                                obj.children.push(new Paragraph({
                                    text: text
                                }));
                            }
                            if(result.search_results[k].photos && result.search_results[k].photos.length) {
                                let photoArray = chunkArray(result.search_results[k].photos,2);
                                for(let photosChunk of photoArray) {
                                    for(let photoObj of photosChunk) {
                                        if(photoObj && typeof photoObj.image !== 'undefined') {
                                            const imageCache = new ImageCaching();
                                            const imageBase = await imageCache.loadNonComponentImage(photoObj.image)
                                            let tempImage = await this.resizeImageDoc(imageBase);
                                            let width;
                                            let height;
                                            let coef;
                                            if (tempImage.width > tempImage.height){
                                                coef = 400 / tempImage.width;
                                                width = tempImage.width * coef;
                                                height = tempImage.height * coef;
                                            } else {
                                                coef = 400 / tempImage.height;
                                                width = tempImage.width * coef;
                                                height = tempImage.height * coef;
                                            }
                                            obj.children.push(new Paragraph({
                                                children: [
                                                    new ImageRun({
                                                        data: tempImage.image,
                                                        transformation: {
                                                            width: width,
                                                            height: height
                                                        },
                                                    }),
                                                ],
                                            }))
                                            if (typeof photoObj.source_url !== 'undefined') {
                                                obj.children.push(new Paragraph({
                                                    text: photoObj.source_url,
                                                    link: photoObj.source_url
                                                }))
                                            }
                                            let date;
                                            if (moment(photoObj.date * 1000).format('DD.MM.YYYY, HH:mm') === 'Invalid date'){
                                                date = photoObj.date
                                            } else {
                                                date = moment(photoObj.date * 1000).format('DD.MM.YYYY, HH:mm')
                                            }
                                            obj.children.push(new Paragraph({
                                                text: date,
                                            })) && resizedPhotos++;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
          this.resizedImages = resizedPhotos;
          const doc = new Document({
              sections: [obj],
          });
          Packer.toBlob(doc).then(blob => {
              saveAs(blob, this.formProps.filename+'.docx');
              this.isLoading = false
              this.$toast.success( this.$t('search.doc_saved') , {
                  position: "top-center",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false
              });
          });

      },
  }
}
