<template>
  <div class="graph" style="box-sizing: content-box; display: inline;">
    <b-popover
        :target="id"
        triggers="hover"
        placement="top"
        container="my-container"
        ref="popover"

    >
      <div class="sourceList" style="display: flex">{{ $t("graph.source") }}:
        <div>
          <span v-for="(item,index) in sourceListLocales" :key="index">
            <span v-if="index !== sourceList.length -1">{{item}},</span>
            <span v-else>{{item}}</span>
          </span>
        </div>
      </div>
      <div v-if="!disableDelete" class="sourceDelete" @click.self="deleteSelectedResult">{{ $t("graph.delete") }}</div>
    </b-popover>

    <i class="feather icon-info" :id="id"></i>


    <b-modal :id="`modal_${id}`" :title="$t('graph.remove_result')" centered ref="delete-graph-modal" hide-footer >
      <h4>{{ setDialogMessage(itemsForDelete) }}</h4>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="confirmModal()">{{ $t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="cancelModal">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>


  </div>

</template>

<script>
import env from "@/configs/env.json";

export default {
  name: "graphTooltip",
  props:['sourceList','sourceListLocales','result','meta','disableDelete','id'],
  data() {
    return {
      itemsForDelete: [],
      sameLevelItems: []
    }
  },
  computed:{
    results() {
      return this.$route.name === 'Result' ? this.$store.state.contacts.results.results:
          this.$store.state.contacts.childResults.results
    },
  },
  methods:{

    confirmModal(){
      if (this.result.level === 2){
        this.itemsForDelete.forEach(item =>{
          let payload = {
            keyVal: this.meta.key,
            result: item
          }
          this.$store.dispatch('contacts/setRemoveSourceResult', payload)
        })
        this.sameLevelItems.forEach(item =>{
          let payload = {
            keyVal: this.meta.key,
            result: item
          }
          this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
            this.$store.dispatch('contacts/updateResult', payload);
          })
        })

      } else {
        this.sameLevelItems.forEach(item =>{
          let payload = {
            keyVal: this.meta.key,
            result: item
          }
          this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
            this.$store.dispatch('contacts/updateResult', payload);
          })
        })
      }
    },

    cancelModal(){
      this.$refs['delete-graph-modal'].hide();
    },


    deleteSelectedResult(){
      this.sameLevelItems = []
      this.results.forEach(allResultsItem =>{
          if (allResultsItem.value === this.result.value){
            this.sameLevelItems.push(allResultsItem)
          }
      })
      let lowerLevel = []
      let dataItems = []
      this.itemsForDelete = [];
      if (this.result.level === 2){
        lowerLevel = this.results.filter((item) => item.level === 3);
        this.result.items.forEach(item=>{
          if (typeof item.search_results !== 'undefined'){
            item.search_results.forEach(searchResult=>{
              searchResult.data.forEach(dataItem=>{
                dataItems.push(dataItem)
              })
            })
          }
        })
        dataItems.forEach(dataItem=>{
          lowerLevel.forEach((lowerItem)=> {
            if (dataItem.param === lowerItem.param && dataItem.value === lowerItem.value ){
              if(this.itemsForDelete.indexOf(lowerItem) === -1) {
                this.itemsForDelete.push(lowerItem);
              }
            }
          })
        })
      }
      this.$refs['delete-graph-modal'].show();
    },

    setDialogMessage(itemsForDelete){
      let temp = []
      itemsForDelete.forEach(item => {
        if (!temp.length){
          temp.push(item.value)
        } else {
          if (temp.indexOf(item.value) === -1){
            temp.push(item.value)
          }
        }
      })
      let message;
      if (this.result.level !== 3 && itemsForDelete && itemsForDelete.length){
        let tempMessage = this.$t('graph.dialog_alert_text')
        temp.forEach((item, index)=>{
          if (index === temp.length -1){
            tempMessage = tempMessage+item
          } else {
            tempMessage = tempMessage+item+', '
          }
        })
        message = tempMessage + this.$t('graph.continue');
      }
      return message;
    },
  },
}
</script>

<style lang="scss" scoped>
.graph{
  cursor: pointer;
}
.sourceList{
  padding: 3px;
  text-align: center;
}

.sourceDelete{
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 3px;
  cursor: pointer;
  text-align: center;
}


</style>
<style>
.popover-body{
  padding: 8px;
}
</style>