<template>
  <div class="card table-card" id="searchList" v-if="selectedSearchResults.length" style="margin-top: 22px">
    <div class="card-header" v-if="currentParam !== ''">
      <h5>{{ $t('search.search_results') }}</h5>
    </div>
    <div class="card-block padding search-list-scroll">
      <div class="table-responsive padding" style="overflow: visible">
        <table class="table table-hover m-b-0 search-list-table" style="position: relative;">
          <thead>
            <tr class="search-list-header">
              <th style="padding: 8px; padding-left: 86px;">{{ $t('search.search_results_query') }}</th>
              <th style="padding: 8px;">{{ $t('result.found') }}</th>
              <th style="padding: 8px;">{{ $t('search.search_results_count') }}</th>
              <th style="padding: 8px;">{{ $t('search.search_results_date') }}</th>
              <th style="padding: 8px;">{{ $t('search.search_results_cost') }}</th>
              <th style="padding: 8px; text-align: center">{{ $t('search.search_results_actions') }}</th>
            </tr>
          </thead>
          <!-- <test-item :result="result" v-for="(result, index) in selectedSearchResults"></test-item> -->
          <SearchListItem :result="result" v-for="(result, index) in selectedSearchResults" :key="index" :ident="index" v-on:force="forceUpdate">
          </SearchListItem>
        </table>
      </div>
    </div>
  </div>
  <div class="card" style="margin-top: 2rem;" v-else>
      <div class="card-body">
        <h5 class="text-center">{{ $t('search.history_empty') }}</h5>
      </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import SearchListItem from "@/components/list/SearchListItem";
import TestItem from './testItem.vue';
import filters from "@/mixins/filters";
import _ from "underscore";

export default {
  mixins: [filters],
  components: {
    SearchListItem,
    TestItem
  },
  data() {
    return {
      selectedSearchResults: []
    }
  },
  computed: {
    ...mapState({
      searchResults: state => state.contacts.searchesBuffer,
      searchParams: state => state.contacts.filteredParams,
      fullSearch: state => state.contacts.searches,
      searchLimit: state => state.contacts.searchLimit,
      searchesFilter: state => state.contacts.searchesFilter,
      loadingState: state => state.contacts.loading,
    }),
    ...mapGetters('mainMenuState', ['currentParam']),
  },
  mounted() {
    this.forceUpdate();
    if (!this.selectedSearchResults.length) {
      this.initLoad();
    }
  },
  methods: {
    forceUpdate() {
      this.showList = false;
      const selectParam = (() => {
        if(this.currentParam === 'mass_isdn') {
          return 'isdn';
        } else if (['geo_search', 'simple_search'].includes(this.currentParam)) {
          return 'full_search';
        }
        return this.currentParam;
      })();
      if (selectParam !== '') {
        let selectedSearchResults = _.sortBy(this.searchResults.filter(item => item.param === selectParam), 'date').reverse()
        this.selectedSearchResults = [];
        this.$nextTick(() => {
          this.selectedSearchResults = _.clone(selectedSearchResults)
        })
      } else {
        let selectedSearchResults = _.sortBy(this.searchResults, 'date').reverse()
        this.selectedSearchResults = [];
        this.$nextTick(() => {
          this.selectedSearchResults = _.clone(selectedSearchResults)
        })
      }
      this.$nextTick(() => {
        this.showList = true;
      });
    },
    filterParams(value) {
    

      this.$store.dispatch('contacts/filterResult', value);
    },
    initLoad() {
      this.$store.dispatch('contacts/initSearchResults').then(
        () => {
          this.loaded = true;
          this.$store.dispatch('contacts/initFilterOptions')
        }
      );
    },

  },
  watch: {
    currentParam() {
      this.forceUpdate();
    },
    searchResults() {
      this.forceUpdate();
    },
    filter(value) {
      this.filterParams(value);
    },
    fullSearch() {
      this.filter = '';
    },
    loadingState(value) {
      this.loading = value;
    },
  }
}
</script>
<style scoped>
/* .search-list-table tbody { display: flex; flex-direction: column-reverse; } */
  .padding{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  @media (max-width: 992px) {
    .search-list-header {
      display: none;
    }
    .search-list-table {
      display: block;
    }
    .search-list-table tbody {
      display: block;
    }
  }
</style>

<style>
  .search-list-scroll .zoom-hover{
    cursor: pointer !important;
  }
  .search-list-table th{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  @media (min-width: 993px) and (max-width: 1156px) {
    .search-list-scroll {
      overflow-x: auto;  
    }
  }
  .child-result {
    position: relative;
  }
  .child-result:not(:last-child) {
    border: 0;
  }
  .child-result td:first-child {
    padding-left: 30px;
  }

  .child-result td {
    border: 0;
  }

  .child-result::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    height: calc(50% + 1px);
    width: 30px;
    border-left: 1px solid rgb(222, 226, 230);
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  .child-result:not(:last-child)::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    height: calc(50% + 1px);
    width: 30px;
    border-left: 1px solid rgb(222, 226, 230);
  }
  .child-result:hover::after {
    border-bottom: 0;
  }
  .child-result:hover::before {
    height: calc(50% + 1px);
    top: -1px;
  }
  .result-item.has-child {
    position: relative;
    border: 0;
  }
  .result-item.has-child td {
    border: 0;
  }
  .result-item.has-child::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50% + 1px);
    width: 10px;
    border-left: 1px solid rgb(222, 226, 230);
    border-top: 1px solid rgb(222, 226, 230);
    border-bottom: 1px solid #fff;
  }
  .result-item.has-child:hover::after {
    border-bottom: 0;
  }
</style>