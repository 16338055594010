<template>
  <div class="sources-columns border-checkbox-section col-sm-6">
    <div class="border-checkbox-group border-checkbox-group-primary">
      <p style="text-align: left;font-weight: 600;margin-bottom: 0.5rem;">
        {{ $t("searchOptions.data_sources") }}
      </p>
      <div v-if="param !=='image'">
        <input
               type="checkbox"
               class="border-checkbox"
               id="cache_search_enabled"
               v-model="payload.cache_search_enabled"
        >
        <label for="cache_search_enabled" class="border-checkbox-label checkboxFlex">
          <div style="display: flex">
            <div style="margin-left: 4px; width: 12rem"> {{ $t('search_options.search_in_db') }}</div>
          </div>
        </label>
      </div>

    </div>

    <div class="border-checkbox-group border-checkbox-group-primary" v-if="(typeof searchOptions[currentParam] !== 'undefined' && searchOptions[currentParam].services.length > 0) || (iterative && isHaveAnyService)">
      <input
          type="checkbox"
          class="border-checkbox"
          id="online_search_enabled"
          v-model="payload.online_search_enabled"
          @change="changeOnlineSearchState"
      >
      <label for="online_search_enabled" class="border-checkbox-label checkboxFlex">
        <div style="display: flex">
          <div style="margin-left: 4px; width: 12rem"> {{ $t('search_options.search_online') }}</div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from "underscore"

export default {
  name: "searchSources",
  props: ['param','searchPayload'],
  data() {
    return {
      payload:{
        cache_search_enabled: undefined,
        online_search_enabled: undefined,
      },
      iterative: undefined,
      fullAlgorithms: {}
    }
  },
  computed: {
    ...mapGetters('contacts', ['searchOptions']),
    options() {
      if(this.param !== 'mass_isdn') {
        return this.searchOptions[this.param]
      } else {
        return this.searchOptions['isdn']
      }
    },
    currentParam(){
      let param = this.param;
      if (param === 'mass_isdn'){
        param = 'isdn'
      }
      return param;
    },
    isHaveAnyService(){
      const keys = Object.keys(this.searchOptions);
      let isAnyService = false;
      for(const key of keys) {
        if (key !== 'image' && this.searchOptions[key].services.length > 0){
          isAnyService = true
        }
      }
      if (isAnyService){
        return true
      } else {
        if (this.payload.online_search_enabled){
          this.payload.online_search_enabled = false;
        }
        return false
      }
    },
  },
  methods: {
    setDefault(){
      if(localStorage.getItem('fullAlgorithms')) {
        this.fullAlgorithms = JSON.parse(localStorage.getItem('fullAlgorithms'))
        if (typeof this.fullAlgorithms[this.currentParam] !== 'undefined'){
          if (typeof  this.fullAlgorithms[this.currentParam].cache_search_enabled !== 'undefined'){
            this.payload.cache_search_enabled = this.fullAlgorithms[this.currentParam].cache_search_enabled
          } else {
            this.payload.cache_search_enabled = false
          }
          if (typeof this.fullAlgorithms[this.currentParam] !== 'undefined'){
            if (typeof this.fullAlgorithms[this.currentParam].online_search_enabled !== 'undefined'){
              this.payload.online_search_enabled = this.fullAlgorithms[this.currentParam].online_search_enabled
            } else {
              this.payload.online_search_enabled = false
            }
          }
        } else {
          this.fullAlgorithms[this.currentParam] = {
            cache_search_enabled: false,
            online_search_enabled: false,
          }
        }
      } else {
        localStorage.setItem('fullAlgorithms', JSON.stringify({}))
      }
    },
    changeOnlineSearchState(){
      this.$emit('changeOnlineSearchState', this.payload.online_search_enabled);
    },
    disableOnlineSearch(val){
      this.payload.online_search_enabled = val != 0;
    },

    setAlgorithms(param){
      if(localStorage.getItem('fullAlgorithms')) {
        let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
        if (typeof obj[param] !== 'undefined'){
          if (typeof this.payload.cache_search_enabled !== 'undefined'){
            obj[param].cache_search_enabled = this.payload.cache_search_enabled
          }
          if (typeof this.payload.online_search_enabled !== 'undefined'){
            if (typeof obj[param] !== 'undefined'){
              obj[param].online_search_enabled = this.payload.online_search_enabled
            }
          } else {
            obj[param].online_search_enabled = false
          }
          localStorage.setItem('fullAlgorithms', JSON.stringify(obj));
        } else {
          obj[param] = {}
          localStorage.setItem('fullAlgorithms', JSON.stringify(obj));
        }
      } else {
        localStorage.setItem('fullAlgorithms', JSON.stringify({}))
      }
      this.$emit('push', this.payload);
    },
  },
  watch: {

    iterative(){
      if (typeof this.searchOptions !== 'undefined' && typeof this.searchOptions[this.param] !== 'undefined' && this.searchOptions[this.param].services.length === 0){
        setTimeout(()=>{
          if (typeof this.searchPayload.iterative_search !== 'undefined'  && !this.searchPayload.iterative_search){
            this.payload.online_search_enabled = false
          } else {
              let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
              if (typeof this.searchPayload.services !== 'undefined' && this.searchPayload.services.length > 0){
                this.payload.online_search_enabled = true
              } else if (typeof obj[this.param] !== 'undefined' && typeof obj[this.param].services !== 'undefined' && obj[this.param].services.length > 0){
                this.payload.online_search_enabled = true
              }
          }
        }, 100)
      } else {
        setTimeout(()=>{
          let obj = JSON.parse(JSON.stringify(this.searchPayload))
          if (this.searchPayload.iterative_search){
              if (typeof obj.services !== 'undefined' && obj.services.length > 0){
                this.payload.online_search_enabled = true
              }
          } else {
            if (typeof obj.services === 'undefined' || obj.services.length === 0){
              this.payload.online_search_enabled = false
            } else if ( _.intersection(obj.services, this.searchOptions[this.param].services.map(item => item.name)).length){
              this.payload.online_search_enabled = true
            } else {
              this.payload.online_search_enabled = false
            }
          }
        }, 100)
      }
    },
    "param"(){
      let param = this.param;
      if (param === 'mass_isdn'){
         param = 'isdn'
      }
      let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
      if (typeof obj[param] !== 'undefined' && typeof obj[param].cache_search_enabled !== 'undefined'){
          if (typeof obj['image'] !== 'undefined'){
            obj['image'].cache_search_enabled = false
          }
          this.payload.cache_search_enabled = obj[param].cache_search_enabled
      } else {
        this.payload.cache_search_enabled = false
      }
      if (typeof obj[param] !== 'undefined' && typeof obj[param].online_search_enabled !== 'undefined'){
        this.payload.online_search_enabled = obj[param].online_search_enabled
      } else {
        this.payload.online_search_enabled = false
      }
      this.setAlgorithms(param)
    },
    "payload.cache_search_enabled"(){
      if(this.param !== 'mass_isdn') {
        this.setAlgorithms(this.param)
      } else {
        this.setAlgorithms('isdn')
      }
    },
    "payload.online_search_enabled"(){
      if(this.param !== 'mass_isdn') {
        this.setAlgorithms(this.param)
      } else {
        this.setAlgorithms('isdn')
      }
    },

  },
  mounted() {
    this.setDefault();
  }

}
</script>
<style scoped lang="scss">
img.service-icon {
  width: 20px;
}
.paramTitle{
  font-weight: 600;
  margin-left: 1rem;
}
.sources-columns {
  column-count: 3;
  @media (max-width: 992px) {
    column-count: 1;
  }
}
.sources-columns {
  position: relative;

  .border-checkbox-section .border-checkbox-group .border-checkbox-label{
    margin-bottom: 10px;
    height: 60px;
  }
  .user-link {
    display: inline-block;
  }
  .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
    width: 100%;
    .check {
      margin-right: calc(0.75em - 1px);
    }
    .control-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
      position: relative;
      &:hover {
        background-color: #fff;
        overflow: visible;
        text-overflow: initial;
        text-decoration: underline;
        z-index: 99;
        padding-right: 20px;
      }
    }
  }
}
</style>