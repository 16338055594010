<template>
    <div>
        {{ i18n.t('result.new_search') }}: <a href="#" @click.prevent="setMenuParam('email')">{{ i18n.t('params.email') }}</a>
    </div>
</template>

<script>
import toastMixin from '@/mixins/toastMixin';
export default {
    name: 'EmailSearch',
    mixins: [toastMixin],
};
</script>

<style scoped>

</style>