<template>
    <tbody>
        <tr>
            <td>
               <pre>{{ result }}</pre> 
            </td>
        </tr>
    </tbody>
</template>

<script>
    export default {
        props: ['result']
    }
</script>