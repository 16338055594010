<template>
    <div class="border-checkbox-section col-sm-6 sources-section">
      <p style="text-align: left;font-weight: 600;margin-bottom: 0.5rem;">
        {{ $t("searchOptions.data_sources") }}
      </p>
      <div class="border-checkbox-group border-checkbox-group-primary">
            <div v-if="param !== 'image'">
                <input type="checkbox" class="border-checkbox" id="cache_search_enabled"
                    v-model="payload.cache_search_enabled">
                <label for="cache_search_enabled" class="border-checkbox-label checkboxFlex">
                    <div style="display: flex">
                        <div style="width: 12rem"> {{ $t('search_options.search_in_db') }}
                            <i class="feather icon-info text-primary"
                                v-if="$v.payload.cache_search_enabled.$invalid"
                                v-tippy="{ placement : 'top',  arrow: true }"
                                :content="$t('servicesSelect.required_option')"
                            ></i>
                        </div>
                    </div>
                </label>
            </div>

        </div>

        <div class="border-checkbox-group border-checkbox-group-primary"
            v-if="isHaveAnyService || (iterative && !isHaveAnyService && allServicesLength)">
            <input type="checkbox" class="border-checkbox" id="online_search_enabled"
                v-model="payload.online_search_enabled">
            <label for="online_search_enabled" class="border-checkbox-label checkboxFlex">
                <div class="d-flex">
                    <div style="width: 12rem"> {{ $t('search_options.search_online') }}
                        <i class="feather icon-info text-primary"
                                v-if="$v.payload.online_search_enabled.$invalid"
                                v-tippy="{ placement : 'top',  arrow: true }"
                                :content="$t('servicesSelect.required_option')"
                            ></i>
                    </div>
                </div>
            </label>
        </div>
    </div>
</template>
<script>
import { Sources } from "@/models/searchOptions/Sources"
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from "underscore";
export default {
    name: 'SearchSources',
    props: ['param'],
    mixins: [validationMixin],
    data() {
        return {
            sources: null,
            payload: {
                cache_search_enabled: false,
                online_search_enabled: false
            },
            iterative: false,
        }
    },
    computed: {
        ...mapGetters('contacts', ['searchOptions']),
        options() {
            if (this.param !== 'mass_isdn') {
                
                return this.searchOptions[this.param]
            } else {
                return this.searchOptions['isdn']
            }
        },
        allServicesLength() {
            return _.reduce(
                Object.values(this.searchOptions), 
                (memo, num) => {return !_.isUndefined(num.services) ? memo + num.services.length: 0}, 0 );
        },
        isHaveAnyService() {
            return !!this.options.services.length;
        },
    },
    validations() {
        const validationObject = {
                image: {
                    payload: {
                        online_search_enabled: {
                            checked(val) {
                                return val
                            }
                        }
                    }
                },
                default: {
                    payload: {
                        online_search_enabled: {
                            checked(val, vm) {
                                const def = vm.online_search_enabled || vm.cache_search_enabled
                                return  this.isHaveAnyService ? def : vm.cache_search_enabled
                            }
                        },
                        cache_search_enabled: {
                            checked(val, vm) {
                                const def = vm.online_search_enabled || vm.cache_search_enabled
                                return this.isHaveAnyService ? def : val
                            }
                        }
                    }
                }
            }
        return !_.isUndefined(validationObject[this.param]) ? validationObject[this.param] : validationObject.default
    },

    mounted() {
        this.sources = new Sources(this.param);
        this.payload = this.sources.options;
        this.$emit('push', this.payload);
    },
    watch: {
        payload: {
            deep: true,
            handler(val) {
                this.sources.updateOptions(val);
                this.pushValidation();
                this.$emit('push', this.payload);
            }
        }
    },
    methods: {
        pushValidation() {
            this.$emit('invalid', this.$v.$invalid)
        },
        setServices(data) {
            if (_.isUndefined(data.services)) {
                this.payload.online_search_enabled = false;
            } else {
                this.payload.online_search_enabled = true;
            }
        },
        setAlgorithms(data) {
            this.iterative = data.iterative_search;
        },
    }
}
</script>
<style>
.paramTitle {
    font-weight: 600;
    margin-left: 1rem;
}

.sources-columns {
    column-count: 3;
}

@media (max-width: 992px) {
    .sources-columns {
        column-count: 1;
    }
}

.sources-columns {
    position: relative;
}

.sources-columns .border-checkbox-section .border-checkbox-group .border-checkbox-label {
    margin-bottom: 10px;
    height: 60px;
}

.sources-columns .user-link {
    display: inline-block;
}

.sources-columns .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
    width: 100%;
}

.sources-columns .b-border-checkbox-group border-checkbox-group-primarybox.checkbox .check {
    margin-right: calc(0.75em - 1px);
}

.sources-columns .b-border-checkbox-group border-checkbox-group-primarybox.checkbox .control-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
    position: relative;
}

.sources-columns .b-border-checkbox-group border-checkbox-group-primarybox.checkbox .control-label:hover {
    background-color: #fff;
    overflow: visible;
    text-overflow: initial;
    text-decoration: underline;
    z-index: 99;
    padding-right: 20px;
}
.sources-section.border-checkbox-section .border-checkbox-group{
  display: block;
  margin-bottom: 3px;
}
</style>