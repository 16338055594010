<template>
<div class="row">
  <div class="col-lg-4 m-b-15" v-for="(photo, index) in result.photos" :key="index">
    <photo-fragment :photo="photo" source="InternetArchive" :is-child="false" />
  </div>
</div>
</template>

<script>
import PhotoFragment from "@/components/PhotoFragment.vue";
export default {
  name: "InternetArchive",
  props: ['result'],
  components: {
    PhotoFragment
  }
}
</script>

<style scoped>

</style>