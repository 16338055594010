<template>
  <div class="result simple-result">
    <div v-if="!loadingResult">
      <div class="container" v-if="!isUndefined(results) && results.length > 0">
  
        <div class="d-grid d-lg-flex justify-content-lg-end btn-save" >
          <button class="btn btn-primary" type="button" :disabled="enablePDF || isLoading" @click="promptPdf();getScreen()" style="text-transform: none">
            <b-spinner small v-if="enablePDF || isLoading"></b-spinner><i v-else class="feather icon-download"></i><span style="padding-left: 4px;">{{$t('pdf.save_pdf')}}</span> 
          </button>
        </div>
        <div v-if="showResults">
  
  
        <graph
          v-if="!isUndefined(results) && results.length > 0"
          ref="graph"
          :image-count="imageCount"
          :meta="meta"
          @setSchemaPic="setSchemaPic"
        />
  
        <map-result v-if="pins.length && this.meta.payload.param === 'full_search'"
          @setSchemaPic="setSchemaPic"
          :pins="pins"
          :location="locationFigure"
          ref="mapresult"
        />
  
<!--        <h5 v-if="locationFigure && fullCount.filtered && fullCount.full">{{ $t('geo.number_from_results', fullCount) }}</h5>-->
        <h5 v-if="locationFigure && fullCount.filtered && fullCount.full">{{beautyNumbers(fullCount.full)}}{{ $t('geo.number_from_results_first') }}{{beautyNumbers(fullCount.filtered)}}{{ $t('geo.number_from_results_second') }}</h5>
  
        <result-level
          :meta="meta"
          :errors="errors"
        />
        </div>
      </div>
      <div v-else>
          <div>
            <h3 style="display: flex;justify-content: center">{{ $t("result.search_results_have_been_deleted") }}</h3>
          </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <b-spinner/>
    </div>
    <b-modal
        v-model="promptPdfActive"
        :title="$t('pdf.save_file')"
        has-modal-card
        hide-footer
        centered
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <PdfPrompt v-bind="formProps"
                   @close="props.close"
                   @pdf-data="pdfUpdateData"
                   @doc-data="resultToDocument"
        ></PdfPrompt>
      </template>
    </b-modal>
  </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import filters from "@/mixins/filters";
import savePdf from "@/mixins/savePdf";
import imageMixin from "@/mixins/imageMixin";
import axios from "@/configs/axios";
import imageAxios from '@/configs/iamgeAxios'
import MonthCalendar from "@/components/monitoring/MonthCalendar";
import ResultLevel from "@/components/results/resultLevel";
import Graph from "@/components/results/graph";
import UnusedServices from "@/components/results/UnusedServices";
import saveDoc from "@/mixins/saveDoc";
import i18n from "@/i18n";
import MapResult from "@/components/results/mapResult";
import _ from "underscore";
const capitalize = (value) =>
  {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
  }
export default {
name: 'Result',
components: {
  MapResult,
  Graph,
  ResultLevel,
  MonthCalendar,
  UnusedServices
},
setup() {
  return {
    isUndefined: _.isUndefined
  }
},
mixins: [filters, savePdf, imageMixin, saveDoc],
  data() {
  return {
    isLoading: false,
    loadingResult: false,
    routeName: this.$route.name,
    pdfPassword: 'test',
    showResults: true,
    imageCount: 0,
    enablePDF: true,
    arr: [],
    schemaPic: null,
    schemaPicSize: []
  }
},
methods: {
  beautyNumbers(val) {
    let temp;
    if (typeof val !== 'undefined') {
      temp = val.toString()
      if (temp.length >= 7 && temp.length < 10) {
        let count = temp.length - 7;
        temp = temp.substr(0, temp.length - 3)
        let first = temp.substr(0, temp.length - 3)
        let lust = temp.substr(1 + count)
        return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.million');
      } else if (temp.length >= 10) {
        let count = temp.length - 10;
        temp = temp.substr(0, temp.length - 6)
        let first = temp.substr(0, temp.length - 3)
        let lust = temp.substr(1 + count)
        return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.billion');
      }
      return val.toLocaleString('ru')
    }
  },
  async getResultById() {
    return new Promise((resolve) => {
      if(!_.isUndefined(this.$route.params.id)) {
        this.loadingResult = true;
        this.$store.dispatch('contacts/updateResultPush', parseInt(this.$route.params.id)).finally(()=>{
          window.scrollTo({ top: 0, behavior: 'smooth' });
          resolve();
          this.loadingResult = false
        })
      } else {
        this.loadingResult = false
        resolve()
      }
    })
  },
  getScreen(){
    if (!_.isUndefined(this.results) && this.results.length > 0){
      this.$refs.graph.screenShot()
    }
    // if(this.$refs.mapresult) {
    //   this.$refs.mapresult.setCardImage()
    // }
  },
  setSchemaPic(dataurl){
    let el = document.getElementById('tree_screenshot');
    if(el) {
      this.schemaPicSize = {
        width: parseInt(el.offsetWidth),
        height: parseInt(el.offsetHeight)
      }
    } else {
      const mapEl = document.getElementById('map');
      if(mapEl) {
        this.schemaPicSize = {
          width: parseInt(mapEl.offsetWidth),
          height: parseInt(mapEl.offsetHeight)
        }
      }
    }
    this.schemaPic = dataurl;
  },
  goToMonitoring(){
    this.$store.dispatch('monitoring/addTargetFromSearch', this.meta.targets).then(()=>{
      this.$router.push({name: 'Monitoring'})
    })
  },
  async loaderForImages(payload) {
    let result = await this.checkCache(payload);
    if (result.isCached){
        this.$store.dispatch('images/addImage', {
          key: payload,
          value: ''
        });
    } else {
      let axiosSettings;
        axiosSettings = {
          method: "get",
          responseType: 'arraybuffer',
          headers: {
            'Authorization': this.token 
          }
        }
      return new Promise((resolve, reject) => {
        // const imAxios = imageAxios(localStorage.getItem('token'))
        imageAxios(payload, axiosSettings)
          .then((resp) => {
              let blob = new Blob([resp.data], {type: "image/jpg"})
              let reader = new window.FileReader();
              reader.readAsDataURL(blob);
              reader.onload = () => {
                this.saveImage(reader.result, payload)
                this.$store.dispatch('images/addImage', {
                  key: payload,
                  value: ''
                });
                resolve()
              }
            })
            .catch(err => {
              reject(err)
            })
      })
    }
  },

  updateResults(){
    this.showResults = false;
    this.$nextTick(()=>{
      this.showResults = true;
      this.$forceUpdate();
    })
  },
  countImages(){
    for (let result of this.results){
      if(result.search_results) {
        for (let searchResult of result.search_results){
          if (typeof  searchResult.photos !== 'undefined'){
            for (let i = 0; i < searchResult.photos.length; i++){
              this.imageCount++;
            }
          }
        }
      }
    }
  },
   loadAllImages(){
      for (let result of this.results){
        if(result.search_results) {
          for (let searchResult of result.search_results){
            if (typeof  searchResult.photos !== 'undefined'){
              for (let i = 0; i < searchResult.photos.length; i++){
                this.loaderForImages(searchResult.photos[i].image)
              }
            }
          }
        }
      }
  },
  sortResult() {
    this.results.sort((a, b) => a.source > b.source ? 1 : -1).sort((a, b) => a.level > b.level ? 1 : -1)
  },
  checkImages() {
    if (this.photoLoaded >= this.imageCount || this.imageCount === 0){
      this.enablePDF = false;
    }
  },
  numberWithCommas(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }
},
computed: {
  ...mapGetters('auth', ['permissions']),

  selectedLocale(){
    if (i18n.locale === 'ua'){
      return 'uk'
    } else {
      return i18n.locale
    }
  },
  pins() {
    const pins = [];
      for(const resultIndex in this.results) {
        if(this.results[resultIndex].search_results) {
          const source = !_.isUndefined(this.results[resultIndex].source_locales) ? this.results[resultIndex].source_locales[this.selectedLocale] : undefined
          for(const sr of this.results[resultIndex].search_results) {
            const addresses = _.where(sr.data, {param: 'address'});
            const name = _.where(sr.data, {param: 'name'});
            const lats = _.where(sr.data, {param: 'latitude'});
            const longs = _.where(sr.data, {param: 'longitude'});
            if(lats.length && longs.length) {
              for (const ind in addresses) {
                if(lats[ind].value !== '-') {
                  let title = ''
                  if(!_.isUndefined(name) && name.length) {
                    title += `<span style="max-width: 500px; display:inline-block;">${capitalize(this.$t('params.name'))}: ${name[0].value}</span> <br>`
                  }
                  if(!_.isUndefined(source)) {
                    title += `<span style="width: 300px; display:inline-block;  white-space: normal">${capitalize(this.$t('result.date_from_db'))}: ${source}</span> <br>`
                  }
                  title += `<span style="max-width: 500px; display:inline-block; white-space: normal;">${capitalize(this.$t('params.address'))}: ${addresses[ind].value}</span>`
                  pins.push({
                    title,
                    location: [lats[ind].value, longs[ind].value],
                    contact: `contact_${resultIndex}`
                  })
                }
              }
            }
          }
        }
      }
    return pins;
  },
  ...mapGetters('contacts', ['results', 'meta', 'errors']),
  ...mapGetters('images', ['images', 'photoLoaded']),
  ...mapGetters('auth', ['user']),
  ...mapGetters('ui', ['services']),
  total() {
    let arr = [];
    if(!_.isUndefined(this.results)) {
      this.results.forEach(result => {
        if (result.search_results) {
          result.search_results.forEach(searchResult => {
            arr.push(searchResult)
          })
        }
      })
    }
    return arr;
  },
  paginatedItems() {
    let page_number = this.current-1
    return this.total.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
  },
  locationFigure() {
    return !_.isUndefined(this.meta.payload.location) ? this.meta.payload.location: null;
  },
  fullCount() {
    const full = _.reduce(this.results, (memo, resultItem) => {return memo + resultItem.matches_count}, 0);
    const filtered = _.reduce(this.results, (memo, resultItem) => {return memo + resultItem.total_count},0)
    return {full, filtered};
  },
},

  watch:{
    photoLoaded(){
      if (!_.isUndefined(this.results) && this.results.length > 0){
        if (this.photoLoaded >= this.imageCount || this.imageCount === 0){
          this.enablePDF = false;
          this.$refs.graph.screenShot();
        }
      }
    },
    results(){
      if (this.results > 0) {
        this.$store.dispatch('contacts/setCurrentResultValue', this.results[0].value)
      }
      this.updateResults();
    },
  },
    async mounted( ) {
      await this.getResultById();
      await this.countImages();
      await this.checkImages();
      await this.loadAllImages();
      await this.setMountedPdfAction();
      await this.$store.dispatch('images/clearImages');
      await this.$store.dispatch('contacts/forceUpdateSearchParams');
    }
  }
  </script>
    <style scoped lang="scss">
    .searchStructure{
      box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);

      .tree {
        min-height:20px;
        padding:19px;
        margin-bottom:20px;
        background-color:#fbfbfb;
        -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
        -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
        ul {
          padding-inline-start: 40px;
        }
      }
      .tree li {
        list-style-type:none;
        margin:0;
        padding:10px 5px 0 120px;
        position:relative;
        &.start-tree {
          padding-left: 0;
        }
      }
      .tree li::before, .tree li::after {
        content:'';
        left:-20px;
        position:absolute;
        right:auto
      }
      .tree li::before {
        border-left:1px solid #999;
        bottom:50px;
        height:100%;
        top:0;
        width:1px
      }
      .tree li::after {
        border-top:1px solid #999;
        height:20px;
        top:30px;
        width:140px
      }
      .tree li span {
        -moz-border-radius:5px;
        -webkit-border-radius:5px;
        border:1px solid #999;
        border-radius:5px;
        display:inline-block;
        padding:3px 8px;
        text-decoration:none
      }
      .tree li.parent_li>span {
        cursor:pointer
      }
      .tree>ul>li::before, .tree>ul>li::after {
        border:0
      }
      .tree li:last-child::before {
        height:30px
      }
      .tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
        background:#eee;
        border:1px solid #94a0b4;
        color:#000
      }

      .structure-title{
        display: flex;
        justify-content: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 1rem;
        background-color: white;
        align-items: stretch;
        box-shadow: 0 0.125em 0.25em rgb(10 10 10 / 10%);
        font-size: 1.25em;
        color: #363636;
        font-weight: 600;
        line-height: 1.125;
      }
    }

    .content h2 {
      font-size: 1.25em;
      padding-top: 10px;
    }

    .pagination{
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 1rem;
    }
    </style>
  <style>
    @media (min-width: 993px) {
      .btn-save {
        position: relative;
        margin-top: -63px;
      }
    }
    body .tooltip {
        max-width: 500px;
        /* width: max-content; */
        white-space: normal;
    }
  </style>
