import axios from "axios";
import https from 'https';
import uuid from '@/helpers/uuid'
import store from '../store'
// import store from "@/store";
// import router from "@/router";
import env from './env.json'
let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.endpoint = `https://${host}/api`
}
const ax = axios.create({});

const instance = ax;

    // const token = store.getters['auth/token'];
    // const setupId = window.localStorage.getItem('setupId');
    
    // if (setupId) {
    //     instance.defaults.headers.common['Setup-Id'] = setupId
    // } else {
    //     let randString = uuid();
    //     window.localStorage.setItem('setupId', randString);
    //     instance.defaults.headers.common['Setup-Id'] = randString;
    // }
    
    
    instance.defaults.timeout = 180 * 1000;
    
    
    // instance.defaults.headers.common['user-agent'] = "GDClient/1.11.0-beta-sandbox-debug (Xiaomi Mi 9T Pro; android 10)";
    
    instance.defaults.baseURL = env.endpoint;
    
    instance.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
    
    const MAX_REQUESTS_COUNT = 50;
    const INTERVAL_MS = 50;
    let PENDING_REQUESTS = 0;
    
    /**
     * Axios Request Interceptor
     */
    instance.interceptors.request.use(function (config) {
        return new Promise((resolve) => {
            let interval = setInterval(() => {
                if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                    PENDING_REQUESTS++
                    clearInterval(interval)
                    resolve(config)
                }
            }, INTERVAL_MS)
            // resolve(config)
        })
        // return config;
    })
    /**
     * Axios Response Interceptor
     */
    instance.interceptors.response.use(function (response) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
        return Promise.resolve(response)
    }, function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        // if (error.response.status === 401) {
        //     store.dispatch('auth/logoutAction')
        //         .then(() => {
        //             return router.push({ name: 'Login' })
        //         })
        // }
        console.log(error.response)
        return Promise.reject(error)
    })
    
    
export default instance
