<template>
    <div>
      <div style="width: 100%; height: 1px; background-color: rgba(0,0,0,0.1);margin-bottom: 8px; margin-top: 8px"></div>
      <div v-if="isLoading" class="panel">
        <div class="panel-block">
          <b-skeleton :animated="true"></b-skeleton>
        </div>
        <div class="panel-block">
          <b-skeleton :animated="true"></b-skeleton>
        </div>
        <div class="panel-block">
          <b-skeleton :animated="true"></b-skeleton>
        </div>
        <div class="panel-block">
          <b-skeleton :animated="true"></b-skeleton>
        </div>
        <div class="panel-block">
          <b-skeleton :animated="true"></b-skeleton>
        </div>
      </div>
      <div class="" v-if="!isLoading && services">
        <div  v-for="(param,paramIndex) in Object.keys(services).filter(serviceKey => iterative_search ? true : serviceKey.toLowerCase() === param.toLowerCase() )" :key="paramIndex">
          <div class="paramTitle" style="margin-bottom: 8px; margin-top: 8px; background-color: rgba(0,0,0,0.05)" v-if="iterative_search && services[param].services.length && ((param !== 'image' && currentParam !== 'image') || currentParam === 'image' )">{{ $t('params.' + param) }}</div>
          <div class="sources-columns border-checkbox-section" :class="{'childPhotoSearch': childPhotoSearch}" v-if="services[param].services.length && ((param !== 'image' && currentParam !== 'image') || currentParam === 'image' )">
            <div v-for="(service,serviceIndex) in services[param].services" :key="serviceIndex">
              <div class="border-checkbox-group border-checkbox-group-primary ">
                <input
                    type="checkbox"
                    class="border-checkbox"
                    :id="`${param}_${serviceIndex}`"
                    v-model="selectedServices"
                    :value="service.name">
                    <label :for="`${param}_${serviceIndex}`" class="border-checkbox-label checkboxFlex">
                      <div style="display: flex">
<!--                        <img :src="service.icon_url" class="service-icon">-->
                        <div style="margin-left: 4px; width: 12rem">{{ service.name }}<span class="coast" v-if="service.offline_result_price > service.online_result_price"> ({{ service.offline_result_price}})</span>
                          <span class="coast" v-else> ({{ service.online_result_price}}{{ $t('servicesSelect.c') }})</span></div>
                      </div>
                    </label>
              </div>
              <div class="border-checkbox-group border-checkbox-group-primary " v-if="service.request_options.length && (currentParam==='isdn' || currentParam ==='mass_isdn')">
                <input
                    @input="setGetContactTags(service)"
                    type="checkbox"
                    class="border-checkbox"
                    :id="service.name+'_'+service.request_options.name"
                    v-model="getContactTags"
                    :value="service.source"
                    :disabled="!getContactSelected"
                    >
                    <label :for="service.name+'_'+service.request_options.name" class="border-checkbox-label checkboxFlex">
                      <div style="display: flex">
<!--                        <img :src="service.icon_url" class="service-icon">-->
                        <div style="margin-left: 4px; width: 12rem; text-transform: capitalize">{{ $t('visualOptions.get_contact_true') }}</div>
                      </div>
                    </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-if="totalPrice.length > 0 && !iterative_search" style="width: 100%;margin-top: 8px; font-weight: 700">{{ $t('servicesSelect.used', { servicesLength: totalPrice.length, totalPrice: totalPrice.totalPrice }) }}{{ $t('servicesSelect.c') }}</div>
          <div v-if="totalPrice.length > 0 && iterative_search" style="width: 100%;margin-top: 8px; font-weight: 700">{{ $t('servicesSelect.used_iterative', { servicesLength: totalPrice.length }) }}</div>
        </div>

      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'underscore';

export default {
    name: 'SearchRates',
    props: ['currentParam','iterative_search', "childPhotoSearch", 'searchPayload'],
    data() {
        return {
          selectedServices: [],
          isLoading: true,
          services: null,
          getContactTags: false,
          totalPrice: {
            totalPrice: 0,
            length: 0
          },
        }
    },
    watch: {
      getContactTags(){
        this.$emit('setGetContactTags', this.getContactTags)
      },
      selectedServices(){
        let obj = JSON.parse(localStorage.getItem('fullAlgorithms'))
        if (typeof obj[this.param] !== 'undefined'){
          obj[this.param].services = this.selectedServices;
        }
        localStorage.setItem('fullAlgorithms', JSON.stringify(obj))
        if (!this.selectedServices.includes("GetContact") && this.getContactTags){
          this.getContactTags = false;
        }
        this.updateServices();
        this.$emit('disableOnlineSearch', this.selectedServices.length)
      },
      "iterative_search"(){
        this.updateServices()
      },
      "currentParam"(){
        let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
        if (typeof obj[this.param].services !== 'undefined'){
          this.selectedServices = obj[this.param].services
        } else {
          this.selectedServices = []
        }
      },
    },
    computed: {
        ...mapGetters('search_rates', ['search_rates']),
        ...mapGetters('contacts', ['searchOptions']),
      param(){
        let param = this.currentParam;
        if (param === 'mass_isdn'){
          param = 'isdn'
        }
        return param;
      },
      getContactSelected(){
          if (this.selectedServices.length){
            return this.selectedServices.includes("GetContact");
          } else {
            return false
          }
      },
    },
    methods: {
        getPrice(list){
          let price = {
            totalPrice: 0,
            length: 0
          }
          if (this.selectedServices.length){
            let temp
            if (list){
              temp = list
            } else {
              temp = this.selectedServices
            }
            temp = Array.from(new Set(temp));
            price.length = temp.length
            temp.forEach(selectedService=> {
              let currentPrice = this.search_rates.services_search_rates.find(rateItem=> rateItem.source === selectedService)
              if (typeof currentPrice !== 'undefined'){
                if (currentPrice.online_result_price > currentPrice.offline_result_price){
                  price.totalPrice = price.totalPrice + currentPrice.online_result_price
                } else {
                  price.totalPrice = price.totalPrice + currentPrice.offline_result_price
                }
              } else {
                if (this.search_rates.defaults.online_result_price > this.search_rates.defaults.offline_result_price){
                  price.totalPrice = price.totalPrice + this.search_rates.defaults.online_result_price
                } else {
                  price.totalPrice = price.totalPrice + this.search_rates.defaults.offline_result_price
                }
              }
            })
          }
          this.totalPrice = price;
        },
        changeOnlineSearchState(val){
          if (val){
            if (this.iterative_search){
              let keys = Object.keys(this.searchOptions);
              let temp = [];
              if (this.currentParam !== 'image'){
                keys = keys.filter(item => item !== 'image')
              }
              for(const key of keys) {
                this.searchOptions[key].services.forEach(service => {
                  temp.push(service.name)
                  this.selectedServices = temp;
                })
              }
            } else {
              let temp = [];
              this.searchOptions[this.param].services.forEach(service => {
                temp.push(service.name)
                this.selectedServices = temp;
              })
            }
          } else {
            this.selectedServices = []
          }
        },
        updateServices(){
            if (this.currentParam === 'mass_isdn' || !this.iterative_search){
              setTimeout(()=>{
                let temp = [];
                temp = this.searchOptions[this.param].services.filter(service => {
                  return this.selectedServices.includes(service.name);
                })
                temp = temp.map(item => { return item.name})
                this.$emit('updateServices', temp)
                this.getPrice(temp);
              }, 100)
            } else {
              setTimeout(()=>{
                let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
                if (this.selectedServices.length > 0){
                  obj[this.param].services = this.selectedServices
                  localStorage.setItem('fullAlgorithms', JSON.stringify(obj));
                }
                this.$emit('updateServices', obj[this.param].services)
                this.getPrice();
              }, 100)

            }
        },

        getSearchRates() {
            this.$store.dispatch('search_rates/getSearchRates').then(() => {
              this.$emit('ratesLoading', true)
              this.getServicesArr()
            })
        },
        setGetContactTags(service){

        },
        setOption(){
          let obj = JSON.parse(localStorage.getItem('fullAlgorithms'));
          if (typeof obj[this.param] !=='undefined' && typeof obj[this.param].services !== 'undefined'){
            this.selectedServices = obj[this.param].services
          } else {
            this.selectedServices = []
          }
          this.updateServices();
        },
        getServicesArr(){
          let tempSearchOptionsArr = this.searchOptions;
          const keys = Object.keys(this.searchOptions);
          for(const key of keys) {
            this.searchOptions[key].services.forEach((service, index)=> {
              let currentPrice = this.search_rates.services_search_rates.find(rateItem=> rateItem.source === service.name)
              if (typeof currentPrice !== 'undefined'){
                tempSearchOptionsArr[key].services[index].offline_result_price = currentPrice.offline_result_price;
                tempSearchOptionsArr[key].services[index].online_result_price = currentPrice.online_result_price
              } else {
                tempSearchOptionsArr[key].services[index].offline_result_price = 1;
                tempSearchOptionsArr[key].services[index].online_result_price = 1;
              }
            })
          }
          this.services =  tempSearchOptionsArr
          this.setOption();
          this.isLoading = false
          this.$emit('ratesLoading', this.isLoading)
        },
    },
    mounted() {
        this.getSearchRates()
    }
}
</script>
<style scoped lang="scss">
.childPhotoSearch{
  display: flex;
  justify-content: start;
  flex-direction: column;
}
img.service-icon {
  width: 20px;
}
.paramTitle{
  font-weight: 600;
  padding-left: 1rem;
}
.sources-columns {
  column-count: 3;
  @media (max-width: 992px) {
    column-count: 1;
  }
}
.sources-columns {
  position: relative;

  .border-checkbox-section .border-checkbox-group .border-checkbox-label{
    margin-bottom: 10px;
    height: 60px;
  }
  .user-link {
    display: inline-block;
  }
  .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
    width: 100%;
    .check {
      margin-right: calc(0.75em - 1px);
    }
    .control-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
      position: relative;
      &:hover {
        background-color: #fff;
        overflow: visible;
        text-overflow: initial;
        text-decoration: underline;
        z-index: 99;
        padding-right: 20px;
      }
    }
  }
  }
</style>