<template>
  <div class="setting birthday-settings">
    <div  v-show="!open">
      <div class="open row" v-if="needShowPreview.canShow" >
<!--        <div style="display: flex; min-width: 14rem; align-items: center">-->
        <div class="col-6 col-lg-4 d-flex border-checkbox-section">
          <div class="border-checkbox-group border-checkbox-group-primary">
            <input
                type="checkbox"
                style="padding-top: 0"
                v-model="checked"
                @change="disableDate"
                class="border-checkbox"
                id="checkbox-birthday-min"
            >
            <label for="checkbox-birthday-min" class="border-checkbox-label">
              <span>{{ $t("birthday_date.birthday_date") }}:</span>
            </label>
          </div>
        </div>

        <div class="col-6 col-lg-8">

          <div>{{needShowPreview.date}}</div>
        </div>
      </div>
    </div>

    <div v-show="open" class="settings-block">
      <div class="row element">
              <div class="col-12 col-lg-3 settings-text border-checkbox-section" style="display: flex; margin-top: 0">
                <div class="border-checkbox-group border-checkbox-group-primary">
                  <input type="checkbox" class="border-checkbox" id="checkbox-birthday" v-model="checked" @change="disableDate(!checked)">
                  <label for="checkbox-birthday" class="border-checkbox-label">
                    <h4 class="title-search-name">{{ $t("birthday_date.birthday_date") }}</h4>
                  </label>
                </div>
              </div>

              <div class="col-12 col-lg-9">
                <div class="row container-radio" >
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col-12 form-radio initial-radio">
                        <div class="radio">
                          <label >
                              <input type="radio" ref="birthdayDate" @input="radioInput('birthdayDate')"
                            v-model="selectDateType" :value="'birthdayDate'">
                              <i class="helper"></i>
                              {{ $t("birthday_date.exact_date") }}
                          </label>
                        </div>
                        <!-- <b-form-radio
                            ref="birthdayDate"
                            style="height: 10px"
                            @input="radioInput"
                            v-model="selectDateType"
                            value="birthdayDate">
                        </b-form-radio> -->
                      </div>
                      <!-- <div class="col-lg-10 col-9">
                        <div class="radio-text"></div>
                      </div> -->
                    </div>
                  </div>

                  <div class="col-lg-9">

<!--                    <datepicker  class="datePicker"   v-model="birthdayDate" :language="setLanguage" :placeholder="this.$t('birthday_date.select_birthday_date')" :min-date="minDate" :max-date="maxDate"></datepicker>-->

                    <!-- <input class="form-control" type="date"
                           v-model="birthdayDate"
                           :disabled="selectDateType !== 'birthdayDate'"
                           :min="checked ? minDate : null"
                           :max="checked ? maxDate : null" />-->
                           <date-picker v-model="birthdayDate"
                            type="date"
                            valueType="YYYY-MM-DD"
                            :placeholder="$t('settings.datePlaceholder')"
                            format="DD.MM.YYYY"
                            :editable="false"
                            :lang="lang[$i18n.locale]"
                            :disabled-date="disabledRange"
                            :disabled="selectDateType !== 'birthdayDate'"></date-picker>
                  </div>
                </div>
                <div v-if="this.selectDateType === 'birthdayDate'">
                  <div v-if="dateInvalid" style="display: flex;justify-content: center; color: darkred; margin-top: 5px">
                    {{ intervalLabel }}
                  </div>
                </div>
              </div>
      </div>


      <div class="row justify-content-end element">
            <div class="col-12 col-lg-9">
              <div class="row" >
                <div class="col-lg-3">
                  <div class="row container-radio">
                    <div class="col-12 form-radio">
                      <div class="radio">
                        <label >
                            <input type="radio" ref="interval" @input="radioInput('interval')"
                          v-model="selectDateType" :value="'interval'">
                            <i class="helper"></i>
                            {{ $t("birthday_date.in_the_interval") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="row ">
                      <div class="col-sm-6 m-b-10 mb-md-0">
                        <b-input
                            type="number"
                            :disabled="selectDateType !== 'interval'"
                            maxlength = "4"
                            :placeholder="this.$t('birthday_date.year')"
                            v-model="from"
                            @input="$v.from.$touch()"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        ></b-input>
                      </div>
                      <div class="col-sm-6">
                        <b-input
                            type="number"
                            :disabled="selectDateType !== 'interval'"
                            maxlength = "4"
                            :placeholder="this.$t('birthday_date.year')"
                            v-model="to"
                            @input="$v.to.$touch()"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        ></b-input>
                      </div>
                  </div>

                </div>

              </div>
              <div v-if="this.selectDateType === 'interval'">
                <div v-if="intervalInvalid" style="display: flex;justify-content: center; color: darkred; margin-top: 5px">
                  {{intervalLabel}}
                </div>
              </div>
            </div>
          </div>



      <div class="row justify-content-end element">
        <div class="col-12 col-lg-9">
          <div class="row container-radio" >
            <div class="col-lg-3">
              <div class="row ">
                <div class="col-12 form-radio">
                  <div class="radio">
                    <label >
                        <input type="radio" ref="age" @input="radioInput('age')"
                      v-model="selectDateType" :value="'age'">
                        <i class="helper"></i>
                        {{ $t("birthday_date.age") }}
                    </label>
                  </div>
                </div>
                <!-- <div class="col-lg-10 col-9">
                  <div class="radio-text"></div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-sm-6 m-b-10 mb-md-0">
                  <b-input
                      type="number"
                      :disabled="selectDateType !== 'age'"
                      maxlength = "2"
                      @input="$v.$touch()"
                      :placeholder="this.$t('birthday_date.from')"
                      v-model="minAge"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  ></b-input>
                </div>
                <div class="col-sm-6">
                  <b-input
                      type="number"
                      :disabled="selectDateType !== 'age'"
                      maxlength = "3"
                      @input="$v.$touch()"
                      :placeholder="this.$t('birthday_date.to')"
                      v-model="maxAge"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  ></b-input>
                </div>
              </div>

            </div>

          </div>
          <div v-if="selectDateType === 'age'" >
            <div v-if="ageInvalid" style="display: flex;justify-content: center; color: darkred;margin-top: 5px">
              {{ageLabel}}
            </div>
          </div>
        </div>
      </div>

      </div>
  </div>


</template>

<script>

import moment from "moment";
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  name: "birthdayDate",
  props:['open'],
  components:{DatePicker} ,
  setup() {
    return {
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      }
    }
  },
  data() {
    return {
      selectLanguage: null,
      date: new Date(),
      birthdayDate: undefined,
      from: undefined,
      to: undefined,
      minAge: undefined,
      maxAge: undefined,
      maxLength: 4,
      selectDateType: 'birthdayDate',
      timeout: null,
      checked: true,
    }
  },
  beforeDestroy() {
    this.clearForm()
  },
  validations() {
    if (this.selectDateType === 'birthdayDate'){
        return {
          birthdayDate: {
            required,
            validDate: function () {
              return this.checked ? parseInt(this.birthdayDate) >= moment().year() - 100 && parseInt(this.birthdayDate) <= moment().year() : true
            }
          },
        }
    } else if (this.selectDateType === 'interval') {
      return {
        from: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
          validInterval: function () {
            return this.checked ? parseInt(this.from) >= (moment().year() - 100) && parseInt(this.from) <= moment().year() && parseInt(this.from) <= parseInt(this.to) : true
          }
        },
        to: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
          validInterval: function () {
            return this.checked ? parseInt(this.to) >= moment().year() - 100 && parseInt(this.to) <= moment().year() && parseInt(this.from) <= parseInt(this.to) : true
          }
        },
      }
    } else if (this.selectDateType === 'age'){
      return {
        minAge: {
          required,
          minLength: minLength(1),
          validAge: function () {
            return this.checked ? (this.minAge >= 1 && this.minAge <= 100 && this.minAge <= this.maxAge) : true
          }
        },
        maxAge: {
          required,
          minLength: minLength(1),
          validAge: function () {
            return this.checked ? (this.maxAge >= 1 && this.maxAge <= 100 && this.minAge <= this.maxAge ) : true
          }
        }
      }
    }
  },
  watch:{
    "$v.$invalid"(){
      clearTimeout(this.timeout);
      let self = this;
      this.timeout = setTimeout(function () {
        if (!self.$v.$invalid){
          self.setDate()
        }
      }, 500)

    },
    checked(){
      this.disableSearchButt();
    },
    birthdayDate(){
      this.disableSearchButt();
    },
    from(){
      this.disableSearchButt();
    },
    to(){
      this.disableSearchButt();
    },
    minAge(){
      this.disableSearchButt();
    },
    maxAge(){
      this.disableSearchButt();
    }
  },
  computed: {
    dateInvalid(){
      if (this.selectDateType === 'birthdayDate'){
        return !!(this.$v.birthdayDate.$invalid && this.birthdayDate !== '' && typeof this.birthdayDate !== 'undefined');
      } else {
        return false
      }
    },
    intervalInvalid(){
      if (this.selectDateType === 'interval'){
          return !!((parseInt(this.from) > parseInt(this.to)) ||
              (((this.$v.from.$invalid && this.$v.to.$dirty) ||
                  (this.$v.from.$invalid && typeof this.from !== 'undefined')) ||
                  ((this.$v.to.$invalid && this.$v.from.$dirty) ||
                      (this.$v.to.$invalid && typeof this.to !== 'undefined'))));
      } else {
        return false
      }
    },
    ageInvalid(){
      if (this.selectDateType === 'age'){
        return !!((this.$v.minAge.$invalid || this.$v.maxAge.$invalid) && (((this.$v.minAge.$invalid && typeof this.minAge !== 'undefined') || (this.$v.maxAge.$invalid && typeof this.maxAge !== 'undefined'))) || parseInt(this.minAge) > parseInt(this.maxAge));
      } else {
        return false
      }
    },
    setLanguage(){
      if (this.$i18n.locale === 'ru'){
        return  this.ru
      } else if (this.$i18n.locale === 'en'){
        return  this.en
      } else if (this.$i18n.locale === 'ua'){
        return  this.uk
      }
    },
    needShowPreview(){
      if (this.selectDateType === 'birthdayDate' && this.birthdayDate){
        return {
          canShow: true,
          date:  moment(this.birthdayDate).format('YYYY') + this.$t('birthday_date.year_title')
        }
      } else if (this.selectDateType === 'interval' && this.from && this.to){
        return {
          canShow: true,
          date: this.$t('birthday_date.interval')+ this.from.substr(0, 4) + ' - ' + this.to.substr(0, 4)
        }
      } else if (this.selectDateType === 'age' && this.minAge && this.maxAge){
        return {
          canShow: true,
          date: this.$t('birthday_date.title_age')+ this.minAge + ' - '+ this.maxAge + this.$t('birthday_date.age_title')
        }
      } else {
        return {
          canShow: false,
        }
      }
    },
    minDate() {
      // return moment().subtract(100, 'year').toDate()
      return moment(moment().subtract(100, 'year').toDate()).format('YYYY-MM-DD')
    },
    maxDate() {
      // return moment().toDate()
      return moment(moment().toDate()).format('YYYY-MM-DD')
    },

    intervalLabel(){
      return this.$t('birthday_date.expected_value_from') + (moment().year() - 100)+ this.$t('birthday_date.expected_value_to') + moment().year();
    },
    ageLabel(){
      return this.$t('birthday_date.expected_value_from') + 1 + this.$t('birthday_date.expected_value_to') + 100;
    },
  },
  methods:{
    customFormatter(date) {
      return moment(date).format('YYYY MMMM Do ,');
    },
    disabledRange(date) {
      return date < moment().subtract(100, 'year').toDate()
          || moment().toDate() <= date;
    },
    disableSearchButt(){
      if (this.checked){
        if (this.selectDateType === 'age' ){
          if ((this.$v.minAge.$invalid || this.$v.maxAge.$invalid) && (((this.$v.minAge.$dirty && this.minAge !== '' ) || (this.$v.maxAge.$dirty && this.maxAge !== ''))) || parseInt(this.minAge) > parseInt(this.maxAge)){
            this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', true)
          }
        } else if (this.selectDateType === 'interval'){
          if (((this.$v.from.$invalid || this.$v.to.$invalid)) || ((this.$v.from.$invalid && typeof this.from !== 'undefined') || (this.$v.to.$invalid && typeof this.to !== 'undefined'))){
            this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', true)
          }
        }  else if (this.selectDateType === 'birthdayDate'){
          if (this.$v.birthdayDate.$invalid && this.birthdayDate !== ''){
            this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', true)
          }
        } else {
          this.setDate()
        }
      } else {
        this.setDate()
      }
    },



    disableDate(val){
      this.$store.dispatch('nameSearchAdditionalSettings/changeAgeState', val)
    },


    setDate(){
      this.disableDate(true)
      // this.checked = true;
      let date = [];
      if (this.selectDateType === 'birthdayDate' && this.birthdayDate !== '' && typeof  this.birthdayDate !== 'undefined' && !this.$v.birthdayDate.$invalid){
        date = {
          "param": "birthdate",
          "value": moment(this.birthdayDate).format('YYYY-MM-DD'),
          "operator": "eq"
        }
      } else if (this.selectDateType === 'interval' && this.from !== '' && typeof this.from !== 'undefined' && this.to !== '' && typeof this.to !== 'undefined' && !this.$v.from.$invalid && !this.$v.to.$invalid && parseInt(this.from) <= parseInt(this.to)){
        date = [
          {
            "param": "birthdate",
            "value": moment(this.from).format('YYYY-MM-DD'),
            "operator": "gte"
          },
          {
            "param": "birthdate",
            "value": moment(this.to).endOf("year").format('YYYY-MM-DD'),
            "operator": "lte"
          }
        ]

      } else if (this.selectDateType === 'age' && this.minAge !== '' && typeof this.minAge !== 'undefined' && this.maxAge !== '' && typeof this.maxAge !== 'undefined' && !this.$v.minAge.$invalid && !this.$v.maxAge.$invalid && this.minAge <= this.maxAge ){
        let minAge = moment().year() - this.minAge
        let maxAge = moment().year() - this.maxAge
        minAge = minAge.toString()
        maxAge = maxAge.toString()
        date = [
          {
            "param": "birthdate",
            "value": moment(maxAge).format('YYYY-MM-DD'),
            "operator": "gte"
          },
          {
            "param": "birthdate",
            "value": moment(minAge).endOf("year").format('YYYY-MM-DD'),
            "operator": "lte"
          }
        ]
      }
      this.$store.dispatch('nameSearchAdditionalSettings/ageFilters', date).then(()=>{
        this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', false)
      })
    },


    clearForm(){
      this.birthdayDate = undefined
      this.from = undefined
      this.to = undefined
      this.minAge = undefined
      this.maxAge = undefined
      this.selectDateType = 'birthdayDate';
      let data = []
      this.$store.dispatch('nameSearchAdditionalSettings/ageFilters', data)
      // this.disableDate();
    },
    radioInput(val){
      let date = [];
      this.$store.dispatch('nameSearchAdditionalSettings/deleteDate')
      if (val === 'birthdayDate' && this.birthdayDate !== '' && typeof  this.birthdayDate !== 'undefined'){
        date = {
          "param": "birthdate",
          "value": moment(this.birthdayDate).format('YYYY-MM-DD'),
          "operator": "eq"
        }
      } else if (val === 'interval' && this.from !== '' && typeof this.from !== 'undefined' && this.to !== '' && typeof this.to !== 'undefined'){
        date = [
          {
            "param": "birthdate",
            "value": moment(this.from).format('YYYY-MM-DD'),
            "operator": "gte"
          },
          {
            "param": "birthdate",
            "value": moment(this.to).format('YYYY-MM-DD'),
            "operator": "lte"
          }
        ]

      } else if (val === 'age' && this.minAge !== '' && typeof this.minAge !== 'undefined' && this.maxAge !== '' && typeof this.maxAge !== 'undefined' ){
        let minAge = moment().year() - this.minAge
        let maxAge = moment().year() - this.maxAge
        minAge = minAge.toString()
        maxAge = maxAge.toString()
        date = [
          {
            "param": "birthdate",
            "value": moment(maxAge).format('YYYY-MM-DD'),
            "operator": "gte"
          },
          {
            "param": "birthdate",
            "value": moment(minAge).format('YYYY-MM-DD'),
            "operator": "lte"
          }
        ]
      }
      this.$store.dispatch('nameSearchAdditionalSettings/ageFilters', date).then(()=>{
        this.$store.dispatch('nameSearchAdditionalSettings/changeButtonState', false)
      })
    },

  },
}
</script>
<style>
.datePicker input{
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
    height: 33px;
    padding-left: 12px;
}
.element{
  margin-top: 0.7rem !important;
}
.setting{
  margin-top: 0.5rem;
}
.settings-block{
  border: 1px solid #dbdbdb !important;
  border-radius: 4px;
  padding: 6px;
}
.open{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 992px) {
  .birthday-settings .container-radio {
    height: 35px;
    align-items: center;
  }
}
.birthday-settings .container-radio .form-radio label {
  height: 35px;
  white-space: nowrap;
}
@media (min-width: 992px) {

  .container-radio .radio .helper::after, .container-radio .radio .helper::before {
    top: 5px;
  }
  /* .initial-radio .radio .helper::after, .initial-radio .radio .helper::before {
    top: 3px;
  } */
}
@media (max-width: 991px) {
  .container-radio .form-radio {
    width: 15px;
  }
}
.mx-datepicker {
  width: 100%;
}
.mx-input {
  border-radius: 2px;
}
</style>