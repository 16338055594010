<template>

  <div class="container" v-if="photos.length" style="margin-bottom: 20px">
    <div class="card-header-title" style="margin-bottom: 0.5rem; margin-top: 0.5rem">{{ $t("result.found") }} {{photos.length}} {{$t("params.photo")}}</div>
    <div class="photoContainer" v-if="photos.length < 3 && this.widthWindow > 1024" style="display:flex; justify-content: space-around">
      <photo-fragment v-for="(photo, index) in photos" :key="index" :photo="photo" :source="source" :meta="meta" />
    </div>
    <div  v-else>
      <VueSlickCarousel v-bind="settings" @click.prevent >
        <div  v-for="(photo,k) in photos" :key="k">
          <photo-fragment :photo="photo" :meta="meta" :is-child="false" />
        </div>
      </VueSlickCarousel>
    </div>
  </div>




</template>


<script>
import PhotoFragment from "../PhotoFragment";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {

  props: ['photos', 'meta', 'source'],
  components: {
    PhotoFragment,
    VueSlickCarousel
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data(){
    return{
      settings: {
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 6,
        "slidesToScroll": 6,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2,
              "dots": false
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": false
            }
          }
        ]
      },
      widthWindow: 0
    }
  },
  mounted() {
    window.addEventListener('resize', (e) => {
      this.widthWindow = window.innerWidth
    })
  }

}
</script>
<style>

.slick-track{
  z-index: 2;
  display: flex !important;
  align-items: center;
}
.slick-next{
  z-index: 100;
}
.slick-next:before{
  color: black;
  margin-left: -5rem;
  text-shadow: 0 1px 5px #fff;
}
.slick-prev:before{
  color: black;
  margin-right: -5rem;
  text-shadow: 0 1px 5px #fff;
}
.slick-prev{
  z-index: 100;
}
</style>
