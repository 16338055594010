<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="container form-group">
        <div class="elementContainer row">
          <div class="inputContainer col-md-11" style="display:flex;"  :class="{ isFocused: isFocused }">
            <vue-country-code
                class="selector"
                @onSelect="onSelect"
                :enabledCountryCode="false"
                :defaultCountry="countryForCarNumber" :disabledFetchingCountry="true"
                :preferredCountries="['ua', 'ru']"
                :dropdownOptions="{ disabledDialCode: true }"
            />

            <the-mask
                id="carNumberInputId"
                class="form-control"
                autocomplete="off"
                type="text"
                :mask="currentMask"
                :tokens="tokens"
                :placeholder="placeholder"
                v-model="payload.value"
                @focus.native="focusOnContainer"
                @blur.native="removeFocus"
            />
          </div>

          <div class="switchContainer col-md-1" style="margin-top: 4px" v-if="!isMobile">
            <div class="form-check form-switch" >
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-tippy="{ placement : 'top',  arrow: true }"
                     :content='$t("search_form.non_standard_car_plate_num")'
                     v-model="nonStandardNumber" :disabled="switchDisabled">
            </div>
          </div>
        </div>
      </div>
      <OptionsSearch param="car_plate_num" @push="setPayload" @invalid="setInvalid" />
      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button class="btn" variant="primary" type="submit" :disabled="$v.payload.$invalid">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>


    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>


</template>


<script>

import {mapGetters} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
import OptionsSearch from "@/components/search/optionsSearch";
import {validationMixin} from "vuelidate";
import openResult from "@/mixins/openResult";

export default {
  name: "carPlateNumForm",
  mixins: [validationMixin, openResult],
  components:{ OptionsSearch },
  data() {
    return {
      payload: {
        param: 'car_plate_num',
        value:''
      },
      modalDialog: false,
      showBtn: true,
      invalid: true,
      isFocused: false,
      countryForCarNumber: localStorage.getItem('selected_country_car') || 'UA',
      currentMask: "",
      placeholder: "",
      numberLength: 0,
      selectedPattern: {},
      tokens: {
        A: {
          pattern: /[а-яА-ЯёЁa-zA-ZІі]/,
          transform: (v) => v.toLocaleUpperCase(),
        },
        "#": {
          pattern: /[0-9]/,
        },
        X: {
          pattern: /[ а-яА-ЯёЁa-zA-Z0-9-Іі]/,
          transform: (v) => v.toLocaleUpperCase(),
        },
      },
      isMobile: false,
      nonStandardNumber: false,
      switchDisabled: false,

    }
  },
  validations() {
    return {
      payload: {
        value: {
          required,
          [this.payload.value]: minLength(this.numberLength),
        },
        optionsValid() {
          return !this.invalid
        }
      }
    }
  },
  watch:{
    nonStandardNumber() {
      this.onSwitcherChange();
    },
  },
  computed:{
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters("auth", ["user"]),

  },
  methods:{
    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
    beforeSearch() {
      let value = this.payload.value;
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    addSearch() {
        if (this.countryForCarNumber === "UZ" && !this.nonStandardNumber) {
          let payloadObj = JSON.parse(JSON.stringify(this.payload));
          payloadObj.value = payloadObj.value.substr(0, 2) + " " + payloadObj.value.substr(2);
          payloadObj.value = payloadObj.value.substr(0, 4) + " " + payloadObj.value.substr(4);
          payloadObj.value = payloadObj.value.substr(0, 8) + " " + payloadObj.value.substr(8);
          this.$store.dispatch("contacts/search", {
            payload: Object.assign({}, payloadObj),
          }).then(() => {
            this.$store.dispatch('ui/getFreeSpaceInfo')
          });
        } else {
          this.$store.dispatch("contacts/search", {
            payload: Object.assign({}, this.payload),
          }).then(() => {
            this.$store.dispatch('ui/getFreeSpaceInfo')
          });

        }

        this.payload.value = null;
        this.val = "";
        delete this.payload.filters
        delete this.payload.alt_values
    },


    focusOnContainer() {
      this.$v.payload.$touch();
      this.isFocused = true;
    },
    removeFocus: function () {
      this.isFocused = false;
    },
    onSelect({ iso2 }) {
      localStorage.setItem("selected_country_car", iso2);
      this.countryForCarNumber = iso2
      this.payload.value = "";
      let countryWithPattern;
      this.countries.forEach((item) => {
        if (item.code === iso2 && item.pattern) {
          countryWithPattern = item;
        }
      });
      if (countryWithPattern) {
        if (iso2 === "RU") {
          // ru numbers can be 11 or 12 symbol length
          this.numberLength = 8;
        } else {
          this.numberLength = countryWithPattern.pattern.replace(
              /\s+/g,
              ""
          ).length;
        }
        this.selectedPattern = countryWithPattern.pattern;
        this.currentMask = countryWithPattern.pattern;
        this.placeholder = countryWithPattern.pattern.replace(/#/gi, "1");
        this.switchDisabled = false;
        this.nonStandardNumber = false;
      } else {
        this.selectedPattern = "";
        this.currentMask = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
        this.numberLength = 5;
        this.placeholder = "";
        this.switchDisabled = true;
        this.nonStandardNumber = true;
      }
    },
    onSwitcherChange: function () {
      if (this.nonStandardNumber) {
        this.numberLength = 3;
        this.setDefaultValueForInput();
        this.placeholder = "";
        this.currentMask = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
      } else {
        this.setDefaultValueForInput();
        this.placeholder = this.selectedPattern.replace(/#/gi, "1");
        this.currentMask = this.selectedPattern;
        this.numberLength = this.selectedPattern.replace(/\s+/g, "").length;
      }
    },
    setDefaultValueForInput: function () {
      let el = document.getElementById("carNumberInputId");
      el.value = "";
      el.dispatchEvent(new Event("input"));
      this.payload.value = "";
    },

  },
  mounted() {
    this.isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        );
  }
}
</script>

<style scoped>

</style>