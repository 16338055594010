<template>
   <div style="margin-top: 2rem">
     <level v-for="(level,index) in levels"
            :key="index"
            :level="level"
            :meta="meta"
            :result-visible="resultVisible"
            :errors="errors"></level>
   </div>
</template>

<script>
import Contact from "@/components/Contact";
import resultsToLevels from "@/mixins/resultsToLevels";
import UnusedServices from "@/components/results/UnusedServices.vue";
import {mapGetters} from "vuex";
import Level from "@/components/Level.vue";
export default {
  name: "resultLevel",
  components: {Level, UnusedServices, Contact},
  props: ['meta', 'errors'],
  mixins:[resultsToLevels],
  data() {
    return {
      visible: false,
    }
  },
  computed:{
    ...mapGetters('ui', ['services']),
    resultVisible(){
      let count = 0;
      if (typeof  this.levels[1] !== 'undefined'){
        this.levels[1].forEach(levelItem =>{
          levelItem.items.forEach(item =>{
            if (item.search_results){
              count += item.search_results.length
            }
          })
        })
      }
      return count === 0
      // return this.levels.length < 2  && this.levels[0].length < 2
    },
    sources() {
      return this.results.filter(result => !result.search_results && result.success).map(result => result.source)
    },
    finalServices() {
      return this.services.filter(result => this.sources.includes(result.name)
          && result.search_params.includes(this.results[0].param));
    },
    errorServices() {
      const sources = this.results.filter(result => !result.search_results && !result.success).map(result => result.source)
      return this.services.filter(result => sources.includes(result.name)
          && result.search_params.includes(this.results[0].param));
    }
  },
  mounted() {


  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.7em;
  .card-header {
    padding: 0.5em !important;
  }
}


.level-title{
  font-size: 1.25em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;

  @media (max-width: 992px) {
    font-size: 1em;
  }
}
.highlighted{
  animation: colorChange 700ms;
}

@keyframes colorChange {
  0% {
    background-color: $primary;
  }
  100%{
    background-color: #fbfbfb;
  }
}

</style>