<template>
  <section class="card">
      <header class="card-header">
        <h4 class="m-b-20">{{ $t("result.results") }}: {{resultOnlineLength}}</h4>
        <div class="d-flex">
          <figure class="flex-shrink-1">
            <p class="image is-48x48">
              <img :src="result.icon" :alt="result.param" @imgSource="onLoadImg">
            </p>
          </figure>
          <div class="flex-grow-1 ms-3">
            <div class="content">
              <h2>
                {{ result.source }}: {{ result.param === 'image' ? '' : result.value }}
              </h2>
            </div>
          </div>
        </div>
      </header>
      <div class="card-content">
        <div class="content">
          <h3 v-if="result.param === 'image'">{{ $t("child_contact.in_accounts") }}:</h3>
          <b-tabs>
            <b-tab :title="$t('child_contact.coincidence')" v-if="separetedScore.same.length > 0">
              <b-tabs>
                <b-tab v-for="(infoResult, index) in separetedScore.same" :key="index" :title="tabNames.same[index]" >
                  <card-result :info-result="infoResult" :meta="meta" :result="result" @updateResults="updateResults"/>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('child_contact.similarity')" v-if="separetedScore.similar.length > 0">
              <b-tabs>
                <b-tab v-for="(infoResult, index) in separetedScore.similar" :key="index" :title="tabNames.similar[index]" >
                  <card-result  :info-result="infoResult" :meta="meta" :result="result" @updateResults="updateResults" />
                </b-tab>
              </b-tabs>
            </b-tab>
          </b-tabs>
        </div>
      </div>
  </section>
</template>
<script>
import CardResult from "./results/CardResult.vue";
  export default {
    props: ['result', 'meta', 'itter', 'resultOnlineLength'],
    components: {
      CardResult
    },
    data() {
      return {
        facesLoaded: false,
        showResults: true,
      }
    },
    computed: {
      separetedScore() {
        let same = [];
        let similar = [];
        if (this.showResults){
           same = this.result.search_results.filter(item => {
            return typeof item.photos[0].score !== 'undefined' ? item.photos[0].score >= 0.575 : true
          });
           similar = this.result.search_results.filter(item => {
            return typeof item.photos[0].score !== 'undefined' ? item.photos[0].score < 0.574 : false
          });
        }
        return {
          same,
          similar
        }
      },
      tabNames() {
        return {
          same: this.separetedScore.same.map(it => {
              if(Array.isArray(it.data) && it.data.length > 0) {
                const nameArr = it.data.filter(itChild => typeof itChild.value !== 'undefined' && itChild.param === 'name')
                return typeof nameArr[0].value !== 'undefined' ? nameArr[0]['value'] : 'no name'
              } else {
                return this.$t("child_contact.no_mane")
              }
             }),
          similar: this.separetedScore.similar.map(it => {
            if(Array.isArray(it.data) && it.data.length > 0) {
              const nameArr = it.data.filter(itChild => typeof itChild.value !== 'undefined' && itChild.param === 'name')
              return typeof nameArr[0].value !== 'undefined' ? nameArr[0]['value'] : this.$t("child_contact.no_mane")
            } else {
              return this.$t("child_contact.no_mane")
            }
          }),
        }
      },
      faces() {
        if(typeof this.result.face_select !== 'undefined' && this.facesLoaded) {
          let photoHolder = document.getElementById("searchPhotoId");
          let photoWidth = photoHolder.offsetWidth;
          let coef = photoWidth / this.result.face_select.width;
          let faces;
          faces = this.result.face_select.face_boxes.map(item => {
            return {
              height: item.height * coef + 'px',
              width: item.width * coef + 'px',
              left: item.left * coef + 'px',
              top: item.top * coef + 'px'
            }
          });
          return faces
        }
        return [];
      }
    },
    methods: {
      updateResults(){
       this.showResults = false;
       this.$nextTick(()=>{
         this.showResults = true;
         this.$forceUpdate();
       })
      },
      returnSrc($event){
        if (typeof this.result.face_select !== 'undefined'){
          this.$refs.searchedFace.setImageParam($event, this.result)
        }
      },
      onLoadImg() {
        this.facesLoaded = true;
        this.$forceUpdate();
      }
    }
  }
</script>