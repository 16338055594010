import moment from "moment";

export default {
    methods:   {
        resultDateState(infoResult){
            let val;
            let before;

            if (typeof infoResult !=='undefined' && infoResult.type === 'database'){
                val = this.$t('result.date_from_offline_db')
            } else {
                before = moment(parseInt(infoResult.info_date), 'X').add(5, 'minutes').isSameOrBefore(moment())
                if (before){
                    val = this.$t('result.date_from_db')
                } else {
                    val = this.$t('result.just_received_online')
                }
            }
            return val;
        },
        resultDateSource(infoResult){
            let val;
            let before;
            if (typeof infoResult !=='undefined' && infoResult.type === 'database'){
                val = 'offlineDB'
            } else {
                before = moment(parseInt(infoResult.info_date), 'X').add(5, 'minutes').isSameOrBefore(moment())
                if (before){
                    val = 'DB'
                } else {
                    val = 'online'
                }
            }
            return val;
        }
    }
}