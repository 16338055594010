<template>
  <div class="block pt-4 mb-6 bordered" v-if="currentParam !== '' && paramAvailability">
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div style="width: auto">
        <section class="card">
          
          <div class="card-body">
            <template v-if="payload.param === 'isdn'">
              <div class="form-group">
                <vue-tel-input style="width: 100%" class="inputFocused" :disabled="searchLimit === 0" v-model="val"
                  :autoDefaultCountry="false" :dropdownOptions="{
                    width: '20rem',
                    showDialCodeInList: true,
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showSearchBox: true
                  }" :inputOptions="{
                    placeholder: $t('search.phoneNumberLabel'),
                  }" mode="auto" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"
                  :validCharactersOnly="true"></vue-tel-input>
              </div>
            </template>

            <template v-else-if="payload.param === 'image'">
              <div class="imageUploadContainer row form-group" :class="{
                'has-name': !!file,
                'is-primary': searchLimit !== 0,
                'is-disabled': searchLimit === 0,
              }">
                <div class="col-12 col-lg-3 m-b-15 mb-lg-0">
                  <div class="d-grid">
                    <div class="file btn-primary btn"
                       style="text-transform: inherit"
                       :title="$t('search.file')"
                       :class="{
                      'has-name': !!file,
                      'is-primary': searchLimit !== 0,
                      'disabled': searchLimit === 0,
                    }" :disabled="searchLimit === 0">
                      {{ $t('search.Click_to_upload') }}
                      <input type="file" name="file" ref="photo" @change="previewFile" accept="image/*" id="file"
                        :disabled="searchLimit === 0">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-9">
                  <img class="photo photo-preview" :src="photoPreviewSrc" alt="" />
                </div>
              </div>

              <div class="web-camera-container" style="margin-top: 0.75rem" v-if="isCameraOpen">
                <div v-show="isCameraOpen && isLoading" class="camera-loading">
                  <ul class="loader-circle">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

                <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
                  <div v-if="isPhotoTaken" class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

                  <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>

                  <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                </div>

                <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
                  <span class="button" @click="takePhoto">
                    <!-- <b-icon pack="fas" icon="camera" size="is-medium" /> -->
                  </span>

                  <!--                <button type="button" class="button" @click="takePhoto">-->
                  <!--                  <img src="https://img.icons8.com/material-outlined/50/000000/camera&#45;&#45;v2.png">-->
                  <!--                </button>-->
                </div>
              </div>
            </template>
            <template v-else-if="payload.param === 'car_plate_num'">
              <div class="container form-group" >
                <div class="elementContainer row" >
                  <div class="inputContainer col-md-11" style="display:flex;"  :class="{ isFocused: isFocused }">
                    <vue-country-code
                        class="selector"
                        @onSelect="onSelect"
                        :enabledCountryCode="false"
                        :defaultCountry="countryForCarNumber" :disabledFetchingCountry="true"
                        :preferredCountries="['ua', 'ru']"
                        :dropdownOptions="{ disabledDialCode: true }"
                        :disabled="searchLimit === 0"
                    />

                    <the-mask
                        id="carNumberInputId"
                        class="form-control"
                        type="text"
                        :mask="currentMask"
                        :tokens="tokens"
                        :placeholder="placeholder"
                        v-model="payload.value"
                        @focus.native="focusOnContainer"
                        @blur.native="removeFocus"
                        :disabled="searchLimit === 0"
                    />
                  </div>

                  <div class="switchContainer col-md-1" style="margin-top: 4px" v-if="!isMobile">
                    <!-- <b-tooltip target="flexSwitchCheckChecked">{{ $t("search_form.non_standard_car_plate_num") }}</b-tooltip> -->
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        v-model="nonStandardNumber" :disabled="switchDisabled || searchLimit === 0">
                    </div>
                  </div>
                </div>
              </div>

            </template>
            <template v-else-if="payload.param === 'mass_isdn'">
              <div class="form-group">
                <textarea v-model="payload.value" class="form-control" :placeholder="$t('side_menu.ISDN_set')"></textarea>
              </div>
            </template>
            <template v-else-if="payload.param === 'name'">
              <div>
                <div class="form-group">
                  <input class="form-control" :placeholder="$t('params.' + payload.param).capitalize()" id="searchName" type="text" :disabled="searchLimit === 0"
                    autocomplete="off" v-model="payload.value" />
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="form-group">
                  <input :type="inputType" id="inputSearch" :disabled="searchLimit === 0" autocomplete="off"
                    v-model="payload.value" :placeholder="$t('placeholders.'+ payload.param)" class="form-control" />
                </div>
              </div>
            </template>
            <name-search-additional-settings :search-payload="payload" ref="nameSearchSettings"
              v-if="payload.param === 'name' && searchOptions.name.advanced" />
            <search-options
                :currentParam="payload.param"
                :iterative_search="payload.iterative_search"
                :param="payload.param"
                @setValidSearchOptions="setValidSearchOptions"
                @updateServices="updateServices()"
                @setGetContactTags="setGetContactTags"
                :expanded="false"
                @push="getPayload">
            </search-options>
            <div class="d-grid" v-if="!disallowFreeSpace">
              <button class="btn" :class="{'btn-default': $v.payload.$invalid || disableSearch || !searchOptionsValid , 'btn-primary': !$v.payload.$invalid && !disableSearch && searchOptionsValid}" type="submit" :disabled="$v.payload.$invalid || disableSearch || !searchOptionsValid">
                {{ $t("search.search") }}
              </button>
            </div>
            <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
          </div>
        </section>
      </div>
    </form>
    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid gap-2">
            <button  :disabled="!showBtn" @click="resolveExistResult('open')" class="btn btn-outline-primary">{{$t('search.open')}}</button>
            <b-button @click="resolveExistResult('update')" class="mt-2" variant="outline-secondary" block >{{ $t('search.update') }}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import filters from "@/mixins/filters";
import openResult from "@/mixins/openResult";
import { required, email, numeric, minLength } from "vuelidate/lib/validators";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Compressor from "compressorjs";
// import tingsChanger from "@/components/settings/settingsChanger";
import massNumber from "@/helpers/massNumber";
import NameSearchAdditionalSettings from "@/components/nameSearchAdditionalSettings";
import SearchOptions from "@/components/searchOptions"
import _ from "underscore";
import SearchRates from '@/components/searchRates/searchRates'

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export default {
  props: ["canCancel"],
  mixins: [filters, openResult],
  components: {
    NameSearchAdditionalSettings,
    // SettingsChanger,
    VueTelInput,
    SearchOptions,
    SearchRates
  },
  data() {
    return {
      notReal: null,
      numbers: "",
      hlr: localStorage.getItem("hlr") ? localStorage.getItem("hlr") : false,
      hlrDisabler: localStorage.getItem("search_services"),
      searchServices: localStorage.getItem("search_services")
        ? localStorage.getItem("search_services")
        : true,
      searchAlgorithms: localStorage.getItem("search_algorithms")
        ? localStorage.getItem("search_algorithms")
        : true,
      intellectualSearch: localStorage.getItem("intellectual_search")
        ? localStorage.getItem("intellectual_search")
        : false,
      payload: {
        param: undefined,
        value: "",
        // service_types: ["online"],
        iterative_search: undefined,
        alternative: undefined,
        // offline: false,
        advanced: undefined
      },
      inputType: "text",
      inputPattern: "",
      val: "",
      input: {},
      country: {},
      phoneObj: {},
      phoneRaw: "",
      file: null,
      modalDialog: false,
      showBtn: true,
      existSearches: [],
      searchAlgorithmDropdownState: true,
      intellectualSearchDropdownState: true,
      preferredCountry: localStorage.getItem("country")
        ? JSON.parse(localStorage.getItem("country")).countryCode
        : "UA",
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      photoFile: null,
      showPhoto: false,

      photoPreviewSrc: "",

      isFocused: false,
      nonStandardNumber: false,
      switchDisabled: false,
      allCountries: this.countries,
      currentMask: "",
      placeholder: "",
      numberLength: 0,
      selectedPattern: {},
      tokens: {
        A: {
          pattern: /[а-яА-ЯёЁa-zA-ZІі]/,
          transform: (v) => v.toLocaleUpperCase(),
        },
        "#": {
          pattern: /[0-9]/,
        },
        X: {
          pattern: /[ а-яА-ЯёЁa-zA-Z0-9-Іі]/,
          transform: (v) => v.toLocaleUpperCase(),
        },
      },

      isMobile: false,
      countryForCarNumber: localStorage.getItem('selected_country_car') || 'UA',
      countryForPhoneNumber: localStorage.getItem("selected_country_isdn") || 'UA',
      disableSearch: false,
      formData: null,
      searchOptionsValid: true
    };
  },
  validations() {
    let validation = {
      payload: {
        param: {
          required,
        },
      },
    };
    // validation.payload.value = { required };
    if (this.payload.param !== "image") {
      validation.payload.value = { required };
      if (this.payload.param === "isdn") {
        validation.payload.value = {
          required,
          validPhone: () =>
            typeof this.input.valid === "undefined" ? false : this.input.valid,
        };
      }
      if (this.payload.param === "email") {
        validation.payload.value = {
          required,
          email,
        };
      }
      if (this.payload.param === "imei") {
        validation.payload.value = {
          required,
          numeric,
          lengthI: () =>
            this.payload.value.length >= 14 && this.payload.value.length <= 15,
        };
      }
      if (this.payload.param === "imsi") {
        validation.payload.value = {
          required,
          numeric,
          lengthI: () => this.payload.value.length === 15,
        };
      }
      if (this.payload.param === "car_plate_num") {
        validation.payload.value = {
          required,
          [this.payload.value]: minLength(this.numberLength),
        };
      }
      if (this.payload.param === "telegram_id") {
        validation.payload.value = {
          required,
          numeric
        };
      }
    }
    return validation;
  },
  computed: {
    ...mapState({
      searchParams: (state) => state.contacts.search_params,
      searchLimit: (state) => state.contacts.searchLimit,
    }),
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters('contacts', ['searchOptions']),
    ...mapGetters("nameSearchAdditionalSettings", ["searchButtonNeedDisable", 'alt_values', 'filters', 'enableAltValues', 'enableAge', 'enableAddress']),
    ...mapGetters('mainMenuState', ['currentParam']),
    ...mapGetters("auth", ["user"]),

    paramAvailability() {
      return this.searchParams.includes(this.currentParam)|| this.searchParams !== 'mass_isdn';
    },

    formDataPayload() {
      let formData = new FormData();
      if (this.file && this.payload.param === "image") {
        // let iterative =
        //   localStorage.getItem("search_algorithms") === "true" ? 1 : 0;
        // let search_services =
        //   localStorage.getItem("search_services") !== "true" ? 1 : 0;
        formData.append("param", this.payload.param);
        formData.append("cache_search_enabled", parseInt( 0));
        let online = this.payload.online_search_enabled ? 1: 0
        formData.append("online_search_enabled", parseInt(online));
        for(const service of this.payload.services) {
          formData.append("services[]", service);
        }

        // formData.append("iterative_search", iterative);
        // const offline = this.payload.offline ? 1 : 0;
        // formData.append("offline", offline);
        // formData.iterative_search = this.searchAlgorithms;
      }
      return formData;
    },
    optionsForAnalytics() {
      let params = [];
      if(this.payload.advanced) {
        params.push('Advanced');
      }
      if(this.payload.iterative_search) {
        params.push('Iterative');
      }
      if(!_.isUndefined(this.payload.filters)) {
        params.push('Filter')
      }
      return params.join(', ');
    }
  },
  methods: {
    setGetContactTags(val){
      if (val){
        let service_options = {
          GetContact: {
            request_option: {
              tags: true
            }
          }
        }
        this.payload.service_options = service_options
      } else {
        delete  this.payload.service_options
      }
    },
    updateServices(val){
      this.payload.services = val;
    },
    setValidSearchOptions(val){
      this.searchOptionsValid = val;
    },

    setAdditionalParamsToNameSearch() {

      if (typeof this.alt_values !== 'undefined' && this.alt_values.length !== 0) {
        if (this.enableAltValues) {
          this.payload.alt_values = this.alt_values;
        }
      }
      if (typeof this.filters !== 'undefined' && this.filters.length !== 0) {
        let temp = this.filters
        if (!this.enableAddress) {
          temp = this.filters.filter(el => el.param !== 'address' && el.param !== 'city')
        }
        if (!this.enableAge) {
          temp = this.filters.filter(el => el.param !== 'birthdate')
        }
        this.payload.filters = temp;
      }
      this.clearNameSearchForm();
    },
    clearNameSearchForm() {
      this.$refs.nameSearchSettings.clearForm();
    },


    setDefaultValueOfJson() {
      // let search_algorithms = localStorage.getItem("search_algorithms");
      // let search_services = localStorage.getItem("search_services");
      // if (search_algorithms === null) {
      //   localStorage.setItem("search_algorithms", "true");
      //   this.payload.iterative_search = true;
      // } else {
      //   this.payload.iterative_search = search_algorithms === "true";
      // }
      // if (search_services === null) {
      //   localStorage.setItem("search_services", "true");
      //   this.payload.offline = false;
      // } else {
      //   this.payload.offline = this.searchServices !== "true";
      // }
      // this.setHlr();
    },
    updatePayload() {
      // if (typeof enableHLR !== "undefined") {
      //   this.hlrDisabler = !enableHLR;
      // }
      // let searchAlgorithms = localStorage.getItem("search_algorithms");
      // let searchServices = localStorage.getItem("search_services");

      // this.payload.offline = searchServices !== "true";
      // this.payload.iterative_search = searchAlgorithms === "true";
    },
    // setHlr(val) {
    //   let hlr = localStorage.getItem("hlr");
    //   if (val) {
    //     let index = this.payload.service_types.indexOf("hlr");
    //     if (index !== -1) {
    //       this.payload.service_types.splice(index, 1);
    //     }
    //   } else {
    //     if (hlr === "true") {
    //       this.payload.service_types.push("hlr");
    //     } else {
    //       let index = this.payload.service_types.indexOf("hlr");
    //       if (index !== -1) {
    //         this.payload.service_types.splice(index, 1);
    //       }
    //     }
    //   }
    // },
    // enableIntellectualSearch(val) {
    //   localStorage.setItem("intellectual_search", val);
    //   this.setIntellectualSearch();
    // },

    setIntellectualSearch() {
      let enable = localStorage.getItem("intellectual_search");
      this.intellectualSearchDropdownState = enable === "true";
      if (enable === "true" && this.payload.param === "name") {
        this.payload.options = {
          size: 100,
          fuzziness: 1,
          translit: true,
        };
      } else {
        delete this.payload.options;
      }
    },

    updateLustSelectedSearchParam() {
      this.$store.dispatch(
        "contacts/setLustSelectedSearchParam",
        this.payload.param
      );
    },
    onSelect({ iso2 }) {
      localStorage.setItem("selected_country_car", iso2);
      this.countryForCarNumber = iso2
      this.payload.value = "";
      let countryWithPattern;
      this.countries.forEach((item) => {
        if (item.code === iso2 && item.pattern) {
          countryWithPattern = item;
        }
      });
      if (countryWithPattern) {
        if (iso2 === "RU") {
          // ru numbers can be 11 or 12 symbol length
          this.numberLength = 8;
        } else {
          this.numberLength = countryWithPattern.pattern.replace(
            /\s+/g,
            ""
          ).length;
        }
        this.selectedPattern = countryWithPattern.pattern;
        this.currentMask = countryWithPattern.pattern;
        this.placeholder = countryWithPattern.pattern.replace(/#/gi, "1");
        this.switchDisabled = false;
        this.nonStandardNumber = false;
      } else {
        this.selectedPattern = "";
        this.currentMask = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
        this.numberLength = 5;
        this.placeholder = "";
        this.switchDisabled = true;
        this.nonStandardNumber = true;
      }
    },
    focusOnContainer() {
      this.$v.payload.$touch();
      this.isFocused = true;
    },
    removeFocus: function () {
      this.isFocused = false;
    },
    onSwitcherChange: function () {
      if (this.nonStandardNumber) {
        this.numberLength = 3;
        this.setDefaultValueForInput();
        this.placeholder = "";
        this.currentMask = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
      } else {
        this.setDefaultValueForInput();
        this.placeholder = this.selectedPattern.replace(/#/gi, "1");
        this.currentMask = this.selectedPattern;
        this.numberLength = this.selectedPattern.replace(/\s+/g, "").length;
      }
    },

    setDefaultValueForInput: function () {
      let el = document.getElementById("carNumberInputId");
      el.value = "";
      el.dispatchEvent(new Event("input"));
      this.payload.value = "";
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
        this.photoFile = null;
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isCameraOpen = false;
          this.isLoading = false;
          this.$buefy.dialog.alert(
            "Функция не поддерживается на этом устройстве"
          );
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);

      this.photoFile = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");
      this.convertImageDataToFile(this.photoFile);
    },

    convertImageDataToFile: function (dataURl) {
      let name = "photo: " + new Date().toLocaleTimeString();
      let arr = dataURl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.file = new File([u8arr], name, { type: mime });
    },

    beforeSearch() {
      let value = this.payload.value;
      if(this.payload.param === 'username') {
        if (value.substring(0, 1) === '@') {
          value = value.substring(1);
        }
      }
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        if (this.payload.param === 'name') {
          this.setAdditionalParamsToNameSearch();
        }
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
    addSearch() {
      if (!this.$v.invalid) {
        if (this.payload.param === "mass_isdn") {
          const valuesA = this.payload.value.split("\n");
          valuesA.map((item) => {
            if (item.length < 5){
              return
            }
            let payloadObj = JSON.parse(JSON.stringify(this.payload));
            let payload = Object.assign(payloadObj, {
              param: "isdn",
              value: massNumber(item),
            });
            this.$store.dispatch("contacts/search", {
              payload,
            });
          });
          this.payload.param = "mass_isdn";
          this.payload.value = null;
          return true;
        }
        if (this.payload.param !== "image") {
          if (this.payload.param === 'car_plate_num' && this.countryForCarNumber === "UZ" && !this.nonStandardNumber) {
            let payloadObj = JSON.parse(JSON.stringify(this.payload));
            payloadObj.value = payloadObj.value.substr(0, 2) + " " + payloadObj.value.substr(2);
            payloadObj.value = payloadObj.value.substr(0, 4) + " " + payloadObj.value.substr(4);
            payloadObj.value = payloadObj.value.substr(0, 8) + " " + payloadObj.value.substr(8);
            this.$store.dispatch("contacts/search", {
              payload: Object.assign({}, payloadObj),
            }).then(() => {
              this.$store.dispatch('ui/getFreeSpaceInfo')
            });
          } else {
            if(this.payload.param === 'username') {
              let value = this.payload.value;
              if (value.substring(0, 1) === '@') {
                value = value.substring(1);
              }
              let payload = Object.assign({}, this.payload);
              payload = Object.assign(payload, {value})
              this.$store.dispatch("contacts/search", {
                payload,
              }).then(() => {
                this.$store.dispatch('ui/getFreeSpaceInfo')
              });
            } else {
              this.$store.dispatch("contacts/search", {
                payload: Object.assign({}, this.payload),
              }).then(() => {
                this.$store.dispatch('ui/getFreeSpaceInfo')
              });
            }
          }
        } else {
          const formPayload = this.formDataPayload;
          const storeObj = this.$store;
          new Compressor(this.file, {
            maxWidth: 1200,
            maxHeight: 1200,
            quality: 0.8,
            success(file) {
              formPayload.delete('value');
              formPayload.append("value", file);
              storeObj.dispatch("contacts/searchFile", {
                payload: formPayload,
                file: this.file.name,
              }).then(() => {
                storeObj.dispatch('ui/getFreeSpaceInfo')
              });
            },
          });
        }
        this.payload.value = null;
        this.val = "";
        delete this.payload.filters
        delete this.payload.alt_values
      }
    },
    updateCountryCode(countryObj) {
      this.country = countryObj;
      if (!localStorage.getItem("country")) {
        localStorage.setItem("country", JSON.stringify(countryObj));
      }
    },
    getValidate(phone, phoneObj) {
      
        // localStorage.setItem("selected_country_isdn", phoneObj.country.iso2)
        this.countryForPhoneNumber = localStorage.getItem('selected_country_isdn');
        this.phoneObj = phoneObj;
        this.phoneRaw = phone;

        let payload = JSON.parse(JSON.stringify(phoneObj));
        this.input = payload;
        this.payload.value = payload.number || "";
        if(typeof phoneObj.country !== 'undefined') {
          localStorage.setItem("selected_country_isdn", phoneObj.country.iso2)
        }
    },
    // changeDisabler() {
    //   if (this.payload.param === "isdn" || this.payload.iterative_search) {
    //     this.hlrDisabler = false;
    //   } else {
    //     this.hlrDisabler = true;
    //   }
    // },
    enableIterativeSearch(val) {
      localStorage.setItem("search_algorithms", val);
      this.setIterativeSearch();
    },

    setIterativeSearch() {
      let enable = localStorage.getItem("search_algorithms");
      this.searchAlgorithmDropdownState = enable === "true";
      this.payload.iterative_search = enable === "true";
    },
    getPayload(data) {
      if(typeof data.service_types !== 'undefined' && data.service_types.length === 0) {
        data.service_types = undefined;
      }
      this.payload = JSON.parse(JSON.stringify(Object.assign(this.payload, data)));
      this.$v.$touch();
    },
    previewFile() {
      this.file = this.$refs.photo.files[0];
    },
  },
  watch: {
    searchButtonNeedDisable() {
      this.disableSearch = this.searchButtonNeedDisable;
    },
    payload: {
      deep: true,
      handler(payloadVal) {
        if(payloadVal.param === 'email') {
          if(payloadVal.value && payloadVal.value.endsWith(" ")) {
            this.payload.value = payloadVal.value.slice(0, -1);
          }
          if(payloadVal.value && payloadVal.value.startsWith(" ")) {
            this.payload.value = payloadVal.value.slice(1);
          }
        }
        this.$store.dispatch("contacts/updatePayload", JSON.stringify(payloadVal));
        // const [val, prevVal] = [payloadVal.param, payloadPrevaVal.param];
      },
    },

    "payload.param": {
      handler (val, prevVal) {
        /// remove additional params is != name
        if (val !== 'name') {
            if (typeof this.payload.alt_values !== 'undefined') {
              this.payload.alt_values = undefined;
            }
            if (typeof this.payload.filters !== 'undefined') {
              this.payload.filters = undefined;
            }
          }

          if(val === 'image' && !this.file) {
            this.disableSearch = true
          } else {
            this.disableSearch = false
          }

          setTimeout(() => {
            if (prevVal === "isdn" && val !== "isdn") {
              this.val = "";
            }
            this.payload.value = "";
          }, 200);
        }
      },
    // "payload.iterative_search": function () {
    //   this.changeDisabler();
    // },
    file(val) {
      this.photoPreviewSrc = window.URL.createObjectURL(val);
      this.payload.value = val;
      if(val && this.payload.param === 'image') {
        this.disableSearch = false;
      }
    },
    hlr(val) {
      if (val) {
        this.payload.service_types.push("hlr");
      } else {
        let index = this.payload.service_types.indexOf("hlr");
        if (index !== -1) {
          this.payload.service_types.splice(index, 1);
        }
      }
    },
    nonStandardNumber() {
      this.onSwitcherChange();
    },
    currentParam(value) {
      this.payload.param = value;
    },
    // val(val, oldVal) {
    //   if (val.length > 9 && Math.abs(val.length - oldVal.length) > 1) {
    //     if (!val.includes('+') && !this.phoneObj.valid) {
    //       this.$nextTick(() => {
    //         this.val = `+${val}`;
    //       })
    //     }
    //   }
    // },
  },
  mounted() {

    this.payload.param = this.currentParam

    // this.setDefaultValueOfJson();
    this.$store.dispatch("contacts/updatePayload", this.payload);
    let param = localStorage.getItem("lastParam");
    if (param) {
      this.payload.param = param;
      this.payload.param = this.currentParam
    } else {
      this.payload.param = "isdn";
    }
    
    // this.changeDisabler();
    // define of user device
    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );

    if (!this.searchParams.length) {
      this.$store.dispatch("contacts/getSearchParams");
    }
    this.$store.dispatch('ui/getFreeSpaceInfo');
    // this.setIterativeSearch();
    // this.setIntellectualSearch();
  },
}
</script>
<style>
.below{
  top: 36px !important;
}
div.file {
  position: relative;
  overflow: hidden;
}

input[type=file] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.photo-preview {
  max-height: 300px;
  max-width: 100%;
}
@media (min-width: 1000px) {
  .vti__dropdown-list.below {
    width: max-content !important;
  }
}
.vti__search_box {
  width: calc(100% - 4px);
}

.vue-country-select.selector .dropdown-list {
  max-width: calc(100vw - 100px);
}
</style>
