<template>
  <div class="card" style="padding: 6px">
    <b-tabs fill>
      <b-tab :title="this.$t('stats.tab_search')" active>
        <grabber-hubs
          :isLoading="isLoading"
        />
      </b-tab>
      <b-tab :title="this.$t('stats.tab_monitoring')">
        <monitoring-hubs
          :isLoading="isLoading"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import GrabberHubs from "@/views/androidDevices/grabberHubs";
import MonitoringHubs from "@/views/androidDevices/monitoringHubs";
export default {
  name: "androidDevices",
  components: {MonitoringHubs, GrabberHubs},
  data() {
    return {
      isLoading: false,
    };
  },
  methods:{
    getGrabbers(){
      this.isLoading = true;
      this.$store.dispatch('grabbersStats/getGrabbers').then(()=>{
        this.isLoading = false
      })
    },
  },
  mounted() {
    this.getGrabbers();
  }
}
</script>

<style scoped>

</style>