<template>
  <div>
    <p class="m-b-0" v-if="services.length"><b>{{$t("result.services_returned_no_results")}}: </b>
      <span class="d-inline-block f-w-700"
            :key="service.id" v-for="(service, index) in services">
            {{ service.name }}{{index === services.length - 1 ? '':',&nbsp;' }}
      </span>
    </p>
    <p v-if="errors.length">
      <span>{{$t("result.services_returned_errors")}}: </span>
      <span class="d-inline-block f-w-700"  :key="`errors${index}`" v-for="(error, index) in errors">
        {{ error.name }}{{index === errors.length - 1 ? '':',&nbsp;' }}
      </span>
    </p>
  </div>
</template>

<script>
import _ from "underscore";
export default {
  name: "UnusedServices",
  props: ['services', 'errors'],
  data() {
    return {
      open: false
    }
  },
  computed: {
    existErrorNumbuster() {
        const objEx = _.find(this.errors, (item) => {
          return item.source === "NumBuster"
        });
        return typeof objEx !== 'undefined';
    }
  },

}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.7em;
  .card-header {
    padding: 0.5em !important;
  }
}


.level-title{
  font-size: 1.25em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
  @media (max-width: 992px) {
    font-size: 1em;
  }
}
.highlighted{
  animation: colorChange 700ms;
}

@keyframes colorChange {
  0% {
    background-color: $primary;
  }
  100%{
    background-color: #fbfbfb;
  }
}
.panel-block{
  padding-left: 10px;
  padding-right: 10px;
  line-height: 2em;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
}
.panel-block:not(:last-child){
  border-bottom: 1px solid #ededed;
}
</style>