<template>
  <div class="card full-text-block">
    <div class="card-body" style="padding-bottom: 8px; padding-top: 8px">
      <div class="form-block">
        <div class="form-block-left">
          <div class="element-label">{{ $t('params.birthdate') | capitalize }}</div>
          <div style="display: flex;vertical-align: center; margin-bottom: 1rem">
            <label style="display: flex; vertical-align: center">
              <input type="radio" ref="birthdayDate" @input="radioInput('birthdayDate')" v-model="selectDateType"
                :value="'birthdayDate'">
              <i class="helper"></i>
            </label>
            <span class="label-birthbay-field">{{
                $t("birthday_date.exact_date") }}
            </span>
              <date-picker v-model="birthdayDate"
                            type="date"
                            valueType="YYYY-MM-DD"
                            :placeholder="$t('settings.datePlaceholder')"
                            format="DD.MM.YYYY"
                            :editable="false"
                            :lang="lang[$i18n.locale]"
                            :disabled-date="disabledRange"
                            :disabled="selectDateType !== 'birthdayDate'"></date-picker>
          </div>

          <div v-if="this.selectDateType === 'birthdayDate'">
            <div v-if="dateInvalid" style="display: flex;justify-content: center; color: darkred; margin-top: 5px">
              {{ intervalLabel }}
            </div>
          </div>



          <div>
            <div style="display: flex;vertical-align: center;margin-bottom: 1rem">
              <label style="display: flex; vertical-align: center">
                <input type="radio" ref="interval" @input="radioInput('interval')" v-model="selectDateType"
                  :value="'interval'">
                <i class="helper"></i>
              </label>
              <span
                class="label-birthbay-field">{{
                  $t("birthday_date.in_the_interval") }}</span>
              <b-input style="margin-right: 1rem" type="number" :disabled="selectDateType !== 'interval'" maxlength="4"
                :placeholder="this.$t('birthday_date.year')" v-model="from" @input="$v.from.$touch()"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"></b-input>

              <b-input type="number" :disabled="selectDateType !== 'interval'" maxlength="4"
                :placeholder="this.$t('birthday_date.year')" v-model="to" @input="$v.to.$touch()"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"></b-input>
            </div>



            <div v-if="this.selectDateType === 'interval'">
              <div v-if="intervalInvalid" style="display: flex;justify-content: center; color: darkred; margin-top: 5px">
                {{ intervalLabel }}
              </div>
            </div>
            <div>
              <div style="display: flex;vertical-align: center; margin-bottom: 1rem">
                <label style="display: flex; vertical-align: center">
                  <input type="radio" ref="age" @input="radioInput('age')" v-model="selectDateType" :value="'age'">
                  <i class="helper"></i>
                </label>
                <span
                  class="label-birthbay-field radio-text">{{ $t("birthday_date.age") }}</span>
                <b-input style="margin-right: 1rem" type="number" min="1" :disabled="selectDateType !== 'age'" maxlength="2"
                  @input="$v.$touch()" :placeholder="this.$t('birthday_date.from')" v-model.number="minAge"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"></b-input>
                <b-input type="number" min="1" :disabled="selectDateType !== 'age'" maxlength="3" @input="$v.$touch()"
                  :placeholder="this.$t('birthday_date.to')" v-model.number="maxAge"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"></b-input>
              </div>
            </div>

            <div v-if="selectDateType === 'age'" >
              <div v-if="ageInvalid" style="display: flex;justify-content: center; color: darkred;margin-top: 5px">
                {{ ageLabel }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-block-right delete_on_birthday">
          <div class="d-flex justify-content-end">
            <button class="btn btn-outline-danger" @click="firstBlock ? $emit('removeblock'): $emit('remove')"><i class="feather icon-trash-2"></i></button>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center full-text-block-connection connection-element" v-if="showConnection"><span class="label label-inverse-primary text-uppercase">{{ $t('fullText.and') }}</span></p>

  </div>
</template>

<script>
import moment from "moment";
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import _ from "underscore";
import filters from '@/mixins/filters'
export default {
  name: "fullTextBirthDate",
  components: { DatePicker },
  props: ['filter','showConnection','firstBlock'],
  mixins: [filters],
  setup() {
    return {
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      }
    }
  },
  data() {
    return {
      selectLanguage: null,
      date: new Date(),
      birthdayDate: undefined,
      from: undefined,
      to: undefined,
      minAge: undefined,
      maxAge: undefined,
      maxLength: 4,
      selectDateType: 'birthdayDate',
      timeout: null,
      checked: true,
      conditions: [],
    }
  },
  beforeDestroy() {
    this.clearForm()
  },
  validations() {
    if (this.selectDateType === 'birthdayDate') {
      return {
        birthdayDate: {
          required,
          validDate: function () {
            return this.checked ? parseInt(this.birthdayDate) >= moment().year() - 100 && parseInt(this.birthdayDate) <= moment().year() : true
          }
        },
      }
    } else if (this.selectDateType === 'interval') {
      return {
        from: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
          validInterval: function () {
            return this.checked ? parseInt(this.from) >= (moment().year() - 100) && parseInt(this.from) <= moment().year() && parseInt(this.from) <= parseInt(this.to) : true
          }
        },
        to: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
          validInterval: function () {
            return this.checked ? parseInt(this.to) >= moment().year() - 100 && parseInt(this.to) <= moment().year() && parseInt(this.from) <= parseInt(this.to) : true
          }
        },
      }
    } else if (this.selectDateType === 'age') {
      return {
        minAge: {
          required,
          minLength: minLength(1),
          validAge: function () {
            return this.checked ? (this.minAge >= 1 && this.minAge <= 100 && this.minAge <= this.maxAge) : true
          }
        },
        maxAge: {
          required,
          minLength: minLength(1),
          validAge: function () {
            return this.checked ? (this.maxAge >= 1 && this.maxAge <= 100 && this.minAge <= this.maxAge ) : true
          }
        }
      }
    }
  },
  watch: {
    "$v.$invalid"(val) {
      clearTimeout(this.timeout);
      let self = this;
      this.timeout = setTimeout(function () {
        if (!self.$v.$invalid) {
          self.setDate()
        }
      }, 500)
      this.$emit('invalid', val)
    },
    checked(){
      this.disableSearchButt();
    },
    birthdayDate(){
      this.disableSearchButt();
    },
    from() {
      this.disableSearchButt();
    },
    to() {
      this.disableSearchButt();
    },
    minAge() {
      this.disableSearchButt();
    },
    maxAge() {
      this.disableSearchButt();
    },
  },
  computed: {

    dateInvalid(){
      if (this.selectDateType === 'birthdayDate'){
        return !!(this.$v.birthdayDate.$invalid && this.birthdayDate !== '' && typeof this.birthdayDate !== 'undefined');
      } else {
        return false
      }
    },
    intervalInvalid(){
      if (this.selectDateType === 'interval'){
        return !!((parseInt(this.from) > parseInt(this.to)) ||
            (((this.$v.from.$invalid && this.$v.to.$dirty) || (this.$v.from.$invalid && typeof this.from !== 'undefined')) ||
                ((this.$v.to.$invalid && this.$v.from.$dirty) || (this.$v.to.$invalid && typeof this.to !== 'undefined'))));
      } else {
        return false
      }
    },
    ageInvalid() {
      if (this.selectDateType === 'age') {
        return !!((this.$v.minAge.$invalid || this.$v.maxAge.$invalid) && (((this.$v.minAge.$dirty && this.minAge !== '') || (this.$v.maxAge.$dirty && this.maxAge !== ''))) || parseInt(this.minAge) > parseInt(this.maxAge));
      } else {
        return false
      }
    },


    setLanguage() {
      if (this.$i18n.locale === 'ru') {
        return this.ru
      } else if (this.$i18n.locale === 'en') {
        return this.en
      } else if (this.$i18n.locale === 'ua') {
        return this.uk
      }
    },

    needShowPreview() {
      if (this.selectDateType === 'birthdayDate' && this.birthdayDate) {
        return {
          canShow: true,
          date: moment(this.birthdayDate).format('YYYY') + this.$t('birthday_date.year_title')
        }
      } else if (this.selectDateType === 'interval' && this.from && this.to) {
        return {
          canShow: true,
          date: this.$t('birthday_date.interval') + this.from.substr(0, 4) + ' - ' + this.to.substr(0, 4)
        }
      } else if (this.selectDateType === 'age' && this.minAge && this.maxAge) {
        return {
          canShow: true,
          date: this.$t('birthday_date.title_age') + this.minAge + ' - ' + this.maxAge + this.$t('birthday_date.age_title')
        }
      } else {
        return {
          canShow: false,
        }
      }
    },


    minDate() {
      // return moment().subtract(100, 'year').toDate()
      return moment(moment().subtract(100, 'year').toDate()).format('YYYY-MM-DD')
    },
    maxDate() {
      // return moment().toDate()
      return moment(moment().toDate()).format('YYYY-MM-DD')
    },



    intervalLabel() {
      return this.$t('birthday_date.expected_value_from') + (moment().year() - 100) + this.$t('birthday_date.expected_value_to') + moment().year();
    },
    ageLabel() {
      return this.$t('birthday_date.expected_value_from') + 1 + this.$t('birthday_date.expected_value_to') + 100;
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).format('YYYY MMMM Do ,');
    },
    disabledRange(date) {
      return date < moment().subtract(100, 'year').toDate()
          || moment().toDate() <= date;
    },
    disableSearchButt() {
      if (this.selectDateType === 'age' ){
        if ((this.$v.minAge.$invalid || this.$v.maxAge.$invalid) && (((this.$v.minAge.$dirty && this.minAge !== '' ) || (this.$v.maxAge.$dirty && this.maxAge !== ''))) || parseInt(this.minAge) > parseInt(this.maxAge)){
          return;
        }
      } else if (this.selectDateType === 'interval'){
        if (((this.$v.from.$invalid || this.$v.to.$invalid)) || ((this.$v.from.$invalid && typeof this.from !== 'undefined') || (this.$v.to.$invalid && typeof this.to !== 'undefined'))){
          return;
        }
      }  else if (this.selectDateType === 'birthdayDate'){
        if (this.$v.birthdayDate.$invalid && this.birthdayDate !== ''){
          return;
        }
      } else {
        this.setDate()
      }
    },


    setDate() {
      // this.checked = true;
      let date = [];
      if (this.selectDateType === 'birthdayDate' && this.birthdayDate !== '' && typeof this.birthdayDate !== 'undefined' && !this.$v.birthdayDate.$invalid) {
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'match_query',
                value: moment(this.birthdayDate).format('YYYY-MM-DD')
              }
            ]
          }
        ]
      } else if (this.selectDateType === 'interval' && this.from !== '' && typeof this.from !== 'undefined' && this.to !== '' && typeof this.to !== 'undefined' && !this.$v.from.$invalid && !this.$v.to.$invalid && parseInt(this.from) <= parseInt(this.to)) {
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_from',
                value: moment(this.from).format('YYYY-MM-DD'),
              }
            ]
          },
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_to',
                value: moment(this.to).endOf("year").format('YYYY-MM-DD'),
              }
            ]
          },
        ]
      } else if (this.selectDateType === 'age' && this.minAge !== '' && typeof this.minAge !== 'undefined' && this.maxAge !== '' && typeof this.maxAge !== 'undefined' && !this.$v.minAge.$invalid && !this.$v.maxAge.$invalid && this.minAge <= this.maxAge ) {
        let minAge = moment().year() - this.minAge
        let maxAge = moment().year() - this.maxAge
        minAge = minAge.toString()
        maxAge = maxAge.toString()
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_from',
                value: moment(maxAge).format('YYYY-MM-DD'),
              }
            ]
          },
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_to',
                value: moment(minAge).endOf("year").format('YYYY-MM-DD'),
              }
            ]
          },
        ]
      }
      this.$emit('filters', date);
    },


    clearForm() {
      this.birthdayDate = undefined
      this.from = undefined
      this.to = undefined
      this.minAge = undefined
      this.maxAge = undefined
      this.selectDateType = 'birthdayDate';

    },
    radioInput(val) {
      
      let date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'match_query',
                value: null
              }
            ]
          }
        ];
      if (val === 'birthdayDate' && this.birthdayDate !== '' && typeof this.birthdayDate !== 'undefined') {
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'match_query',
                value: moment(this.birthdayDate).format('YYYY-MM-DD')
              }
            ]
          }
        ]
      } else if (val === 'interval' && this.from !== '' && typeof this.from !== 'undefined' && this.to !== '' && typeof this.to !== 'undefined') {
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_from',
                value: moment(this.from).format('YYYY-MM-DD'),
              }
            ]
          },
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_to',
                value: moment(this.to).format('YYYY-MM-DD'),
              }
            ]
          },
        ]
      } else if (val === 'age' && this.minAge !== '' && typeof this.minAge !== 'undefined' && this.maxAge !== '' && typeof this.maxAge !== 'undefined') {
        let minAge = moment().year() - this.minAge
        let maxAge = moment().year() - this.maxAge
        minAge = minAge.toString()
        maxAge = maxAge.toString()
        date = [
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_to',
                value: moment(maxAge).format('YYYY-MM-DD'),
              }
            ]
          },
          {
            optional: false,
            fields: ['birthdate'],
            conditions: [
              {
                operation: 'range_from',
                value: moment(minAge).format('YYYY-MM-DD'),
              }
            ]
          },
        ]
      }
      this.$emit('filters', date);
    },
    setPayload(payload) {
      if(payload[0].conditions[0].operation === 'match_query') {
        this.birthdayDate = payload[0].conditions[0].value
      } else {
        this.selectDateType = 'interval';
        this.radioInput('interval');
        for(const filter of payload) {
          if(filter.conditions[0].operation === 'range_from') {
            const from = moment(filter.conditions[0].value, 'YYYY-MM-DD')
            this.from = from.format('YYYY');
            this.maxAge = moment().diff(from, 'years')
          } else if(filter.conditions[0].operation === 'range_to') {
            const to = moment(filter.conditions[0].value, 'YYYY-MM-DD');
            this.to = to.format('YYYY');
            this.minAge = moment().diff(to, 'years')
          }
        }
      }
    },
    naturalMountAction() {
      return false;
    }
  },
  mounted() {
    this.$emit('invalid', this.$v.$invalid)
    this.radioInput('birthdayDate');
    this.$store.dispatch('nameSearchAdditionalSettings/deleteDate')
  }
}
</script>

<style>
.datePicker input{
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  height: 33px;
  padding-left: 12px;
}

.form-block {
  display: flex;
}
.form-block-left {
  width: calc(100% - 60px);
}
.form-block-right {
  width: 60px;
}
.delete_on_birthday {
  padding-top: 35px;
}

.label-birthbay-field {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-left: 12px;
  min-width: 7rem
}
@media (max-width: 575px) {
  .label-birthbay-field {
    min-width: 5rem;
    margin-left: 5px;
  }
  .form-block-left {
    width: calc(100% - 40px);
  }
  .form-block-left .col-sm-4 {
    margin-bottom: 5px;
  }
  .form-block-right {
    width: 40px;
  }
  .form-block-right button.btn {
    width: 30px;
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
    text-align: center;
    height: 35px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .card.full-text-block .card-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>