import { Result } from "../models/result";
import { isUndefined } from "underscore";
export default {
    methods: {
        async rewriteKey(key, searchResults) {
            const res = await Result.getResultByKey(key)
            if(!res && searchResults[key] || isUndefined(res) && searchResults[key]) {
                let tempResult = searchResults[key]
                tempResult.results = searchResults[key].results.map(result => {
                    return Object.assign(result, {search_results_count:  result.search_results && !isUndefined(result.search_results) && result.search_results.length ? result.search_results.length : 0})
                })
                const payload = {
                    out: tempResult
                }
                await this.$store.dispatch('contacts/updateRewriteResult', payload)
                const writtenRes = await Result.createResult(key, tempResult);
            }
        },
        async rewriteResults(user) {
            const localSearches = localStorage.getItem('searches');
            const localRes = localStorage.getItem('searchResults');
            const keys = localSearches ? JSON.parse(localSearches)[user] : []
            const searchResults = localRes ? JSON.parse(localRes): {};
            for(const item of keys) {
                await this.rewriteKey(item.keyVal, searchResults)
                if(!isUndefined(item.children) && item.children.length) {
                    for(const child of item.children) {
                        await this.rewriteKey(child.keyVal, searchResults)
                    }
                }
            }
        }
    },
}

