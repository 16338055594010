<template>
    <div>
        {{ i18n.t('result.new_search') }}: <a href="#" @click.prevent="setMenuParam('car_plate_num')">{{ i18n.t('params.car_plate_num') }}</a>
    </div>
</template>

<script>
import toastMixin from '@/mixins/toastMixin';
export default {
    name: 'CarPlateNumSearch',
    mixins: [toastMixin],
};
</script>

<style scoped>

</style>