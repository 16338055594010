<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="search">
                <div class="form-group">
                    <input type="text" v-model="value" class="form-control" :placeholder="$t('fullText.simple_placeholder')" @input="preparePayload">
                </div>
                <div class="border-checkbox-section form-group p-l-15">
                    <div class="border-checkbox-group border-checkbox-group-primary">
                        <input type="checkbox" class="border-checkbox" id="exclude_checkbox" v-model="exclude"
                            @change="preparePayload">
                        <label for="exclude_checkbox" class="border-checkbox-label">
                            {{ $t('fullText.exclude_words') }}
                        </label>
                    </div>
                </div>
                <div class="form-group p-l-15">
                    <input type="text" class="form-control" v-model="excludedValue" @input="preparePayload"
                        :disabled="!exclude">
                </div>

                <div class="d-grid" v-if="!disallowFreeSpace">
                    <b-button  variant="primary" class="btn" type="submit" :disabled="$v.$invalid">{{$t("search.search") }}</b-button>
                </div>
                <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
            </form>
        </div>
    </div>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import RuleValidation from "@/components/RuleValidation.vue";
import { mapGetters } from "vuex";
export default {
    mixins: [validationMixin],
    components: { RuleValidation },
    data() {
        return {
            payload: {
                filters: []
            },
            value: null,
            validationRules: {
                required
            },
            exclude: false,
            excludedValue: null,
            isLoading: false
        }
    },
    validations() {
        return {
            value: this.validationRules,
            excludedValue: {
                requiredIfExcluded: requiredIf(this.exclude)
            }
        }
    },
    computed: {
        ...mapGetters("ui", ['disallowFreeSpace']),
    },
    methods: {
        preparePayload() {
            const filters = this.value.trim().split(' ').map(word => {
                return {
                    optional: false,
                    conditions: [
                        {
                            operation: 'match_query',
                            value: word
                        }
                    ]
                }
            });

            if (this.exclude && this.excludedValue.length) {
                const excludedFilters = this.excludedValue.trim().split(' ').map(word => {
                    return {
                        optional: false,
                        conditions: [
                            {
                                operation: 'not_match_query',
                                value: word
                            }
                        ]
                    }
                })
                this.payload.filters = filters.concat(excludedFilters);
            } else {
                this.payload.filters = filters;
            }

            this.$v.value.$touch();
            this.$v.excludedValue.$touch();
        },
        search() {
            let payload = JSON.parse(JSON.stringify(this.payload));
            this.$store.dispatch('contacts/fullSearch', { payload, type: 'simple_search' }).finally(() => {
                this.$store.dispatch('ui/getFreeSpaceInfo');
            })
        },
    },
    mounted() {
        this.$store.dispatch('ui/getFreeSpaceInfo');
    }

}
</script>