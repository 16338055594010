<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="form-group">
        <input type="text" id="inputSearch"  autocomplete="off"
               v-model="payload.value" :placeholder="`${$t('placeholders.enter')} ${$t('placeholders.'+ payload.param)}`" class="form-control" />
      </div>
      <OptionsSearch param="itn" @push="setPayload" @invalid="setInvalid" />
      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button class="btn" variant="primary" type="submit" :disabled="$v.payload.$invalid">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>

    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import openResult from "@/mixins/openResult";
import OptionsSearch from "@/components/search/optionsSearch";
import {maxLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  mixins: [validationMixin, openResult],
  components:{ OptionsSearch },
  name: "carVinForm",
  data() {
    return {
      payload: {
        param: 'car_vin',
        value:''
      },
      modalDialog: false,
      showBtn: true,
      invalid: true
    }
  },
  validations() {
    return {
      payload: {
        value: {
          required,
          maxLength: maxLength(17)
        },
        optionsValid() {
          return !this.invalid
        }
      }
    }
  },
  computed:{
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters("auth", ["user"]),
  },
  methods:{
    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
    beforeSearch() {
      let value = this.payload.value;
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    addSearch() {
      this.$store.dispatch("contacts/search", {
        payload: Object.assign({}, this.payload),
      }).then(() => {
        this.$store.dispatch('ui/getFreeSpaceInfo')
      });
      this.payload.value = null;
      this.val = "";
    },
  },

}
</script>

<style scoped>

</style>