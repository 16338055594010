import { required, email } from "vuelidate/lib/validators";
import FullTextBlockAddress from "./FullTextBlockAddress.vue";
import fullTextBirthDate from "@/components/fullText/fullTextBirthDate";
import FullTextMap from "@/components/FullTextMap";
export default{
    personal: [
        {name: "name",},
        {name: "birthplace"},
        {name: "birthdate", component: fullTextBirthDate, compatibility: null},
        {name: "job"},
        {name: "company"},
        {name: "military"},
        {name: "address", addAddition: null},
        {name: "education"},
        {name: "skills"},
    ],
    documents: [
        {name: "passport"},
        {name: "passport_details"},
        {name: "itn"},
        {name: "driver_license"},
        {name: "driver_details"},
        {name: "birth_certificate"},
        {name: "insurance_id"},
    ],
    geo: [
        {name: "geo_search", component: FullTextMap, compatibility: null},
    ],
    cellphone: [
        {name: "isdn"},
        {name: "imei"},
        {name: "imsi"},
        {name: "mcc"},
        {name: "mnc"},
        {name: "mcc_mnc"},
        {name: "msc"},
        {name: "msin"},
        {name: "subscriber_status"},
        {name: "status"},
        {name: "last_registration_time"},
    ],
    car: [
        {name: "car_model"},
        {name: "car_plate_num"},
        {name: "car_manufacture_year"},
        {name: "car_color"},
        {name: "car_engine_number"},
        {name: "car_engine_capacity"},
        {name: "car_engine_power"},
        {name: "car_reg_certificate"},
        {name: "car_passport"},
        {name: "car_vin"},
        {name: "status"},
    ],
    social_networks: [
        {name: "email"},
        {name: "username"},
        {name: "group"},
        {name: "about"},
        {name: "web_message"},
        {name: "review"},
        {name: "event"},
        {name: "social_url"},
        {name: "telegram_id"},
    ],
    allData: [
        {name: "allData", compatibility: null}
    ]
    
}