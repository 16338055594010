import _, { isUndefined } from "underscore";
export default function (obj, item) {
    let nameArr = [];
    let addrArr = [];
    let countResult = 0;
    let countSources = obj.length
    if(obj.length && obj[0].param === 'image'){
        item.image = obj[0].value
    }
    let allObj = obj.sort((a, b) => a.source > b.source ? 1 : -1).sort((a, b) => a.level > b.level ? 1 : -1);
    for(let item of allObj ){
        if (typeof item.search_results !== 'undefined' && item.search_results){
            countResult += item.search_results.length;
        }
    }
    item.countResult = countResult;

    if(isUndefined(item.initial) || !item.cost.length) {
        let temp = []
        let totalCost = 0;
        obj.forEach(result =>{
            if (typeof result !== "undefined" && !result.error  ){
                totalCost += result.cost;
                temp.push({
                    param: result.param,
                    level: result.level,
                    source: result.source,
                    source_locales: result.source_locales,
                    cost: result.cost,
                    icon: result.icon,
                    local: result.local,
                    value: result.value
                })
            }
        })
        temp = temp.sort(function (a, b){ return b.cost - a.cost })
        item.totalCost = totalCost;
        item.cost = temp;
    }

    if (obj.length && item.param !== 'image' ){
        if(obj.some(item => !!item.search_results)){
            let priority = obj.filter(item => {
                return ['Telegram', 'Viber', 'Skype'].includes(item.source)
            });
            if(priority.length) {
                obj = priority;
            }
            for (let objItem of obj) {
                if(objItem.search_results) {
                    let nameFilter = objItem.search_results[0].data.filter((el) => {
                        return ['name', 'first_name'].includes(el.param);
                    })
                    nameArr.push(...nameFilter);

                    // let addrFilter = [];
                    //     = objItem.search_results[0].data.filter((el) => {
                    //     return ['address'].includes(el.param);
                    // })
                    //

                    objItem.search_results.forEach(searchResult=> {
                        searchResult.data.forEach(data=>{
                           if (data.param === 'address'){
                               addrArr.push(data.value)
                           }
                        })
                    })



                    // addrArr.push(...addrFilter);

                    // let address = search_results
                }
            }
        }
    } else if (obj.length && item.param === 'image'){
        let tempArr = [];
        let photos = 0
        obj.forEach(item => {
            if(Array.isArray(item.search_results)) {
                item.search_results.forEach(innerItem => {
                    innerItem.photos.forEach(photo => {
                        photos++
                        let name;
                        if (innerItem.data.length > 0 && Array.isArray(innerItem.data)) {
                            innerItem.data.forEach(item => {
                                if (item.param && item.param === "name") {
                                    name = item.value;
                                }
                            })
                        } else {
                            name = 'Нет имени'
                        }
                        if (name !== 'Нет имени'){
                            tempArr.push({
                                name : name,
                                photos: photo,
                            })
                        }
                    })
                })
            }
        })
        item.countResult = photos;

        tempArr.sort(function (a, b){
            return b.photos.score - a.photos.score
        })
        nameArr = [];
        if(tempArr.length) {
            let item = {
                value: tempArr[0].name
            }
            nameArr.push(item)
        }
    }

    if(nameArr.length) {
        item.name = nameArr[0].value || "No name"
    }  else {
        item.name = isUndefined(item.name) || item.name.length === 0 ? 'No name' : item.name
    }
    if (addrArr.length){
        if (obj[0].source === 'TelegramGeo' && obj[0].search_results.length && typeof obj[0].search_results[0] !== 'undefined' && typeof obj[0].search_results[0].locations !== 'undefined' && typeof obj[0].search_results[0].locations[0].address !== 'undefined'){
            item.address = obj[0].search_results[0].locations[0].address
        } else {
            item.address = addrArr[0]
        }
    }
    item.countSources = countSources
    if(obj.length && obj[0].error) {
        item.error = obj[0].error
        item.errorName = obj[0].errorName
    } else {
        item.error = false
    }
    if (obj.length && typeof obj[0].image_url !== "undefined"){
        item.image_url = obj[0].image_url;
        item.name = "many faces"
    }
    if (obj.length && typeof obj[0].face_select !=='undefined' && typeof obj[0].face_select.is_multiple !== 'undefined'){
        item.is_multiple = obj[0].face_select.is_multiple;
    }
    if (obj.length && typeof obj[0].face_select !== 'undefined'){
        item.face_select = obj[0].face_select;
    }

    if (typeof item.face_select === 'undefined' && !item.error){
        let resultWithImage;
        if (allObj.length){
            let tempArr = [];
            allObj.map( source =>{ source.search_results && source.search_results.map( searchResult =>{ tempArr.push(searchResult) }) })
            resultWithImage = tempArr.find(searchResultItem => searchResultItem.photos.length > 0)
            const initialValue = 0;
            const sumWithInitial = tempArr.reduce(
                (accumulator, currentValue) => {
                    const currentPhotosCount = _.isUndefined(currentValue.photos) ? 0 : currentValue.photos.length
                    return accumulator + currentPhotosCount
                },
                initialValue,
            );
            item.photosCount = sumWithInitial;
        }
        if (typeof resultWithImage !== 'undefined'){
            item.imageFromResults = resultWithImage.photos[0].image
        }
    }

    item.initial = true;
}

