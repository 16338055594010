<template>
  <div class="grabbers">
    <div  style="padding: 1rem; border-left: 1px solid rgb(222, 226, 230); border-right: 1px solid rgb(222, 226, 230)">
      <div >{{count}}</div>
    </div>
    <div class="card" style="padding: 1rem; margin-bottom: 0">
      <b-table
          style="max-height: calc(100vh - 25rem)"
          sticky-header
          head-variant="light"
          :items="monitors"
          :fields="columns"
          :empty-text="$t('billing.empty_text')"
          responsive
          show-empty
          small
          :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(grabber_id)="row">
          <div> {{ row.value }}</div>
        </template>

        <template #cell(1)="row">
          <div style="display: flex; flex-direction: column;">
            <i class="feather icon-check text-success" style="display: flex; justify-content: center" v-if="row.item.online_status" ></i>
            <i class="feather icon-x text-danger" style="display: flex; justify-content: center" v-if="!row.item.online_status"></i>
          </div>
        </template>


        <template #cell(stats.device_type)="row">
          <div v-if="typeof  row.item.stats !== 'undefined' && typeof row.item.stats.device_type !=='undefined'">
            <i class="feather icon-smartphone" v-if="row.value === 1"></i>
            <i class="feather icon-monitor" v-else></i>
          </div>
        </template>


        <template #cell(online_status)="row">
          <i class="feather icon-check text-success" v-if="row.value"></i>
          <i class="feather icon-x text-danger" v-if="!row.value"></i>
        </template>

        <template #cell(stats.usb_connected)="row">
          <i class="feather icon-check text-success" v-if="row.value"></i>
        </template>

        <template #cell(stats.battery_percent)="row">
          <div style="display: flex; flex-direction: column" v-if="typeof  row.item.stats !== 'undefined' && row.item.stats.device_type === 1">
            <i class="feather icon-battery text-success" v-if="row.value > 60"></i>
            <i class="feather icon-battery text-warning" v-if="row.value <= 60 && row.value >= 25"></i>
            <i class="feather icon-battery text-danger" v-if="row.value < 25"></i>
            <small>{{row.value}}%</small>
          </div>
        </template>


        <template #cell(stats.dest_apps)="row">
          <div v-for="app in row.value">
            <small>{{app}}</small>
          </div>
        </template>

        <template #cell(2)="row">
          <div>{{row.item.isdn_list}}</div>
        </template>


      </b-table>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "monitoringHubs",
  props:['isLoading'],
  data() {
    return {
      disabled: true,
    };
  },
  computed: {
    ...mapGetters('grabbersStats', ['monitors']),
    count(){
      let phones = 0;
      let emulators = 0;
      let str = '';
      this.monitors.forEach(grabber=> {
        if (typeof grabber.stats !== 'undefined' &&  grabber.stats.device_type === 1){
          phones++
        } else if(typeof grabber.stats !== 'undefined') {
          emulators++
        }
      })
      str =  this.$t('stats.grabbersConnected')+ ': '+  this.monitors.length + ' ('+ this.$t('stats.phones')+ ' - '+phones+', '+ this.$t('stats.emulators')+' - '+emulators+')'
      return  str
    },
    columns(){
      return [
        {
          key: 'monitor_id',
          label: this.$t('stats.id'),
        },
        {
          key: '1',
          label: this.$t('stats.online'),
        },
        {
          key: 'stats.device_type',
          label: this.$t('stats.device_type'),
        },
        {
          key: 'online_status',
          label: this.$t('stats.internet'),
        },
        {
          key: 'stats.usb_connected',
          label: this.$t('stats.USB'),
        },
        {
          key: 'stats.battery_percent',
          label: this.$t('stats.battery'),
        },
        {
          key: 'stats.dest_apps',
          label: this.$t('stats.target_applications'),
        },
        {
          key: '2',
          label: this.$t('stats.target_count'),
        },
      ]
    },
  }
}
</script>

<style lang="scss">
.grabbers .b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
  vertical-align: middle;
  text-align: center;
}
.grabbers .b-table-sticky-header > .table.b-table > thead > tr > th[role=columnheader] > div {
  white-space: break-spaces !important;
}
.grabbers .thead-light th {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.grabbers tbody td {
  vertical-align: middle;
  text-align: center;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.countyFlag{
  .county{
    border: none;
    .vti__input{
      display: none !important;
    }
    .vti__dropdown-arrow{
      display: none;
    }
    .vti__dropdown{
      cursor: auto;
    }
    .vti__dropdown:hover{
      background-color: transparent !important;
    }
  }
  .county:focus-within{
    box-shadow: none;

  }
}
</style>

<style scoped lang="scss">
.feather{
  font-size: 1.8em;
}
.small{
  font-size: 1em;
}
.bordered{
  border: solid 1px #ccc;
}


</style>